<div
  class="widget-bearbeiten-container"
  [@openCloseOptions]="optionsOpen ? 'optionsOpen' : 'optionsClosed'"
  (@openCloseOptions.done)="onOptionsAnimationDone($event)"
>
  <div class="icon-container" [ngClass]="{ active: optionsOpen }" (click)="toggleOptions()">
    <i
      class="fa icon"
      [ngClass]="{ active: optionsOpen, 'fa-angle-up': !optionsOpen, 'fa-angle-down': optionsOpen }"
      aria-hidden="true"
    ></i>
  </div>
  <div class="options-content text-center" *ngIf="showOptions">
    <div class="options-text">{{ textPraefixKey | translate }}</div>
    <div class="pretty p-default p-round" *ngFor="let tage of AnzahlTage">
      <input
        [name]="radioButtonName + '_' + tage"
        type="radio"
        [(ngModel)]="anzahlTageModel"
        (ngModelChange)="emitAnzahlTageChange($event)"
        [value]="tage"
      />
      <div class="state p-primary-o">
        <label>{{ tage }}</label>
      </div>
    </div>

    <div class="d-inline-block">{{ textSuffixKey | translate }}</div>
  </div>
</div>
