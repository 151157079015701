import { AnimationEvent } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { BasispreisTiergruppe, WINDOW } from '@core/constants';
import { PortalThemeColors } from '@core/theme/theme.interfaces';
import { UserPropertiesBereiche } from '@core/user/user.enums';
import { CurrentUser } from '@core/user/user.interfaces';
import { UserService } from '@core/user/user.service';
import { ThemeService } from '@iq-angular-libs/portal';
import { TranslateService } from '@ngx-translate/core';
import { find } from 'lodash-es';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BasispreisBase } from '../share/basispreis-base/basispreis-base';
import { getWidgetBearbeitenContainerAnimation } from '../share/widgets';
import { BasispreisData } from '../share/widgets.interfaces';
import { WidgetsService } from '../share/widgets.service';

interface Tiergruppe {
  displayName: string;
  value: BasispreisTiergruppe;
}

@Component({
  selector: 'iq-basispreis-rinder-widget',
  templateUrl: './basispreis-rinder-widget.component.html',
  styleUrls: ['./basispreis-rinder-widget.component.scss'],
  animations: [getWidgetBearbeitenContainerAnimation(40)]
})
export class BasispreisRinderWidgetComponent extends BasispreisBase implements OnInit {
  /**
   * Specifies whether the options should be displayed.
   */
  showOptions = false;

  /**
   * Used for the animation of the options area.
   */
  optionsOpen = false;

  /**
   * The animal groups available for selection.
   */
  tiergruppen: Tiergruppe[] = [
    { displayName: this.translate.instant('WIDGETS.BASISPREIS.JUNGBULLEN'), value: BasispreisTiergruppe.Jungbullen },
    { displayName: this.translate.instant('WIDGETS.BASISPREIS.KUEHE'), value: BasispreisTiergruppe.Kuehe }
  ];

  /**
   * The selected group of animals.
   */
  selectedTiergruppe: Tiergruppe = this.tiergruppen[0];

  /**
   * The current user.
   */
  private currentUser: CurrentUser;

  /**
   * Constructor
   * @param window The injected window object.
   * @param translate {@link TranslateService}
   * @param themeService {@link ThemeService}
   * @param widgetsService {@link WidgetsService}
   */
  constructor(
    @Inject(WINDOW) window: Window,
      translate: TranslateService,
      themeService: ThemeService<PortalThemeColors>,
    protected widgetsService: WidgetsService,
    private userService: UserService
  ) {
    super(window, translate, themeService, widgetsService);
    this.lineChartOptions.chartId = 'basispreis-rinder-chart';
  }

  /**
   * Triggers the fetching of the data
   */
  ngOnInit() {
    super.ngOnInit();

    this.userService
      .getCurrentUser()
      .pipe(take(1))
      .subscribe((currentUser: CurrentUser) => {
        if (currentUser && currentUser.userProperties.widgets) {
          this.currentUser = currentUser;

          const foundGruppe = find(this.tiergruppen, (tiergruppe: Tiergruppe) => tiergruppe.value === currentUser.userProperties.widgets.RinderpreisWidgetTiergruppe);

          if (foundGruppe) {
            this.selectedTiergruppe = foundGruppe;
          }
        }

        this.getData();
      });
  }

  /**
   * Called as soon as the animation of the options is finished.
   * Sets the {@link showOptions} accordingly whether the options are now open or closed.
   * @param event AnimationEvent
   */
  onOptionsAnimationDone(event: AnimationEvent) {
    if (event.toState === 'optionsClosed') {
      this.showOptions = false;
    } else {
      this.showOptions = true;
    }
  }

  /**
   * Opens and closes the option area of the widget and shows the option elements.
   */
  toggleOptions() {
    this.optionsOpen = !this.optionsOpen;
  }

  /**
   * Called as soon as another animal group is selected. Reloads the data of the widget.
   * @param gruppe
   */
  tiergruppeChanged() {
    if (this.currentUser) {
      this.currentUser.userProperties.widgets.RinderpreisWidgetTiergruppe = this.selectedTiergruppe.value;
      this.userService
        .postUserproperties(UserPropertiesBereiche.WIDGETS, this.currentUser.userProperties)
        .pipe(take(1))
        .subscribe();
    }

    this.getData();
  }

  /**
   * Retrieves the base price data for the sows.
   */
  protected getBasispreisData(): Observable<BasispreisData> {
    switch (this.selectedTiergruppe.value) {
    case BasispreisTiergruppe.Jungbullen:
      return this.widgetsService.getBasispreisJungbullenWidgetData();
      break;
    case BasispreisTiergruppe.Kuehe:
      return this.widgetsService.getBasispreisKueheWidgetData();
      break;
    default:
      this.selectedTiergruppe = this.tiergruppen[0];
      return this.widgetsService.getBasispreisJungbullenWidgetData();
    }
  }
}
