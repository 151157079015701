import { Pipe, PipeTransform } from '@angular/core';
import { Schlachtbetrieb, Schlachtbetriebe } from './filter/filter.interfaces';
import { filter, orderBy } from 'lodash-es';
import { orderCaseUnsensitiv } from '@iq-angular-libs/portal';

@Pipe({
  name: 'schlachtbetriebname'
})
export class SchlachtbetriebnamePipe implements PipeTransform {
  transform(value: string, schlachtbetriebe?: Schlachtbetriebe): string {
    let name = '-';
    if (schlachtbetriebe && schlachtbetriebe.length > 0) {
      name = this.getSchlachtbetriebName(value, schlachtbetriebe);
    }
    return name;
  }

  private getSchlachtbetriebName(filterValue: string, schlachtbetriebe: Schlachtbetriebe): string {
    if (!filterValue) {
      const sortedSchlachtbetriebe = orderBy<Schlachtbetrieb>(
        schlachtbetriebe,
        [orderCaseUnsensitiv('anzahlTiere')],
        ['desc']
      );
      return sortedSchlachtbetriebe[0].displayName;
    }
    const foundSchlachtbetrieb = filter<Schlachtbetrieb>(schlachtbetriebe, { id: filterValue });
    if (foundSchlachtbetrieb.length > 0) {
      return foundSchlachtbetrieb[0].displayName;
    } else {
      return '-';
    }
  }
}
