import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Checks if the {@link FormControls} 'newPassword' and 'confirmPassword' are identical. If not, a error
 * object {@link ValidationErrors} with 'confirmPassword' property will be returned.
 * @param control The {@link FormGroup}, which must have the {@link FormControls} 'newPassword' and 'confirmPassword'.
 */
export const confirmPasswordValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const newPassword = control.get('newPassword');
  const confirmPassword = control.get('confirmPassword');

  return newPassword && confirmPassword && newPassword.value !== confirmPassword.value
    ? { confirmPassword: true }
    : null;
};
