<div class="modal-header">
  <h4 class="modal-title float-left">{{ 'NEUES_BACKEND.NEUE_VERSION_VERFUEGBAR' | translate }}</h4>
</div>
<div class="modal-body">
  <p>{{ 'NEUES_BACKEND.NEUE_VERSION_HINWEIS' | translate }}</p>
</div>
<div class="modal-footer">
  <div class="ml-auto">
    <button type="button" class="btn btn-primary" (click)="reloadPage()">
      <i class="fa fa-refresh mr-2" aria-hidden="true"></i> {{ 'NEUES_BACKEND.NEUE_VERSION_LADEN' | translate }}
    </button>
  </div>
</div>
