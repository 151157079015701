import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FilterComponent } from '../filter-component.interfaces';
import { SelectedFilterItemsChangedEvent } from '../filter-component.events';
import { MultiselectChangedEvent, MultiselectClosedEvent, MultiselectSettings } from '@iq-angular-libs/portal';
import { Subject } from 'rxjs';
import { FilterService } from '@share/filter/filter.service';
import { GlobalFilterService } from '@core/global-filter/global-filter.service';
import { takeUntil } from 'rxjs/operators';
import { FilterBereiche } from '@share/filter/filter-bereiche';
import { EberRassen } from '@share/filter/filter.interfaces';

@Component({
  selector: 'iq-eber-rassen-filter',
  templateUrl: './eber-rassen-filter.component.html'
})
export class EberRassenFilterComponent implements OnInit, OnDestroy, FilterComponent {
  /**
   * The selected boar breeds
   */
  @Input()
  selectedItems: EberRassen;

  /**
   * The filter section.
   */
  @Input()
  filterBereich: FilterBereiche;

  /**
   * Indicates whether the filter is disabled or not.
   */
  @Input()
  filterDisabled: boolean;

  /**
   * Indicator whether an event should be emitted with each selection.
   */
  @Input()
  updateOnChange: boolean;

  /**
   * Event emitter that fires an event when the filter has changed.
   */
  @Output()
  selectedItemsChanged: EventEmitter<SelectedFilterItemsChangedEvent>;

  /**
   * The settings of the (multiselect component){@link MultiselectButtonComponent}
   */
  settings: MultiselectSettings;

  /**
   * All existing boar breeds
   */
  eberRassen: EberRassen;

  /**
   * The active boar breeds
   */
  activeEberRassen: EberRassen;

  /**
   * Unsubscribe-Stream.
   * Is used in (ngOnDestroy){@link EberRassenFilterComponent#ngOnDestroy} to filter all
   * to close open subscriptions.
   */
  private unsubscribe$: Subject<void>;

  /**
   * Constructor
   * @param filterService filter service
   * @param globalFilterService globaler filter service
   */
  constructor(private filterService: FilterService, private globalFilterService: GlobalFilterService) {
    this.eberRassen = [];
    this.activeEberRassen = [];

    this.settings = {
      id: 'eberrassen-filter-multiselect',
      sortProp: 'displayName',
      enableDisplayFirstSelectedItem: true,
      enableDisplaySelectedShortnames: false,
      buttonClasses: 'btn btn-secondary iq-select-button iq-btn-filter',
      buttonMaxWidth: 250,
      dropdownAppendTo: 'body'
    };

    this.selectedItemsChanged = new EventEmitter<SelectedFilterItemsChangedEvent>();
    this.unsubscribe$ = new Subject<void>();
  }

  /**
   * Loads all and all active boar breeds at the beginning.
   * Registers with GlobalFilterService to be informed about changes of the comercial units.
   */
  ngOnInit() {
    this.getEberRassen();
    this.getActiveEberRassen();
    this.subscribeOnGlobalFilterChangedEvents();
  }

  /**
   * Closes the FilterComponentChanged event emitter.
   * Fires an event of the unsubscribe stream and closes it afterwards.
   */
  ngOnDestroy(): void {
    this.selectedItemsChanged.complete();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Is called as soon as the multiselect selection has changed and the dropdown has closed.
   * @param event {@link MultiselectClosedEvent}
   */
  onMultiselectClosed(event: MultiselectClosedEvent) {
    if (!this.updateOnChange) {
      const filterComponentChangedEvent = new SelectedFilterItemsChangedEvent(event.selectedItems);
      this.selectedItemsChanged.emit(filterComponentChangedEvent);
    }
  }

  /**
   * Is called as soon as the multiselect selection has changed.
   * @param event {@link MultiselectChangedEvent}
   */
  onMultiselectChanged(event: MultiselectChangedEvent) {
    if (this.updateOnChange) {
      const selectedFilterItemsChangedEvent = new SelectedFilterItemsChangedEvent(event.selectedItems);
      this.selectedItemsChanged.emit(selectedFilterItemsChangedEvent);
    }
  }

  /**
   * Queries all existing boar breeds of the user from the {@link FilterService}.
   */
  private getEberRassen(): void {
    this.filterService.getEberRassen(this.filterBereich).subscribe({
      next: (eberRassen: EberRassen) => {
        this.eberRassen = eberRassen;
      }
    });
  }

  /**
   * Queries all active boar breeds of the user from {@link FilterService}.
   */
  private getActiveEberRassen(): void {
    this.filterService.getActiveEberRassen(this.filterBereich).subscribe({
      next: (activeEberRassen: EberRassen) => {
        this.activeEberRassen = activeEberRassen;
      }
    });
  }

  /**
   * Registers to the comercial units changes stream of the (GlobalFilterServices){@link GlobalFilterService}
   * to reload the active boar breeds in case of changes.
   */
  private subscribeOnGlobalFilterChangedEvents(): void {
    this.globalFilterService
      .getBetriebsstaetteChangedObservable()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.getActiveEberRassen();
      });
  }
}
