import { DecimalPipe } from '@angular/common';
import {
  AfterContentChecked,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FORMAT } from '@core/constants';
import { PortalThemeColors } from '@core/theme/theme.interfaces';
import { ThemeService } from '@iq-angular-libs/portal';
import { TranslateService } from '@ngx-translate/core';
import { LegendItem } from '@share/chart-helper/chart.interfaces';
import { ceil, floor, forEach } from 'lodash-es';
import * as dayjs from 'dayjs';
import { Observable, Subject } from 'rxjs';
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { Widget, WidgetComponent } from '../startseite.interfaces';
import { WidgetSizeChangedEvent } from '../widgets.events';
import { BasispreisData, BasispreisDiagrammData, BasispreisDiagrammSeriesDataPoint } from '../widgets.interfaces';
import { WidgetsService } from '../widgets.service';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BasispreisBase implements OnInit, OnDestroy, AfterContentChecked, WidgetComponent {
  @Input()
  widget: Widget;

  /**
   * The base priceWidget-Conainer
   */
  @ViewChild('basispreisContainerDiv', { static: true })
  private basispreisContainerDiv: ElementRef;

  /**
   * The error message
   */
  errMessage: string;

  /**
   * Indicator whether data is loaded
   */
  dataLoading: boolean;

  /**
   * The base price data for the piglets
   */
  basispreisData: BasispreisData;

  /**
   * The converted chart data
   */
  convertedData: BasispreisDiagrammData;

  /**
   * The legends entries
   */
  legendItems: LegendItem[] = [];

  /**
   * The options for the diagram
   */
  lineChartOptions = {
    chartId: 'basispreis-' + Math.random(),
    multi: [],
    showXAxis: true,
    xAxisTicks: [],
    showYAxis: true,
    gradient: false,
    showLegend: false,
    legendTitle: '',
    showXAxisLabel: true,
    xAxisLabel: '',
    showYAxisLabel: true,
    yAxisLabel: '',
    yScaleMin: null,
    yScaleMax: null,
    xAxisTickFormatting: (d) => '',
    yAxisTickFormatting: (d) => this.decimalPipe.transform(d, '1.2-2'),
    colorScheme: null,
    seriesTooltipTemplate: TemplateRef,
    autoScale: true,
  };

  /**
   * The Date format to use in the DatePipe
   */
  dateFormat = FORMAT.DATE;

  /**
   * Unsubscribe Stream
   */
  unsubscribe$: Subject<void>;

  /**
   * The current container width of the diagram container
   */
  private currentContainerWidth: number;

  /**
   * DecimalPipe for formatting numbers
   */
  private decimalPipe: DecimalPipe;

  /**
   * Constructor
   * @param window The injected window object.
   * @param translate {@link TranslateService}
   * @param themeService {@link ThemeService}
   */
  constructor(
    private window: Window,
    protected translate: TranslateService,
    protected themeService: ThemeService<PortalThemeColors>,
    protected widgetsService: WidgetsService
  ) {
    this.convertedData = [];
    this.unsubscribe$ = new Subject<void>();
    this.decimalPipe = new DecimalPipe(this.translate.getDefaultLang());
    this.lineChartOptions.xAxisLabel = this.translate.instant('WIDGETS.BASISPREIS.INTERVALL');
    this.lineChartOptions.yAxisLabel = this.translate.instant('WIDGETS.BASISPREIS.BASISPREIS_EURO_PRO_KILOGRAMM_SG');
  }

  /**
   * Sets the domain of the color space (for the chart) and subscribed to changes of the ThemeService.
   */
  ngOnInit() {
    const themeColors = this.themeService.getActiveThemeColors();
    this.setXAxisTicksAndLabel();
    this.lineChartOptions.colorScheme = {
      domain: [
        themeColors.chartLighterBlue,
        themeColors.chartLightBlue,
        themeColors.chartOrange,
        themeColors.chartOrange,
      ],
    };

    this.widgetsService
      .getWidgetItemResizedObservable()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((event) => event.widget.id === this.widget.id)
      )
      .subscribe({
        next: (event: WidgetSizeChangedEvent) => {
          this.widget = event.widget;
          this.setXAxisTicksAndLabel();
        },
      });

    this.themeService
      .getThemeChangedObservable()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        const colors = this.themeService.getActiveThemeColors();
        this.lineChartOptions.colorScheme = {
          domain: [colors.chartLighterBlue, colors.chartLightBlue, colors.chartOrange, colors.chartOrange],
        };
        this.convertDiagrammData();
      });
  }

  /**
   * Checks if the container width has changed after Angular performed a ContentCheck.
   * If yes, perform a (refresh of the dashboard){@link DashboardComponent#refreshDashboad}.
   */
  ngAfterContentChecked() {
    if (this.basispreisContainerDiv && this.basispreisContainerDiv.nativeElement) {
      const divElement: HTMLDivElement = this.basispreisContainerDiv.nativeElement;

      if (this.currentContainerWidth !== divElement.clientWidth) {
        this.currentContainerWidth = divElement.clientWidth;
        this.resizeBasispreisChart();
      }
    }
  }

  /**
   * Fires an event of the unsubscribe stream and closes the stream afterwards.
   */
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Fetches the base price data from the BE.
   */
  protected getData() {
    this.errMessage = null;
    this.dataLoading = true;
    this.basispreisData = null;

    this.getBasispreisData()
      .pipe(
        finalize(() => {
          this.dataLoading = false;
        })
      )
      .subscribe({
        next: (data: BasispreisData) => {
          if (data) {
            this.basispreisData = data;
            this.convertDiagrammData();
            this.setMinMax();
          }
        },
        error: (errMessage) => {
          this.errMessage = 'FEHLER.DATEN_NICHT_GELADEN';
        },
      });
  }

  /**
   * Sets the X-axis ticks and the X-axis label
   */
  private setXAxisTicksAndLabel() {
    if (this.widget.cols === 2) {
      this.lineChartOptions.xAxisTicks = [
        this.translate.instant('WIDGETS.BASISPREIS.JAN'),
        this.translate.instant('WIDGETS.BASISPREIS.FEB'),
        this.translate.instant('WIDGETS.BASISPREIS.MAR'),
        this.translate.instant('WIDGETS.BASISPREIS.APR'),
        this.translate.instant('WIDGETS.BASISPREIS.MAI'),
        this.translate.instant('WIDGETS.BASISPREIS.JUNI'),
        this.translate.instant('WIDGETS.BASISPREIS.JULI'),
        this.translate.instant('WIDGETS.BASISPREIS.AUG'),
        this.translate.instant('WIDGETS.BASISPREIS.SEP'),
        this.translate.instant('WIDGETS.BASISPREIS.OKT'),
        this.translate.instant('WIDGETS.BASISPREIS.NOV'),
        this.translate.instant('WIDGETS.BASISPREIS.DEZ'),
      ];
      this.lineChartOptions.xAxisLabel = '';
    } else {
      this.lineChartOptions.xAxisTicks = [];
      this.lineChartOptions.xAxisLabel = this.translate.instant('WIDGETS.BASISPREIS.INTERVALL');
    }
  }

  /**
   * Resized the diagram after the size of the parent container has changed.
   */
  private resizeBasispreisChart() {
    this.lineChartOptions.multi = [...this.convertedData];
  }

  /**
   * Sets the min and max values for the Y axis.
   */
  private setMinMax() {
    let max;
    let min;
    this.convertedData.forEach((d) => {
      d.series.forEach((c) => {
        if (!max) {
          max = c.value;
          min = c.value;
        } else {
          if (c.value > max) {
            max = c.value;
          }
          if (c.value < min) {
            min = c.value;
          }
        }
      });
    });
    this.lineChartOptions.yScaleMin = floor(min, 1);
    this.lineChartOptions.yScaleMax = ceil(max, 1);
  }

  /**
   * Converts the base price data into a format suitable for the chart.
   */
  private convertDiagrammData() {
    const themeColors = this.themeService.getActiveThemeColors();
    const years = ['VorletztesJahr', 'LetztesJahr', 'DiesesJahr'];
    const tempDiagrammData: BasispreisDiagrammData = [];

    forEach(years, (year) => {
      if (this.basispreisData && this.basispreisData[year] && this.basispreisData[year].length > 0) {
        const currentYear = dayjs(this.basispreisData[year][0].Von).year();
        const currentYearString = currentYear.toString();
        let lineColor = '';
        switch (year) {
        case 'VorletztesJahr':
          lineColor = themeColors.chartLighterBlue;
          break;
        case 'LetztesJahr':
          lineColor = themeColors.chartLightBlue;
          break;
        case 'DiesesJahr':
          lineColor = themeColors.chartOrange;
          break;
        }

        // Push current year with color as LegendItem
        this.legendItems.push({ id: currentYearString, name: currentYearString, color: lineColor });

        let seriesData: BasispreisDiagrammSeriesDataPoint[] = [];
        this.basispreisData[year].forEach((data, index) => {
          if (data.Wert !== null) {
            seriesData.push({
              name: data.Zaehler,
              value: data.Wert,
              data: {
                from: data.Von,
                to: data.Bis,
              },
            });
          }

          if ((data.Wert === null || index + 1 === this.basispreisData[year].length) && seriesData.length > 0) {
            tempDiagrammData.push({
              name: currentYearString,
              color: lineColor,
              series: seriesData,
            });
            if (data.Wert === null) {
              seriesData = [];
            }
          }
        });
      }
    });

    this.legendItems = this.legendItems.reverse();
    this.convertedData = tempDiagrammData;
    this.lineChartOptions.multi = tempDiagrammData;
  }

  /**
   * Invokes the appropriate WidgetService method to retrieve the correct data for the given base price widget.
   * @abstract
   */
  protected abstract getBasispreisData(): Observable<BasispreisData>;
}
