import { AnimationEvent } from '@angular/animations';
import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { AnzahlTage } from '@core/constants';
import { UserPropertiesBereiche } from '@core/user/user.enums';
import { CurrentUser } from '@core/user/user.interfaces';
import { UserService } from '@core/user/user.service';
import { ColumnInformation } from '@iq-angular-libs/portal';
import { TranslateService } from '@ngx-translate/core';
import {
  FocusBetriebsstaettenData,
  FocusBetriebsstaettenOptions,
  FocusBetriebsstaetteOptions
} from '@share/table-cell-components/table-cell-components.interfaces';
import { ISlimScrollEvent, SlimScrollEvent } from 'ngx-slimscroll';
import { of, Subject, throwError } from 'rxjs';
import { filter, finalize, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Widget, WidgetComponent } from '../share/startseite.interfaces';
import { getWidgetBearbeitenContainerAnimation } from '../share/widgets';
import { AuffaelligeBetriebeTabs } from '../share/widgets.enums';
import { AuffaelligeBetriebe, AuffaelligeBetriebeWidgetData } from '../share/widgets.interfaces';
import { WidgetsService } from '../share/widgets.service';

@Component({
  selector: 'iq-auffaellige-betriebsstaetten-widget',
  templateUrl: './auffaellige-betriebe-widget.component.html',
  styleUrls: ['./auffaellige-betriebe-widget.component.scss'],
  animations: [getWidgetBearbeitenContainerAnimation(40)]
})
export class AuffaelligeBetriebeWidgetComponent implements OnInit, OnDestroy, WidgetComponent {
  /**
   * The structure information of the widget.
   */
  @Input()
  widget: Widget;

  /**
   * The data to be obtained from BE for the suspicious comerical units
   */
  betriebsstaetten: AuffaelligeBetriebeWidgetData;

  /**
   * The data for the operating sites with days since last delivery batch
   */
  tageSeitLetzterLieferpartie: AuffaelligeBetriebe = [];

  /**
   * The data for the comercial units without delivery batches
   */
  betriebsstaettenOhneSchlachtdaten: AuffaelligeBetriebe = [];

  /**
   * Indicates whether data is currently being loaded.
   */
  betriebsstaettenLoading = false;

  /**
   * Number of days Enum
   */
  anzahlTageEnum: AnzahlTage[];

  /**
   * The number of days selected in the options.
   */
  anzahlTage: AnzahlTage = AnzahlTage.Sechzig;

  /**
   * The registration numbers of the loaded comerical units for the {@link FocusBetriebsstaettenComponent}.
   */
  focusBetriebsstaettenDataTage: FocusBetriebsstaettenData;

  /**
   * The options for the {@link FocusBetriebsstaettenComponent}.
   */
  focusBetriebsstaettenOptions: FocusBetriebsstaettenOptions;

  /**
   * The options for the {@link FocusBetriebsstaetteComponent}.
   */
  focusBetriebsstaetteOptions: FocusBetriebsstaetteOptions;

  /**
   * Contains the column information for the table.
   */
  columns: ColumnInformation[];

  /**
   * Contains the column information for the table without number of days
   */
  columnsOhneSchlachtdaten: ColumnInformation[];

  /**
   * Used for the animation of the options area.
   */
  optionsOpen = false;

  /**
   * Specifies whether the options should be displayed.
   */
  showOptions = false;

  /**
   * The current user
   */
  currentUser: CurrentUser;

  /**
   * Indicates which tab is selected.
   */
  selectedTab: AuffaelligeBetriebeTabs = AuffaelligeBetriebeTabs.TageSeitLetzterPartie;

  /**
   * Represents the possible tabs in the widget.
   */
  AuffaelligeBetriebeTabs = AuffaelligeBetriebeTabs;

  /**
   * Event emitter for the SlimScroll component.
   */
  slimScrollEvents = new EventEmitter<ISlimScrollEvent>();

  /**
   * Unsusbscribe-Stream.
   */
  unsubscribe$ = new Subject<void>();

  /** Name of radio buttons inside one radio button group */
  radioButtonName: string;

  /**
   * Constructor.
   * @param translate the {@link TranslateService}
   * @param decimalPipe the {@link DecimalPipe}
   * @param userService the {@link UserService}
   * @param widgetsService the {@link WidgetsService}
   */
  constructor(
    private translate: TranslateService,
    private decimalPipe: DecimalPipe,
    private userService: UserService,
    private widgetsService: WidgetsService
  ) {
    this.anzahlTageEnum = [AnzahlTage.Dreissig, AnzahlTage.Sechzig, AnzahlTage.Neunzig];
    this.betriebsstaetten = {
      TageSeitLetzterPartie: null,
      OhneSchlachtdaten: null
    };

    this.focusBetriebsstaettenDataTage = {
      filteredRegistrierungsnummern: []
    };

    this.focusBetriebsstaettenOptions = {
      buttonText: this.translate.instant('FOKUS.ALLE_UEBERNEHMEN')
    };

    this.focusBetriebsstaetteOptions = {
      tooltipText: this.translate.instant('FOKUS.IN_GLOBALEN_FILTER_UEBERNEHMEN'),
      tooltipPlacement: 'left'
    };

    this.columns = [
      {
        field: 'Registrierungsnummer',
        headerTranslationKey: '',
        minWidth: '120',
        cellClass: (column: ColumnInformation) => 'text-bold'
      },
      {
        field: 'AnzahlTage',
        headerTranslationKey: '',
        minWidth: '80',
        cellClass: (column: ColumnInformation) => 'text-muted',
        cellFilter: {
          pipe: this.decimalPipe,
          pipeArgs: ['1.0-0']
        }
      }
    ];

    this.columnsOhneSchlachtdaten = [
      {
        field: 'Registrierungsnummer',
        headerTranslationKey: '',
        minWidth: '120',
        cellClass: (column: ColumnInformation) => 'text-bold'
      },
      {
        field: 'BetriebsstaettenName',
        headerTranslationKey: '',
        minWidth: '80',
        cellClass: (column: ColumnInformation) => 'text-muted'
      }
    ];
  }

  /**
   * Triggers the loading of the data for the widget.
   */
  ngOnInit() {
    this.generateRadioButtonName();
    this.userService
      .getCurrentUser()
      .pipe(
        take(1),
        finalize(() => {
          this.getData();
        })
      )
      .subscribe(currentUser => {
        if (currentUser) {
          this.currentUser = currentUser;
          this.anzahlTage = currentUser.userProperties.widgets.AuffaelligeBetriebeWidgetAnzahlTage
            ? currentUser.userProperties.widgets.AuffaelligeBetriebeWidgetAnzahlTage
            : this.anzahlTage;
        }
      });

    this.subscribeOnWidgetItemResizedStream();
  }

  /**
   * Fires an event of the unsubscribe stream and closes it afterwards.
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Called as soon as in the options the number of days change.
   * @param anzahlTage The selected number of days
   */
  anzahlTageChanged(anzahlTage: number) {
    if (this.currentUser) {
      this.currentUser.userProperties.widgets.AuffaelligeBetriebeWidgetAnzahlTage = anzahlTage;
      this.userService
        .postUserproperties(UserPropertiesBereiche.WIDGETS, this.currentUser.userProperties)
        .pipe(take(1))
        .subscribe();
    }
    this.getData();
  }

  /**
   * If the "Days since last delivery batch" tab is active, the Options panel is toggled.
   * No toggling is performed in the "Without slaughter data" tab.
   */
  toggleOptionsPanel() {
    if (this.selectedTab === AuffaelligeBetriebeTabs.TageSeitLetzterPartie) {
      this.optionsOpen = !this.optionsOpen;
    }
  }

  /**
   * Called as soon as the animation of the options is finished.
   * Sets the {@link showOptions} accordingly whether the options are now open or closed.
   * @param event AnimationEvent
   */
  onOptionsAnimationDone(event: AnimationEvent) {
    if (event.toState === 'optionsClosed') {
      this.showOptions = false;
    } else {
      this.showOptions = true;
    }
  }

  /**
   * Sets the selected tab and adjusts the selected ProductProgramList accordingly.
   * @param event Click-Event
   * @param selectedTab The selected tab (0 or 1)
   */
  selectTab(event: Event, selectedTab: AuffaelligeBetriebeTabs) {
    event.preventDefault();
    this.selectedTab = selectedTab;
    if (selectedTab === AuffaelligeBetriebeTabs.OhneSchlachtdaten) {
      this.optionsOpen = false;
    }
  }

  /**
   * Gets the VVVO data from the WidgetService.
   */
  private getData(): void {
    this.betriebsstaetten = {
      TageSeitLetzterPartie: null,
      OhneSchlachtdaten: null
    };
    this.betriebsstaettenLoading = true;
    this.focusBetriebsstaettenDataTage.filteredRegistrierungsnummern = [];

    this.widgetsService
      .getAuffaelligeBetriebeWidgetData(this.anzahlTage)
      .pipe(
        switchMap((data: AuffaelligeBetriebeWidgetData) => {
          if (data) {
            this.betriebsstaetten = data;
            this.tageSeitLetzterLieferpartie = data.TageSeitLetzterPartie;
            this.betriebsstaettenOhneSchlachtdaten = data.OhneSchlachtdaten;
            return of(this.betriebsstaetten);
          } else {
            return throwError('Auffällige Betriebe: Fehler beim Laden der Daten');
          }
        }),
        tap((data: AuffaelligeBetriebeWidgetData) => {
          this.focusBetriebsstaettenDataTage.filteredRegistrierungsnummern = this.betriebsstaetten.TageSeitLetzterPartie.map(
            betriebsstaette => betriebsstaette.Registrierungsnummer
          );
        }),
        finalize(() => {
          this.betriebsstaettenLoading = false;
        })
      )
      .subscribe({
        error: (err: Error) => {
          console.error(err);
        }
      });
  }

  /**
   * Subscribes to the stream for resizing the widgets.
   */
  private subscribeOnWidgetItemResizedStream() {
    this.widgetsService
      .getWidgetItemResizedObservable()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(event => event.widget.id === this.widget.id)
      )
      .subscribe({
        next: () => {
          const event = new SlimScrollEvent({
            type: 'recalculate'
          });

          this.slimScrollEvents.emit(event);
        }
      });
  }

  /** Generate name of radio buttons inside one radio button group */
  private generateRadioButtonName(): void {
    this.radioButtonName = 'RB_' + Math.random().toString(36).substring(0, 10);
  }
}
