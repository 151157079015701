import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '@core/user/user.service';
import { NewPasswordRequestData } from '@core/user/user.interfaces';
import { confirmPasswordValidator } from '@share/iq-validators';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'iq-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent implements OnInit {
  /**
   * Specifies if a password changes is forced or not.
   */
  @Input()
  mustChangePassword: boolean;

  submitted: boolean;

  submitting: boolean;

  /**
   * Stream that informs if the modal dialog was closed with accept or decline.
   */
  onClose$: Subject<boolean>;

  form: FormGroup;

  /**
   * Regex definition for the letters
   */
  private readonly buchstaben: string;

  /**
   * Regex definition for the uppercase letters
   */
  private readonly grossBuchstaben: string;

  /**
   * Regex definition for the numbers
   */
  private readonly zahlen: string;

  /**
   * Regex defintion for the special characters
   */
  private readonly sonderzeichen: string;

  /**
   * Regex definition for the forbidden characters
   */
  private readonly verboteneZeichen;

  /**
   * Stores the regular expression for the passwort validation.
   * The passwort must has to contain three of the following four character groups:
   * (lowercase letters, uppercase letters, special characters, numbers)
   */
  private readonly regex: RegExp;

  /**
   * Constructor.
   * Initializes the regular expression definition for the password field.
   */
  constructor(
    private bsModalRef: BsModalRef,
    private translate: TranslateService,
    private toastr: ToastrService,
    private userService: UserService
  ) {
    this.buchstaben = '(?=.*[a-z])';
    this.grossBuchstaben = '(?=.*[A-Z])';
    this.zahlen = '(?=.*\\d)';
    this.sonderzeichen = '(?=.*[\\x21-\\x2F\\x3A-\\x40\\x5B-\\x60\\x7B-\\x7E])';
    this.verboteneZeichen = '(?!.*[\\s\\t\\n])';

    this.regex = new RegExp(
      '((' +
        this.buchstaben +
        this.grossBuchstaben +
        this.zahlen +
        ')|' +
        '(' +
        this.buchstaben +
        this.grossBuchstaben +
        this.sonderzeichen +
        ')|' +
        '(' +
        this.grossBuchstaben +
        this.zahlen +
        this.sonderzeichen +
        ')|' +
        '(' +
        this.buchstaben +
        this.zahlen +
        this.sonderzeichen +
        '))' +
        this.verboteneZeichen
    );
  }

  /**
   * Initializes the form group and its formcontrols beside some
   * other component variables.
   */
  ngOnInit() {
    const controls = {
      oldPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(24),
        Validators.pattern(this.regex)
      ]),
      confirmPassword: new FormControl('', [Validators.required]),
      acceptAgbs: new FormControl(!this.mustChangePassword, Validators.requiredTrue)
    };

    this.form = new FormGroup(controls, { validators: [confirmPasswordValidator] });
    this.submitted = false;
    this.submitting = false;
    this.onClose$ = new Subject<boolean>();
  }

  /**
   * Forwards request for setting the new passwort to the PasswortService.
   * Resets the form afterwards and closes the modal dialog.
   * If the password was not saved, an error toast will be displayed.
   * If the password was saved successfully, an info toast will be displayed.
   */
  submit(): void {
    this.submitted = true;
    if (this.form.valid) {
      const passwordData: NewPasswordRequestData = {
        PasswortNeu: this.form.controls['newPassword'].value,
        PasswortAlt: this.form.controls['oldPassword'].value
      };

      this.submitting = true;
      this.userService
        .postUserPassword(passwordData)
        .pipe(
          finalize(() => {
            this.submitting = false;
          })
        )
        .subscribe({
          next: () => {
            this.toastr.success(
              this.translate.instant('PASSWORT.TOASTS.PASSWORT_GESPEICHERT'),
              this.translate.instant('PASSWORT.TOASTS.PASSWORT_GESPEICHERT_TITEL')
            );
            this.onClose$.next(true);
            this.onClose$.complete();
            this.bsModalRef.hide();
          },
          error: (err: HttpErrorResponse) => {
            if (err && err.headers && err.headers.get('ErrorCode') === '0014') {
              this.toastr.error(
                this.translate.instant('EXCEPTIONS.0014'),
                this.translate.instant('ALLGEMEIN.TOASTS.FEHLER')
              );
              this.form.controls['oldPassword'].setErrors({ oldPassword: true });
            } else {
              this.toastr.error(
                this.translate.instant('PASSWORT.TOASTS.FEHLER_NEUES_PASSWORT'),
                this.translate.instant('ALLGEMEIN.TOASTS.FEHLER')
              );
              if (!this.mustChangePassword) {
                this.onClose$.next(false);
                this.onClose$.complete();
                this.bsModalRef.hide();
              } else {
                this.resetForm();
              }
            }
          }
        });
    } else {
      this.toastr.error(
        this.translate.instant('PASSWORT.TOASTS.BITTE_EINGABE_UEBERPRUEFEN'),
        this.translate.instant('ALLGEMEIN.TOASTS.FEHLER')
      );
    }
  }

  /**
   * Closes the modal dialog when mustChangePassword is false.
   * Else the dialog can't be closed.
   */
  cancel() {
    if (!this.mustChangePassword) {
      this.onClose$.next(false);
      this.onClose$.complete();
      this.bsModalRef.hide();
    }
  }

  /**
   * Convenience-Getter, for easier control access.
   */
  get controls() {
    return this.form.controls;
  }

  private resetForm(): void {
    this.submitted = false;
    this.form.reset({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      acceptAgbs: !this.mustChangePassword
    });
  }
}
