import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
  ElementRef,
  Renderer2,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { AntibiotikaTacho } from 'src/app/antibiotika/monitoring-overview/share/antibiotika.interfaces';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'iq-antibiotika-tacho-chart',
  templateUrl: './antibiotika-tacho-chart.component.html',
  styleUrls: ['./antibiotika-tacho-chart.component.scss']
})
export class AntibiotikaTachoChartComponent implements OnInit, OnChanges, AfterViewInit {
  /**
   * The antibiotic data
   */
  @Input()
  tachoData: AntibiotikaTacho;

  /**
   * The arc1 element ref to draw the green area
   */
  @ViewChild('arc1', { read: ElementRef })
  arc1: ElementRef;

  /**
   * The arc2 element ref to draw the yellow area
   */
  @ViewChild('arc2', { read: ElementRef })
  arc2: ElementRef;

  /**
   * The arc3 element ref to draw the red area
   */
  @ViewChild('arc3', { read: ElementRef })
  arc3: ElementRef;

  /**
   * The degrees for the speedometer needle
   */
  deg: number;

  constructor(private renderer: Renderer2) {}

  /**
   * Triggers the drawing of the speedometer
   */
  ngOnInit(): void {
    this.redrawTacho();
  }

  /**
   * Triggers a redraw of the speedometer with every new score
   */
  ngOnChanges(): void {
    this.redrawTacho();
  }

  /**
   * Define the three colored areas after the speedometer is drawn.
   */
  ngAfterViewInit(): void {
    this.renderer.setAttribute(this.arc1.nativeElement, 'd', this.describeArc(200, 150, 100, -90, 0));
    this.renderer.setAttribute(this.arc2.nativeElement, 'd', this.describeArc(200, 150, 100, -0, 45));
    this.renderer.setAttribute(this.arc3.nativeElement, 'd', this.describeArc(200, 150, 100, 45, 90));
  }

  /**
   * Redraw the speedometer.
   */
  redrawTacho(): void {
    this.deg = Math.round(this.convertTherapieindexToDeg(this.tachoData));
  }

  private polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians)
    };
  }

  private describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number) {
    const start = this.polarToCartesian(x, y, radius, endAngle);
    const end = this.polarToCartesian(x, y, radius, startAngle);
    const arcSweep = endAngle - startAngle <= 180 ? '0' : '1';
    const d = ['M', start.x, start.y, 'A', radius, radius, 0, arcSweep, 0, end.x, end.y].join(' ');
    return d;
  }

  private convertTherapieindexToDeg(data: AntibiotikaTacho): number {
    const tachoData = cloneDeep(data);

    if (tachoData.Therapieindex < 0) {
      tachoData.Therapieindex = 0;
    } else if (tachoData.Therapieindex > tachoData.DritteGrenze) {
      tachoData.Therapieindex = tachoData.DritteGrenze;
    }

    const interval = this.getInterval(tachoData);
    const a = (interval[1].y - interval[0].y) / (interval[1].x - interval[0].x);
    const b = interval[0].y - a * interval[0].x;
    return a * tachoData.Therapieindex + b;
  }

  private getInterval(tachoData: AntibiotikaTacho) {
    if (
      tachoData.Therapieindex >= tachoData.ErsteGrenze &&
      !(tachoData.Therapieindex === 0 && tachoData.ErsteGrenze === 0)
    ) {
      if (tachoData.Therapieindex >= tachoData.ZweiteGrenze) {
        return [
          { x: tachoData.ZweiteGrenze, y: 135 },
          { x: tachoData.DritteGrenze, y: 180 }
        ];
      } else {
        return [
          { x: tachoData.ErsteGrenze, y: 90 },
          { x: tachoData.ZweiteGrenze, y: 135 }
        ];
      }
    } else {
      return [
        { x: 0, y: 0 },
        { x: tachoData.ErsteGrenze, y: 90 }
      ];
    }
  }
}
