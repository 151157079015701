import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isString } from 'lodash-es';

@Pipe({
  name: 'geschlecht'
})
export class GeschlechtPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  /**
   * Transforms the given gender string to a translated singular version
   * of the gender. If no translation key for the gender is found the
   * gender string will be returned.
   *
   * @param value the gender in form of a string
   */
  transform(value: any): any {
    if (!isString(value)) {
      return value;
    }

    const geschlechtKey = 'GESCHLECHTER_EINZAHL.' + value.toUpperCase();
    const translation = this.translate.instant(geschlechtKey);

    if (translation !== geschlechtKey) {
      return translation;
    }

    return value;
  }
}
