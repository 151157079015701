<div [formGroup]="form">
  <div>
    <select class="form-control mb-2 float-left"
            formControlName="SelectedLetzteXTage">
      <option *ngFor="let zeitraum of zeitraeume"
              [value]="zeitraum.mode">
        {{ zeitraum.translationKey | translate }}
      </option>
    </select>
  </div>
  <div [hidden]="letzteXTage.mode !== letzteXTageMode.FREIE_EINGABE">
    <label class="control-label text-bold mr-1"
           for="anzahlTageInput">
      {{ 'FILTER.DATUM_FILTER.ANZAHL_TAGE' | translate }}:
    </label>
    <input #letzteXTageAnzahlTageInput
           name="anzahlTageInput"
           id="anzahlTageInput"
           type="number"
           class="form-control anzahl-tage"
           formControlName="AnzahlTage"
           min="0"
           step="1"
           [class.is-invalid]="submitted && form.invalid && form.controls['AnzahlTage'].errors">
  </div>
</div>
