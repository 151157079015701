import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FilterComponent } from '../filter-component.interfaces';
import { SelectedFilterItemsChangedEvent } from '../filter-component.events';
import { MultiselectChangedEvent, MultiselectClosedEvent, MultiselectSettings } from '@iq-angular-libs/portal';
import { Subject } from 'rxjs';
import { FilterService } from '@share/filter/filter.service';
import { GlobalFilterService } from '@core/global-filter/global-filter.service';
import { takeUntil } from 'rxjs/operators';
import { FilterBereiche } from '@share/filter/filter-bereiche';
import { SauRassen } from '@share/filter/filter.interfaces';

@Component({
  selector: 'iq-sau-rassen-filter',
  templateUrl: './sau-rassen-filter.component.html'
})
export class SauRassenFilterComponent implements OnInit, OnDestroy, FilterComponent {
  /**
   * The selected sow breeds
   */
  @Input()
  selectedItems: SauRassen;

  /**
   * The filter sections.
   */
  @Input()
  filterBereich: FilterBereiche;

  /**
   * Indicates whether the filter is disabled or not.
   */
  @Input()
  filterDisabled: boolean;

  /**
   * Indicator whether an event should be emitted with each selection.
   */
  @Input()
  updateOnChange: boolean;

  /**
   * Event emitter that fires an event when the filter has changed.
   */
  @Output()
  selectedItemsChanged: EventEmitter<SelectedFilterItemsChangedEvent>;

  /**
   * The settings of the (multiselect component){@link MultiselectButtonComponent}
   */
  settings: MultiselectSettings;

  /**
   * All available sow breeds
   */
  sauRassen: SauRassen;

  /**
  * The active sow breeds
  */
  activeSauRassen: SauRassen;

  /**
   * Unsubscribe-Stream.
   * Used in (ngOnDestroy){@link SauRassenFilterComponent#ngOnDestroy} to close all open subscriptions.
   */
  private unsubscribe$: Subject<void>;

  /**
   * Constructor
   * @param filterService filter service
   * @param globalFilterService globaler filter service
   */
  constructor(private filterService: FilterService, private globalFilterService: GlobalFilterService) {
    this.sauRassen = [];
    this.activeSauRassen = [];

    this.settings = {
      id: 'saurassen-filter-multiselect',
      sortProp: 'displayName',
      enableDisplayFirstSelectedItem: true,
      enableDisplaySelectedShortnames: false,
      buttonClasses: 'btn btn-secondary iq-select-button iq-btn-filter',
      buttonMaxWidth: 250,
      dropdownAppendTo: 'body'
    };

    this.selectedItemsChanged = new EventEmitter<SelectedFilterItemsChangedEvent>();
    this.unsubscribe$ = new Subject<void>();
  }

  /**
   * Initially loads all and all active sow breeds.
   * Registers with GlobalFilterService to be informed about changes in the facilities.
   */
  ngOnInit() {
    this.getSauRassen();
    this.getActiveSauRassen();
    this.subscribeOnGlobalFilterChangedEvents();
  }

  /**
   * Closes the FilterComponentChanged event emitter.
   * Fires an event of the unsubscribe stream and closes it afterwards.
   */
  ngOnDestroy(): void {
    this.selectedItemsChanged.complete();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Is called as soon as the multiselect selection has changed and the dropdown has closed.
   * @param event {@link MultiselectClosedEvent}
   */
  onMultiselectClosed(event: MultiselectClosedEvent) {
    if (!this.updateOnChange) {
      const filterComponentChangedEvent = new SelectedFilterItemsChangedEvent(event.selectedItems);
      this.selectedItemsChanged.emit(filterComponentChangedEvent);
    }
  }

  /**
   * Is called as soon as the multiselect selection has changed.
   * @param event {@link MultiselectChangedEvent}
   */
  onMultiselectChanged(event: MultiselectChangedEvent) {
    if (this.updateOnChange) {
      const selectedFilterItemsChangedEvent = new SelectedFilterItemsChangedEvent(event.selectedItems);
      this.selectedItemsChanged.emit(selectedFilterItemsChangedEvent);
    }
  }

  /**
   * Queries all existing sow breeds of the user from {@link FilterService}.
   */
  private getSauRassen(): void {
    this.filterService.getSauRassen(this.filterBereich).subscribe({
      next: (sauRassen: SauRassen) => {
        this.sauRassen = sauRassen;
      }
    });
  }

  /**
   * Queries all active sow breeds of the user from {@link FilterService}.
   */
  private getActiveSauRassen(): void {
    this.filterService.getActiveSauRassen(this.filterBereich).subscribe({
      next: (activeSauRassen: SauRassen) => {
        this.activeSauRassen = activeSauRassen;
      }
    });
  }

  /**
   * Registers to the comercial units changes stream of the (GlobalFilterServices){@link GlobalFilterService}
   * to reload the active sow breeds in case of changes.
   */
  private subscribeOnGlobalFilterChangedEvents(): void {
    this.globalFilterService
      .getBetriebsstaetteChangedObservable()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.getActiveSauRassen();
      });
  }
}
