import { InjectionToken } from '@angular/core';
import { Vergleichsgruppen } from '@share/vergleichswerte/vergleichswerte.interfaces';
import { MFAClassificationData } from '../schlachtdaten/lieferzeitraum/lieferzeitraum.interfaces';
import { MFAClassification } from '../schlachtdaten/lieferzeitraum/share/lieferzeitraum.enums';

/**
 * Injection token, which represents the string 'Window' for the window string injection
 */
export const WINDOW = new InjectionToken<string>('Window');

/**
 * General constants
 */
export class COMMON {
  public static readonly API_ROOT: string = 'backend/api';
  public static readonly ASSETS_ROOT: string = 'assets';
  public static readonly MAX_SEGMENTE_ANZAHL: number = 10; // max number segments
  /**
   * regex for general inputs
   * \u00c4-\u00df = Ä, Ö, Ü, ß
   * \u00e4-\u00fc = ä, ö, ü
   * \w = a-zA-Z_
   */
  public static readonly REGEX_GENERAL_INPUT: string = '^[^\\s\\W][\u00c4-\u00df\u00e4-\u00fc\\w-.:/()"#+<>%! ]+$';
  public static readonly REGEX_VVVO_INPUT: string = '[0-9]{15}';
  public static readonly REGEX_VERTRAGSNUMMER_INPUT: string = '^VP[0-9a-zA-Z\u00c4-\u00df\u00e4-\u00fc]{6}$';
  public static readonly REGEX_GANZE_ZAHLEN: string = '^\\d+$'; // regex for integers
  public static readonly REGEX_POSITIVE_NUMBER_MAX_TWO_DECIMALS = '^(\\d+)([,.]\\d{1,2})?$';
  public static readonly NUMBER_EPSILON: number = Number.EPSILON !== undefined ? Number.EPSILON : Math.pow(2, -52); // polyfill für IE
  public static readonly VERTRAGSNUMMER_INPUT_START_CHARS = 'VP';

  public static readonly SLIMSCROLL = {
    BAR_WIDTH: '9',
    BAR_OPACITY: '1.0',
    BAR_BACKGROUND: '#bababa', // corresponds to gray-500
    BAR_MARGIN: '0',
    GRID_BACKGROUND: '#ffffff',
    TOUCH_SCROLL_STEP: '50',
  };

  public static readonly ENABLE_SEGMENT_TOGGLE = {
    SO: false,
    ETM: false,
    ETZ: false,
  };
}

/**
 * The constants for the evaluation of tables
 */
export class TABLE {
  public static readonly REGISTRIERUNGSNUMMER = 'Registrierungsnummer'; // registrationnumber
  public static readonly BESITZER_ID = 'BesitzerId'; // owner id
  public static readonly BESITZER_NAME = 'BesitzerName'; // owner name
}

/**
 * The constants for the formatted representation of date values
 */
export class FORMAT {
  public static readonly DATE = 'dd.MM.yyyy';
  public static readonly SHORT_DATE = 'dd.MM.yy';
  public static readonly WEEK_NUMBER_YEAR = 'ww / yyyy';
  public static readonly DATE_FILTER = 'date: \'dd.MM.yyyy\'';
  public static readonly SIMPLE_DATE = 'd.M.yyyy';
  public static readonly DATE_D3 = '%d.%m.%Y';
  public static readonly DATE_INPUT = 'dd.MM.yy';
  public static readonly DATE_TIME = 'dd.MM.yyyy HH:mm';
  public static readonly TIME_HOURS_MINUTES_SECONDS = 'HH:mm:ss';
  public static readonly TIME_HOURS_MINUTES_SECONDS_DATE = 'HH:mm:ss (dd.MM.yyyy)';
}

/**
 * These constants will be used to save the columns of the
 * specific table in the backend.
 */
export class COLUMNS {
  public static readonly SCHLACHTDATEN = {
    LIEFERPARTIEN: {
      OVERVIEW: 'SoLieferpartieuebersicht',
    },
    BETRIEBSVERGLEICH: {
      IXP: 'SoBetriebsvergleichIxp',
      MFA: 'SoBetriebsvergleichMfa',
    },
    BEFUNDDATEN: {
      OVERVIEW: 'SoBefunddatenuebersicht',
      KUPIERVERZICHT: 'SoBefunddatenKupierverzicht',
    },
    WIEGELISTE: 'SoWiegeliste',
    AGGREGATIONS: {
      IXP: 'SoAggregationsIxp',
      MFA: 'SoAggregationsMfa'
    }
  };

  public static readonly MASTGRUPPEN = {
    OVERVIEW: 'MastgruppenUebersicht',
    CATEGORIES: 'MastgruppenCategories',
    DETAILS: {
      LIEFERPARTIEN: 'MastgruppenDetailsLieferpartien',
    },
    FUTTERVORLAGEN: {
      OVERVIEW: 'FuttervorlagenOverview',
    },
  };

  public static readonly BLACKBOX = {
    OVERVIEW: 'BlackboxUebersicht',
  };

  public static readonly SALMONELLENMONITORING = {
    OVERVIEW: 'SalmoUebersicht',
  };

  public static readonly ANTIBIOTIKA = {
    OVERVIEW: 'AntiUebersicht',
  };

  public static readonly QUALITAETSSICHERUNG = {
    OVERVIEW: 'QsUebersicht',
  };

  public static readonly QS_MANAGEMENT = {
    BETRIEBSLISTE: 'QsManagementBetriebsliste',
    MAENGELLISTE: 'QsManagementMaengelliste',
  };

  public static readonly BETRIEBSSTAETTEN_UEBERSICHT = 'Betriebsstaettenuebersicht';

  public static readonly UNTERBERATER_UEBERSICHT = 'Unterberater';

  public static readonly ITW = {
    OVERVIEW: 'ItwUebersicht',
  };

  public static readonly ETM = {
    EINZELTIERLISTE: 'ETM_Einzeltierliste',
  };

  public static readonly ETZ = {
    EINZELTIERLISTE: 'ETZ_Einzeltierliste',
    RANGIERUNG: {
      SAUEN: 'ETZ_Rangierung_Sauen',
      EBER: 'ETZ_Rangierung_Eber',
    },
  };

  public static readonly BENACHRICHTIGUNG = {
    OVERVIEW: 'BenachrichtigungUebersicht',
  };

  public static readonly VERTRAGSVERWALTUNG = {
    OVERVIEW: 'VertragsverwaltungUebersicht',
  };

  public static readonly LOYALTY_BONUS = {
    OVERVIEW: 'TreuebounsUebersicht',
    CATEGORIES: 'TreuebounsUebersichtCat'
  };
}

/**
 * Describes the type of the menu points
 */
export declare type Menupunkte = number[];

/**
 * The menu point constants
 */
export class MENUPUNKTE {
  public static readonly STARTSEITE = 0;
  public static readonly BETRIEBSSTAETTEN = 17;
  public static readonly UNTERBERATER = 22;
  public static readonly ITW = 28;
  public static readonly BLACKBOX = 33;
  public static readonly MASTGRUPPEN = 38;
  public static readonly BENACHRICHTIGUNGEN = 39;

  public static readonly SCHLACHTDATEN = Object.freeze({
    SCHLACHTDATEN: 1,
    LIEFERPARTIEN: 5,
    WIEGELISTE: 6,
    BASISVERGLEICH: 7,
    AGGREGATION: 8,
    MASKENBETRACHTUNG: 19,
    LIEFERZEITRAUM: 20,
    DATENDOWNLOAD: 21,
    BETRIEBSVERGLEICH: 23,
    MASKENVERGLEICH: 27,
    LANDWIRT_PLUS_WERBESEITE: 29,
    BEFUNDDATEN: 31,
  });

  public static readonly SALMO = Object.freeze({
    SALMO: 2,
    TGI: 37,
  });

  public static readonly QS = Object.freeze({
    QS: 3,
    AUDITING: 14,
    REMEDYING_DEFECTS: 15,
  });

  public static readonly ANTIBIOTIKA = Object.freeze({
    ANTIBIOTIKA: 18,
    MONITORING: 50,
    ZERO_REPORTS: 51
  });

  public static readonly QS_MANAGEMENT = Object.freeze({
    QS_MANAGEMENT: 24,
    BETRIEBSLISTE: 25,
    MAENGELLISTE: 26,
  });

  public static readonly ETM = Object.freeze({
    ETM: 4,
    BASISVERGLEICH: 10,
    EINZELTIERLISTE: 34,
  });

  public static readonly ETZ = Object.freeze({
    ETZ: 32,
    BASISVERGLEICH: 9,
    EINZELTIERLISTE: 35,
    RANGIERUNG: 36,
  });

  public static readonly PARTNERSCHAFT = Object.freeze({
    PARTNERSCHAFT: 40,
    EXPERTEN_NETZWERK: 41,
    MARKTBERICHT: 42,
    VERTRAGSVERWALTUNG: 43,
    STAMMDATENVERWALTUNG: 44,
    TREUEBONUS: 52,
  });

  public static readonly ITW_TOENNIES = Object.freeze({
    ITW: 45,
    PARTICIPATION: 46,
    DOCUMENTS: 47,
    TRAINING: 48,
  });
}

/**
 * Type of the programs
 */
export declare type Programme = string[];

/**
 * The constants of the different programs
 */
export class PROGRAMME {
  public static readonly SO = 'SO';
  public static readonly QS = 'QS';
  public static readonly SALMO = 'SALMO';
  public static readonly SALMO_DRITTER = 'SALMO_DRITTER';
  public static readonly IKB_QS = 'IKB-QS';
  public static readonly QS_GAP = 'QS-GAP';
  public static readonly GLOBALGAP = 'GLOBALGAP';
  public static readonly QS_GLOBALGAP = 'QS+GLOBALGAP';
  public static readonly ETM = 'ETM';
  public static readonly ETZ = 'ETZ';
  public static readonly ANTI = 'ANTI';
  public static readonly ANTI_DRITTER = 'ANTI_DRITTER';
  public static readonly ITW = 'ITW';
  public static readonly BLACKBOX = 'BB';
  public static readonly MG_R = 'MG-R';
  public static readonly MG_W = 'MG-W';
}

/**
 * The constants of the different areas.
 */
export class AREAS {
  public static readonly SCHLACHTDATEN = {
    SCHLACHTDATEN: 'SCHLACHTDATEN',
    IXP: 'IXP',
    MFA: 'MFA',
  };
}

export class VALUES {
  public static readonly GLOBAL_FILTER_SELECTION_LIMIT = 1000;

  public static readonly DEFAULT_OPTIMALBEREICH = {
    MIN: 80,
    MAX: 110,
  };

  public static readonly MASTGRUPPEN = {
    // other reductions
    SONSTIGE_ABGAENGE: {
      MAX_INPUT_DATE_DAYS: 170,
    },
  };

  public static readonly AGGREGATION = {
    OPTIMALBEREICH_MIN: 80,
    OPTIMALBEREICH_MAX: 112,
  };

  // comparison of organizations
  public static readonly BETRIEBSVERGLEICH = {
    MINDEST_ANZAHl_TIERE: 1, // minimum number of animals
  };

  // delivery period
  public static readonly LIEFERZEITRAUM = {
    OPTIMALBEREICH_MIN: 80, // optimal range minimum
    OPTIMALBEREICH_MAX: 112,
  };

  // single animal identification in breeding
  public static readonly ETZ = {
    RANGIERUNG: {
      MINDEST_ANZAHl_NACHKOMMEN: 1, // minimum number of descendants
      MINDEST_ANZAHl_WUERFE: 1, // minimum number of litter
    },
  };

  public static readonly VERGLEICHSGRUPPEN: Vergleichsgruppen = [
    { displayName: 'Top 10%', value: 10 },
    { displayName: 'Top 25%', value: 25 },
    { displayName: 'Ø', value: 100 },
  ];

  public static readonly MFA_CLASSIFICATION: MFAClassificationData[] = [
    { id: MFAClassification.All, translationKey: 'SCHLACHTDATEN.MFA_CLASSIFICATION.FOM_AND_AUTOFOM' },
    { id: MFAClassification.AutoFOM, translationKey: 'SCHLACHTDATEN.MFA_CLASSIFICATION.AUTOFOM' },
    { id: MFAClassification.FOM, translationKey: 'SCHLACHTDATEN.MFA_CLASSIFICATION.FOM' }
  ];
}

/**
 * The file types for the data download
 */
export class FILETYPES {
  public static readonly EXCEL = 'excel';
  public static readonly CSV = 'csv';
}

/**
 * IQ/Toennies portal demo users
 */
export class DEMOUSERS {
  public static readonly DEMO_USER_IQ_1 = 'K055409';
  public static readonly DEMO_USER_IQ_2 = 'K051940';
  public static readonly DEMO_USER_TOENNIES = 'T155409';
}

/**
 * Enums for the data download areas. Represents the download path for the respective areas.
 */
export enum DatendownloadSection {
  DATENDOWNLOAD = 'schlachtdaten/datendownload',
  LIEFERPARTIEN = 'schlachtdaten/lieferpartien',
  QS_MANAGEMENT_BETRIEBSLISTE = 'qsmanagement/betriebsliste',
  QS_MANAGEMENT_MANGELLISTE = 'qsmanagement/mangelliste',
  LOYALTY_BONUS_LIST = 'partnership/contracts/loyalty-bonus',
  PARTNERSHIP_CONTRACTS = 'partnership/contracts',
  MASTGRUPPEN = 'mastgruppen',
  UNTERBERATER = 'portaldaten/unterberater',
}

/**
 * Enum for the time periods
 */
export enum ZeitraumIntervalle {
  TAG = 0, // day
  WOCHE = 1, // week
  MONAT = 3, // month
  QUARTAL = 2, // quarter
}

/**
 * Enum for the gender section under Rangierung
 */
export enum RangierungGeschlechtBereiche {
  SAUEN = 'sauen', // sows
  EBER = 'eber', // boars
}

/**
 * Enum for the animal groups of the basic price widget
 */
export enum BasispreisTiergruppe {
  Schweine = 1, // pigs
  Sauen = 2, // sows
  Ferkel = 3, // piglets
  Jungbullen = 4, // young bulls
  Kuehe = 5, // cows
}

/**
 * Enum which describes the possible number of days of the widgets
 */
export enum AnzahlTage {
  Unbekannt = 0, // unknown
  Dreissig = 30, // thirty
  Sechzig = 60, // sixty
  Neunzig = 90, // ninety
}
