import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'lieferberechtigung'
})
export class LieferberechtigungPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: number, args?: any): string {
    switch (value) {
    case 0:
      return this.translate.instant('LIEFERBERECHTIGUNG.NEIN');
    case 1:
      return this.translate.instant('LIEFERBERECHTIGUNG.JA');
    case 3:
      return this.translate.instant('LIEFERBERECHTIGUNG.ABGELAUFEN');
    case 4:
      return this.translate.instant('LIEFERBERECHTIGUNG.SPERRE');
    case 5:
      return this.translate.instant('LIEFERBERECHTIGUNG.NOCH_NICHT');
    default:
      return value.toString();
    }
  }
}
