<div [formGroup]="form">
  <div class="from">
    <label class="text-bold mb-1"> {{ 'FILTER.DATUM_FILTER.VON' | translate }}: </label>
    <iq-date
      [inputFormGroup]="form"
      [inputFormControlName]="'From'"
      [tabIndex]="1"
      [focusOnInit]="true"
      [submitted]="submitted"
      [minDate]="minDate"
      [maxDate]="currentMaxDate"
      (inputChanged)="onFromDateChanged($event)"
    >
    </iq-date>
  </div>
  <div class="to mt-3">
    <label class="text-bold mb-1"> {{ 'FILTER.DATUM_FILTER.BIS' | translate }}: </label>
    <iq-date
      [inputFormGroup]="form"
      [inputFormControlName]="'To'"
      [tabIndex]="2"
      [focusOnInit]="false"
      [submitted]="submitted"
      [minDate]="currentMinDate"
      [maxDate]="maxDate"
      (inputChanged)="onToDateChanged($event)"
    >
    </iq-date>
  </div>
</div>
