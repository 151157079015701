import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { DatumInfo } from '@share/filter/uebergeordnete-filter.interfaces';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatumFilterMode, LetzteXTageMode } from '@share/filter/datum-filter.enums';
import { cloneDeep, isArray } from 'lodash-es';
import { takeUntil } from 'rxjs/operators';
import { FilterBereiche } from '@share/filter/filter-bereiche';
import { FilterService } from '@share/filter/filter.service';
import { Schlachttage } from '@share/filter/filter.interfaces';

@Component({
  selector: 'iq-datum-filter-modal',
  templateUrl: './datum-filter-modal.component.html',
  styleUrls: ['./datum-filter-modal.component.scss']
})
export class DatumFilterModalComponent implements OnInit, OnDestroy {
  /**
   * The information about the selected date mode and the selected elements.
   */
  @Input()
  datumInfo: DatumInfo;

  /**
   * The filter section of the filter.
   */
  @Input()
  filterBereich: FilterBereiche;

  /**
   * The form group, which represents the date configuration in the template.
   */
  form: FormGroup;

  /**
   * Indicates whether the filter has already been confirmed by the user.
   */
  submitted: boolean;

  /**
   * Stream, which informs the subscriber whether the modal dialog
   * has been closed by confirming or canceling changes.
   */
  onClose$: Subject<DatumInfo | null>;

  /**
   * Indicates whether the selection of the individual slaughter days should be displayed.
   */
  hideSchlachttage: boolean;

  /**
   * Provision of the modes in the template
   */
  datumFilterMode = DatumFilterMode;

  /**
   * All available slaughter days for the user.
   */
  schlachttage: Schlachttage;

  /**
   * All active slaughter days for the globally selected comercial units.
   */
  activeSchlachttage: Schlachttage;

  /**
   * Constructor
   * @param bsModalRef
   */
  constructor(private bsModalRef: BsModalRef, private filterService: FilterService) {
    this.submitted = false;
    this.onClose$ = new Subject<DatumInfo | null>();
  }

  /**
   * Initializes the filter mode with LastXdays if no mode exists.
   * Initializes the form for the date filter.
   */
  ngOnInit() {
    this.schlachttage = [];
    this.activeSchlachttage = [];

    this.hideSchlachttage = this.filterBereich === FilterBereiche.ETZ;

    if (!this.datumInfo.mode) {
      this.datumInfo.mode = DatumFilterMode.LETZTE_X_TAGE;
      this.datumInfo.letzteXTage = {
        mode: LetzteXTageMode.LETZTE_30_TAGE
      };
    }

    this.form = new FormGroup({
      SelectedFilterMode: new FormControl(this.datumInfo.mode, [Validators.required]),
      SelectedLetzteXTage: new FormControl(),
      AnzahlTage: new FormControl(0, [Validators.min(0)]),
      From: new FormControl(''),
      To: new FormControl('')
    });

    this.getSchlachttage();
    this.getActiveSchlachttage();

    this.subscribeOnSelectedFilterModeChangeEvents();
  }

  /**
   * Closes the when destroying the component.
   */
  ngOnDestroy(): void {
    this.onClose$.complete();
  }

  /**
   * Resets the filter.
   * Closes the modal dialog and passes the reset date information to the
   * caller of the modal dialog. Is called up when the button "Filter zurücksetzen" is pressed.
   */
  resetFilter() {
    const resettedData: DatumInfo = {
      mode: null,
      letzteXTage: null,
      schlachttage: null,
      zeitraum: null
    };

    this.submitted = false;

    this.onClose$.next(resettedData);
    this.onClose$.complete();
    this.bsModalRef.hide();
  }

  /**
   * Closes the modal dialog without applying changes.
   */
  cancel(): void {
    this.onClose$.next(null);
    this.onClose$.complete();
    this.bsModalRef.hide();
  }

  /**
   * Closes the dialog if the form is valid.
   */
  submit(): void {
    this.submitted = true;

    if (this.form.valid) {
      const newDatumInfo = this.createNewDatumInfo();

      this.onClose$.next(newDatumInfo);
      this.onClose$.complete();
      this.bsModalRef.hide();
    }
  }

  /**
   * Saves the new selected slaughter days.
   * @param newSelectedSchlachttage
   */
  onSelectedSchlachttageChanged(newSelectedSchlachttage: Schlachttage) {
    if (this.datumInfo.mode === DatumFilterMode.SCHLACHTTAGE) {
      this.datumInfo.schlachttage = newSelectedSchlachttage;
    }
  }

  /**
   * Convenience getters to provide easier access to the form controls.
   */
  get controls() {
    return this.form.controls;
  }

  /**
   * Reacts to the mode change and initializes the values of the
   * datumInfo property accordingly.
   */
  private modeChanged(newMode: DatumFilterMode) {
    const selectedLetzteXTage = this.form.controls['SelectedLetzteXTage'];

    this.datumInfo.mode = newMode;
    selectedLetzteXTage.setValidators([]);

    switch (newMode) {
    case DatumFilterMode.SCHLACHTTAGE:
      this.datumInfo.schlachttage = [];
      this.datumInfo.letzteXTage = null;
      this.datumInfo.zeitraum = null;
      break;

    case DatumFilterMode.ZEITRAUM:
      this.datumInfo.zeitraum = {
        from: null,
        to: null
      };
      this.datumInfo.letzteXTage = null;
      this.datumInfo.schlachttage = null;
      break;

    case DatumFilterMode.LETZTE_X_TAGE:
      this.datumInfo.schlachttage = null;
      this.datumInfo.zeitraum = null;
      this.datumInfo.letzteXTage = {
        mode: LetzteXTageMode.LETZTE_30_TAGE
      };
      selectedLetzteXTage.setValidators([Validators.required]);
      break;

    default:
      this.datumInfo.mode = null;
      this.datumInfo.schlachttage = null;
      this.datumInfo.letzteXTage = null;
      this.datumInfo.zeitraum = null;
      this.form.controls['SelectedFilterMode'].setErrors({ SelectedFilterMode: true });
    }
  }

  /**
   * Creates a new DateInfo object based on the selection.
   */
  private createNewDatumInfo(): DatumInfo {
    const selectedFilterMode = this.form.controls['SelectedFilterMode'].value;
    const newDatumInfo: DatumInfo = {
      mode: selectedFilterMode,
      letzteXTage: null,
      schlachttage: null,
      zeitraum: null
    };

    switch (newDatumInfo.mode) {
    case DatumFilterMode.LETZTE_X_TAGE:
      newDatumInfo.letzteXTage = cloneDeep(this.datumInfo.letzteXTage);
      break;

    case DatumFilterMode.SCHLACHTTAGE:
      newDatumInfo.schlachttage = cloneDeep(this.datumInfo.schlachttage);
      break;

    case DatumFilterMode.ZEITRAUM:
      newDatumInfo.zeitraum = cloneDeep(this.datumInfo.zeitraum);
      break;

    default:
    }

    this.handleSpecialDatumCases(newDatumInfo);
    return newDatumInfo;
  }

  /**
   * Handles exceptional cases.
   * Checks if slaughter days or free selectable period is selected, but no matching values are given.
   * If yes, the filter is reset. Otherwise nothing happens.
   */
  private handleSpecialDatumCases(newDatumInfo: DatumInfo) {
    if (newDatumInfo) {
      switch (newDatumInfo.mode) {
      case DatumFilterMode.SCHLACHTTAGE:
        if (!Array.isArray(newDatumInfo.schlachttage) || newDatumInfo.schlachttage.length === 0) {
          newDatumInfo.mode = null;
          newDatumInfo.schlachttage = null;
        }
        break;

      case DatumFilterMode.ZEITRAUM:
        if (!newDatumInfo.zeitraum || (!newDatumInfo.zeitraum.from && !newDatumInfo.zeitraum.to)) {
          newDatumInfo.mode = null;
          newDatumInfo.zeitraum = null;
        }
        break;

      default:
      }
    }
  }

  /**
   * Gets all available slaughter days for the active user.
   */
  private getSchlachttage(): void {
    this.filterService.getSchlachttage(this.filterBereich).subscribe({
      next: (schlachttage: Schlachttage) => {
        this.schlachttage = schlachttage;
      }
    });
  }

  /**
   * Gets all active slaughter days for the globally selected comerical units.
   */
  private getActiveSchlachttage(): void {
    this.filterService.getActiveSchlachttage(this.filterBereich).subscribe({
      next: (activeSchlachttage: Schlachttage) => {
        this.activeSchlachttage = activeSchlachttage;
      }
    });
  }

  /**
   * Subscribes to the changes of the radio buttons.
   */
  private subscribeOnSelectedFilterModeChangeEvents(): void {
    const selectedFilterModeControl = this.form.controls['SelectedFilterMode'];

    selectedFilterModeControl.valueChanges.pipe(takeUntil(this.onClose$)).subscribe({
      next: (newMode: DatumFilterMode) => {
        this.modeChanged(newMode);
      }
    });
  }
}
