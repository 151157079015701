import { RouterModule } from '@angular/router';
import { MultiselectModule } from '@iq-angular-libs/portal';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgCheckComponent } from './svg-check/svg-check.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { EmailValidationComponent } from './email-validation/email-validation.component';
import { CommaSeparatedPipe } from './comma-separated.pipe';
import { FocusBetriebsstaetteComponent } from './table-cell-components/focus-betriebsstaette/focus-betriebsstaette.component';
import { FocusBetriebsstaettenComponent } from './table-cell-components/focus-betriebsstaetten/focus-betriebsstaetten.component';
import { FocusBetriebsstaettenModalComponent } from './focus-betriebsstaetten-modal/focus-betriebsstaetten-modal.component';
import { LieferberechtigungComponent } from './table-cell-components/lieferberechtigung/lieferberechtigung.component';
import { QsStatusComponent } from './table-cell-components/qs-status/qs-status.component';
import { QsCategoryPipe } from './qs-category.pipe';
import { HalbjahrComponent } from './table-cell-components/halbjahr/halbjahr.component';
import { AntibiotikaBelegePipe } from './antibiotika-belege.pipe';
import { AntibiotikaTherapieindexPipe } from './antibiotika-therapieindex.pipe';
import { DefaultComponent } from '@share/default/default.component';
import { IqTooltipAreaComponent } from './chart-helper/iq-tooltip-area/iq-tooltip-area.component';
import { IqCircleSeriesComponent } from './chart-helper/iq-circle-series/iq-circle-series.component';
import { SingleSelectNavigationComponent } from './single-select-navigation/single-select-navigation.component';
import { RoemischeZahlPipe } from './roemische-zahl.pipe';
import { SalmoTachoChartComponent } from './salmo-tacho-chart/salmo-tacho-chart.component';
import { LieferberechtigungPipe } from './lieferberechtigung.pipe';
import { VergleichswerteBearbeitenButtonComponent } from './vergleichswerte-bearbeiten-button/vergleichswerte-bearbeiten-button.component';
import { ComboSeriesVerticalComponent } from './line-bar-chart/series-vertical/series-vertical.component';
import { LineBarChartComponent } from './line-bar-chart/line-bar-chart.component';
import { IqLineSeriesComponent } from './chart-helper/iq-line-series/iq-line-series.component';
import { IqLineComponent } from './chart-helper/iq-line.component';
import { OptimalbereichProzenteComponent } from './line-bar-chart/optimalbereich-prozente/optimalbereich-prozente.component';
import { BarTooltipComponent } from './line-bar-chart/bar-tooltip/bar-tooltip.component';
import { LineTooltipComponent } from './line-bar-chart/line-tooltip/line-tooltip.component';
import { MaskeninformationenService } from '../schlachtdaten/schlachtdaten-share/maskeninformationen/maskeninformationen.service';
import { HelpButtonComponent } from './help-button/help-button.component';
import { HelpButtonModalComponent } from './help-button-modal/help-button-modal.component';
import { DateComponent } from './date/date.component';
import { IqBaseChartComponent } from './chart-helper/iq-base-chart.component';
import { SelectDiagrammValuesModalComponent } from '../schlachtdaten/basisvergleich/share/select-diagramm-values-modal/select-diagramm-values-modal.component';
import { SchlachtbetriebnamePipe } from './schlachtbetriebname.pipe';
import { YesNoPipe } from './yes-no.pipe';
import { ColorPickerModule } from 'ngx-color-picker';
import { AntibiotikaTachoChartComponent } from './antibiotika-tacho-chart/antibiotika-tacho-chart.component';
import { DatendownloadButtonComponent } from './datendownload-button/datendownload-button.component';
import { LaddaModule } from 'angular2-ladda';
import { DetailsAufrufenButtonComponent } from './details-aufrufen-button/details-aufrufen-button.component';
import { DetailsAufrufenModalComponent } from './details-aufrufen-button/details-aufrufen-modal/details-aufrufen-modal.component';
import { GeschlechtPipe } from './geschlecht.pipe';
import { BefundTableCellComponent } from './table-cell-components/befund/befund-table-cell.component';
import { WiegelisteTableCellComponent } from '../schlachtdaten/schlachtdaten-share/wiegeliste-table-cell/wiegeliste-table-cell.component';
import { IqLegendComponent } from '@share/chart-helper/iq-legend/legend.component';
import { MindestanzahlButtonComponent } from './mindestanzahl-button/mindestanzahl-button.component';
import { MindestanzahlModalComponent } from './mindestanzahl-modal/mindestanzahl-modal.component';
import { IqXAxisComponent } from './chart-helper/iq-x-axis.component';
import { IqXAxisTicksComponent } from './chart-helper/iq-x-axis-ticks.component';
import { IqDatePipe } from './iq-date.pipe';
import { BefundPipe } from './befund.pipe';
import { NullPipe } from './null.pipe';
import { ConfirmDeclineModalComponent } from './confirm-decline-modal/confirm-decline-modal.component';
import { NumberInputDirective } from './number-input/number-input.directive';
import { VergleichswerteBearbeitenModalComponent } from './vergleichswerte-bearbeiten/vergleichswerte-bearbeiten-modal.component';
import { ReactiveInputComponent } from './reactive-input/reactive-input.component';
import { CommonModule as IqCommonModule } from '@iq-angular-libs/portal';
import { ActionButtonComponent } from './action-button/action-button.component';
import { DiscardChangesModalComponent } from '../settings/share/discard-changes-modal/discard-changes-modal.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    LaddaModule,
    AccordionModule,
    BsDatepickerModule,
    BsDropdownModule,
    PaginationModule,
    TooltipModule,
    NgxChartsModule,
    ColorPickerModule,
    IqCommonModule,
    MultiselectModule,
  ],
  declarations: [
    SvgCheckComponent,
    EmailValidationComponent,
    CommaSeparatedPipe,
    FocusBetriebsstaetteComponent,
    FocusBetriebsstaettenComponent,
    FocusBetriebsstaettenModalComponent,
    LieferberechtigungComponent,
    QsStatusComponent,
    QsCategoryPipe,
    HalbjahrComponent,
    AntibiotikaBelegePipe,
    AntibiotikaTherapieindexPipe,
    DefaultComponent,
    ComboSeriesVerticalComponent,
    IqTooltipAreaComponent,
    IqCircleSeriesComponent,
    SingleSelectNavigationComponent,
    RoemischeZahlPipe,
    SalmoTachoChartComponent,
    AntibiotikaTachoChartComponent,
    LieferberechtigungPipe,
    VergleichswerteBearbeitenModalComponent,
    VergleichswerteBearbeitenButtonComponent,
    LineBarChartComponent,
    IqLineSeriesComponent,
    IqLineComponent,
    BarTooltipComponent,
    LineTooltipComponent,
    IqBaseChartComponent,
    OptimalbereichProzenteComponent,
    HelpButtonComponent,
    HelpButtonModalComponent,
    DateComponent,
    SelectDiagrammValuesModalComponent,
    SchlachtbetriebnamePipe,
    YesNoPipe,
    DetailsAufrufenButtonComponent,
    DetailsAufrufenModalComponent,
    DatendownloadButtonComponent,
    GeschlechtPipe,
    BefundTableCellComponent,
    WiegelisteTableCellComponent,
    IqLegendComponent,
    IqXAxisComponent,
    IqXAxisTicksComponent,
    MindestanzahlButtonComponent,
    MindestanzahlModalComponent,
    IqDatePipe,
    BefundPipe,
    NullPipe,
    ConfirmDeclineModalComponent,
    NumberInputDirective,
    ReactiveInputComponent,
    ActionButtonComponent,
    DiscardChangesModalComponent,
  ],
  exports: [
    SvgCheckComponent,
    EmailValidationComponent,
    FocusBetriebsstaetteComponent,
    FocusBetriebsstaettenComponent,
    LieferberechtigungComponent,
    QsStatusComponent,
    HalbjahrComponent,
    DefaultComponent,
    ComboSeriesVerticalComponent,
    IqTooltipAreaComponent,
    IqCircleSeriesComponent,
    SingleSelectNavigationComponent,
    RoemischeZahlPipe,
    AntibiotikaTherapieindexPipe,
    SalmoTachoChartComponent,
    AntibiotikaTachoChartComponent,
    QsCategoryPipe,
    LieferberechtigungPipe,
    VergleichswerteBearbeitenModalComponent,
    VergleichswerteBearbeitenButtonComponent,
    LineBarChartComponent,
    IqLineComponent,
    BarTooltipComponent,
    LineTooltipComponent,
    IqBaseChartComponent,
    OptimalbereichProzenteComponent,
    IqLineSeriesComponent,
    HelpButtonComponent,
    HelpButtonModalComponent,
    SchlachtbetriebnamePipe,
    YesNoPipe,
    AntibiotikaBelegePipe,
    DetailsAufrufenButtonComponent,
    DetailsAufrufenModalComponent,
    DatendownloadButtonComponent,
    DateComponent,
    BefundTableCellComponent,
    WiegelisteTableCellComponent,
    IqLegendComponent,
    IqXAxisComponent,
    IqXAxisTicksComponent,
    MindestanzahlButtonComponent,
    MindestanzahlModalComponent,
    IqDatePipe,
    BefundPipe,
    NullPipe,
    ConfirmDeclineModalComponent,
    NumberInputDirective,
    ReactiveInputComponent,
    ActionButtonComponent,
  ],
  // Todo: MaskeninformationenService can be removed from SchlachtdatenShare module later
  // https://github.com/angular/angular/issues/14324
  providers: [MaskeninformationenService],
})
export class SharedModule {}
