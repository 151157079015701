import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'iqDate',
})
export class IqDatePipe extends DatePipe implements PipeTransform {
  constructor(private translate: TranslateService) {
    super(translate.getDefaultLang());
  }

  /**
   * Transforms the passed value into an appropriate date based on the passed parameters.
   * If the input is empty, an 'offen' string is returned instead.
   * @param value the value which should be returned
   * @param format the desired format
   * @param timezone the timezone
   * @param locale the locale
   */
  transform(value: any, format = 'mediumDate', timezone?: string, locale?: string): any {
    const result = super.transform(value, format, timezone, locale);

    if (result === null) {
      return this.translate.instant('FILTER.DATUM_FILTER.OFFEN');
    }

    return result;
  }
}
