import { Component, EventEmitter, Output } from '@angular/core';
import { ShowLoginViewEvent } from '../share/login-events';

@Component({
  selector: 'iq-password-link-requested',
  templateUrl: './password-link-requested.component.html'
})
export class PasswordLinkRequestedComponent {
  /**
   * The event to display the login view
   */
  @Output() showLogin: EventEmitter<ShowLoginViewEvent>;

  constructor() {
    this.showLogin = new EventEmitter<ShowLoginViewEvent>();
  }

  /**
   * Emits the event to display the login view.
   * @param event The click event
   */
  showLoginView(event) {
    event.preventDefault();
    const showLoginEvent = new ShowLoginViewEvent();
    this.showLogin.emit(showLoginEvent);
  }
}
