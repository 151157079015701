<button
  class="btn btn-secondary iq-btn-filter"
  type="button"
  tooltip="{{ 'FILTER.DISABLED_TOOLTIP' | translate }}"
  placement="bottom"
  [isDisabled]="showNotAvailableTooltip !== true"
  [class.disabled]="filterDisabled"
  (click)="filterDisabled ? null : openTzKalkModal()"
>
  {{ buttonText }}<i class="fa fa-caret-down iq-select-caret-icon ml-1" aria-hidden="true"></i>
</button>
