import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

/**
 * Checks if the user is authenticated
 */
@Injectable()
export class AuthGuard  {
  constructor(
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrService,
    private authService: AuthService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const url: string = state.url;
    return this.checkLogin(url);
  }

  /**
   * Checks if the user is logged in and redirects to the login page
   * when not logged in and not already on the login page.
   * If logged in and currently on the login page path, redirect to
   * the startpage.
   * @param url the path the user is currently in
   */
  checkLogin(url: string): boolean {
    if (!this.authService.isLoggedIn && url === '/login') {
      return true;
    }

    if (this.authService.isLoggedIn && url !== '/login') {
      return true;
    }

    if (this.authService.isLoggedIn && url === '/login') {
      this.router.navigate(['/portal/startseite']);
      return false;
    }

    this.authService.redirectTo = url;
    this.router.navigate(['/login']);

    const title = 'ALLGEMEIN.TOASTS.HINWEIS';
    const message = 'ROUTING.TOASTS.NICHT_EINGELOGGT';
    this.translate.get([message, title]).subscribe(translations => {
      this.toastr.info(translations[message], translations[title]);
    });

    return false;
  }
}
