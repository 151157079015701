import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginBaseLayoutComponent } from './login-base-layout/login-base-layout.component';
import { LoginComponent } from './login/login.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PasswordForgottenComponent } from './password-forgotten/password-forgotten.component';
import { PasswordLinkRequestedComponent } from './password-link-requested/password-link-requested.component';
import { CoreModule } from '@core/core.module';
import { LaddaModule } from 'angular2-ladda';
import { TimerPipe } from './timer/timer.pipe';
import { BlockedIpService } from './blocked-ip/blocked-ip.service';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, LaddaModule, CoreModule],
  declarations: [
    LoginBaseLayoutComponent,
    LoginComponent,
    PasswordForgottenComponent,
    PasswordLinkRequestedComponent,
    TimerPipe
  ],
  providers: [BlockedIpService]
})
export class LoginModule {}
