import { Component, Input, OnInit } from '@angular/core';
import {
  SelectionChangedEvent,
  SingleSelectData
} from '@share/single-select-navigation/single-select-navigation.component';
import { finalize } from 'rxjs/operators';
import { Widget, WidgetComponent } from '../share/startseite.interfaces';
import { BetriebsstaettenSalmonellenKategorie, BetriebsstaetteSalmonellenKategorie } from '../share/widgets.interfaces';
import { WidgetsService } from '../share/widgets.service';

@Component({
  selector: 'iq-salmonellen-kategorie-widget',
  templateUrl: './salmonellen-kategorie-widget.component.html',
  styleUrls: ['./salmonellen-kategorie-widget.component.scss']
})
export class SalmonellenKategorieWidgetComponent implements OnInit, WidgetComponent {
  @Input()
  widget: Widget;

  /**
   * The error message.
   */
  errMessage = null;

  /**
   * Indicates whether comerical units are currently loaded.
   */
  dataLoading = false;

  /**
   * Saves the widget data which will be used for the select.
   */
  selectData: SingleSelectData;

  /**
   * The currently selected operating site
   */
  selectedBetriebsstaette: BetriebsstaetteSalmonellenKategorie;

  /**
   * Constructor
   * @param widgetsService {@link WidgetsService}
   */
  constructor(private widgetsService: WidgetsService) {}

  /**
   * Triggers the loading of the data.
   */
  ngOnInit() {
    this.getData();
  }

  /**
   * Called as soon as the selected site has changed in the SingleSelectNavigation Component.
   * @param event SelectionChangedEvent that contains the selected comerical unit
   */
  onSelectionChanged(event: SelectionChangedEvent) {
    this.selectedBetriebsstaette = <BetriebsstaetteSalmonellenKategorie>event.selectedBetriebsstaette;
  }

  /**
   * Determines the comerial units of the WidgetService.
   */
  private getData() {
    this.errMessage = null;
    this.dataLoading = true;
    this.widgetsService
      .getBetriebsstaettenSalmonellenKategorie()
      .pipe(
        finalize(() => {
          this.dataLoading = false;
        })
      )
      .subscribe({
        next: (data: BetriebsstaettenSalmonellenKategorie) => {
          if (Array.isArray(data) && data.length > 0) {
            this.selectData = {
              betriebsstaetten: data,
              selectedBetriebsstaette: data[0],
              selectedBetriebsstaetteIndex: 0
            };
            this.selectedBetriebsstaette = data[0];
          }
        },
        error: err => {
          this.errMessage = 'FEHLER.DATEN_NICHT_GELADEN';
        }
      });
  }
}
