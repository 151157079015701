import { Component } from '@angular/core';

/**
 * The component is displayed to the user as soon as a route is navigated to that does not exist.
 */
@Component({
  selector: 'iq-page-not-found',
  templateUrl: './page-not-found.component.html'
})
export class PageNotFoundComponent {
  /**
   * Constructor.
   */
  constructor() {}
}
