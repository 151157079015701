import { Subject } from 'rxjs';
import { MinMax } from '../uebergeordnete-filter.interfaces';
import { isNil } from 'lodash-es';
import { takeUntil } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Input, OnDestroy, OnInit, Directive } from '@angular/core';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class FilterRangesModalBaseComponent implements OnInit, OnDestroy {
  /**
   * The slaughter weights.
   */
  @Input()
  ranges: MinMax;

  /**
   * The form group that represents the from-to values in the template.
   */
  form: FormGroup;

  /**
   * Indicates whether the filter has already been confirmed by the user.
   */
  submitted: boolean;

  /**
   * Stream, which informs the subscriber whether the modal dialog has been
   * closed by confirming or canceling changes.
   */
  onClose$: Subject<MinMax | null>;

  /**
   *
   * @param bsModalRef
   */
  constructor(private bsModalRef: BsModalRef) {
    this.submitted = false;
    this.onClose$ = new Subject<MinMax | null>();
  }

  /**
   * Initializes the form of the modal and subscribes to the change events of the inputs.
   */
  ngOnInit() {
    this.form = new FormGroup({
      Min: new FormControl(this.ranges.min, [Validators.min(0)]),
      Max: new FormControl(this.ranges.max, [Validators.min(0)])
    });

    this.subscribeOnInputChangeEvents();
  }

  /**
   * Closes the onClose$ stream when destroying the component.
   */
  ngOnDestroy(): void {
    this.onClose$.complete();
  }

  /**
   * Resets the filter completely. Then closes the modal dialog.
   */
  resetFilter(): void {
    const resettedData: MinMax = {
      min: null,
      max: null
    };

    this.submitted = false;
    this.form.reset();

    this.onClose$.next(resettedData);
    this.onClose$.complete();
    this.bsModalRef.hide();
  }

  /**
   * Closes the modal dialog without applying changes.
   */
  cancel(): void {
    this.onClose$.next(null);
    this.onClose$.complete();
    this.bsModalRef.hide();
  }

  /**
   * Closes the dialog if the form is valid.
   */
  submit(): void {
    this.submitted = true;

    if (this.form.valid) {
      const min = this.form.controls['Min'].value;
      const max = this.form.controls['Max'].value;

      const ranges: MinMax = {
        min: isNil(min) ? null : min,
        max: isNil(max) ? null : max
      };

      this.onClose$.next(ranges);
      this.onClose$.complete();
      this.bsModalRef.hide();
    }
  }

  /**
   * Convenience getters to provide easier access to the form controls.
   */
  get controls() {
    return this.form.controls;
  }

  /**
   * Subscribes to the changes of the min and max input field and resets the validators
   * for both fields using the current values of the other field.
   */
  protected subscribeOnInputChangeEvents(): void {
    const minControl = this.form.controls['Min'];
    const maxControl = this.form.controls['Max'];

    minControl.valueChanges.pipe(takeUntil(this.onClose$)).subscribe({
      next: (value: number) => {
        if (isNil(value)) {
          maxControl.setValidators([Validators.min(0)]);
        } else {
          maxControl.setValidators([Validators.min(minControl.value)]);
        }

        maxControl.updateValueAndValidity({ emitEvent: false });
      }
    });

    maxControl.valueChanges.pipe(takeUntil(this.onClose$)).subscribe({
      next: (value: number) => {
        if (isNil(value)) {
          minControl.setValidators([Validators.min(0)]);
        } else {
          minControl.setValidators([Validators.min(0), Validators.max(maxControl.value)]);
        }

        maxControl.updateValueAndValidity({ emitEvent: false });
      }
    });
  }
}
