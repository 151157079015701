import { Component } from '@angular/core';
import { applyPipe, CellComponent, PipeParameters } from '@iq-angular-libs/portal';

@Component({
  selector: 'iq-wiegeliste-table-cell',
  templateUrl: './wiegeliste-table-cell.component.html'
})
export class WiegelisteTableCellComponent implements CellComponent {
  /**
   * The table rows data
   */
  data: { Wert: string | number | null };

  /**
   * The status list
   */
  options?: {
    field: string;
    cellFilter?: PipeParameters;
  };

  /**
   * The applyPipe function
   */
  applyPipe: (data: any, pipeData: PipeParameters) => string;

  /**
   * Constructor.
   */
  constructor() {
    this.applyPipe = applyPipe;
  }
}
