import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn: 'https://561d018fe73f4780b440c6d091e4a9bb@sentry.iq-agrar.de/7',
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    new Integrations.BrowserTracing({
      tracingOrigins: [
        // Prod links
        'https://portal.iq-agrar.de',
        'https://toennies-agrarportal.de',
        // Staging links
        'https://staging.iq-agrar.de',
        'https://staging.toennies-agrarportal.de',
        // Dev links
        'https://develop-portal.iq-agrar.de',
        'https://develop-portal.toennies-agrarportal.de'
      ],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  environment: environment.production ? 'production' : 'development',
  release: environment.toennies ? 'Toennies' : 'IQ-Agrar',
  beforeSend: (sentryEvent): any => {
    if (sentryEvent?.request?.url && sentryEvent.request.url.includes('staging')) {
      sentryEvent.environment = 'staging';
    }
    return sentryEvent;
  },

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
