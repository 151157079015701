import { Component, Input, forwardRef, Renderer2, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { trigger, state, style, transition, animate } from '@angular/animations';

// The animation for the input linie
export const inputeUnderlineAnimation = [
  state(
    'inputActive',
    style({
      borderWidth: '0px 0px 2px 0px',
      width: '100%'
    })
  ),
  state(
    'inputInactive',
    style({
      borderWidth: '0px 0px 0px 0px',
      width: '0%'
    })
  ),
  transition('inputInactive => inputActive', [animate('0.4s ease')]),
  transition('inputActive => inputInactive', [animate('0.2s ease')])
];

// The animation for the input label
const labelAnimation = [
  state('labelSmall', style({ fontSize: '12px', top: '-15px' })),
  state('labelNormal', style({ fontSize: '13px', top: '2px' })),
  transition('labelSmall => labelNormal', [animate('0.4s ease')]),
  transition('labelNormal => labelSmall', [animate('0.2s ease')])
];

/**
 * Component which is used as wrapper for a input element. This component needs
 * a input element as child element to work.
 *
 * <iq-reactive-input>
 *  <input type="text" ... />
 * </iq-reactive-input>
 */
@Component({
  selector: `iq-reactive-input[formControlName],
      iq-reactive-input[formControl],
      iq-reactive-input[ngModel]`,
  templateUrl: './reactive-input.component.html',
  styleUrls: ['./reactive-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReactiveInputComponent),
      multi: true
    }
  ],
  animations: [
    trigger('activeInactiveColorLine', inputeUnderlineAnimation),
    trigger('inputLabelAnimation', labelAnimation)
  ]
})
export class ReactiveInputComponent implements OnDestroy, AfterViewInit, ControlValueAccessor {
  /** The translation key for the input label */
  @Input()
  labelTranslationKey: string;

  /** Change function, which must be called when the input value changes */
  onChange: (_: any) => void;

  /** This function must be called when the input should me marked as "touched" or "blurred" */
  onTouched: () => void;

  /** Determines if the input has the focus */
  inputActive = false;

  /** Saves the last entered value of the input */
  currentValue: string | undefined;

  /** Saves the input element, which will integrated via ng-content */
  inputElement: HTMLInputElement;

  /** Stores the functions to unregister the listeners later on */
  private blurListener: () => void;
  private focusListener: () => void;
  private inputChangeListener: () => void;
  private changeListener: () => void;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  /**
   * Checks if the input element is available as ViewChild and registers blur, focus and change event.
   * If no input is available, an error is thrown indicating that this component needs
   * an input element as child.
   */
  ngAfterViewInit() {
    this.inputElement = this.elementRef.nativeElement.querySelector('input[type="text"],input[type="number"]');
    if (!this.inputElement) {
      throw new Error(
        'no input element given as component child! usage: <iq-reactive-input><input /></iq-reactive-input>'
      );
    }

    if (this.currentValue) {
      this.renderer.setProperty(this.inputElement, 'value', this.currentValue);
    }
    this.currentValue = this.inputElement.value;
    this.blurListener = this.renderer.listen(this.inputElement, 'blur', this.blurEvent.bind(this));
    this.focusListener = this.renderer.listen(this.inputElement, 'focus', this.focusEvent.bind(this));
    this.changeListener = this.renderer.listen(this.inputElement, 'change', this.changeEvent.bind(this));
    this.inputChangeListener = this.renderer.listen(this.inputElement, 'input', this.inputEvent.bind(this));
  }

  /**
   * Unregister the event listeners
   */
  ngOnDestroy() {
    this.blurListener();
    this.focusListener();
    this.inputChangeListener();
    this.changeListener();
  }

  /**
   * Refreshes the input DOM element with the passed value.
   * @param inputNumber the inside the form control set value
   */
  writeValue(inputNumber: any): void {
    if (this.currentValue !== inputNumber && this.inputElement) {
      const normalizedValue: number | string | null = inputNumber == null ? '' : inputNumber;
      this.renderer.setProperty(this.inputElement, 'value', normalizedValue);
    }
    this.currentValue = inputNumber;
  }

  /**
   * Registers a OnChange function, which must be called when the value of
   * the DOM-Element changes.
   * @param fn the function to be called.
   */
  registerOnChange(fn: any): void {
    this.onChange = (value: string) => {
      if (this.inputElement.type === 'number') {
        fn(value === '' ? null : parseFloat(value.replace(',', '.')));
      }
      fn(value);
    };
  }

  /**
   * Registers a function which must be called on focusing of the DOM element
   * @param fn the function to be called
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Will be called when the input is disabled via the FormControl. Disables the
   * DOM-Element with the help of the renderer {@link Renderer2}
   */
  setDisabledState?(isDisabled: boolean): void {
    this.renderer.setProperty(this.inputElement, 'disabled', isDisabled);
  }

  /** Will be called when the input loses the focus */
  private blurEvent() {
    this.inputActive = false;
    this.onTouched();
  }

  private changeEvent(event) {
    this.currentValue = event.target.value === '' ? null : event.target.value;
  }

  /** Will be called when the input gets focus */
  private focusEvent() {
    this.inputActive = true;
  }

  /** Will be triggert on every input */
  private inputEvent(event) {
    this.onChange(event.target.value);
    this.currentValue = event.target.value === '' ? null : event.target.value;
  }
}
