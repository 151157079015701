import { Injectable } from '@angular/core';
import {
  Betriebsstaetten,
  EberNummern,
  EberRassen,
  Einsenderzeichen,
  EtmFilterData,
  EtzFilterData,
  FilterData,
  FilterListTypes,
  FilterServiceBodyData,
  Geschlechter,
  SauNummern,
  SauRassen,
  Schlachtbetrieb,
  Schlachtbetriebe,
  Schlachttage,
  SoEtmFilterData,
  SoFilterData,
  WurfNummern
} from '@share/filter/filter.interfaces';
import { Observable, of, ReplaySubject, throwError } from 'rxjs';
import { COMMON, MENUPUNKTE } from '@core/constants';
import { FilterBereiche } from '@share/filter/filter-bereiche';
import { switchMap, take, tap } from 'rxjs/operators';
import { GlobalFilterService } from '@core/global-filter/global-filter.service';
import { DataService } from '@core/data/data.service';
import {
  BetriebsstaetteBE,
  BetriebsstaettenBE,
  EberNummernBE,
  EberRassenBE,
  EinsenderzeichenBE,
  EtmFilterDataBE,
  EtzFilterDataBE,
  GeschlechtBE,
  GeschlechterBE,
  SauNummernBE,
  SauRassenBE,
  SchlachtbetriebBE,
  SchlachtbetriebeBE,
  SchlachttageBE,
  SegmentTotals,
  SoEtmFilterDataBE,
  SoFilterDataBE,
  WurfNummernBE
} from '@share/filter/filter-backend.interfaces';
import { cloneDeep, isArray, orderBy } from 'lodash-es';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '@mattlewis92/angular-2-local-storage';
import { LOCALSTORAGE } from '@core/local-storage/local-storage.constants';
import { WebAccessService } from '@core/web-access/web-access.service';
import { next } from '@core/next-observable';
import { SegmentsQuery } from '@share/filter/segment-query.interfaces';
import { orderCaseUnsensitiv } from '@iq-angular-libs/portal';

export interface IFilterService {
  getSchlachtbetriebe(filterBereich: FilterBereiche): Observable<Schlachtbetriebe>;
  getGeschlechter(filterBereich: FilterBereiche): Observable<Geschlechter>;
  getEinsenderzeichen(filterBereich: FilterBereiche): Observable<Einsenderzeichen>;
  getSauRassen(filterBereich: FilterBereiche): Observable<SauRassen>;
  getSauNummern(filterBereich: FilterBereiche): Observable<SauNummern>;
  getWurfNummern(filterBereich: FilterBereiche): Observable<WurfNummern>;
  getEberRassen(filterBereich: FilterBereiche): Observable<EberRassen>;
  getEberNummern(filterBereich: FilterBereiche): Observable<EberNummern>;
  getBetriebsstaetten(filterBereich: FilterBereiche): Observable<Betriebsstaetten>;
  getSchlachttage(filterBereich: FilterBereiche): Observable<Schlachttage>;
  getAllFilters(filterBereich: FilterBereiche): Observable<SoFilterData | EtmFilterData | EtzFilterData>;

  getActiveSchlachtbetriebe(filterBereich: FilterBereiche): Observable<Schlachtbetriebe>;
  getActiveGeschlechter(filterBereich: FilterBereiche): Observable<Geschlechter>;
  getActiveEinsenderzeichen(filterBereich: FilterBereiche): Observable<Einsenderzeichen>;
  getActiveSauRassen(filterBereich: FilterBereiche): Observable<SauRassen>;
  getActiveSauNummern(filterBereich: FilterBereiche): Observable<SauNummern>;
  getActiveWurfNummern(filterBereich: FilterBereiche): Observable<WurfNummern>;
  getActiveEberRassen(filterBereich: FilterBereiche): Observable<EberRassen>;
  getActiveEberNummern(filterBereich: FilterBereiche): Observable<EberNummern>;
  getActiveBetriebsstaetten(filterBereich: FilterBereiche): Observable<Betriebsstaetten>;
  getActiveSchlachttage(filterBereich: FilterBereiche): Observable<Schlachttage>;

  getTotals(segmentQuery: any, filterBereich: FilterBereiche): Observable<SegmentTotals>;
  getVergleichsSchlachtbetriebId(filterBereich: FilterBereiche): Observable<string | null>;
  filterReady(filterBereich: FilterBereiche): Observable<void>;
  reset(): void;
}

/**
 * The service provides data for the filters of the different filter ranges (SO, ETM, ETZ).
 */
@Injectable({
  providedIn: 'root'
})
export class FilterService implements IFilterService {
  /**
   * Holds the data for the respective area under consideration
   */
  private currentData: null | FilterData;

  /**
   * Holds the data for the slaughter data area
   */
  private soData: FilterData;

  /**
   * Holds the data for the ETM area
   */
  private etmData: FilterData;

  /**
   * Holds the data for the ETZ area
   */
  private etzData: FilterData;

  /**
   * Constructor.
   */
  constructor(
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private dataService: DataService,
    private globalFilterService: GlobalFilterService,
    private webaccessService: WebAccessService
  ) {
    this.currentData = null;

    this.initSoData();
    this.initEtmData();
    this.initEtzData();

    this.globalFilterService.getBetriebsstaetteChangedObservable().subscribe({
      next: () => {
        this.onBetriebsstaettenChanged();
      }
    });
  }

  /** ****************** Getter for the filter *****************************/

  /**
   * Identifies the slaughterhouses.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  getSchlachtbetriebe(filterBereich: FilterBereiche): Observable<Schlachtbetriebe> {
    return <Observable<Schlachtbetriebe>>this.getDataForFilter(filterBereich, 'schlachtbetriebe');
  }

  /**
   * Determines the genders.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  getGeschlechter(filterBereich: FilterBereiche): Observable<Geschlechter> {
    return <Observable<Geschlechter>>this.getDataForFilter(filterBereich, 'geschlechter');
  }

  /**
   * Determines the sender signs.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  getEinsenderzeichen(filterBereich: FilterBereiche): Observable<Einsenderzeichen> {
    return <Observable<Einsenderzeichen>>this.getDataForFilter(filterBereich, 'einsenderzeichen');
  }

  /**
   * Determines the sow breeds.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  getSauRassen(filterBereich: FilterBereiche): Observable<SauRassen> {
    return <Observable<SauRassen>>this.getDataForFilter(filterBereich, 'sauRassen');
  }

  /**
   * Determines the sow numbers.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  getSauNummern(filterBereich: FilterBereiche): Observable<SauNummern> {
    return <Observable<SauNummern>>this.getDataForFilter(filterBereich, 'sauNummern');
  }

  /**
   * Determines the litter numbers.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  getWurfNummern(filterBereich: FilterBereiche): Observable<WurfNummern> {
    return <Observable<WurfNummern>>this.getDataForFilter(filterBereich, 'wurfNummern');
  }

  /**
   * Determines the boar breeds.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  getEberRassen(filterBereich: FilterBereiche): Observable<EberRassen> {
    return <Observable<EberRassen>>this.getDataForFilter(filterBereich, 'eberRassen');
  }

  /**
   * Determines the boar numbers.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  getEberNummern(filterBereich: FilterBereiche): Observable<EberNummern> {
    return <Observable<EberNummern>>this.getDataForFilter(filterBereich, 'eberNummern');
  }

  /**
   * Determines the operating sites.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  getBetriebsstaetten(filterBereich: FilterBereiche): Observable<Betriebsstaetten> {
    return <Observable<Betriebsstaetten>>this.getDataForFilter(filterBereich, 'betriebsstaetten');
  }

  /**
   * Determines the days of slaughter.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  getSchlachttage(filterBereich: FilterBereiche): Observable<Schlachttage> {
    return <Observable<Schlachttage>>this.getDataForFilter(filterBereich, 'schlachttage');
  }

  /**
   * Returns the data of the SO filters.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  getAllFilters(filterBereich: FilterBereiche): Observable<SoFilterData | EtmFilterData | EtzFilterData> {
    return this.setVariablesForFilterBereich(filterBereich).pipe(
      switchMap(() => {
        this.setVariablesForFilterBereich(filterBereich);

        if (!this.currentData.dataIsLoading) {
          this.currentData.dataIsLoading = new ReplaySubject<SoFilterData | EtmFilterData | EtzFilterData>(1);
          this.loadData(filterBereich);
        }

        return this.currentData.dataIsLoading;
      }),
      switchMap(() => {
        this.setVariablesForFilterBereich(filterBereich);
        return of(this.currentData.filterData);
      })
    );
  }

  /** ****************** Getter for currently active data*****************************/

  /**
   * Identifies the active slaughterhouses.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  getActiveSchlachtbetriebe(filterBereich: FilterBereiche): Observable<Schlachtbetriebe> {
    return <Observable<Schlachtbetriebe>>this.getDataForActiveFilter(filterBereich, 'schlachtbetriebe');
  }

  /**
   * Determines the active genders.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  getActiveGeschlechter(filterBereich: FilterBereiche): Observable<Geschlechter> {
    return <Observable<Geschlechter>>this.getDataForActiveFilter(filterBereich, 'geschlechter');
  }

  /**
   * Determines the active sender signs.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  getActiveEinsenderzeichen(filterBereich: FilterBereiche): Observable<Einsenderzeichen> {
    return <Observable<Einsenderzeichen>>this.getDataForActiveFilter(filterBereich, 'einsenderzeichen');
  }

  /**
   * Determines the active sow breeds.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  getActiveSauRassen(filterBereich: FilterBereiche): Observable<SauRassen> {
    return <Observable<SauRassen>>this.getDataForActiveFilter(filterBereich, 'sauRassen');
  }

  /**
   * Determines the active sow numbers.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  getActiveSauNummern(filterBereich: FilterBereiche): Observable<SauNummern> {
    return <Observable<SauNummern>>this.getDataForActiveFilter(filterBereich, 'sauNummern');
  }

  /**
   * Determines the active litter numbers.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  getActiveWurfNummern(filterBereich: FilterBereiche): Observable<WurfNummern> {
    return <Observable<WurfNummern>>this.getDataForActiveFilter(filterBereich, 'wurfNummern');
  }

  /**
   * Determines the active boar breeds.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  getActiveEberRassen(filterBereich: FilterBereiche): Observable<EberRassen> {
    return <Observable<EberRassen>>this.getDataForActiveFilter(filterBereich, 'eberRassen');
  }

  /**
   * Determines the active boar numbers.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  getActiveEberNummern(filterBereich: FilterBereiche): Observable<EberNummern> {
    return <Observable<EberNummern>>this.getDataForActiveFilter(filterBereich, 'eberNummern');
  }

  /**
   * Determines the active VVVOs.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  getActiveBetriebsstaetten(filterBereich: FilterBereiche): Observable<Betriebsstaetten> {
    return <Observable<Betriebsstaetten>>this.getDataForActiveFilter(filterBereich, 'betriebsstaetten');
  }

  /**
   * Determines the active slaughter days.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  getActiveSchlachttage(filterBereich: FilterBereiche): Observable<Schlachttage> {
    return <Observable<Schlachttage>>this.getDataForActiveFilter(filterBereich, 'schlachttage');
  }

  /** ****************** Other public functions *****************************/

  /**
   * Gets the number of pigs for several segments (slaughter data filter)
   * @param segmentsQuery Array with segments to be included
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  getTotals(segmentsQuery: SegmentsQuery, filterBereich: FilterBereiche): Observable<SegmentTotals> {
    return this.setVariablesForFilterBereich(filterBereich).pipe(
      switchMap(() => this.createBodyDataForRegistrierungsnummerAndSegmente(segmentsQuery)),
      switchMap((bodyData: FilterServiceBodyData) => {
        this.setVariablesForFilterBereich(filterBereich);
        return this.dataService.postDataWithParameters<SegmentTotals>(
          this.currentData.serviceBase + '/totals',
          bodyData
        );
      })
    );
  }

  /**
   * !! Exclusively for slaughter data (SO):
   * Determines the slaughterhouse ID of the reference slaughterhouse.
   * If an Id is stored in the LocalStorage, it is taken. Otherwise the slaughterhouse with the most animals.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  getVergleichsSchlachtbetriebId(filterBereich: FilterBereiche): Observable<string | null> {
    const schlachtbetriebId: string = this.localStorageService.get(
      LOCALSTORAGE.VERGLEICHS_SCHLACHTBETRIEB_ID + '.' + filterBereich
    );

    if (schlachtbetriebId) {
      return of(schlachtbetriebId);
    } else {
      return this.getActiveSchlachtbetriebe(filterBereich).pipe(
        switchMap((schlachtbetriebe: Schlachtbetriebe) => {
          if (schlachtbetriebe.length > 0) {
            const sortedSchlachtbetriebe = orderBy<Schlachtbetrieb>(
              schlachtbetriebe,
              [orderCaseUnsensitiv('anzahlTiere')],
              ['desc']
            );
            return of(sortedSchlachtbetriebe[0].id);
          } else {
            return of(null);
          }
        })
      );
    }
  }

  /**
   * Indicates whether the slaughter data filter data is currently being loaded.
   * If not, the loading is started.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  filterReady(filterBereich: FilterBereiche): Observable<void> {
    return this.setVariablesForFilterBereich(filterBereich).pipe(
      switchMap(() => {
        this.setVariablesForFilterBereich(filterBereich);

        if (!this.currentData.dataIsLoading) {
          this.currentData.dataIsLoading = new ReplaySubject<SoFilterData | EtmFilterData | EtzFilterData>(1);
          this.loadData(filterBereich);
        }

        return this.currentData.dataIsLoading;
      }),
      switchMap((data: SoFilterData | EtmFilterData | EtzFilterData) => next())
    );
  }

  /**
   * Resets the values of the service.
   */
  reset(): void {
    // general data
    this.currentData = null;

    // slaughter data
    this.soData.dataIsLoading = null;
    if (this.soData.activeDataIsLoading) {
      this.soData.activeDataIsLoading.complete();
    }
    this.soData.activeDataIsLoading = null;

    this.soData.filterData = <SoFilterData>this.soData.filterData;
    this.soData.filterData.schlachtbetriebe = null;
    this.soData.filterData.geschlechter = null;
    this.soData.filterData.schlachttage = null;
    this.soData.filterData.betriebsstaetten = null;
    this.soData.filterData.einsenderzeichen = null;

    this.soData.activeFilterData = <SoFilterData>this.soData.activeFilterData;
    this.soData.activeFilterData.schlachtbetriebe = null;
    this.soData.activeFilterData.geschlechter = null;
    this.soData.activeFilterData.schlachttage = null;
    this.soData.activeFilterData.betriebsstaetten = null;
    this.soData.activeFilterData.einsenderzeichen = null;

    // ETM data
    this.etmData.dataIsLoading = null;
    if (this.etmData.activeDataIsLoading) {
      this.etmData.activeDataIsLoading.complete();
    }
    this.etmData.activeDataIsLoading = null;

    this.etmData.filterData = <EtmFilterData>this.etmData.filterData;
    this.etmData.filterData.schlachtbetriebe = null;
    this.etmData.filterData.geschlechter = null;
    this.etmData.filterData.schlachttage = null;
    this.etmData.filterData.betriebsstaetten = null;
    this.etmData.filterData.einsenderzeichen = null;

    this.etmData.activeFilterData = <EtmFilterData>this.etmData.activeFilterData;
    this.etmData.activeFilterData.schlachtbetriebe = null;
    this.etmData.activeFilterData.geschlechter = null;
    this.etmData.activeFilterData.schlachttage = null;
    this.etmData.activeFilterData.betriebsstaetten = null;
    this.etmData.activeFilterData.einsenderzeichen = null;

    // ETZ data
    this.etzData.dataIsLoading = null;
    if (this.etzData.activeDataIsLoading) {
      this.etzData.activeDataIsLoading.complete();
    }
    this.etzData.activeDataIsLoading = null;

    this.etzData.filterData = <EtzFilterData>this.etzData.filterData;
    this.etzData.filterData.geschlechter = null;
    this.etzData.filterData.schlachttage = null;
    this.etzData.filterData.betriebsstaetten = null;
    this.etzData.filterData.sauRassen = null;
    this.etzData.filterData.sauNummern = null;
    this.etzData.filterData.wurfNummern = null;
    this.etzData.filterData.eberRassen = null;
    this.etzData.filterData.eberNummern = null;

    this.etzData.activeFilterData = <EtzFilterData>this.etzData.activeFilterData;
    this.etzData.activeFilterData.geschlechter = null;
    this.etzData.activeFilterData.schlachttage = null;
    this.etzData.activeFilterData.betriebsstaetten = null;
    this.etzData.activeFilterData.sauRassen = null;
    this.etzData.activeFilterData.sauNummern = null;
    this.etzData.activeFilterData.wurfNummern = null;
    this.etzData.activeFilterData.eberRassen = null;
    this.etzData.activeFilterData.eberNummern = null;
  }

  /** ****************** Initialization of the data *****************************/

  /**
   * Initializes the (SO data){@link FilterService#soData} with initial values.
   */
  private initSoData(): void {
    this.soData = {
      dataIsLoading: null,
      activeDataIsLoading: null,
      serviceBase: COMMON.API_ROOT + '/schlachtdaten/filter',
      filterData: <SoFilterData>{
        schlachtbetriebe: null,
        geschlechter: null,
        einsenderzeichen: null,
        betriebsstaetten: null,
        schlachttage: null
      },
      activeFilterData: <SoFilterData>{
        schlachtbetriebe: null,
        geschlechter: null,
        einsenderzeichen: null,
        betriebsstaetten: null,
        schlachttage: null
      }
    };
  }

  /**
   * Initializes the (ETM data){@link FilterService#emtData} with initial values
   */
  private initEtmData(): void {
    this.etmData = {
      dataIsLoading: null,
      activeDataIsLoading: null,
      serviceBase: COMMON.API_ROOT + '/etm/filter',
      filterData: <EtmFilterData>{
        schlachtbetriebe: null,
        geschlechter: null,
        einsenderzeichen: null,
        betriebsstaetten: null,
        schlachttage: null
      },
      activeFilterData: <EtmFilterData>{
        schlachtbetriebe: null,
        geschlechter: null,
        einsenderzeichen: null,
        betriebsstaetten: null,
        schlachttage: null
      }
    };
  }

  /**
   * Initializes the (ETZ data){@link FilterService#emtData} with initial values.
   */
  private initEtzData(): void {
    this.etzData = {
      dataIsLoading: null,
      activeDataIsLoading: null,
      serviceBase: COMMON.API_ROOT + '/etz/filter',
      filterData: <EtzFilterData>{
        schlachtbetriebe: null,
        geschlechter: null,
        einsenderzeichen: null,
        betriebsstaetten: null,
        schlachttage: null,
        sauRassen: null,
        sauNummern: null,
        wurfNummern: null,
        eberRassen: null,
        eberNummern: null
      },
      activeFilterData: <EtzFilterData>{
        schlachtbetriebe: null,
        geschlechter: null,
        einsenderzeichen: null,
        betriebsstaetten: null,
        schlachttage: null,
        sauRassen: null,
        sauNummern: null,
        wurfNummern: null,
        eberRassen: null,
        eberNummern: null
      }
    };
  }

  /** ****************** Setter to set the correct data *****************************/

  /**
   * Checks whether the passed section is contained in the constant
   * FilterBereiche and assign associated data
   * to the general data.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  private setVariablesForFilterBereich(filterBereich: FilterBereiche): Observable<void> {
    switch (filterBereich) {
    case FilterBereiche.SO:
      this.currentData = this.soData;
      break;

    case FilterBereiche.ETM:
      this.currentData = this.etmData;
      break;

    case FilterBereiche.ETZ:
      this.currentData = this.etzData;
      break;

    default:
      this.currentData = null;
      const errorMessage = 'FilterService: Wrong Filter Bereich';
      console.error(errorMessage);
      return throwError(errorMessage);
    }

    return next();
  }

  /** ****************** Help-Getter for the filter *****************************/

  /**
   * Help method for evaluating the transmitted property.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   * @param propertyKey A key of the filterData object.
   */
  private getDataForFilter(filterBereich: FilterBereiche, propertyKey: string): Observable<FilterListTypes> {
    return this.setVariablesForFilterBereich(filterBereich).pipe(
      switchMap(() => {
        this.setVariablesForFilterBereich(filterBereich);

        if (!this.currentData.dataIsLoading) {
          this.currentData.dataIsLoading = new ReplaySubject<SoFilterData | EtmFilterData | EtzFilterData>(1);
          this.loadData(filterBereich);
        }

        return this.currentData.dataIsLoading.asObservable();
      }),
      take(1),
      switchMap((filterData: SoFilterData | EtmFilterData | EtzFilterData) => of(cloneDeep(filterData[propertyKey])))
    );
  }

  /** ****************** Help-Getter for the current active data *****************************/

  /**
   * Helper method for evaluating the transmitted property.
   * If no registration numbers are selected, empty data is returned
   * and no request to the BE will be made.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   * @param propertyKey A key of the activeFilterData object.
   */
  private getDataForActiveFilter(filterBereich: FilterBereiche, propertyKey: string): Observable<FilterListTypes> {
    return this.setVariablesForFilterBereich(filterBereich).pipe(
      switchMap(() => {
        this.setVariablesForFilterBereich(filterBereich);

        if (!this.currentData.activeDataIsLoading) {
          this.currentData.activeDataIsLoading = new ReplaySubject<SoFilterData | EtmFilterData | EtzFilterData>(1);
          this.loadActiveData(filterBereich);
        }

        return this.currentData.activeDataIsLoading.asObservable();
      }),
      take(1),
      switchMap((activeFilterData: SoFilterData | EtmFilterData | EtzFilterData) => {
        this.setVariablesForFilterBereich(filterBereich);
        return of(cloneDeep(activeFilterData[propertyKey]));
      })
    );
  }

  /** ****************** Loading the data and setting the prepared data *****************************/

  /**
   * Loads the respective data for the filters and sets the
   * Properties of the filterData object.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  private loadData(filterBereich: FilterBereiche): void {
    this.setVariablesForFilterBereich(filterBereich)
      .pipe(
        switchMap(() => this.getFilter(filterBereich)),
        tap((data: SoFilterDataBE | EtmFilterDataBE | EtzFilterDataBE) => {
          this.setVariablesForFilterBereich(filterBereich);

          this.currentData.filterData.geschlechter = this.prepareGeschlechter(data.Geschlecht);
          this.currentData.filterData.betriebsstaetten = this.prepareBetriebsstaetten(data.Betriebsstaetten);
          this.currentData.filterData.schlachttage = this.prepareSchlachttage(data.Schlachttage);

          switch (filterBereich) {
          case FilterBereiche.ETZ:
            const etzData = <EtzFilterDataBE>data;
            this.currentData.filterData = <EtzFilterData>this.currentData.filterData;
            this.currentData.filterData.sauRassen = this.prepareSauRassen(etzData.SauRasse);
            this.currentData.filterData.sauNummern = this.prepareSauNummern(etzData.SauNr);
            this.currentData.filterData.wurfNummern = this.prepareWurfNummern(etzData.WurfNr);
            this.currentData.filterData.eberRassen = this.prepareEberRassen(etzData.EberRasse);
            this.currentData.filterData.eberNummern = this.prepareEberNummern(etzData.EberNr);
            break;

          case FilterBereiche.SO:
          case FilterBereiche.ETM:
            const soEtmData = <SoEtmFilterDataBE>data;
            this.currentData.filterData = <SoEtmFilterData>this.currentData.filterData;
            this.currentData.filterData.schlachtbetriebe = this.prepareSchlachtbetriebe(soEtmData.Schlachtbetrieb);
            this.currentData.filterData.einsenderzeichen = this.prepareEinsenderzeichen(soEtmData.Einsenderzeichen);
            break;

          default:
          }

          this.currentData.dataIsLoading.next(this.currentData.filterData);
        })
      )
      .subscribe();
  }

  /**
   * Loads the respective active data for the filters
   * and sets the properties of the activeFilterData object.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  private loadActiveData(filterBereich: FilterBereiche): void {
    this.setVariablesForFilterBereich(filterBereich)
      .pipe(
        switchMap(() => this.globalFilterService.extractSelectedRegistrierungsnummern()),
        switchMap((registrierungsnummern: string[]) => {
          if (registrierungsnummern.length > 0) {
            return this.getActiveFilter(filterBereich);
          } else {
            return of({});
          }
        }),
        tap((data: SoFilterDataBE | EtmFilterDataBE | EtzFilterDataBE) => {
          this.setVariablesForFilterBereich(filterBereich);

          this.currentData.activeFilterData.geschlechter = this.prepareGeschlechter(data.Geschlecht);
          this.currentData.activeFilterData.betriebsstaetten = this.prepareBetriebsstaetten(data.Betriebsstaetten);
          this.currentData.activeFilterData.schlachttage = this.prepareSchlachttage(data.Schlachttage);

          switch (filterBereich) {
          case FilterBereiche.ETZ:
            const etzData = <EtzFilterDataBE>data;
            this.currentData.activeFilterData = <EtzFilterData>this.currentData.activeFilterData;
            this.currentData.activeFilterData.sauRassen = this.prepareSauRassen(etzData.SauRasse);
            this.currentData.activeFilterData.sauNummern = this.prepareSauNummern(etzData.SauNr);
            this.currentData.activeFilterData.wurfNummern = this.prepareWurfNummern(etzData.WurfNr);
            this.currentData.activeFilterData.eberRassen = this.prepareEberRassen(etzData.EberRasse);
            this.currentData.activeFilterData.eberNummern = this.prepareEberNummern(etzData.EberNr);
            break;

          case FilterBereiche.SO:
          case FilterBereiche.ETM:
            const soEtmData = <SoEtmFilterDataBE>data;
            this.currentData.activeFilterData = <SoEtmFilterData>this.currentData.activeFilterData;
            this.currentData.activeFilterData.schlachtbetriebe = this.prepareSchlachtbetriebe(
              soEtmData.Schlachtbetrieb
            );
            this.currentData.activeFilterData.einsenderzeichen = this.prepareEinsenderzeichen(
              soEtmData.Einsenderzeichen
            );
            break;

          default:
          }

          this.currentData.activeDataIsLoading.next(this.currentData.activeFilterData);
        })
      )
      .subscribe();
  }

  /** ****************** Loads data from backend *****************************/

  /**
   * Loads the data for the respective filters from the BE.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  private getFilter(filterBereich: FilterBereiche): Observable<SoFilterDataBE | EtmFilterDataBE | EtzFilterDataBE> {
    return this.setVariablesForFilterBereich(filterBereich).pipe(
      switchMap(() => this.globalFilterService.extractRegistrierungsnummern()),
      switchMap((registrierungsnummern: string[]) => {
        const bodyData: FilterServiceBodyData = {
          Registrierungsnummern: registrierungsnummern
        };
        return of(bodyData);
      }),
      switchMap((bodyData: FilterServiceBodyData) => {
        this.setVariablesForFilterBereich(filterBereich);
        return this.dataService.postDataWithParameters<SoFilterDataBE | EtmFilterDataBE | EtzFilterDataBE>(
          this.currentData.serviceBase + '/data',
          bodyData
        );
      })
    );
  }

  /**
   * Loads the current active data for the filters from the BE.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  private getActiveFilter(filterBereich): Observable<SoFilterDataBE | EtmFilterDataBE | EtzFilterDataBE> {
    return this.setVariablesForFilterBereich(filterBereich).pipe(
      switchMap(() => this.createBodyDataForRegistrierungsnummer()),
      switchMap((bodyData: FilterServiceBodyData) => {
        this.setVariablesForFilterBereich(filterBereich);
        return this.dataService.postDataWithParameters<SoFilterDataBE | EtmFilterDataBE | EtzFilterDataBE>(
          this.currentData.serviceBase + '/data',
          bodyData
        );
      })
    );
  }

  /** ****************** Help functions for converting the data *****************************/

  /**
   * Help function for converting slaughterhouse data.
   * Sets the properties 'displayName' and 'id' per slaughterhouse.
   * @param schlachtbetriebeBE The slaughterhouses from the backend
   */
  private prepareSchlachtbetriebe(schlachtbetriebeBE: SchlachtbetriebeBE): Schlachtbetriebe {
    const schlachtbetriebe: Schlachtbetriebe = [];
    if (Array.isArray(schlachtbetriebeBE)) {
      schlachtbetriebeBE.forEach((schlachtbetriebBE: SchlachtbetriebBE) => {
        schlachtbetriebe.push({
          displayName: schlachtbetriebBE.SchlachtbetriebName,
          id: schlachtbetriebBE.SchlachtbetriebId,
          anzahlTiere: schlachtbetriebBE.AnzahlSchweine
        });
      });
    }
    return schlachtbetriebe;
  }

  /**
   * Help function to convert the gender data.
   * Sets per gender the properties 'displayName' and 'id'.
   * @param geschlechterBE The genders from the backend
   */
  private prepareGeschlechter(geschlechterBE: GeschlechterBE): Geschlechter {
    const geschlechter: Geschlechter = [];
    if (Array.isArray(geschlechterBE)) {
      geschlechterBE.forEach((geschlechtBE: GeschlechtBE) => {
        geschlechter.push({
          id: geschlechtBE.Id,
          displayName: geschlechtBE.Id,
          shortName: geschlechtBE.Kuerzel
        });
      });
    }
    return geschlechter;
  }

  /**
   * Help function to convert the sender character data.
   * Sets the properties 'displayName' and 'id' per sender character.
   * @param einsenderzeichenBE The sender characters from the backend
   */
  private prepareEinsenderzeichen(einsenderzeichenBE: EinsenderzeichenBE): Einsenderzeichen {
    const einsenderzeichen: Einsenderzeichen = [];
    if (Array.isArray(einsenderzeichenBE)) {
      einsenderzeichenBE.forEach((einEinsenderzeichenBE: string) => {
        einsenderzeichen.push({
          id: einEinsenderzeichenBE,
          displayName: einEinsenderzeichenBE
        });
      });
    }
    return einsenderzeichen;
  }

  /**
   * Helper function for converting the sow-race data.
   * Sets the properties 'displayName' and 'id' per sender character.
   * @param sauRassenBE the sow races from the backend
   */
  private prepareSauRassen(sauRassenBE: SauRassenBE): SauRassen {
    const sauRassen: SauRassen = [];
    if (Array.isArray(sauRassenBE)) {
      sauRassenBE.forEach((sauRasseBE: string) => {
        sauRassen.push({
          id: sauRasseBE,
          displayName: sauRasseBE
        });
      });
    }
    return sauRassen;
  }

  /**
   * Help function for converting the sow number data.
   * Sets per sender character the properties 'displayName' and 'id'.
   * @param sauNummernBE The sow numbers from the backend
   */
  private prepareSauNummern(sauNummernBE: SauNummernBE): SauNummern {
    const sauNummern: SauNummern = [];
    if (Array.isArray(sauNummernBE)) {
      sauNummernBE.forEach((sauNummerBE: string) => {
        sauNummern.push({
          displayName: sauNummerBE,
          id: sauNummerBE
        });
      });
    }
    return sauNummern;
  }

  /**
   * Help function for converting the throw number data.
   * Sets per sender character the properties 'displayName' and 'id'.
   * @param wurfNummernBE the litter numbers from the backend
   */
  private prepareWurfNummern(wurfNummernBE: WurfNummernBE) {
    const wurfNummern: WurfNummern = [];
    if (Array.isArray(wurfNummernBE)) {
      wurfNummernBE.forEach((wurfNummerBE: string) => {
        wurfNummern.push({
          displayName: wurfNummerBE,
          id: wurfNummerBE
        });
      });
    }
    return wurfNummern;
  }

  /**
   * Help function for converting the boar breed data.
   * Sets the properties 'displayName' and 'id' per sender character.
   * @param eberRassenBE the boar races from the backend
   */
  private prepareEberRassen(eberRassenBE: EberRassenBE): EberRassen {
    const eberRassen: EberRassen = [];
    if (Array.isArray(eberRassenBE)) {
      eberRassenBE.forEach((eberRasseBE: string) => {
        eberRassen.push({
          displayName: eberRasseBE,
          id: eberRasseBE
        });
      });
    }
    return eberRassen;
  }

  /**
   * Help function for converting the boar number data.
   * Sets per sender character the properties 'displayName' and 'id'.
   * @param eberNummernBE the boar numbers from the backend
   */
  private prepareEberNummern(eberNummernBE: EberNummernBE): EberNummern {
    const eberNummern: EberNummern = [];
    if (Array.isArray(eberNummernBE)) {
      eberNummernBE.forEach((eberNummerBE: string) => {
        eberNummern.push({
          displayName: eberNummerBE,
          id: eberNummerBE
        });
      });
    }
    return eberNummern;
  }

  /**
   * Help function for converting the VVVO data.
   * Sets the properties 'displayName' and 'id' for each VVVO
   * @param betriebsstaettenBE the comercial units from the backend
   */
  private prepareBetriebsstaetten(betriebsstaettenBE: BetriebsstaettenBE): Betriebsstaetten {
    const betriebsstaetten: Betriebsstaetten = [];
    if (Array.isArray(betriebsstaettenBE)) {
      betriebsstaettenBE.forEach((betriebsstaetteBE: BetriebsstaetteBE) => {
        let displayName = betriebsstaetteBE.Registrierungsnummer;
        displayName += betriebsstaetteBE.Name ? ': ' + betriebsstaetteBE.Name : '';
        displayName += betriebsstaetteBE.Ort ? ' (' + betriebsstaetteBE.Ort + ')' : '';
        betriebsstaetten.push({
          id: betriebsstaetteBE.Registrierungsnummer,
          displayName: displayName,
          name: betriebsstaetteBE.Name
        });
      });
    }
    return betriebsstaetten;
  }

  /**
   * Help function to convert the slaughter day data.
   * Sets the properties 'displayName', 'id' and 'active' per slaughter day.
   * @param schlachttageBE the slaughter date from the backend
   */
  private prepareSchlachttage(schlachttageBE: SchlachttageBE): Schlachttage {
    const schlachttage: Schlachttage = [];
    if (Array.isArray(schlachttageBE)) {
      schlachttageBE.forEach((schlachttagBE: string) => {
        const datePipe = new DatePipe(this.translate.getDefaultLang());
        schlachttage.push({
          id: datePipe.transform(schlachttagBE, 'd.M.yyyy'),
          displayName: datePipe.transform(schlachttagBE, 'dd.MM.yyyy'),
          date: schlachttagBE
        });
      });
    }
    return schlachttage;
  }

  /** *************** Help functions to create the parameters for the backend ***********************/

  /**
   * Determines the selected registration numbers and returns them as parameters.
   */
  private createBodyDataForRegistrierungsnummer(): Observable<FilterServiceBodyData> {
    return this.globalFilterService.extractSelectedRegistrierungsnummern().pipe(
      switchMap((registrierungsnummern: string[]) => {
        const bodyData: FilterServiceBodyData = {
          Registrierungsnummern: registrierungsnummern
        };
        return of(bodyData);
      })
    );
  }

  /**
   * Determines the selected registration numbers and returns them with
   * as a parameter in the passed segment query.
   */
  private createBodyDataForRegistrierungsnummerAndSegmente(
    segmentsQuery: SegmentsQuery
  ): Observable<FilterServiceBodyData> {
    return this.createBodyDataForRegistrierungsnummer().pipe(
      tap((bodyData: FilterServiceBodyData) => {
        bodyData.Segments = segmentsQuery;
      })
    );
  }

  /**
   * Is called as soon as the operating sites of (GlobalFilterServices){@link GlobalFilterService}
   * and retrieves the new data for the slaughter data areas, if the area
   * is activated for the user
   */
  private onBetriebsstaettenChanged(): void {
    this.webaccessService.hasWebAccess(MENUPUNKTE.SCHLACHTDATEN.SCHLACHTDATEN).subscribe({
      next: (hatZugriff: boolean) => {
        if (hatZugriff) {
          this.setVariablesForFilterBereich(FilterBereiche.SO);

          if (this.currentData.activeDataIsLoading) {
            this.currentData.activeDataIsLoading.complete();
          }

          this.currentData.activeDataIsLoading = new ReplaySubject<SoFilterData | EtmFilterData | EtzFilterData>(1);
          this.loadActiveData(FilterBereiche.SO);
        }
      }
    });

    this.webaccessService.hasWebAccess(MENUPUNKTE.ETM.ETM).subscribe({
      next: (hatZugriff: boolean) => {
        if (hatZugriff) {
          this.setVariablesForFilterBereich(FilterBereiche.ETM);

          if (this.currentData.activeDataIsLoading) {
            this.currentData.activeDataIsLoading.complete();
          }

          this.currentData.activeDataIsLoading = new ReplaySubject<SoFilterData | EtmFilterData | EtzFilterData>(1);
          this.loadActiveData(FilterBereiche.ETM);
        }
      }
    });

    this.webaccessService.hasWebAccess(MENUPUNKTE.ETZ.ETZ).subscribe({
      next: (hatZugriff: boolean) => {
        if (hatZugriff) {
          this.setVariablesForFilterBereich(FilterBereiche.ETZ);

          if (this.currentData.activeDataIsLoading) {
            this.currentData.activeDataIsLoading.complete();
          }

          this.currentData.activeDataIsLoading = new ReplaySubject<SoFilterData | EtmFilterData | EtzFilterData>(1);
          this.loadActiveData(FilterBereiche.ETZ);
        }
      }
    });
  }
}
