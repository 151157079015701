import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forEach } from 'lodash-es';
import { Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuffaelligeBetriebeWidgetComponent } from '../auffaellige-betriebe-widget/auffaellige-betriebe-widget.component';
import { BasispreisFerkelWidgetComponent } from '../basispreis-ferkel-widget/basispreis-ferkel-widget.component';
import { BasispreisMastschweineWidgetComponent } from '../basispreis-mastschweine-widget/basispreis-mastschweine-widget.component';
import { BasispreisRinderWidgetComponent } from '../basispreis-rinder-widget/basispreis-rinder-widget.component';
import { BasispreisSauenWidgetComponent } from '../basispreis-sauen-widget/basispreis-sauen-widget.component';
import { InfoWidgetComponent } from '../info-widget/info-widget.component';
import { LetzteLieferpartienComponent } from '../letzte-lieferpartien/letzte-lieferpartien.component';
import { QsMaengelbeseitigungWidgetComponent } from '../qs-maengelbeseitigung-widget/qs-maengelbeseitigung-widget.component';
import { QsZulassungWidgetComponent } from '../qs-zulassung-widget/qs-zulassung-widget.component';
import { QsZusammenfassungWidgetComponent } from '../qs-zusammenfassung-widget/qs-zusammenfassung-widget.component';
import { RegisteredUnregisteredVVVOsWidgetComponent } from '../registered-unregistered-vvvos-widget/registered-unregistered-vvvos.component';
import { SalmonellenKategorieWidgetComponent } from '../salmonellen-kategorie-widget/salmonellen-kategorie-widget.component';
import { TermineWidgetComponent } from '../termine-widget/termine-widget.component';
import { ToenniesAgrarBlogWidgetComponent } from '../toennies-agrarblog-widget/toennies-agrarblog-widget.component';
import { WidgetCategory } from './startseite.enums';
import { Widget, WidgetData, WidgetInfo, Widgets, WidgetsData } from './startseite.interfaces';
import { WidgetsService } from './widgets.service';

interface Translations {
  [key: string]: string;
}

export interface IWidgetsManagerService {
  getAvailableWidgets(): Observable<Widgets>;
  reset(): void;
}

@Injectable({
  providedIn: 'root' // is used by the ResetService
})
export class WidgetsManagerService implements IWidgetsManagerService {
  /**
   * Holds the information about all existing widgets (icons, titles and which component to use).
   */
  private widgetInfo: WidgetInfo;

  /**
   * Holds the translation keys.
   */
  private translations: Translations | null = null;

  /**
   * Holds the available widgets.
   */
  private availableWidgets: Widgets | null = null;

  /**
   * Initializes the service.
   * @param translate {@link TranslateService}
   * @param widgetService {@link WidgetsService}
   */
  constructor(private translate: TranslateService, private widgetService: WidgetsService) {
    this.widgetInfo = {
      '1': {
        component: InfoWidgetComponent,
        titleKey: 'WIDGETS.INFO.TITEL',
        titleIcon: 'fa fa-info-circle',
        category: WidgetCategory.Allgemein,
        enableToggleWidth: true,
        enableToggleHeight: true
      },
      '2': {
        component: BasispreisMastschweineWidgetComponent,
        titleKey: 'WIDGETS.BASISPREIS.TITEL_MASTSCHWEINE',
        category: WidgetCategory.Marktinformationen,
        enableToggleWidth: true
      },
      '3': {
        component: LetzteLieferpartienComponent,
        titleKey: 'WIDGETS.LETZTE_LIEFERPARTIEN.TITEL',
        titleIcon: 'fa fa-bar-chart',
        category: WidgetCategory.Schlachtdaten,
        enableToggleHeight: true
      },
      '4': {
        component: AuffaelligeBetriebeWidgetComponent,
        titleKey: 'WIDGETS.AUFFAELLIGE_BETRIEBSSTAETTEN.TITEL',
        titleIcon: 'fa fa-bar-chart',
        category: WidgetCategory.Schlachtdaten,
        enableToggleHeight: true
      },
      '5': {
        component: QsZulassungWidgetComponent,
        titleKey: 'WIDGETS.QS_ABLAUF_ZULASSUNG.TITEL',
        titleIcon: 'iq-icon-font iq-icon-font-qualitaetssicherung',
        category: WidgetCategory.Qualitaetssicherung,
        enableToggleHeight: true
      },
      '6': {
        component: SalmonellenKategorieWidgetComponent,
        titleKey: 'WIDGETS.SALMO_TACHO.TITEL',
        titleIcon: 'iq-icon-font iq-icon-font-salmonellenmonitoring',
        category: WidgetCategory.Qualitaetssicherung
      },
      '7': {
        component: TermineWidgetComponent,
        titleKey: 'WIDGETS.TERMINE.TITEL',
        category: WidgetCategory.Allgemein,
        enableToggleHeight: true
      },
      '8': {
        component: QsZusammenfassungWidgetComponent,
        titleKey: 'WIDGETS.QS_ZUSAMMENFASSUNG.TITEL',
        titleIcon: 'iq-icon-font iq-icon-font-qualitaetssicherung',
        category: WidgetCategory.Qualitaetssicherung
      },
      '9': {
        component: BasispreisFerkelWidgetComponent,
        titleKey: 'WIDGETS.BASISPREIS.TITEL_FERKEL',
        category: WidgetCategory.Marktinformationen,
        enableToggleWidth: true
      },
      '10': {
        component: BasispreisSauenWidgetComponent,
        titleKey: 'WIDGETS.BASISPREIS.TITEL_SAUEN',
        category: WidgetCategory.Marktinformationen,
        enableToggleWidth: true
      },
      '11': {
        component: BasispreisRinderWidgetComponent,
        titleKey: 'WIDGETS.BASISPREIS.TITEL_RINDER',
        category: WidgetCategory.Marktinformationen,
        enableToggleWidth: true
      },
      '12': {
        component: QsMaengelbeseitigungWidgetComponent,
        titleKey: 'WIDGETS.QS_MAENGELBESEITIGUNG.TITEL',
        titleIcon: 'iq-icon-font iq-icon-font-qualitaetssicherung',
        category: WidgetCategory.Qualitaetssicherung,
        enableToggleHeight: true
      },
      '13': {
        component: ToenniesAgrarBlogWidgetComponent,
        titleKey: 'WIDGETS.TOENNIES_AGRARBLOG.TITEL',
        category: WidgetCategory.Allgemein,
        enableToggleHeight: false,
        enableToggleWidth: false
      },
      '14': {
        component: RegisteredUnregisteredVVVOsWidgetComponent,
        titleKey: 'WIDGETS.REGISTERED_UNREGISTERED_VVVOS.TITLE',
        category: WidgetCategory.Schlachtdaten,
        titleIcon: 'fa fa-bar-chart',
        enableToggleHeight: true,
        enableToggleWidth: false
      }
    };
  }

  /**
   * Returns all available widgets.
   */
  getAvailableWidgets(): Observable<Widgets> {
    if (this.availableWidgets) {
      return of(<Widgets>this.availableWidgets);
    } else {
      return this.getTranslations().pipe(
        switchMap((translations: Translations) => this.widgetService.getAvailableWidgets()),
        switchMap((backendWidgets: WidgetsData) => {
          if (Array.isArray(backendWidgets)) {
            this.availableWidgets = this.buildAvailableWidgets(backendWidgets);
            return of(<Widgets>this.availableWidgets);
          }

          return throwError('No available Widgets. Data: ' + backendWidgets);
        })
      );
    }
  }

  /**
   * Resets the cached data of the service.
   */
  reset(): void {
    this.translations = null;
    this.availableWidgets = null;
  }

  /**
   * Provides the translations for the widget titles
   */
  private getTranslations(): Observable<Translations> {
    if (this.translations) {
      return of(<Translations>this.translations);
    } else {
      const translationKeys = [];

      forEach(this.widgetInfo, (widget, key) => {
        translationKeys.push(widget.titleKey);
      });

      return this.translate.get(translationKeys).pipe(
        switchMap((translations: Translations) => {
          this.translations = translations;
          return of(<Translations>this.translations);
        })
      );
    }
  }

  /**
   * Combines the widget information from the BE with the information from the FE and the translated titles.
   */
  private buildAvailableWidgets(backendWidgets: WidgetsData): Widgets {
    const widgets: Widgets = [];

    forEach(backendWidgets, (backendWidget: WidgetData, key) => {
      if (this.widgetInfo[backendWidget.Id]) {
        const titleKey = this.widgetInfo[backendWidget.Id].titleKey;
        const titleIcon = this.widgetInfo[backendWidget.Id].titleIcon;
        const widget: Widget = {
          id: backendWidget.Id,
          component: this.widgetInfo[backendWidget.Id].component,
          displayName: this.translations[titleKey],
          title: this.translations[titleKey],
          name: backendWidget.Name,
          category: this.widgetInfo[backendWidget.Id].category,
          disabled: !backendWidget.Ausblendbar,
          enableToggleHeight: this.widgetInfo[backendWidget.Id].enableToggleHeight,
          enableToggleWidth: this.widgetInfo[backendWidget.Id].enableToggleWidth,
          x: undefined,
          y: undefined,
          rows: undefined,
          cols: undefined,
        };

        if (titleIcon) {
          widget.titleIcon = titleIcon;
        }

        widgets.push(widget);
      }
    });

    return widgets;
  }
}
