import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe for displaying a timer in the format: hh:mm:ss.
 */
@Pipe({
  name: 'timer'
})
export class TimerPipe implements PipeTransform {
  /**
   * Transforms the input (numerical value) into the desired format (hh:mm:ss)
   * @param input The numerical value in seconds
   */
  transform(input: number): any {
    const seconds = input % 60;
    const minutes = Math.floor((input % 3600) / 60);
    const hours = Math.floor(input / 3600);
    return this.format(hours) + ':' + this.format(minutes) + ':' + this.format(seconds);
  }

  /**
   * Formats the number passed.
   * Adds a leading zero if necessary.
   * @param n The number to format
   */
  private format(n: number): string {
    return (n < 10 ? '0' : '') + n;
  }
}
