import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FilterComponent } from '@share/filter/filter-components/filter-component.interfaces';
import { DatumInfo, LetzteXTage } from '@share/filter/uebergeordnete-filter.interfaces';
import { SelectedFilterItemsChangedEvent } from '@share/filter/filter-components/filter-component.events';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { filter as rxjsFilter, take } from 'rxjs/operators';
import { cloneDeep, isEqual, isNil } from 'lodash-es';
import { DatumFilterMode, LetzteXTageMode } from '@share/filter/datum-filter.enums';
import { Schlachttage } from '@share/filter/filter.interfaces';
import { DatePipe } from '@angular/common';
import { FORMAT } from '@core/constants';
import { DatumFilterModalComponent } from '@share/filter/filter-components/datum-filter/datum-filter-modal/datum-filter-modal.component';
import { FilterBereiche } from '@share/filter/filter-bereiche';

@Component({
  selector: 'iq-datum-filter',
  templateUrl: './datum-filter-button.component.html'
})
export class DatumFilterButtonComponent implements OnInit, OnChanges, OnDestroy, FilterComponent {
  /**
   * The selected dates
   */
  @Input()
  selectedItems: DatumInfo;

  /**
   * The filter section.
   */
  @Input()
  filterBereich: FilterBereiche;

  /**
   * Indicates whether the filter is disabled or not.
   */
  @Input()
  filterDisabled: boolean;

  /**
   * Event emitter that fires an event when the filter has changed.
   */
  @Output()
  selectedItemsChanged: EventEmitter<SelectedFilterItemsChangedEvent>;

  /**
   * The button text to be displayed.
   */
  buttonText: string;

  /**
   * The DatePipe for formatting date values
   */
  private datePipe: DatePipe;

  /**
   * Constructor.
   * @param translate {@link TranslateService}
   * @param modalService {@link BsModalService}
   */
  constructor(private translate: TranslateService, private modalService: BsModalService) {
    this.selectedItemsChanged = new EventEmitter<SelectedFilterItemsChangedEvent>();
    this.datePipe = new DatePipe(this.translate.getDefaultLang());
  }

  /**
   * Initializes the button text with an empty string.
   */
  ngOnInit() {
    this.buttonText = this.formatButtonText(this.selectedItems);
  }

  /**
   * If the filter item is changed, the button text is reformatted.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.buttonText = this.formatButtonText(this.selectedItems);
  }

  /**
   * Closes the FilterComponentChanged event emitter.
   */
  ngOnDestroy(): void {
    this.selectedItemsChanged.complete();
  }

  /**
   * Opens a modal dialog that is used to set the carcass weight.
   */
  openDatumModal(): void {
    const modalOptions: ModalOptions<DatumFilterModalComponent> = {
      backdrop: 'static',
      initialState: {
        datumInfo: cloneDeep(this.selectedItems),
        filterBereich: this.filterBereich
      }
    };
    const modalRef = this.modalService.show(DatumFilterModalComponent, modalOptions);

    modalRef.content.onClose$
      .asObservable()
      .pipe(
        take(1),
        rxjsFilter((newDatumInfo: DatumInfo | null) => !isNil(newDatumInfo))
      )
      .subscribe({
        next: (newDatumInfo: DatumInfo) => {
          const hasChanged = !isEqual(this.selectedItems, newDatumInfo);
          if (hasChanged) {
            const event = new SelectedFilterItemsChangedEvent(newDatumInfo);
            this.selectedItemsChanged.emit(event);
          }
        }
      });
  }

  /**
   * Formats the button text.
   */
  private formatButtonText(datumInfo: DatumInfo): string {
    let text = '';

    if (!datumInfo || !datumInfo.mode) {
      text = this.translate.instant('FILTER.PLACEHOLDER');
    } else {
      switch (datumInfo.mode) {
      case DatumFilterMode.SCHLACHTTAGE:
        text = this.formatSchlachttage(datumInfo.schlachttage);
        break;

      case DatumFilterMode.ZEITRAUM:
        text = this.formatZeitraum(datumInfo.zeitraum.from, datumInfo.zeitraum.to);
        break;

      case DatumFilterMode.LETZTE_X_TAGE:
        text = this.formatLetzteXTage(datumInfo.letzteXTage);
        break;

      default:
        text = '';
      }
    }

    return text;
  }

  /**
   * Help method for formatting the slaughter days.
   */
  private formatSchlachttage(schlachttage: Schlachttage | null) {
    let text = '';

    if (schlachttage.length === 1) {
      const item = schlachttage[0];
      text += item.displayName;
    } else {
      text += schlachttage.length;
      text += ' ';
      text += this.translate.instant('FILTER.DATUM_FILTER.SCHLACHTTAGE');
      text += ' ';
      text += this.translate.instant('FILTER.DATUM_FILTER.AUSGWAEHLT');
    }

    return text;
  }

  /**
   * Help method for formatting a period.
   */
  private formatZeitraum(from: Date | null, to: Date | null) {
    let text = '';
    const noLimitString = this.translate.instant('FILTER.DATUM_FILTER.OFFEN');

    if (from) {
      text += this.datePipe.transform(from, FORMAT.DATE_INPUT);
    } else {
      text += noLimitString;
    }

    text += ' - ';

    if (to) {
      text += this.datePipe.transform(to, FORMAT.DATE_INPUT);
    } else {
      text += noLimitString;
    }
    return text;
  }

  /**
   * Help method for formatting the slaughter days.
   */
  private formatLetzteXTage(letzteXTage: LetzteXTage) {
    let text = '';

    switch (letzteXTage.mode) {
    case LetzteXTageMode.LETZTE_30_TAGE:
      text = this.translate.instant('FILTER.DATUM_FILTER.LETZTE_30_TAGE');
      break;

    case LetzteXTageMode.LETZTE_90_TAGE:
      text = this.translate.instant('FILTER.DATUM_FILTER.LETZTE_90_TAGE');
      break;

    case LetzteXTageMode.LETZTE_180_TAGE:
      text = this.translate.instant('FILTER.DATUM_FILTER.LETZTE_180_TAGE');
      break;

    case LetzteXTageMode.LETZTE_365_TAGE:
      text = this.translate.instant('FILTER.DATUM_FILTER.LETZTE_365_TAGE');
      break;

    case LetzteXTageMode.FREIE_EINGABE:
      if (letzteXTage.anzahlTage === 1) {
        text = this.translate.instant('FILTER.DATUM_FILTER.LETZTER_TAG');
      } else {
        text = this.translate.instant('FILTER.DATUM_FILTER.LETZTE_XY_TAGE', { anzahlTage: letzteXTage.anzahlTage });
      }
      break;

    default:
      text = '';
    }
    return text;
  }
}
