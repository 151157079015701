import { Component, Inject, OnInit } from '@angular/core';
import { WINDOW } from '@core/constants';
import { PortalThemeColors } from '@core/theme/theme.interfaces';
import { ThemeService } from '@iq-angular-libs/portal';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { BasispreisBase } from '../share/basispreis-base/basispreis-base';
import { BasispreisData } from '../share/widgets.interfaces';
import { WidgetsService } from '../share/widgets.service';

@Component({
  selector: 'iq-basispreis-ferkel-widget',
  templateUrl: './basispreis-ferkel-widget.component.html',
  styleUrls: ['./basispreis-ferkel-widget.component.scss']
})
export class BasispreisFerkelWidgetComponent extends BasispreisBase implements OnInit {
  /**
   * Constructor
   * @param window The injected window object.
   * @param translate {@link TranslateService}
   * @param themeService {@link ThemeService}
   * @param widgetsService {@link WidgetsService}
   */
  constructor(
    @Inject(WINDOW) window: Window,
      translate: TranslateService,
      themeService: ThemeService<PortalThemeColors>,
    protected widgetsService: WidgetsService
  ) {
    super(window, translate, themeService, widgetsService);
    this.lineChartOptions.chartId = 'basispreis-ferkel-chart';
    this.lineChartOptions.yAxisLabel = this.translate.instant('WIDGETS.BASISPREIS.BASISPREIS_EURO_PRO_TIER');
  }

  /**
   * Triggers the fetching of the data
   */
  ngOnInit() {
    super.ngOnInit();
    this.getData();
  }

  /**
   * Retrieves the base price data for the piglets.
   */
  protected getBasispreisData(): Observable<BasispreisData> {
    return this.widgetsService.getBasispreisFerkelWidgetData();
  }
}
