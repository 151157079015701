import { FocusBetriebsstaettenModalComponent } from '@share/focus-betriebsstaetten-modal/focus-betriebsstaetten-modal.component';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { CurrentUser } from './../user/user.interfaces';
import { filter as filterRxjs, switchMap, take, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@core/user/user.service';
import { cloneDeep, filter, find, forEach, isArray, isNil } from 'lodash-es';
import { VALUES } from './../constants';
import { LocalStorageService } from '@mattlewis92/angular-2-local-storage';
import { Injectable } from '@angular/core';
import { LOCALSTORAGE } from '@core/local-storage/local-storage.constants';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, race, Subject } from 'rxjs';
import { FilterBetriebsstaette } from '@share/betriebsstaette.interface';
import { FilterBereiche } from '@share/filter/filter-bereiche';
import { UserPropertiesBereiche } from '@core/user/user.enums';

export interface IGlobalFilterService {
  getBetriebsstaetteChangedObservable(): void;
  setSelectedRegistrierungsnummern(user: CurrentUser, selectedRegistrierungsnummern: string[], toast?: boolean): void;
  getRegistrierungsnummern(): Observable<FilterBetriebsstaette[]>;
  getSelectedRegistrierungsnummern(): Observable<FilterBetriebsstaette[]>;
  extractRegistrierungsnummern(): Observable<string[]>;
  extractSelectedRegistrierungsnummern(): Observable<string[]>;
  ownerIsIdentical(betriebsstaetten: FilterBetriebsstaette[]): boolean;
  isLandwirt(): Observable<boolean>;
  reset(): void;
  addBetriebsstaette(selectedRegistrierungsnummer: string): void;
  focusBetriebstaetten(regnummern: string[], ignoreUserProperties?: boolean): void;
}

@Injectable()
export class GlobalFilterService implements IGlobalFilterService {
  /**
   * The available comercial units from the current user
   */
  private betriebsstaetten: FilterBetriebsstaette[] | null;

  /**
   * Subject to listen for comercial units changes
   */
  private betriebsstaettenChanged$: Subject<void>;

  constructor(
    private translate: TranslateService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private localStorageService: LocalStorageService,
    private userService: UserService
  ) {
    this.betriebsstaetten = null;
    this.betriebsstaettenChanged$ = new Subject<void>();

    this.subscribeOnBetriebsstaettenChangedEvents();
  }

  /**
   * Return the `betriebsstaettenChanged$` subject as observable
   */
  getBetriebsstaetteChangedObservable(): Observable<void> {
    return this.betriebsstaettenChanged$.asObservable();
  }

  /**
   * Selects the handed over registrationnumbers while deselecting all other registrationnumbers.
   * If the given registrationnumber-array is empty, null or undefined all registrationnumbers
   * will be deselected.
   * @param currentUser the current user.
   * @param selectedRegistrierungsnummern the selected registrationnumbers.
   * @param showToast if true, a toast will be displayed
   */
  setSelectedRegistrierungsnummern(
    currentUser: CurrentUser,
    selectedRegistrierungsnummern: string[] | null | undefined,
    broadcast: boolean = true,
    showToast?: boolean
  ): void {
    const isMassenzugang = currentUser.IstMassenzugang;
    let limitCount = 0;

    this.deselectAllRegistrierungsnummern();

    forEach(selectedRegistrierungsnummern, (registrierungsnummer: string) => {
      const index = find(this.betriebsstaetten, ['Registrierungsnummer', registrierungsnummer]);
      if (index && (limitCount < VALUES.GLOBAL_FILTER_SELECTION_LIMIT || !isMassenzugang)) {
        index.selected = true;
        limitCount++;
      }
    });

    if (broadcast) {
      this.betriebsstaettenChanged$.next();
    }

    if (showToast && limitCount === VALUES.GLOBAL_FILTER_SELECTION_LIMIT && isMassenzugang) {
      this.toastr.info(
        this.translate.instant('GLOBALE_FILTER.BETRIEBSSTAETTEN_FILTER.TOASTS.ALLE_MARKIEREN_LIMIT', {
          limit: VALUES.GLOBAL_FILTER_SELECTION_LIMIT
        }),
        this.translate.instant('ALLGEMEIN.TOASTS.HINWEIS')
      );
    } else if (showToast) {
      this.toastr.info(
        this.translate.instant('GLOBALE_FILTER.BETRIEBSSTAETTEN_FILTER.TOASTS.BETRIEBSSTAETTEN_UEBERNOMMEN'),
        this.translate.instant('ALLGEMEIN.TOASTS.HINWEIS')
      );
    }
  }

  /**
   * Returns the registrationnumbers of the user.
   * If they are not available they will be requested from the backend.
   * Afterwards a automatic selection of registrationnumbers takes place.
   */
  getRegistrierungsnummern(): Observable<FilterBetriebsstaette[]> {
    if (this.betriebsstaetten) {
      return of(cloneDeep(this.betriebsstaetten));
    } else {
      return this.userService.getCurrentUser().pipe(
        take(1),
        switchMap((currentUser: CurrentUser) => {
          const betriebsstaetten: FilterBetriebsstaette[] = cloneDeep(currentUser.Betriebsstaetten);

          forEach(betriebsstaetten, (betriebsstaette: FilterBetriebsstaette) => {
            betriebsstaette.displayName = betriebsstaette.Registrierungsnummer;
            betriebsstaette.displayName += betriebsstaette.Name ? ': ' + betriebsstaette.Name : '';
            betriebsstaette.displayName += betriebsstaette.Ort ? ' (' + betriebsstaette.Ort + ')' : '';
          });

          this.betriebsstaetten = betriebsstaetten;
          this.initSelectedRegistrierungsnummern(currentUser);
          return of(cloneDeep(this.betriebsstaetten));
        })
      );
    }
  }

  /**
   * Returns the selected registrationnumbers in form of an array of objects
   */
  getSelectedRegistrierungsnummern(): Observable<FilterBetriebsstaette[]> {
    return this.getRegistrierungsnummern().pipe(
      switchMap(betriebsstaetten => {
        const selectedRegistrierungsnummern = filter(betriebsstaetten, { selected: true });
        return of(selectedRegistrierungsnummern);
      })
    );
  }

  /**
   * Return the users registrationnumbers in form of an number array.
   * This function is mainly used for requests to the backend.
   */
  extractRegistrierungsnummern(): Observable<string[]> {
    return this.getRegistrierungsnummern().pipe(
      switchMap((betriebsstaetten: FilterBetriebsstaette[]) => {
        let nummern: string[] = [];
        if (Array.isArray(betriebsstaetten)) {
          nummern = betriebsstaetten.map(item => item.Registrierungsnummer);
        }
        return of(nummern);
      })
    );
  }

  /**
   * Return the selected registrationnumbers from the user in form of an number array.
   * This function is mainly used for requests to the backend.
   */
  extractSelectedRegistrierungsnummern(program?: string): Observable<string[]> {
    return this.getSelectedRegistrierungsnummern().pipe(
      switchMap((selectedBetriebsstaetten: FilterBetriebsstaette[]) => {
        let nummern: string[] = [];
        if (Array.isArray(selectedBetriebsstaetten)) {
          let betriebsstaetten = selectedBetriebsstaetten;
          if (program) {
            betriebsstaetten = selectedBetriebsstaetten.filter(item => item.Programme?.includes(program));
          }
          nummern = betriebsstaetten.map(item => item.Registrierungsnummer);
        }
        return of(nummern);
      })
    );
  }

  /**
   * Checks if the given comercial units have the same owner.
   * @param betriebsstaetten comercial units to check
   */
  ownerIsIdentical(betriebsstaetten: FilterBetriebsstaette[]): boolean {
    let identical = true;
    if (Array.isArray(betriebsstaetten)) {
      for (let i = 1; i < betriebsstaetten.length; i++) {
        if (
          betriebsstaetten[i].BesitzerId !== betriebsstaetten[0].BesitzerId ||
          isNil(betriebsstaetten[0].BesitzerId)
        ) {
          identical = false;
          break;
        }
      }
    }
    return identical;
  }

  /**
   * Checks if the user is a farmer.
   * Gets all registrationnumbers and checks via `ownerIsIdentical` if the user
   * is the owner of all available comerical units. If this is the case, he is
   * a farmer.
   */
  isLandwirt(): Observable<boolean> {
    return this.getRegistrierungsnummern().pipe(
      switchMap(betriebsstaetten => of(this.ownerIsIdentical(betriebsstaetten)))
    );
  }

  /**
   * Resets the stored comercial units
   */
  reset() {
    this.betriebsstaetten = null;
  }

  /**
   * Selects the comercial units with the given registrationnumbers
   * @param registrierungsnummern the registrationnumber of the comercial units which should be selected
   */
  addBetriebsstaette(registrierungsnummern: string) {
    let isMassenzugang = false;

    this.userService
      .getCurrentUser()
      .pipe(
        take(1),
        switchMap((currentUser: CurrentUser) => {
          isMassenzugang = currentUser.IstMassenzugang;
          return this.getSelectedRegistrierungsnummern();
        })
      )
      .subscribe((selectedRegistrierungsnummern: FilterBetriebsstaette[]) => {
        const betriebsstaette: FilterBetriebsstaette[] = filter(this.betriebsstaetten, [
          'Registrierungsnummer',
          registrierungsnummern
        ]);

        const limitReached = isMassenzugang
          ? !(
            !selectedRegistrierungsnummern ||
              selectedRegistrierungsnummern.length < VALUES.GLOBAL_FILTER_SELECTION_LIMIT
          )
          : false;

        if (Array.isArray(betriebsstaette)) {
          if (betriebsstaette.length > 0) {
            if (!betriebsstaette[0].selected && !limitReached) {
              betriebsstaette[0].selected = true;

              this.betriebsstaettenChanged$.next();

              this.toastr.info(
                this.translate.instant('GLOBALE_FILTER.BETRIEBSSTAETTEN_FILTER.TOASTS.BETRIEBSSTAETTE_UEBERNOMMEN'),
                this.translate.instant('ALLGEMEIN.TOASTS.HINWEIS')
              );
            } else if (!betriebsstaette[0].selected && limitReached) {
              this.toastr.info(
                this.translate.instant('GLOBALE_FILTER.BETRIEBSSTAETTEN_FILTER.TOASTS.AUSWAHL_LIMIT_ERREICHT', {
                  limit: VALUES.GLOBAL_FILTER_SELECTION_LIMIT
                }),
                this.translate.instant('ALLGEMEIN.TOASTS.HINWEIS')
              );
            } else {
              this.toastr.info(
                this.translate.instant(
                  'GLOBALE_FILTER.BETRIEBSSTAETTEN_FILTER.TOASTS.BETRIEBSSTAETTE_BEREITS_VORHANDEN'
                ),
                this.translate.instant('ALLGEMEIN.TOASTS.HINWEIS')
              );
            }
          } else {
            this.toastr.warning(
              this.translate.instant('GLOBALE_FILTER.BETRIEBSSTAETTEN_FILTER.TOASTS.BETRIEBSSTAETTE_NICHT_UEBERNOMMEN'),
              this.translate.instant('ALLGEMEIN.TOASTS.WARNUNG')
            );
          }
        }
      });
  }

  /**
   * Opens a modal dialog that allows to add the selected comercial units.
   * @param registrierungsnummern the registrationnumbers that should be selected
   * @param ignoreUserProperties determines if the user properties should be ignored
   */
  focusBetriebstaetten(registrierungsnummern: string[], ignoreUserProperties?: boolean) {
    this.userService
      .getCurrentUser()
      .pipe(take(1))
      .subscribe((user: CurrentUser) => {
        if (user.userProperties.allgemein.RegnummernFokussierenHinweisAnzeigen && !ignoreUserProperties) {
          const modalOptions: ModalOptions<FocusBetriebsstaettenModalComponent> = {
            backdrop: 'static',
            initialState: {
              currentUser: user,
              registrierungsnummern: registrierungsnummern,
              propertiesHint: {
                bereich: UserPropertiesBereiche.ALLGEMEIN,
                field: 'RegnummernFokussierenHinweisAnzeigen'
              },
              rubrikTitel: null
            }
          };

          const modalRef = this.modalService.show(FocusBetriebsstaettenModalComponent, modalOptions);
          const modalHidden$ = this.modalService.onHidden.pipe(
            filterRxjs(reason => reason === 'esc'),
            switchMap(() => of(<boolean>false))
          );

          race<boolean[]>([modalRef.content.onClose$.asObservable(), modalHidden$])
            .pipe(take(1))
            .subscribe({
              next: closed => {
                if (closed) {
                  this.setSelectedRegistrierungsnummern(user, registrierungsnummern, true);
                }
              },
              error: () => {}
            });
        } else {
          this.setSelectedRegistrierungsnummern(user, registrierungsnummern, true);
        }
      });
  }

  /**
   * Subscribs to the "comerical untis changed" stream.
   * On each event, the inside localstorage stored comparison slaughterhouse ids for all filter section will be deleted.
   * If selected comercial units exist, the respectiv registrationnumbers will be stored inside the localstorage.
   */
  private subscribeOnBetriebsstaettenChangedEvents(): void {
    this.getBetriebsstaetteChangedObservable()
      .pipe(
        switchMap(() => {
          this.localStorageService.remove(LOCALSTORAGE.VERGLEICHS_SCHLACHTBETRIEB_ID + '.' + FilterBereiche.SO);
          this.localStorageService.remove(LOCALSTORAGE.VERGLEICHS_SCHLACHTBETRIEB_ID + '.' + FilterBereiche.ETM);
          this.localStorageService.remove(LOCALSTORAGE.VERGLEICHS_SCHLACHTBETRIEB_ID + '.' + FilterBereiche.ETZ);
          this.localStorageService.remove(LOCALSTORAGE.FILTER.GLOBAL.BETRIEBSSTAETTEN_FILTER_SELECTION);

          return this.getSelectedRegistrierungsnummern();
        }),
        filterRxjs((selectedBetriebsstaetten: FilterBetriebsstaette[]) => isArray(selectedBetriebsstaetten)),
        tap((selectedBetriebsstaetten: FilterBetriebsstaette[]) => {
          const nummern = selectedBetriebsstaetten.map(item => item.Registrierungsnummer);
          this.localStorageService.set(LOCALSTORAGE.FILTER.GLOBAL.BETRIEBSSTAETTEN_FILTER_SELECTION, nummern);
        })
      )
      .subscribe();
  }

  /**
   * Unselects all registrationnumbers
   */
  private deselectAllRegistrierungsnummern() {
    if (this.betriebsstaetten) {
      forEach(this.betriebsstaetten, (betriebsstaette: FilterBetriebsstaette) => {
        betriebsstaette.selected = false;
      });
    }
  }

  /**
   * Selects the registrationnumbers according to the entries in the localstorage, when there are entries available.
   * If no entries available, a check is performed if the user owns all comercial units.
   * If he owns all comercial units, all comercial units will be selected.
   */
  private initSelectedRegistrierungsnummern(user: CurrentUser): void {
    const filterSelection = this.localStorageService.get<string[]>(
      LOCALSTORAGE.FILTER.GLOBAL.BETRIEBSSTAETTEN_FILTER_SELECTION
    );
    if (filterSelection) {
      if (Array.isArray(filterSelection)) {
        this.setSelectedRegistrierungsnummern(user, filterSelection, false);
      }
    } else if (Array.isArray(this.betriebsstaetten) && this.ownerIsIdentical(this.betriebsstaetten)) {
      const allBetriebsstaetten = this.betriebsstaetten.map((betriebsstaette: FilterBetriebsstaette) => betriebsstaette.Registrierungsnummer);

      this.setSelectedRegistrierungsnummern(user, allBetriebsstaetten, true, false);
    }
  }
}
