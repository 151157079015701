<div class="modal-header">
  <h4 class="modal-title">{{ 'FEHLER.FEHLERSEITE_TITEL' | translate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="decline()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="server-error-picture ml-auto mr-auto"></div>
  <div class="text-content mt-4">
    <h3>{{ 'FEHLER.CANNOT_PROCEED' | translate }}</h3>
    <p class="pt-3">{{ 'FEHLER.TRY_AGAIN_LATER' | translate }}</p>
  </div>
</div>
<div class="modal-footer">
  <div class="ml-auto mr-auto">
    <button type="button" class="btn btn-primary" (click)="decline()">
      {{ 'ALLGEMEIN.SCHLIESSEN' | translate }}
    </button>
  </div>
</div>
