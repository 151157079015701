import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'antibiotikaTherapieindex'
})
export class AntibiotikaTherapieindexPipe implements PipeTransform {
  /**
   * DecimalPipe to format the value
   */
  private decimalPipe: DecimalPipe;

  /**
   * Initializes the decimal pipe
   * @param translate {@link TranslateService}
   */
  constructor(private translate: TranslateService) {
    this.decimalPipe = new DecimalPipe(this.translate.getDefaultLang());
  }

  /**
   * Transforms the therapy index values in the correct values.
   * If value is null/undefined an empty string is returned.
   * If a therapy value is null the translation key 'ANTIBIOTIKA.THERAPIEINDEX.KEINER'
   * will be translated and returned.
   */
  transform(value: any, args?: any): any {
    if (isNil(value)) {
      return '';
    } else {
      if (isNil(value.Value2)) {
        return isNil(value.Value1)
          ? this.translate.instant('ANTIBIOTIKA.THERAPIEINDEX.KEINER')
          : this.decimalPipe.transform(value.Value1, '1.2-2');
      } else {
        return (
          this.decimalPipe.transform(value.Value1, '1.2-2') + '/' + this.decimalPipe.transform(value.Value2, '1.2-2')
        );
      }
    }
  }
}
