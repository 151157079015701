import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LegendItem } from '@share/chart-helper/chart.interfaces';
import { Router } from '@angular/router';

@Component({
  selector: 'iq-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IqLegendComponent {
  /**
   * The data to be displayed left-justified in the legend
   */
  @Input()
  dataLeft: LegendItem[];

  /**
   * The data to be displayed right-aligned in the legend
   */
  @Input()
  dataRight: LegendItem[];

  /**
   * Router is required to query the current Url
   */
  constructor(public router: Router) {}

  /**
   * Provides the necessary elements for creating a gradient
   * @param color
   */
  getGradientElements(color: string | string[]): string[] {
    if (typeof color === 'string') {
      return [color];
    } else if (Array.isArray(color)) {
      return color;
    }

    return [];
  }
}
