import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalFilterService } from '@core/global-filter/global-filter.service';
import { CurrentUser } from '@core/user/user.interfaces';
import { UserService } from '@core/user/user.service';
import { CellComponent, SortConfig } from '@iq-angular-libs/portal';
import { TranslateService } from '@ngx-translate/core';
import { FilterBetriebsstaette } from '@share/betriebsstaette.interface';
import { concat, forEach } from 'lodash-es';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { switchMap, take, tap } from 'rxjs/operators';
import { DetailsAufrufenModalComponent } from './details-aufrufen-modal/details-aufrufen-modal.component';

/**
 * Specifies the available options when the DetailsButtonComponent is used as CellComponent.
 */
export interface DetailsAufrufenOptions {
  tooltipPlacement: string;
  tooltipText: string;
  buttonText?: string;
  rubrikTitelTranslationKey: string;
  route: string;
  routeParamData: string[];
  routeParamStatic: string[];
  skipLocation: boolean;
  showIcon: boolean;
}

@Component({
  selector: 'iq-details-aufrufen-button',
  templateUrl: './details-aufrufen-button.component.html'
})
export class DetailsAufrufenButtonComponent implements OnInit, CellComponent {
  /** * Data structure if the component is used in the OverviewTable component ***/

  /**
   * The data of the table row
   */
  @Input()
  data: any;

  /**
   * The SortConfig of the table
   */
  @Input()
  sortConfig?: SortConfig;

  /**
   * The options that contain all relevant data for the component
   */
  @Input()
  options?: DetailsAufrufenOptions;

  /** * Data structure for normal use of the component ***/

  /**
   * The displayed button Text
   */
  @Input()
  buttonText: string;

  /**
   * The route information
   */
  @Input()
  routeInformation: string[];

  /**
   * Tooltip Labeling
   */
  @Input()
  tooltipText: string;

  /**
   * Tooltip Position
   */
  @Input()
  tooltipPlacement: string;

  /**
   * Active category
   */
  @Input()
  rubrikTitelTranslationKey: string;

  /**
   * Selected registration number
   */
  @Input()
  selectedRegistrierungsnummer: string;

  /**
   * Change URL to status bar
   */
  @Input()
  skipLocationChange = false;

  /**
   * Indicates whether the icon is displayed in the button
   */
  @Input()
  showIcon = true;

  /**
   * Specifies whether the component is used as CellComponent
   */
  isCellComponent: boolean;

  /**
   * Currently logged in user
   */
  currentUser: CurrentUser;

  constructor(
    private globalFilterService: GlobalFilterService,
    private translate: TranslateService,
    private userService: UserService,
    private router: Router,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    // If data and options are present, the component will be used in the OverviewTableComponent and
    // the data from the options will be set to the "normal" properties of the component.
    // So the component can be used as usual.
    if (this.data && this.options) {
      this.isCellComponent = true;
      this.buttonText = this.options.buttonText;
      this.tooltipText = this.options.tooltipText;
      this.tooltipPlacement = this.options.tooltipPlacement;
      this.rubrikTitelTranslationKey = this.options.rubrikTitelTranslationKey;
      this.selectedRegistrierungsnummer = this.data['Registrierungsnummer'];
      this.skipLocationChange = this.options.skipLocation;
      this.routeInformation = this.buildRouteInformation();
      this.showIcon = this.options.showIcon;
    }
  }

  /**
   * Gets the selected registration numbers of the comerical units filter.
   * Checks whether the transferred comerical unit is selected.
   * Gets the data of the current user.
   * Opens a modal dialog, which informs about the addition of the comerical units,
   * if the comerical unit is not selected and the user settings allow to open the dialog.
   */
  startDetailsNavigation(): void {
    let selectedRegistrierungsnummern: FilterBetriebsstaette[];

    this.globalFilterService
      .getSelectedRegistrierungsnummern()
      .pipe(
        take(1),
        tap(betriebstaetten => {
          selectedRegistrierungsnummern = betriebstaetten;
        }),
        switchMap(() => this.userService.getCurrentUser().pipe(take(1))),
        tap((user: CurrentUser) => {
          this.currentUser = user;

          if (selectedRegistrierungsnummern.length === 0) {
            this.openDetails(true);
          } else if (
            selectedRegistrierungsnummern.length === 1 &&
            selectedRegistrierungsnummern[0].Registrierungsnummer === this.selectedRegistrierungsnummer
          ) {
            this.openDetails(false);
          } else {
            if (this.currentUser.userProperties.allgemein.DetailNavigationHinweisAnzeigen) {
              this.openModal();
            } else {
              this.openDetails(true);
            }
          }
        })
      )
      .subscribe();
  }

  /**
   * Opens the detail view of the respective category and adds the comerical unit to the selected comerical units if necessary
   * @param broadcast Specifies whether the element should be added to the selected sites
   */
  openDetails(broadcast: boolean): void {
    if (broadcast && this.selectedRegistrierungsnummer) {
      this.globalFilterService.setSelectedRegistrierungsnummern(this.currentUser, [this.selectedRegistrierungsnummer]);
    }

    this.router.navigate(this.routeInformation, { skipLocationChange: this.skipLocationChange });
  }

  /**
   * Öffnet das Modal
   */
  openModal(): void {
    const modalOptions: ModalOptions<DetailsAufrufenModalComponent> = {
      backdrop: 'static',
      initialState: {
        currentUser: this.currentUser,
        rubrikTitel: this.translate.instant(this.rubrikTitelTranslationKey)
      }
    };

    const modalRef = this.modalService.show(DetailsAufrufenModalComponent, modalOptions);

    modalRef.content.onClose$
      .asObservable()
      .pipe(take(1))
      .subscribe({
        next: closed => {
          if (closed) {
            this.openDetails(true);
          }
        },
        error: () => {}
      });
  }

  /**
   * Builds a route information array representing the route to be navigated.
   * This function is only used if the component is used as CellComponent.
   */
  private buildRouteInformation(): string[] {
    let routeInformations = [this.options.route];
    if (this.options && this.options.routeParamData) {
      forEach(this.options.routeParamData, value => {
        routeInformations.push(this.data[value] || 'null');
      });
    }
    if (this.options && this.options.routeParamStatic) {
      routeInformations = concat(routeInformations, this.options.routeParamStatic);
    }

    if (this.sortConfig.sortFields?.length) {
      routeInformations.push(this.sortConfig.sortFields[0].sortType ? this.sortConfig.sortFields[0].sortType : '');
      routeInformations.push(this.sortConfig.sortFields[0].sortOrder ? this.sortConfig.sortFields[0].sortOrder : '');
    } else if (this.sortConfig.sortType && this.sortConfig.sortOrder) {
      routeInformations.push(this.sortConfig.sortType);
      routeInformations.push(this.sortConfig.sortOrder);
    }

    return routeInformations;
  }
}
