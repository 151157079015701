import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ConfirmDeclineModalOptions } from './shared/confirm-decline-modal-options.interface';

@Component({
  selector: 'iq-confirm-decline-modal',
  templateUrl: './confirm-decline-modal.component.html'
})
export class ConfirmDeclineModalComponent implements ConfirmDeclineModalOptions {
  /** Translation key for the modal heading */
  @Input()
  ueberschriftTranslationKey: string;

  /** Translation key for the modal description */
  @Input()
  beschreibungTranslationKey: string;

  /** An object that represents the values for the placeholders in the description text */
  @Input()
  beschreibungsVariablen?: { [key: string]: string | number };

  /** Translation key for the modal hint */
  @Input()
  hinweisTranslationKey?: string;

  /** Translation key for the accept/accept button */
  @Input()
  annehmenButtonTranslationKey?: string;

  /** Translation key for the cancel/reject button */
  @Input()
  ablehnenButtonTranslationKey?: string;

  /**
   * Action button main class
   */
  @Input()
  buttonClass = 'btn-primary';

  /**
   * Subject to transmit events when closing the modal.
   */
  onClose$: Subject<boolean>;

  constructor(private bsModalRef: BsModalRef) {
    this.onClose$ = new Subject();
  }

  /**
   * Is called when modal content is accepted/accepted. Push true into the
   * $onClose Subject and close the modal.
   */
  accept() {
    this.onClose$.next(true);
    this.onClose$.complete();
    this.bsModalRef.hide();
  }

  /**
   * Is called if modal content is rejected, the modal is aborted. Pushs false into the
   * $onClose Subject and close the modal.
   */
  decline() {
    this.onClose$.next(false);
    this.onClose$.complete();
    this.bsModalRef.hide();
  }
}
