import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FilterComponent } from '../../filter-component.interfaces';
import { MinMax } from '../../../uebergeordnete-filter.interfaces';
import { SelectedFilterItemsChangedEvent } from '../../filter-component.events';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, isEqual, isNil } from 'lodash-es';
import { DecimalPipe } from '@angular/common';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { filter as rxjsFilter, take } from 'rxjs/operators';
import { TzKalkFilterModalComponent } from '../tz-kalk-filter-modal/tz-kalk-filter-modal.component';
import { FilterBereiche } from '@share/filter/filter-bereiche';

@Component({
  selector: 'iq-tz-kalk-filter',
  templateUrl: './tz-kalk-filter-button.component.html'
})
export class TzKalkFilterButtonComponent implements OnInit, OnChanges, OnDestroy, FilterComponent {
  /**
   * The selected genders
   */
  @Input()
  selectedItems: MinMax;

  /**
   * The filter section.
   */
  @Input()
  filterBereich: FilterBereiche;

  /**
   * Indicates whether the filter is disabled or not.
   */
  @Input()
  filterDisabled: boolean;

  /**
   * Specifies whether or not a tooltip should be displayed when the filter is deactivated.
   */
  @Input()
  showNotAvailableTooltip?: boolean;

  /**
   * Event emitter that fires an event when the filter has changed.
   */
  @Output()
  selectedItemsChanged: EventEmitter<SelectedFilterItemsChangedEvent>;

  /**
   * The button text to be displayed.
   */
  buttonText: string;

  /**
   * The decimal pipe for formatting numbers.
   */
  private decimalPipe: DecimalPipe;

  /**
   * Constructor.
   * @param translate {@link TranslateService}
   * @param globalFilterService {@link GlobalFilterService}
   */
  constructor(private translate: TranslateService, private modalService: BsModalService) {
    this.selectedItemsChanged = new EventEmitter<SelectedFilterItemsChangedEvent>();
    this.decimalPipe = new DecimalPipe(this.translate.getDefaultLang());
  }

  /**
   * Initializes the button text with an empty string.
   */
  ngOnInit() {
    this.buttonText = this.formatButtonText(this.selectedItems);
  }

  /**
   *If the filter item is changed, the button text is reformatted.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.buttonText = this.formatButtonText(this.selectedItems);
  }

  /**
   * Closes the FilterComponentChanged event emitter.
   */
  ngOnDestroy(): void {
    this.selectedItemsChanged.complete();
  }

  /**
   * Opens a modal dialog that is used to set the TzKalk filter.
   */
  openTzKalkModal(): void {
    const modalOptions: ModalOptions<TzKalkFilterModalComponent> = {
      backdrop: 'static',
      initialState: {
        ranges: cloneDeep<MinMax>(this.selectedItems)
      }
    };
    const modalRef = this.modalService.show(TzKalkFilterModalComponent, modalOptions);

    modalRef.content.onClose$
      .asObservable()
      .pipe(
        take(1),
        rxjsFilter((newTzKalk: MinMax | null) => !isNil(newTzKalk))
      )
      .subscribe({
        next: (newTzKalk: MinMax) => {
          const hasChanged = !isEqual(this.selectedItems, newTzKalk);
          if (hasChanged) {
            const event = new SelectedFilterItemsChangedEvent(newTzKalk);
            this.selectedItemsChanged.emit(event);
          }
        }
      });
  }

  /**
   * Formats the button text.
   */
  private formatButtonText(tzkalk: MinMax): string {
    let text = '';
    if (!tzkalk || (isNil(tzkalk.min) && isNil(tzkalk.max))) {
      text = this.translate.instant('FILTER.PLACEHOLDER');
    } else {
      if (!isNil(tzkalk.min)) {
        text += this.decimalPipe.transform(tzkalk.min, '1.0-5') + ' ' + this.translate.instant('MASSEINHEITEN.G');
      } else {
        text += this.translate.instant('FILTER.TZ_KALK_FILTER.OFFEN');
      }

      text += ' - ';

      if (!isNil(tzkalk.max)) {
        text += this.decimalPipe.transform(tzkalk.max, '1.0-5') + ' ' + this.translate.instant('MASSEINHEITEN.G');
      } else {
        text += this.translate.instant('FILTER.TZ_KALK_FILTER.OFFEN');
      }
    }
    return text;
  }
}
