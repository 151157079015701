import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '@core/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { CurrentUser } from '@core/user/user.interfaces';
import { FocusBetriebsstaettenModalComponent } from '@share/focus-betriebsstaetten-modal/focus-betriebsstaetten-modal.component';
import { filter, switchMap, take } from 'rxjs/operators';
import { of, race } from 'rxjs';
import { GlobalFilterService } from '@core/global-filter/global-filter.service';
import { Router } from '@angular/router';
import { UserPropertiesBereiche } from '@core/user/user.enums';

@Component({
  selector: 'iq-focus-betriebsstaetten-nav-button',
  templateUrl: './focus-betriebsstaetten-nav-button.component.html',
  styleUrls: ['./focus-betriebsstaetten-nav-button.component.scss']
})
export class FocusBetriebsstaettenNavButtonComponent implements OnInit {
  /**
   * The tooltip text for the button
   */
  @Input()
  tooltipText: string;

  /**
   * Tooltip placement
   */
  @Input()
  tooltipPlacement: string;

  /**
   * The text for the button (translated)
   */
  @Input()
  buttonText: string;

  /**
   * The comercial units which should be taken over
   */
  @Input()
  betriebsstaetten: string[];

  /**
   * The route that should be navigated to
   */
  @Input()
  toRoute: string;

  /**
   * The title of the section which should be navigated to
   */
  @Input()
  rubrikTitelTranslationKey: string;

  /**
   * Constructor
   * @param userService {@link UserService}
   * @param translate {@link TranslateService}
   * @param modalService {@link BsModalService}
   * @param globalFilterService {@link GlobalFilterService}
   * @param router {@link Router}
   */
  constructor(
    private userService: UserService,
    private translate: TranslateService,
    private modalService: BsModalService,
    private globalFilterService: GlobalFilterService,
    private router: Router
  ) {}

  /**
   * Sets the initial tooltip text and the placement, when not already set
   */
  ngOnInit() {
    this.tooltipText = this.tooltipText || '';
    this.tooltipPlacement = this.tooltipPlacement || 'bottom';
  }

  /**
   * Gets the data of the current user.
   * Open the modal dialog, which informs
   * Opens a modal dialog which informs about focusing the registration numbers,
   * if the user settings allow the dialog to be opened.
   * Opens the rubic specified with @link{iqPortalApp.directive.IqFokusRegistrierungsnummernNavButtonCtrl:openRubrik}.
   */
  startNavigation() {
    this.userService
      .getCurrentUser()
      .pipe(take(1))
      .subscribe((user: CurrentUser) => {
        if (user.userProperties[UserPropertiesBereiche.ALLGEMEIN].RegnummernFokussierenMitRoutingHinweisAnzeigen) {
          const modalOptions: ModalOptions<FocusBetriebsstaettenModalComponent> = {
            backdrop: 'static',
            initialState: {
              currentUser: user,
              registrierungsnummern: this.betriebsstaetten,
              propertiesHint: {
                bereich: UserPropertiesBereiche.ALLGEMEIN,
                field: 'RegnummernFokussierenMitRoutingHinweisAnzeigen'
              },
              rubrikTitel: this.translate.instant(this.rubrikTitelTranslationKey)
            }
          };

          const modalRef = this.modalService.show(FocusBetriebsstaettenModalComponent, modalOptions);
          const modalHidden$ = this.modalService.onHidden.pipe(
            filter(reason => reason === 'esc'),
            switchMap(() => of(<boolean>false))
          );

          race<boolean[]>([modalRef.content.onClose$.asObservable(), modalHidden$])
            .pipe(take(1))
            .subscribe({
              next: closed => {
                if (closed) {
                  this.openRubrik();
                }
              },
              error: () => {}
            });
        } else {
          this.openRubrik();
        }
      });
  }

  /**
   * Fires an event to focus the registrationsnumbers
   * Navigates to the given rubic
   */
  private openRubrik() {
    if (this.betriebsstaetten) {
      this.globalFilterService.focusBetriebstaetten(this.betriebsstaetten, true);
    }
    this.router.navigate([this.toRoute]);
  }
}
