<ngx-charts-chart
  id="{{ chartId }}"
  [view]="[width, height]"
  [showLegend]="legend"
  [legendOptions]="legendOptions"
  [activeEntries]="activeEntries"
  [animations]="animations"
>
  <svg:g [attr.transform]="transform" class="bar-chart chart">
    <svg:g
      iq-charts-x-axis
      *ngIf="xAxis"
      [xScale]="xScale"
      [dims]="dims"
      [showLabel]="showXAxisLabel"
      [labelText]="xAxisLabel"
      [showGridLines]="true"
      [tickFormatting]="xAxisTickFormatting"
      [rotateTicks]="rotateXAxisTicks"
      (dimensionsChanged)="updateXAxisHeight($event)"
    ></svg:g>
    <svg:g
      ngx-charts-y-axis
      *ngIf="yAxis"
      [yScale]="yScale"
      [dims]="dims"
      [yOrient]="yOrientLeft"
      [showGridLines]="showGridLines"
      [showLabel]="showYAxisLabel"
      [labelText]="yAxisLabel"
      [tickFormatting]="yAxisTickFormatting"
      (dimensionsChanged)="updateYAxisWidth($event)"
    ></svg:g>
    <svg:g
      ngx-charts-y-axis
      *ngIf="yAxis"
      [yScale]="yScaleLine"
      [dims]="dims"
      [yOrient]="yOrientRight"
      [showGridLines]="false"
      [showLabel]="showRightYAxisLabel"
      [labelText]="yAxisLabelRight"
      [yAxisOffset]="-5"
      [tickFormatting]="yAxisRightTickFormatting"
      (dimensionsChanged)="updateYAxisWidth($event)"
    ></svg:g>
    <svg:g
      iq-charts-line
      *ngIf="nullLinePath"
      class="line-series"
      [path]="nullLinePath"
      [stroke]="'lightgrey'"
      [strokeWidth]="'3.0px'"
      [animations]="animations"
      [class.active]="true"
    />
    <svg:g
      iq-charts-line
      *ngIf="y1AxisLinePath"
      class="line"
      [path]="y1AxisLinePath"
      [stroke]="'#000'"
      [strokeWidth]="'1'"
      [animations]="animations"
    />
    <svg:g
      iq-charts-line
      *ngIf="y2AxisLinePath"
      class="line"
      [path]="y2AxisLinePath"
      [stroke]="'#000'"
      [strokeWidth]="'1'"
      [animations]="animations"
    />
    <svg:g
      iq-combo-charts-series-vertical
      [xScale]="xScale"
      [yScale]="yScale"
      [colors]="colors"
      [series]="results"
      [seriesLine]="lineChart"
      [dims]="dims"
      [gradient]="gradient"
      [tooltipTemplate]="tooltipTemplateBar"
      [tooltipDisabled]="tooltipDisabled"
      [activeEntries]="activeEntries"
      [animations]="animations"
      [scaleAbsolute]="yLeftAxisScaleAbsolute"
      [highlightProperty]="highlightProperty"
      [highlightColor]="highlightColor"
      (bandwidth)="updateLineWidth($event)"
    ></svg:g>
  </svg:g>
  <svg:g [attr.transform]="transform" class="line-chart chart">
    <svg:g *ngFor="let series of lineChart; trackBy: trackBy">
      <svg:g
        iq-charts-line-series
        [xScale]="xScaleLine"
        [yScale]="yScaleLine"
        [colors]="colorsLine"
        [data]="series"
        [chartLineData]="lineChart"
        [activeEntries]="activeEntries"
        [scaleType]="scaleType"
        [curve]="curve"
        [rangeFillOpacity]="rangeFillOpacity"
        [animations]="animations"
        [tooltipTemplate]="tooltipTemplateLine"
        [tooltipDisabled]="tooltipDisabled"
      />
    </svg:g>
  </svg:g>
</ngx-charts-chart>
