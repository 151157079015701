import { Component, Input, OnInit } from '@angular/core';
import { ActionButtonOptions } from './action-button-options.interface';

@Component({
  selector: 'iq-action-button',
  templateUrl: './action-button.component.html'
})
export class ActionButtonComponent implements OnInit {
  /**
   * The data that will be passed into the callback
   */
  @Input() data: any;

  /**
   * The available options, like callback, button icon, button tooltip
   */
  @Input() options: ActionButtonOptions;

  /**
   * Button css class
   */
  btnClass = 'btn-primary';

  /**
   * Button icon to be shown
   */
  btnIcon = '';

  /**
   * Button tooltip that is shown on mouse over
   */
  btnTooltip = '';

  /**
   * Lifecycle hook.
   * Checks the list of options and assign them to appropriate properties
   */
  ngOnInit(): void {
    if (this.options) {
      this.btnClass = this.options.btnClass || this.btnClass;
      this.btnIcon = this.options.btnIcon || this.btnIcon;
      this.btnTooltip = this.options.btnTooltip || this.btnTooltip;
    }
  }

  /**
   * Handler for button click that is call callback function
   */
  onButtonClick(): void {
    if (this.options && typeof this.options.callback === 'function') {
      this.options.callback(this.data);
    }
  }
}
