import { Component, Inject } from '@angular/core';
import { WINDOW } from '@core/constants';

/**
 * The component is used in the absence of LocalStorage support
 * to alert users to this fact.
 */
@Component({
  selector: 'iq-local-storage-not-supported',
  templateUrl: './local-storage-not-supported.component.html'
})
export class LocalStorageNotSupportedComponent {
  /**
   * Constructor.
   * @param window The injected window object.
   */
  constructor(@Inject(WINDOW) private window: Window) {}

  /**
   * Performs a reload of the complete page.
   * @param event The Click Event
   */
  reloadPage(event: Event): void {
    event.preventDefault();
    this.window.location.reload();
  }
}
