import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { UserService } from '../user/user.service';
import { take } from 'rxjs/operators';
import { LOCALSTORAGE } from '../local-storage/local-storage.constants';
import { COMMON, WINDOW } from '../constants';
import { LockscreenService } from '@core/lockscreen/lockscreen.service';

export interface ISessionService {
  sessionActive: BehaviorSubject<boolean>;
  resetSessionNext: () => void;
}

@Injectable()
export class SessionService implements ISessionService {
  /**
   * Subject which defines whether the session if active or not.
   */
  sessionActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  private serviceBase: string = COMMON.API_ROOT + '/portaldaten/session';

  /**
   * A from the BE specified maximum lifetime of the session without reseting.
   */
  private tokenLifeTime?: number;

  /**
   * ReplaySubject which emits the token lifetime.
   */
  private tokenLifeTimeReplaySubject: ReplaySubject<number> = new ReplaySubject<number>(1);

  /**
   * The instance of a timeout-functionm, which represents the session.
   */
  private timeoutInstance?: number;

  constructor(
    private http: HttpClient,
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrService,
    private userService: UserService,
    private lockscreenService: LockscreenService,
    @Inject(WINDOW) private window: Window
  ) {
    this.sessionActive.asObservable().subscribe(isSessionActive => this.checkActiveSession(isSessionActive));

    if (this.window.addEventListener) {
      this.window.addEventListener('storage', event => this.onStorageChanged(event));
    }
  }

  /**
   * Stops the current session and starts a new one.
   */
  public resetSessionNext(): void {
    this.stopSession();
    this.startSession();
  }

  /**
   * Checks the current session. If the session is still active, extend the session, else end it.
   * @param isSessionActive specifies if the session is active
   */
  private checkActiveSession(isSessionActive: boolean): void {
    if (isSessionActive) {
      this.startSession();
    } else {
      this.stopSession();
    }
  }

  /**
   * Requests the date of the given url extension.
   */
  private getData(urlExtension: string): Observable<number> {
    const config = {
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Expires: '-1',
        Pragma: 'no-cache'
      }
    };

    return this.http.get<number>(`${this.serviceBase}/${urlExtension}`, config);
  }

  /**
   * Returns the general time of a token cycle.
   */
  private getTokenLifeTime(): Observable<number> {
    if (!this.tokenLifeTime) {
      this.getData('lifetime').subscribe({
        next: (tokenLifeTime: number) => {
          this.tokenLifeTimeReplaySubject.next(tokenLifeTime);
        },
        error: (error) => {
          this.tokenLifeTime = null;
        }
      });
    }

    return this.tokenLifeTimeReplaySubject.asObservable();
  }

  /**
   * Returns the remaining time of the token.
   */
  private getTokenRestTime(): Observable<number> {
    return this.getData('rest');
  }

  /**
   * Initiates the logout from a user and navigates to the login page.
   * Pops an info toast to describing the session expiration.
   * Closes all modal dialogs and ends the session.
   */
  private doLogout(): void {
    this.router.navigate(['login']);
    this.toastr.info(
      this.translate.instant('ROUTING.TOASTS.SESSION_EXPIRED'),
      this.translate.instant('ROUTING.TOASTS.SESSION_EXPIRED_TITLE'),
      {
        disableTimeOut: true
      }
    );

    if (!this.lockscreenService.isNewBackendModalOpen()) {
      this.lockscreenService.closeAllModals();
    }

    this.sessionActive.next(false);
  }

  /**
   * Creates a new session if none exists.
   * The session consists of a timeout function, which will be executed if the given time runs out
   * and checks via the backend if the session is expired.
   * @param remainingTokenTime the remaining lifetime of the token
   */
  private createNewSession(remainingTokenTime: number): number {
    if (!this.timeoutInstance) {
      if (!this.sessionActive.getValue()) {
        this.sessionActive.next(true);
      }
      const timer = this.window.setTimeout(() => {
        if (this.timeoutInstance) {
          this.timeoutInstance = null;
          this.getTokenRestTime().subscribe({
            next: tokenRestTime => {
              if (!tokenRestTime) {
                if (this.sessionActive.getValue() && !this.router.isActive('login', {
                  paths: 'exact',
                  queryParams: 'exact',
                  fragment: 'ignored',
                  matrixParams: 'ignored'
                })) {
                  this.doLogout();
                }
              } else {
                this.timeoutInstance = this.createNewSession(tokenRestTime);
              }
            },
            error: error => {
              this.doLogout();
            }
          });
        }
      }, remainingTokenTime * 1000);
      return timer;
    } else {
      return this.timeoutInstance;
    }
  }

  /**
   * Starts a session for the users.
   * Requests the token lifetime and creates the session.
   */
  private startSession(): void {
    this.getTokenLifeTime().subscribe(tokenLifeTime => {
      this.tokenLifeTime = tokenLifeTime;
      this.timeoutInstance = this.createNewSession(this.tokenLifeTime);
    });
  }

  /**
   * Stops the session if one exists.
   */
  private stopSession(): void {
    if (this.timeoutInstance) {
      this.window.clearTimeout(this.timeoutInstance);
      this.timeoutInstance = null;
    }
  }

  /**
   * Reacts to changes inside the localstorage. If the changes concern the current user id, the new id will be
   * compared with the existing user. If they are not matching, the user will be logged out.
   * @param event storage event
   */
  private onStorageChanged(event): void {
    if (!event.key) {
      return;
    }
    const prefix = LOCALSTORAGE.PREFIX;
    if (event.key === prefix + '.' + LOCALSTORAGE.CURRENT_USER_ID) {
      if (!document.hasFocus || !document.hasFocus()) {
        this.userService
          .getCurrentUser()
          .pipe(take(1))
          .subscribe(currentUser => {
            let newUserId: string | null;
            try {
              newUserId = JSON.parse(event.newValue);
            } catch (e) {
              console.error('SessionService - Parse event.newValue (value: "' + event.newValue + '"): ' + e);
              newUserId = null;
            }

            if (
              currentUser.Id &&
              newUserId !== currentUser.Id &&
              this.sessionActive.getValue() &&
              !this.router.isActive('login', {
                paths: 'exact',
                queryParams: 'exact',
                fragment: 'ignored',
                matrixParams: 'ignored'
              })
            ) {
              this.stopSession();
              this.doLogout();
            }
          });
      }
    }
  }
}
