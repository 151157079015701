import { Component, Input, OnInit } from '@angular/core';
import { bestimmeSgKlassenRangeForTooltip } from '@share/chart-helper/chart-helper';

@Component({
  selector: 'iq-bar-tooltip',
  templateUrl: './bar-tooltip.component.html'
})
export class BarTooltipComponent {
  @Input()
  model: any;

  bestimmeSgKlassenRangeForTooltip = bestimmeSgKlassenRangeForTooltip;

  constructor() {}
}
