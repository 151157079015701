import { forwardRef, ElementRef, Renderer2, HostListener, Directive } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * This directive must be set on input elements of the type number if decimal numbers kann be entered.
 * It eleminates the problem that everything entered after a ',' will be removed in the internet explorer.
 */
@Directive({
  selector: `input[iqNumberInput][type=number][formControlName],
    input[iqNumberInput][type=number][formControl],
    input[iqNumberInput][type=number][ngModel]`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputDirective),
      multi: true
    }
  ]
})
export class NumberInputDirective implements ControlValueAccessor {
  /** This function must be called when the input value changes*/
  onChange: (_: any) => void;

  /** This function must be called when is input should be marked as "touched" or "blurred" */
  onTouched: () => void;

  /** Saves the last entered value  */
  private oldValue: number | undefined;

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {
    this.oldValue = undefined;
  }

  /** Listens for input events */
  @HostListener('input', ['$event.target'])
  inputEvent(input) {
    this.onChange(input.value);
  }

  /** Listens on the blur event */
  @HostListener('blur')
  inputBlurEvent() {
    this.onTouched();
  }

  /** Listens for the change event */
  @HostListener('change', ['$event.target'])
  inputChangeEvent(input) {
    this.onChange(input.value);
  }

  /**
   * Will be called programmatically if the input value changes or is initially set.
   * @param inputNumber the initial value of the input
   */
  writeValue(inputNumber: number): void {
    // If the old input value is identical to the new input value, do not reset the value property.
    // This prevents IE from misbehaving, which programmatically resets the value after a comma
    // was entered. This results in "5," converted to "5" in the input field.
    if (this.oldValue !== inputNumber) {
      const normalizedValue: number | string | null = inputNumber == null ? '' : inputNumber;
      this._renderer.setProperty(this._elementRef.nativeElement, 'value', normalizedValue);
      this.oldValue = inputNumber;
    }
  }

  /**
   * Registers a function which will be called when the input value changes.
   * @param fn the function that must be called when the input value changes.
   */
  registerOnChange(fn: any): void {
    this.onChange = (value: string) => {
      fn(value === '' ? null : parseFloat(value.replace(',', '.')));
    };
  }

  /**
   * Registers a function when input gets marked as blurred or touched.
   * @param fn function that must be called when the blur event occurs.
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Sets the disable state of the input.
   * @param isDisabled the disabled state
   */
  setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
  }
}
