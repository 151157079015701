import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { StyleTypes, formatLabel } from '@swimlane/ngx-charts';
import { ThemeService } from '@iq-angular-libs/portal';
import { PortalThemeColors } from '@core/theme/theme.interfaces';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'g[iq-combo-charts-series-vertical]',
  template: `
    <svg:g
      ngx-charts-bar
      *ngFor="let bar of bars; let index = index"
      [class.transparent]="index === 1"
      [width]="bar.width"
      [height]="bar.height"
      [x]="bar.x"
      [y]="bar.y"
      [fill]="highlightColor && isActive(bar.data) ? highlightColor : bar.color"
      [stops]="bar.gradientStops"
      [data]="bar.data"
      [orientation]="'horizontal'"
      [roundEdges]="bar.roundEdges"
      [gradient]="gradient"
      [isActive]="isActive(bar.data)"
      [animations]="animations"
      (activate)="onBarEntered($event)"
      (deactivate)="onBarLeft($event)"
      ngx-tooltip
      [tooltipDisabled]="tooltipDisabled"
      [tooltipTemplate]="tooltipTemplate"
      [tooltipPlacement]="tooltipPlacement"
      [tooltipContext]="bar.data"
      [tooltipType]="tooltipType"
    ></svg:g>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComboSeriesVerticalComponent implements OnChanges {
  @Input()
  dims;
  @Input()
  type = 'standard';
  @Input()
  series;
  @Input()
  seriesLine;
  @Input()
  xScale;
  @Input()
  yScale: d3.ScaleLinear<number, number>;
  @Input()
  colors;
  @Input()
  tooltipDisabled = false;
  @Input()
  tooltipTemplate;
  @Input()
  gradient: boolean;
  @Input()
  activeEntries: any[];
  @Input()
  seriesName: string;
  @Input()
  animations = true;
  @Input()
  scaleAbsolute = false;
  /**
   * The highlightProperty can be set, if a highlighting over multiple bars should occur.
   * The corresponding property must be inside the data object of the bars.
   */
  @Input()
  highlightProperty = null;
  /**
   * The highlightColor can be set additionally to the highlightProperty to specify the
   * color of the highlighted bars.
   */
  @Input()
  highlightColor: string;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  select = new EventEmitter();
  @Output()
  activate = new EventEmitter();
  @Output()
  deactivate = new EventEmitter();
  @Output()
  bandwidth = new EventEmitter();

  bars: any;
  x: any;
  y: any;
  activatedBar: any;

  constructor(private themeService: ThemeService<PortalThemeColors>) {}

  get tooltipType(): StyleTypes {
    return StyleTypes.tooltip;
  }

  get tooltipPlacement() {
    return 'top';
  }

  ngOnChanges(changes): void {
    this.update();
  }

  update(): void {
    const themeColors = this.themeService.getActiveThemeColors();
    let width;
    if (this.series.length) {
      width = this.xScale.bandwidth();
      this.bandwidth.emit(width);
    }

    let d0 = 0;
    let total;
    if (this.type === 'normalized') {
      total = this.series.map(d => d.value).reduce((sum, d) => sum + d, 0);
    }
    let min;

    this.bars = this.series.map((d, index) => {
      let value = d.value;
      const data = d.data;
      const label = d.name;
      const formattedLabel = formatLabel(label);

      const bar: any = {
        value,
        label,
        data: d,
        width,
        formattedLabel,
        height: 0,
        x: 0,
        y: 0
      };

      if (this.type === 'standard') {
        min = Math.min(...this.yScale.domain());
        bar.height = Math.abs(this.yScale(value) - this.yScale(this.scaleAbsolute ? 0 : min));
        bar.x = this.xScale(label);

        bar.y = this.yScale(value);
      } else if (this.type === 'stacked') {
        const offset0 = d0;
        const offset1 = offset0 + value;
        d0 += value;

        bar.height = this.yScale(offset0) - this.yScale(offset1);
        bar.x = 0;
        bar.y = this.yScale(offset1);
        bar.offset0 = offset0;
        bar.offset1 = offset1;
      } else if (this.type === 'normalized') {
        let offset0 = d0;
        let offset1 = offset0 + value;
        d0 += value;

        if (total > 0) {
          offset0 = (offset0 * 100) / total;
          offset1 = (offset1 * 100) / total;
        } else {
          offset0 = 0;
          offset1 = 0;
        }

        bar.height = this.yScale(offset0) - this.yScale(offset1);
        bar.x = 0;
        bar.y = this.yScale(offset1);
        bar.offset0 = offset0;
        bar.offset1 = offset1;
        value = (offset1 - offset0).toFixed(2) + '%';
      }

      if (this.colors.scaleType === 'ordinal') {
        if (this.colors.colorDomain && this.colors.colorDomain.length > 0) {
          bar.color = this.colors.colorDomain[0];
        } else if (bar && bar.data && bar.data.data && bar.data.data.color) {
          bar.color = bar.data.data.color;
        } else {
          bar.color = this.colors.getColor(data);
          if (bar.color === 'gradientMax') {
            bar.gradientStops = [
              {
                offset: 0,
                color: themeColors.ampelGruenDiagramm,
                opacity: 1.0
              },
              {
                offset: 100,
                color: themeColors.ampelRotDiagramm,
                opacity: 1.0
              }
            ];
          } else if (bar.color === 'gradientMin') {
            bar.gradientStops = [
              {
                offset: 0,
                color: themeColors.ampelRotDiagramm,
                opacity: 1.0
              },
              {
                offset: 100,
                color: themeColors.ampelGruenDiagramm,
                opacity: 1.0
              }
            ];
          } else {
            bar.gradientStops = [
              {
                offset: 0,
                color: bar.color,
                opacity: 1.0
              },
              {
                offset: 100,
                color: bar.color,
                opacity: 1.0
              }
            ];
          }
        }
      } else {
        if (this.type === 'standard') {
          bar.color = this.colors.getColor(value);
          bar.gradientStops = this.colors.getLinearGradientStops(value);
        } else {
          bar.color = this.colors.getColor(bar.offset1);
          bar.gradientStops = this.colors.getLinearGradientStops(bar.offset1, bar.offset0);
        }
      }

      let tooltipLabel = formattedLabel;
      if (this.seriesName) {
        tooltipLabel = `${this.seriesName} • ${formattedLabel}`;
      }

      return bar;
    });
  }

  isActive(entry): boolean {
    if (!this.activeEntries) {
      return false;
    }
    if (
      this.activatedBar &&
      this.highlightProperty &&
      entry.data[this.highlightProperty] === this.activatedBar.data[this.highlightProperty]
    ) {
      return true;
    }

    const item = this.activeEntries.find(d => entry?.name === d?.name && entry?.series === d?.series);
    return item !== undefined;
  }

  onClick(data): void {
    this.select.emit(data);
  }

  trackBy(index, bar): string {
    return bar.label;
  }

  /**
   * Is called as soon as the mouse is moved over a bar. Saves this bar as active bar.
   * @param data the data of the bar
   */
  onBarEntered(data) {
    this.activatedBar = data;
  }

  /**
   * Is called as soon as the mouse leaves a bar. Deletes the current active bar.
   * @param data the data of the bar
   */
  onBarLeft(data) {
    this.activatedBar = null;
  }
}
