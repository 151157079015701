import { Injectable } from '@angular/core';
import { AnzahlTage, COMMON } from '@core/constants';
import { DataService } from '@core/data/data.service';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { UserWidgetsList, Widget, WidgetsData } from './startseite.interfaces';
import { WidgetSizeChangedEvent } from './widgets.events';
import {
  AuffaelligeBetriebeWidgetData,
  BasispreisData,
  BetriebsstaettenLetzeLieferpartien,
  BetriebsstaettenSalmonellenKategorie,
  InfoWidgetData,
  QsMaengelbeseitgungsWidgetData,
  QsZulassungData,
  QsZusammenfassungData,
  RegistrationNumber,
  Termine
} from './widgets.interfaces';

export interface IWidgedService {
  getWidgetItemResizedObservable(): Observable<WidgetSizeChangedEvent>;
  fireWidgetSizeChangedEvent(widget: Widget): void;
  getAvailableWidgets(): Observable<WidgetsData>;
  getWidgetsOfUser(): Observable<UserWidgetsList>;
  postWidgetsOfUser(widgets: UserWidgetsList): Observable<void>;
  getInfoWidgetData(): Observable<InfoWidgetData>;
  getBasispreisMastschweineWidgetData(): Observable<BasispreisData>;
  getBasispreisFerkelWidgetData(): Observable<BasispreisData>;
  getBasispreisSauenWidgetData(): Observable<BasispreisData>;
  getBasispreisJungbullenWidgetData(): Observable<BasispreisData>;
  getBasispreisKueheWidgetData(): Observable<BasispreisData>;
  getTermineWidgetData(): Observable<Termine>;
  getAuffaelligeBetriebeWidgetData(anzahlTage: AnzahlTage): Observable<AuffaelligeBetriebeWidgetData>;
  getBetriebsstaettenLetzteLieferpartien(): Observable<BetriebsstaettenLetzeLieferpartien>;
  getQsZusammenfassungWidgetData(): Observable<QsZusammenfassungData>;
  getBetriebsstaettenSalmonellenKategorie(): Observable<BetriebsstaettenSalmonellenKategorie>;
  getQsAblaufZulassungWidgetData(anzahlTage: AnzahlTage): Observable<QsZulassungData>;
  getQsMaengelbeseitigungWidgetData(anzahlTage: AnzahlTage): Observable<QsMaengelbeseitgungsWidgetData>;
  getAgrarportalBlogPosts(): Observable<any[]>;
  getListOfRegistrationNumbers(registered: boolean, period: AnzahlTage): Observable<RegistrationNumber[]>;
  reset(): void;
}

@Injectable({
  providedIn: 'root' // is used by the ResetService
})
export class WidgetsService implements IWidgedService {
  /**
   * Holds the base URL for all requests of the WidgetService.
   */
  private serviceBase: string;

  /**
   * Widget Resize Stream. Fires an event whenever a widget resizes in height or width.
   */
  private widgetSizeChanged$ = new Subject<WidgetSizeChangedEvent>();

  /**
   * Holds the base price cattle widget data for the young bulls.
   */
  private basispreisJungbullenData: BasispreisData | null;

  /**
   * Holds the base price cattle widget data for the cows.
   */
  private basispreisKueheData: BasispreisData | null;

  /**
   * Holds the data for the "QA approval" widget.
   */
  private qsAblaufZulassungData: Map<AnzahlTage, QsZulassungData | null>;

  /**
   * Holds the data for the "QA Defect Elimination" widget.
   */
  private qsMaengelbeseitigungData: Map<AnzahlTage, QsMaengelbeseitgungsWidgetData | null>;

  /**
   * Holds the data for the "Conspicuous farms" widget.
   */
  private auffaelligeBetriebeData: Map<AnzahlTage, AuffaelligeBetriebeWidgetData | null>;

  /**
   * Holds the data for registered registration numbers
   */
  private registeredRegNumbersData: Map<AnzahlTage, RegistrationNumber[] | null>;

  /**
   * Holds the data for registered registration numbers
   */
  private unregisteredRegNumbersData: Map<AnzahlTage, RegistrationNumber[] | null>;

  /**
   * Constructor.
   * Sets the (base URL){@link WidgetsService#serviceBase}
   * @param dataService {@link DataService}
   */
  constructor(private dataService: DataService) {
    this.serviceBase = COMMON.API_ROOT + '/portaldaten/widgets';

    this.basispreisJungbullenData = null;
    this.basispreisKueheData = null;
    this.qsAblaufZulassungData = new Map<AnzahlTage, QsZulassungData>();
    this.auffaelligeBetriebeData = new Map<AnzahlTage, AuffaelligeBetriebeWidgetData>();
    this.qsMaengelbeseitigungData = new Map<AnzahlTage, QsMaengelbeseitgungsWidgetData>();
    this.registeredRegNumbersData = new Map<AnzahlTage, RegistrationNumber[]>();
    this.unregisteredRegNumbersData = new Map<AnzahlTage, RegistrationNumber[]>();
  }

  /**
   * Returns the widget resize stream as an observable.
   */
  getWidgetItemResizedObservable(): Observable<WidgetSizeChangedEvent> {
    return this.widgetSizeChanged$.asObservable().pipe(debounceTime(400));
  }

  /**
   * Fires an event for the passed widget via the widget resize stream
   * @param widget
   */
  fireWidgetSizeChangedEvent(widget: Widget) {
    const event = new WidgetSizeChangedEvent(widget);
    this.widgetSizeChanged$.next(event);
  }

  /**
   * Returns an array with all available widgets
   */
  getAvailableWidgets(): Observable<WidgetsData> {
    return this.dataService.getData(this.serviceBase + '/available');
  }

  /**
   * Returns an array with all widgets that the user has selected.
   */
  getWidgetsOfUser(): Observable<UserWidgetsList> {
    return this.dataService.getData(this.serviceBase);
  }

  /**
   * Posts an array with all widgets that the user has selected.
   */
  postWidgetsOfUser(widgets: UserWidgetsList): Observable<void> {
    const bodyData = {
      WidgetPositions: widgets
    };
    return this.dataService.postDataWithParameters(this.serviceBase, bodyData);
  }

  /**
   * Returns the data of the news widget.
   */
  getInfoWidgetData(): Observable<InfoWidgetData> {
    return this.dataService.getData(this.serviceBase + '/data/info');
  }

  /**
   * Returns the data of the base price fattening pig widget.
   */
  getBasispreisMastschweineWidgetData(): Observable<BasispreisData> {
    const parameter = { headers: { Accept: 'application/json' } };
    return this.dataService.getDataWithParameters(this.serviceBase + '/data/basispreis', parameter);
  }

  /**
   * Returns the data of the base price piglet widget.
   */
  getBasispreisFerkelWidgetData(): Observable<BasispreisData> {
    const parameter = { headers: { Accept: 'application/json' } };
    return this.dataService.getDataWithParameters(this.serviceBase + '/data/basispreis/ferkel', parameter);
  }

  /**
   * Provides the data of the base price sow widget.
   */
  getBasispreisSauenWidgetData(): Observable<BasispreisData> {
    const parameter = { headers: { Accept: 'application/json' } };
    return this.dataService.getDataWithParameters(this.serviceBase + '/data/basispreis/sauen', parameter);
  }

  /**
   * Provides the young bull data of the base price cattle widget.
   */
  getBasispreisJungbullenWidgetData(): Observable<BasispreisData> {
    if (this.basispreisJungbullenData) {
      return of(this.basispreisJungbullenData);
    } else {
      const parameter = { headers: { Accept: 'application/json' } };
      return this.dataService.getDataWithParameters(this.serviceBase + '/data/basispreis/jungbullen', parameter).pipe(
        tap((data: BasispreisData) => {
          this.basispreisJungbullenData = data;
        })
      );
    }
  }

  /**
   * Returns the cows data of the base price cattle widget.
   */
  getBasispreisKueheWidgetData(): Observable<BasispreisData> {
    if (this.basispreisKueheData) {
      return of(this.basispreisKueheData);
    } else {
      const parameter = { headers: { Accept: 'application/json' } };
      return this.dataService.getDataWithParameters(this.serviceBase + '/data/basispreis/kuehe', parameter).pipe(
        tap((data: BasispreisData) => {
          this.basispreisKueheData = data;
        })
      );
    }
  }

  /**
   * Returns the data of the appointment widget.
   */
  getTermineWidgetData(): Observable<Termine> {
    return this.dataService.getData(this.serviceBase + '/data/termine');
  }

  /**
   * Returns the data of the "Days since last delivery lot" widget.
   */
  getAuffaelligeBetriebeWidgetData(anzahlTage: AnzahlTage): Observable<AuffaelligeBetriebeWidgetData> {
    const cachedData = this.auffaelligeBetriebeData.get(anzahlTage);
    if (cachedData) {
      return of(cachedData);
    } else {
      const parameter = {
        headers: { Accept: 'application/json' },
        params: { anzahlTage: anzahlTage }
      };
      return this.dataService.getDataWithParameters(this.serviceBase + '/data/lieferpartie/auffaellig', parameter).pipe(
        tap((data: AuffaelligeBetriebeWidgetData) => {
          this.auffaelligeBetriebeData.set(anzahlTage, data);
        })
      );
    }
  }

  /**
   * Returns the data of the Last Delivery Lot widget.
   */
  getBetriebsstaettenLetzteLieferpartien(): Observable<BetriebsstaettenLetzeLieferpartien> {
    return this.dataService.getData(this.serviceBase + '/data/lieferpartie/last');
  }

  /**
   * Returns the data of the "Salmonella category" widget.
   */
  getBetriebsstaettenSalmonellenKategorie(): Observable<BetriebsstaettenSalmonellenKategorie> {
    return this.dataService.getData(this.serviceBase + '/data/salmotacho');
  }

  /**
   * Returns the data of the QA Admission procedure widget.
   */
  getQsAblaufZulassungWidgetData(anzahlTage: AnzahlTage): Observable<QsZulassungData> {
    const cachedData = this.qsAblaufZulassungData.get(anzahlTage);
    if (cachedData) {
      return of(cachedData);
    } else {
      const parameter = {
        headers: { Accept: 'application/json' },
        params: { anzahlTage: anzahlTage }
      };
      return this.dataService.getDataWithParameters(this.serviceBase + '/data/qs', parameter).pipe(
        tap((data: QsZulassungData) => {
          this.qsAblaufZulassungData.set(anzahlTage, data);
        })
      );
    }
  }

  /**
   * Returns the data of the QS summary widget.
   */
  getQsZusammenfassungWidgetData(): Observable<QsZusammenfassungData> {
    const parameter = {
      headers: { Accept: 'application/json' }
    };
    return this.dataService.getDataWithParameters(this.serviceBase + '/data/qszusammenfassung', parameter);
  }

  /**
   * Provides the data for the QA defect removal widget.
   * @param anzahlTage The number of days in which a defect must be removed.
   */
  getQsMaengelbeseitigungWidgetData(anzahlTage: AnzahlTage) {
    const cachedData = this.qsMaengelbeseitigungData.get(anzahlTage);
    if (cachedData) {
      return of(cachedData);
    } else {
      const parameter = {
        headers: { Accept: 'application/json' },
        params: { anzahlTage: anzahlTage }
      };
      return this.dataService.getDataWithParameters(this.serviceBase + '/data/qsmaengelbeseitigung', parameter).pipe(
        tap((data: QsMaengelbeseitgungsWidgetData) => {
          this.qsMaengelbeseitigungData.set(anzahlTage, data);
        })
      );
    }
  }

  /** Returns data for Toennies blog */
  getAgrarportalBlogPosts(): Observable<any[]> {
    return this.dataService.getData(COMMON.API_ROOT + '/blog-posts');
  }

  /**
   * Returns data for registered / unregistered registration numbers widget
   * @param unregistered true if unregistered numbers required, otherwise false
   * @param period described in {@link AnzahlTage} enum: 30, 60, 90
   * @returns Observable of registration number list
   */
  getListOfRegistrationNumbers(unregistered: boolean, period: AnzahlTage): Observable<RegistrationNumber[]> {
    if (unregistered) {
      const cachedData = this.unregisteredRegNumbersData.get(period);
      if (cachedData) {
        return of(cachedData);
      }

      return this.dataService.getDataWithParameters(this.serviceBase + '/data/unregistered-registration-numbers-log', {
        params: { Days: period }
      }).pipe(
        tap((data: RegistrationNumber[]) => {
          this.unregisteredRegNumbersData.set(period, data);
        })
      );
    } else {
      const cachedData = this.registeredRegNumbersData.get(period);
      if (cachedData) {
        return of(cachedData);
      }

      return this.dataService.getDataWithParameters(this.serviceBase + '/data/registered-registration-numbers-log', {
        params: { Days: period }
      }).pipe(
        tap((data: RegistrationNumber[]) => {
          this.registeredRegNumbersData.set(period, data);
        })
      );
    }
  }

  /**
   * Resets the cached data of the service.
   */
  reset(): void {
    this.basispreisJungbullenData = null;
    this.basispreisKueheData = null;
    this.qsAblaufZulassungData.clear();
    this.qsMaengelbeseitigungData.clear();
    this.auffaelligeBetriebeData.clear();
    this.registeredRegNumbersData.clear();
    this.unregisteredRegNumbersData.clear();
  }
}
