import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations';

/**
 * Returns the Angular animation for collapsing or expanding the widget edit menu.
 * @param expandedContainerHeight The height that the menu has when unfolded.
 */
export function getWidgetBearbeitenContainerAnimation(expandedContainerHeight: number): AnimationTriggerMetadata {
  return trigger('openCloseOptions', [
    state(
      'optionsOpen',
      style({
        height: `${expandedContainerHeight}px`
      })
    ),
    state(
      'optionsClosed',
      style({
        height: '8px'
      })
    ),
    transition('optionsOpen <=> optionsClosed', [animate('0.3s')])
  ]);
}
