import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Menupunkte } from '@core/constants';
import { isNil } from 'lodash-es';
import { filter, switchMap } from 'rxjs/operators';

/**
 * This service handles the web accesses of the user.
 */
@Injectable()
export class WebAccessService {
  /**
   * Behaviour-Subject, which stores the menu points.
   */
  private menupunkte$: BehaviorSubject<Menupunkte>;

  constructor() {
    this.menupunkte$ = new BehaviorSubject<Menupunkte>([]);
  }

  /**
   * Emits the given menu points with the menu point subject {@link WebAccessService#menupunkte$}.
   * @param menupunkte {@link Menupunkte}
   */
  public setMenupunkte(menupunkte: Menupunkte): void {
    this.menupunkte$.next(menupunkte);
  }

  /**
   * Returns the menu point subject, which not emits when the menu points are not set.
   */
  public getMenupunkte(): Observable<Menupunkte> {
    return this.menupunkte$.pipe(filter((menupunkte: Menupunkte) => !isNil(menupunkte)));
  }

  /**
   * Indicates if the user has access to the given menu point.
   * @param menupunkt the menu point to check
   */
  hasWebAccess(menupunkt: number): Observable<boolean> {
    return this.getMenupunkte().pipe(
      switchMap((menupunkte: Menupunkte) => of(<boolean>menupunkte.includes(menupunkt)))
    );
  }

  /**
   * Completes the old menu point stream and reinitializes it.
   */
  reset(): void {
    this.menupunkte$.complete();
    this.menupunkte$ = new BehaviorSubject<Menupunkte>([]);
  }
}
