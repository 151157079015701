<div class="details-nav-container mb-1">
  <div class="details-nav-btn-container">
    <button
      class="btn btn-primary btn-sm details-nav-btn details-nav-btn-prev"
      type="button"
      [disabled]="selectData.selectedBetriebsstaetteIndex <= 0"
      (click)="prevBetriebsstaette()"
    >
      <i class="fa fa-chevron-left"></i>
    </button>
  </div>
  <div class="details-nav-content">
    <div class="details-nav-content-row">
      <div class="select-betriebsstaette-container">
        <select
          class="form-control select-betriebsstaette"
          [(ngModel)]="selectData.selectedBetriebsstaette"
          (ngModelChange)="onSelectionChanged($event)"
        >
          <option *ngFor="let item of selectData.betriebsstaetten" [ngValue]="item">
            {{ item.Registrierungsnummer }}: {{ item.BetriebsstaettenName }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div class="details-nav-btn-container details-nav-btn-container-next">
    <button
      class="btn btn-primary btn-sm details-nav-btn details-nav-btn-next"
      type="button"
      [disabled]="selectData.selectedBetriebsstaetteIndex + 1 >= selectData.betriebsstaetten.length"
      (click)="nextBetriebsstaette()"
    >
      <i class="fa fa-chevron-right"></i>
    </button>
  </div>
</div>
