import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { isNil } from 'lodash-es';

export class RoutingHelpers {
  /**
   * Determines the last element of the ActivatedRoute tree, which corresponds to the
   * current route.
   */
  static determineLastActivatedRoute(activatedRoute: ActivatedRoute): ActivatedRoute {
    let currentActivatedRoute = activatedRoute;
    while (!isNil(currentActivatedRoute.firstChild)) {
      currentActivatedRoute = currentActivatedRoute.firstChild;
    }
    return currentActivatedRoute;
  }

  /**
   * Looks for a parent route snapshot of a given child route snapshot, which contains
   * the given attribute inside the data object.
   * Returns the found snapshot.
   * @param attributeName name of the property the route snapshot should contain
   * @param routeSnapshot {@link ActivatedRouteSnapshot} - the starting snapshot
   */
  static findFirstParentWithData(attributeName: string, routeSnapshot: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    let currentRoute: ActivatedRouteSnapshot = routeSnapshot;

    if (attributeName) {
      let value: any | null;

      if (routeSnapshot.data && routeSnapshot.data[attributeName]) {
        value = routeSnapshot.data[attributeName];
      } else {
        value = null;
      }
      while (value === null && currentRoute.parent) {
        currentRoute = currentRoute.parent;
        if (currentRoute.data && !isNil(currentRoute.data[attributeName])) {
          value = currentRoute.data[attributeName];
        }
      }
    }

    return currentRoute;
  }

  /**
   * Looks for a parent route snapshot of a given child route snapshot, which contains
   * the given attribute inside the params object.
   * Returns the found snapshot.
   * @param attributeName name of the property the route snapshot should contain
   * @param routeSnapshot {@link ActivatedRouteSnapshot} - the starting snapshot
   */
  static findFirstParentWithParam(
    attributeName: string,
    routeSnapshot: ActivatedRouteSnapshot
  ): ActivatedRouteSnapshot {
    let currentRoute: ActivatedRouteSnapshot = routeSnapshot;

    if (attributeName) {
      let value: any | null;

      if (routeSnapshot.params && routeSnapshot.params[attributeName]) {
        value = routeSnapshot.params[attributeName];
      } else {
        value = null;
      }
      while (value === null && currentRoute.parent) {
        currentRoute = currentRoute.parent;
        if (currentRoute.params && !isNil(currentRoute.params[attributeName])) {
          value = currentRoute.params[attributeName];
        }
      }
    }

    return currentRoute;
  }

  /**
   * Looks for a route snapshot that contains the given attribute inside the data or paras object.
   * If it exists, the value will be returned.
   * @param attributeName name of the property the route snapshot should contain
   * @param routeSnapshot {@link ActivatedRouteSnapshot} - the starting snapshot
   * @param findInParams specifies if params object should be searched, default is false
   */
  static getDataOfRouteSnapshot<T>(
    attributeName: string,
    routeSnapshot: ActivatedRouteSnapshot,
    findInParams: boolean = false
  ): T | null {
    let data: T | null = null;
    let foundSnapshot: ActivatedRouteSnapshot | null = null;

    switch (findInParams) {
    case true:
      foundSnapshot = this.findFirstParentWithParam(attributeName, routeSnapshot);
      if (foundSnapshot) {
        data = foundSnapshot.params[attributeName] as T;
      }
      break;

    case false:
      foundSnapshot = this.findFirstParentWithData(attributeName, routeSnapshot);
      if (foundSnapshot) {
        data = foundSnapshot.data[attributeName] as T;
      }
      break;
    }

    // if data is from type string and contains 'null', set it really to null
    if (typeof data === 'string') {
      data = data === 'null' ? null : data;
    }

    return data;
  }
}
