<span class="iq-input">
  <div class="input-container">
    <ng-content></ng-content>
    <span
      style="pointer-events: none;"
      [@inputLabelAnimation]="inputActive || currentValue ? 'labelSmall' : 'labelNormal'"
      class="label"
      >{{ labelTranslationKey | translate }}
    </span>
  </div>
  <span class="input-label input-underline">
    <span
      [@activeInactiveColorLine]="inputActive ? 'inputActive' : 'inputInactive'"
      class="input-label input-underline-colored"
    ></span>
  </span>
</span>
