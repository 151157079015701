import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from '@mattlewis92/angular-2-local-storage';
import { LOCALSTORAGE } from '../local-storage/local-storage.constants';

@Injectable()
export class CredentialGuard  {
  /**
   * Determines if the password must be changed
   */
  private mustChangePassword: boolean;

  /**
   * Determines if the portal e-mail address has to be set from the user
   */
  private mustValidateEmail: boolean;

  constructor(private router: Router, private localStorageService: LocalStorageService) {}

  /**
   * Checks if the router-state can be entered (activated)
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkCanActivate(state.url);
  }

  /**
   * Check if the child paths of the route state can be entered
   */
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkCanActivate(state.url);
  }

  /**
   * Checks if the url can be activiated. Also check if a redirect to the password / e-mail form or the startpage should occur
   * @param url the url the redirect checks will be performed with
   */
  private checkCanActivate(url: string): boolean {
    this.mustChangePassword = this.localStorageService.get(LOCALSTORAGE.MUST_CHANGE_PASSWORD);
    this.mustValidateEmail = this.localStorageService.get(LOCALSTORAGE.MUST_VALIDATE_EMAIL);

    const mustRedirect = this.checkIfMustRedirect(url);
    if (mustRedirect) {
      return false;
    }

    const mustRedirectToStartpage = this.checkIfNavigationToStartseite(url);
    if (mustRedirectToStartpage) {
      return false;
    }
    return true;
  }

  /**
   * Checks if a redirect to the "change password" modal or the emai validation modal
   * needs to be performed
   */
  private checkIfMustRedirect(url: string): boolean {
    if (this.mustChangePassword && url !== '/modal/initial-password') {
      this.router.navigate(['modal/initial-password']);
      return true;
    }

    if (!this.mustChangePassword && this.mustValidateEmail && url !== '/modal/initial-email') {
      this.router.navigate(['modal/initial-email']);
      return true;
    }

    return false;
  }

  /**
   * Checks if a redirect to the startpage should be performed
   */
  private checkIfNavigationToStartseite(url: string): boolean {
    if (
      (!this.mustChangePassword && (url === '/modal' || url === '/modal/initial-password')) ||
      (!this.mustValidateEmail && (url === '/modal' || url === '/modal/initial-email'))
    ) {
      this.router.navigate(['/portal/startseite']);
      return true;
    }
    return false;
  }
}
