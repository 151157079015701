<div class="widget-content-container qs-ablauf-zulassung">
  <iq-loading-indicator *ngIf="dataLoading"></iq-loading-indicator>
  <div *ngIf="errMessage && !dataLoading" class="mt-2 row">
    <h4>{{ errMessage | translate }}</h4>
  </div>
  <div *ngIf="!dataLoading && !errMessage && qsZulassungData" class="h-100">
    <div class="tabs-container h-100">
      <ul class="nav nav-tabs">
        <li class="nav-item" [class.active]="selectedTab === 0">
          <a class="nav-link" (click)="selectTab($event, 0)" href="#">{{ produktProgrammLists[0].title }}</a>
        </li>
        <li class="nav-item" [class.active]="selectedTab === 1">
          <a class="nav-link" (click)="selectTab($event, 1)" href="#">{{ produktProgrammLists[1].title }}</a>
        </li>
      </ul>
      <div class="tab-content-height">
        <div slimScroll class="h-100 scroll-space" [scrollEvents]="slimScrollEvents">
          <div class="mt-2">
            <p *ngIf="qsZulassungData[selectedProduktProgrammList.field].length === 0" class="text-center mt-2 mb-3">
              {{ 'KEINE_DATENSAETZE_VORHANDEN' | translate }}
            </p>
            <div
              class="table-container table-responsive mb-1"
              *ngIf="qsZulassungData[selectedProduktProgrammList.field].length > 0"
            >
              <table class="table table-hover">
                <tbody>
                  <tr *ngFor="let betriebsstaette of qsZulassungData[selectedProduktProgrammList.field]">
                    <td class="vertical-baseline">
                      <div class="text-bold">{{ betriebsstaette.Registrierungsnummer }}</div>
                      <div class="text-muted">{{ betriebsstaette.BetriebsstaettenName }}</div>
                    </td>
                    <td class="vertical-baseline">
                      <div class="text-bold">
                        <span>{{ 'WIDGETS.QS_ABLAUF_ZULASSUNG.ZULASSUNG' | translate }}: </span>
                        <span>{{ betriebsstaette.ZugelassenBis | date: dateFormat }}</span>
                      </div>
                      <div class="text-muted">{{ betriebsstaette.ProduktName }}</div>
                    </td>
                    <td class="btn-column">
                      <iq-details-aufrufen-button
                        [routeInformation]="[
                          'portal/qualitaetssicherung/auditierung/betriebsstaette',
                          betriebsstaette.Registrierungsnummer,
                          betriebsstaette.ProduktId,
                          sortConfig.sortFields[0].sortType,
                          sortConfig.sortFields[0].sortOrder
                        ]"
                        [selectedRegistrierungsnummer]="betriebsstaette.Registrierungsnummer"
                        rubrikTitelTranslationKey="WIDGETS.QS_ABLAUF_ZULASSUNG.QUALITAETSSICHERUNG"
                        tooltip="{{ 'WIDGETS.QS_ABLAUF_ZULASSUNG.DETAILS' | translate }}"
                        placement="left"
                        container="body"
                        *ngIf="betriebsstaette.MitDetails"
                      >
                      </iq-details-aufrufen-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="text-right mb-3" *ngIf="qsZulassungData[selectedProduktProgrammList.field].length !== 0">
              <iq-focus-betriebsstaetten-nav-button
                [betriebsstaetten]="produktProgrammListRegistrierungsnummern[selectedProduktProgrammList.field]"
                [rubrikTitelTranslationKey]="'WIDGETS.QS_ABLAUF_ZULASSUNG.QUALITAETSSICHERUNG'"
                [toRoute]="'portal/qualitaetssicherung/auditierung'"
                [buttonText]="'FOKUS.ALLE_UEBERNEHMEN' | translate"
              >
              </iq-focus-betriebsstaetten-nav-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- edit widget -->
  <iq-anzahl-tage-option
    [textPraefixKey]="'WIDGETS.QS_ABLAUF_ZULASSUNG.ABLAUF_IN'"
    [textSuffixKey]="'WIDGETS.QS_ABLAUF_ZULASSUNG.TAGEN'"
    [defaultAnzahlTage]="anzahlTage"
    (anzahlTageChanged)="anzahlTageChanged($event)"
  ></iq-anzahl-tage-option>
</div>
