import { Injectable } from '@angular/core';
import { COMMON } from '@core/constants';
import { DataService } from '@core/data/data.service';
import { GlobalFilterService } from '@core/global-filter/global-filter.service';
import { ColumnInformation, SortConfig, TableOptions } from '@iq-angular-libs/portal';
import { FilterBereiche } from '@share/filter/filter-bereiche';
import { ListFilterQuery } from '@share/filter/segment-query.interfaces';
import { SegmentService } from '@share/filter/segment.service';
import { MinMax } from '@share/filter/uebergeordnete-filter.interfaces';
import { toISODateString } from '@share/helper-functions';
import { filter as _filter, forEach, isNil, isNumber, toInteger } from 'lodash-es';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter as filterRxjs, map, switchMap, take } from 'rxjs/operators';
import { Zeitraum } from '../lieferpartien/share/lieferpartien.interfaces';
import {
  DatendownloadBodyData,
  DatendownloadData,
  DatendownloadFilterQuery,
  DatendownloadMinMaxQuery,
  DatendownloadService as IDatendownloadService
} from './datendownload.interfaces';

@Injectable({
  providedIn: 'root'
})
export class DatendownloadService implements IDatendownloadService {
  /** Subject for the server polling to check if the file was generated */
  polling$: Subject<string> | null;

  /** The time that is waited until the next poll. */
  pollingTimeout = 0;

  /** Factor used to determine the appropriate ranges when greater/smaller is used in the filters. */
  e = 0.0001;

  /** Holds the base URL for all requests of the logging service. */
  private serviceBase = COMMON.API_ROOT;

  /**
   * Constructor.
   * @param dataService the {@link DataService}
   * @param globalFilterService the {@link GlobalFilterService}
   * @param segmentService the {@link SegmentService}
   */
  constructor(
    private dataService: DataService,
    private globalFilterService: GlobalFilterService,
    private segmentService: SegmentService
  ) {
    this.polling$ = null;
  }

  /**
   * Adds the passed period to the parameters
   * @param zeitraum A period object with from/to properties
   * @param bodyData The body data that is sent to the backend during the data download request
   */
  private static addZeitraumToBodyData(zeitraum: Zeitraum, bodyData: DatendownloadBodyData) {
    bodyData.Von = toISODateString(zeitraum.from);
    bodyData.Bis = toISODateString(zeitraum.to);
  }

  /**
   * Adds the passed sort to the parameters
   * @param sort A sort object with sortType and sortReverse properties
   * @param bodyData The body data that is sent to the backend during the data download request
   */
  private static addSortToParameter(sort: SortConfig, bodyData: DatendownloadBodyData) {
    bodyData.Sort = {
      Attribut: sort.sortFields[0]?.sortType,
      Asc: sort.sortFields[0]?.sortOrder === 'asc'
    };
  }

  /**
   * Provides the data export info for the selected SO filters and segment filters (number of data sets, number of IXP, number of MFA).
   * @param bereich The area in which the data export is to be performed
   * @return Observable, which is resolved with the data export info.
   */
  getExportInfo(bereich: string): Observable<DatendownloadData> {
    return this.getParameterForUebergeordneteFilter().pipe(
      switchMap(bodyData => this.dataService.postDataWithParameters<DatendownloadData>(
        `${this.serviceBase}/${bereich}/info`,
        bodyData
      ))
    );
  }

  /**
   * Creates the download parameters for download with SO filters.
   * @return The download parameters for downloading the currently displayed content
   */
  getDownloadBodyData(): Observable<DatendownloadBodyData> {
    let regnummern: string[] = [];

    return this.globalFilterService.extractSelectedRegistrierungsnummern().pipe(
      switchMap(registrierungsnummern => {
        regnummern = registrierungsnummern;
        return this.segmentService.getFilterQueryForActiveSegment(FilterBereiche.SO);
      }),
      map(query => {
        const bodyData = {
          Registrierungsnummern: regnummern,
          Filters: query as DatendownloadFilterQuery
        };
        return bodyData;
      })
    );
  }

  /**
   * Creates and returns the download parameters for tables with filters.
   * @param tableOptions the {@link TableOptions}
   * @param zeitraum The period which is considered for the download
   * @param sort The sorting configuration of the table
   * @return The download parameters for downloading the currently displayed content
   */
  getDownloadBodyDataTable(
    tableOptions: TableOptions,
    zeitraum?: Zeitraum,
    sort?: SortConfig,
    ignoreRegistrationNumbers = false,
    extraFilterTerms?: ListFilterQuery[],
  ): Observable<DatendownloadBodyData> {
    return this.globalFilterService.extractSelectedRegistrierungsnummern().pipe(
      map(registrierungsnummern => {
        const filters: DatendownloadFilterQuery = {
          Terms: [],
          Ranges: [],
          Dates: null
        };

        // fill the qsfilter object
        const searchEnabledColumns = _filter<ColumnInformation>(
          tableOptions.columns,
          (column: ColumnInformation) => !column.disableFiltering
        );
        forEach(searchEnabledColumns, column => {
          if (Array.isArray(column.itemFilter)) {
            if (column.itemFilter.length > 0) {
              const filter: ListFilterQuery = {
                Name: column.field,
                Werte: []
              };
              forEach(column.itemFilter, item => {
                const id: string = isNumber(item.id) ? item.id.toString() : item.id;
                filter.Werte.push(id);
              });
              filters.Terms.push(filter);
            }
          } else if (column.itemFilter !== '') {
            const modifiedItemFilter = (column.itemFilter as string).replace(/\s+/g, '');
            if (
              modifiedItemFilter.startsWith('>') ||
              modifiedItemFilter.startsWith('<') ||
              modifiedItemFilter.startsWith('=')
            ) {
              const ranges = this.createRangeParameter(modifiedItemFilter);
              const filter: DatendownloadMinMaxQuery = {
                Name: column.field,
                Min: ranges.min,
                Max: ranges.max
              };
              filters.Ranges.push(filter);
            } else {
              const filter: ListFilterQuery = {
                Name: column.field,
                Werte: [column.itemFilter.toString()]
              };
              filters.Terms.push(filter);
            }
          }
        });

        if (extraFilterTerms?.length) {
          filters.Terms = [...filters.Terms, ...extraFilterTerms];
        }

        let bodyData: DatendownloadBodyData;

        if (ignoreRegistrationNumbers) {
          bodyData = filters as unknown as DatendownloadBodyData;
        } else {
          bodyData = {
            Registrierungsnummern: registrierungsnummern,
            Filters: filters
          };
        }

        if (zeitraum) {
          DatendownloadService.addZeitraumToBodyData(zeitraum, bodyData);
        }
        if (sort) {
          DatendownloadService.addSortToParameter(sort, bodyData);
        }

        return bodyData;
      })
    );
  }

  /**
   * Starts data download for a specified range and file types.
   * Uses server polling for slaughter data and a direct download for other areas.
   * @param bereich The area in which the data export is to be performed
   * @param fileType The data type in which the download should be provided
   * @param bodyData The body data that is sent to the backend during the data download request
   * @param polling Specifies whether to start the download with polling
   * @param queryData The query params data that is attached to URL
   * @return Observable, which is resolved with the download link
   */
  download(bereich: string, fileType: string, bodyData: DatendownloadBodyData, polling: boolean, queryData?: Object): Observable<string> {
    if (polling) {
      return this.generateFile(bereich, fileType, bodyData).pipe(
        switchMap(downloadScheduled => {
          if (downloadScheduled === false) {
            return throwError(() => new Error('Backend is not ready'));
          }
          return this.checkIfFileIsReady(bereich, fileType);
        })
      );
    } else {
      return this.getDownloadLink(bereich, fileType, bodyData, queryData);
    }
  }

  /**
   * Creates a Promise for verifying successful generation of the file and starts server polling.
   * @param bereich The area in which the download will be performed
   * @param fileType The file type for which a download is to be provided
   */
  checkIfFileIsReady(bereich: string, fileType: string): Observable<string> {
    if (!this.polling$) {
      this.polling$ = new Subject<string>();

      // delay pollCheck by 1 tick, so that the subject is returned as observable in any case
      setTimeout(() => {
        this.pollCheck(bereich, fileType);
      });
    }

    return this.polling$.asObservable();
  }

  /** Returns the polling observable */
  getPollingObservable(): Observable<string> | null {
    if (this.polling$) {
      return this.polling$.asObservable();
    }
    return null;
  }

  /**
   * Creates a Range object depending on the input passed.
   * @param enteredValue The filter input
   * @return The created Range object
   */
  private createRangeParameter(enteredValue: string): MinMax {
    const range = { min: null, max: null };

    // Replace comma with dots to make parsing work
    enteredValue = enteredValue.replace(/,/g, '.');
    if (enteredValue.startsWith('=')) {
      range.min = parseFloat(enteredValue.substring(1));
      range.max = parseFloat(enteredValue.substring(1));
    }
    if (enteredValue.startsWith('<') && enteredValue.indexOf('=') !== 1) {
      range.min = null;
      range.max = parseFloat(enteredValue.substring(1)) - this.e;
    }
    if (enteredValue.startsWith('<=')) {
      range.min = null;
      range.max = parseFloat(enteredValue.substring(2));
    }

    if (enteredValue.startsWith('>') && enteredValue.indexOf('=') !== 1) {
      range.min = parseFloat(enteredValue.substring(1)) + this.e;
      range.max = null;
    }
    if (enteredValue.startsWith('>=')) {
      range.min = parseFloat(enteredValue.substring(2));
      range.max = null;
    }
    return range;
  }

  /**
   * Creates the parameters based on the global filter, the soFilter and based on the selected segment and returns them.
   * @return {Observable} Observable, which is resolved with the parameters for the data export
   */
  private getParameterForUebergeordneteFilter() {
    let sofilters: DatendownloadFilterQuery;

    return this.segmentService.getFilterQueryForActiveSegment(FilterBereiche.SO).pipe(
      switchMap(query => {
        sofilters = query as DatendownloadFilterQuery;
        return this.globalFilterService.extractSelectedRegistrierungsnummern();
      }),
      map(registrierungsnummern => {
        const bodyData = {
          Registrierungsnummern: registrierungsnummern,
          Filters: sofilters
        };
        return bodyData;
      }),
      catchError(err => throwError(() => err))
    );
  }

  /**
   * Returns a download link directly without server polling.
   * @param bereich The area in which the download was started
   * @param fileType The file type
   * @param bodyData The body data that is sent to the backend during the data download request
   * @param queryData The query params data that is attached to URL
   * @return Observable, which is resolved with the download link
   */
  private getDownloadLink(bereich: string, fileType: string, bodyData: DatendownloadBodyData, queryData?: Object): Observable<string> {
    const parameter = { responseType: 'text' };
    const queryParams = this.encodeQueryParams(queryData);
    return this.dataService
      .postDataWithParameters(`${this.serviceBase}/${bereich}/${fileType}${queryParams}`, bodyData, parameter)
      .pipe(
        map(token => {
          const date = new Date();
          const link = `${this.serviceBase}/${bereich}/${fileType}?clientTime=${date.toISOString()}&token=${token as string}`;
          return link;
        })
      );
  }

  /**
   * Triggers the generation of the file for download.
   * @param bereich The area in which a data export should be started
   * @param fileType The file type for which a download link should be generated.
   * @param bodyData The body data that is sent to the backend during the data download request
   * @return Observable which resolves successfully when generation is successfully started.
   */
  private generateFile(bereich: string, fileType: string, bodyData: DatendownloadBodyData): Observable<boolean> {
    return this.dataService.postDataWithParameters(`${this.serviceBase}/${bereich}/${fileType}`, bodyData);
  }

  /**
   * Checks by server polling if the file was completely generated.
   * @param bereich The area in which the download was started
   * @param fileType The file type for which a download is to be provided
   */
  private pollCheck(bereich: string, fileType: string) {
    this.pollingTimeout = this.pollingTimeout === 0 ? 1000 : toInteger(this.pollingTimeout * 1.6);
    this.pollingTimeout = this.pollingTimeout > 15000 ? 1000 : this.pollingTimeout;

    this.dataService
      .getData<number>(`${this.serviceBase}/${bereich}/${fileType}/status`)
      .pipe(
        take(1),
        switchMap(downloadStatus => {
          if (downloadStatus === 2) {
            // ready
            this.pollingTimeout = 0;
            const parameter = { responseType: 'text' };
            return this.dataService.getDataWithParameters<string>(
              `${this.serviceBase}/${bereich}/${fileType}/token`,
              parameter
            );
          } else if (downloadStatus === 1) {
            setTimeout(() => {
              this.pollCheck(bereich, fileType);
            }, this.pollingTimeout);
            return of(null);
          } else {
            return throwError(() => new Error('Download canceled / interrupted'));
          }
        }),
        filterRxjs((token: string | null) => !isNil(token))
      )
      .subscribe({
        next: (token: string) => {
          const date = new Date();
          const link = `${this.serviceBase}/${bereich}/${fileType}?clientTime=${date.toISOString()}&token=${token}`;
          this.polling$.next(link);
          this.polling$.complete();
          this.polling$ = null;
        },
        error: err => {
          this.pollingTimeout = 0;
          this.polling$.error(err);
          this.polling$ = null;
        }
      });
  }

  /**
   * Encodes query data object to appropriate query params format
   * @param queryData object that should be encoded
   * @returns encoded query params string
   */
  private encodeQueryParams(queryData: Object): string {
    if (!queryData || !Object.keys(queryData).length) {
      return '';
    }
    const queryString = Object.keys(queryData).map(
      paramKey => encodeURI(`${paramKey}=${queryData[paramKey]}`)
    ).join('&');

    return '?' + queryString;
  }
}
