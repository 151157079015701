<div class="modal-header">
  <div class="float-left" *ngIf="!mustChangePassword">
    <h4 class="modal-title">{{ 'PASSWORT.PASSWORT_AENDERN' | translate }}</h4>
  </div>
  <div class="float-left" *ngIf="mustChangePassword">
    <h4 class="modal-title">{{ 'PASSWORT.HERZLICH_WILLKOMMEN' | translate }} {{ 'PASSWORT.IM_PORTAL' | translate }}</h4>
  </div>
  <button type="button" class="close float-right" aria-label="Close" *ngIf="!mustChangePassword" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="col-12">
    <div class="text-center mb-4" *ngIf="mustChangePassword">
      <span>{{ 'PASSWORT.INFO_ERSTE_ANMELDUNG' | translate }}</span>
      <span>{{ 'PASSWORT.INFO_KENNWORT_EINGEBEN' | translate }}</span>
    </div>

    <form
      id="changePasswordForm"
      name="changePasswordForm"
      class="form needs-validation"
      novalidate
      (keyup.enter)="submit()"
      [formGroup]="form"
    >
      <div class="form-group">
        <div class="row">
          <label class="col-sm-5 col-12 col-form-label text-right" *ngIf="!mustChangePassword">
            {{ 'PASSWORT.ALTES_PASSWORT' | translate }}:
          </label>
          <label class="col-sm-5 col-12 col-form-label text-right" *ngIf="mustChangePassword">
            {{ 'PASSWORT.INITIALPASSWORT' | translate }}:
          </label>
          <div class="col-sm-7 col-12">
            <input
              id="oldPassword"
              class="form-control"
              type="password"
              formControlName="oldPassword"
              [class.is-invalid]="submitted && controls['oldPassword'].invalid"
            />
            <div class="invalid-feedback" *ngIf="submitted && controls['oldPassword'].errors">
              <span *ngIf="controls['oldPassword'].errors.required"> {{ 'ALLGEMEIN.LEERES_FELD' | translate }} </span>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center mb-3">
        <div>
          <u>{{ 'PASSWORT.KENNWORTRICHTLINIE_HINWEIS' | translate }}:</u>
        </div>
        <div>{{ 'PASSWORT.KENNWORTRICHTLINIE' | translate }}</div>
      </div>

      <div class="form-group">
        <div class="row">
          <label class="col-sm-5 col-12 col-form-label text-right"> {{ 'PASSWORT.IHR_PASSWORT' | translate }}: </label>
          <div class="col-sm-7 col-12">
            <input
              id="newPassword"
              class="form-control"
              type="password"
              formControlName="newPassword"
              [class.is-invalid]="submitted && controls['newPassword'].invalid"
            />
            <div class="invalid-feedback" *ngIf="submitted && controls['newPassword'].errors">
              <span *ngIf="controls['newPassword'].errors.required"> {{ 'ALLGEMEIN.LEERES_FELD' | translate }} </span>
              <span *ngIf="!controls['newPassword'].errors.required">
                {{ 'PASSWORT.PASSWORT_ENTSPRICHT_NICHT_DER_KENNWORTRICHTLINIE' | translate }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <label class="col-sm-5 col-12 col-form-label text-right">
            {{ 'PASSWORT.PASSWORT_WIEDERHOLEN' | translate }}:
          </label>
          <div class="col-sm-7 col-12">
            <input
              id="confirmPassword"
              class="form-control"
              type="password"
              formControlName="confirmPassword"
              [class.is-invalid]="
                (submitted && controls['confirmPassword'].invalid) ||
                (submitted && form.invalid && form.errors && form.errors['confirmPassword'])
              "
            />
            <div
              class="invalid-feedback"
              *ngIf="
                (submitted && controls['confirmPassword'].errors) ||
                (submitted && form.errors && form.errors['confirmPassword'])
              "
            >
              <span *ngIf="controls['confirmPassword'].errors?.required">
                {{ 'ALLGEMEIN.LEERES_FELD' | translate }}
              </span>
              <span
                *ngIf="form.errors && form.errors['confirmPassword'] && !controls['confirmPassword'].errors?.required"
              >
                {{ 'PASSWORT.PASSWOERTER_STIMMEN_NICHT_UEBEREIN' | translate }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group mb-0" [hidden]="!mustChangePassword">
        <div class="row">
          <div class="col-1 col-sm-2 pt-3">
            <div class="float-none float-sm-right pretty p-svg mr-0">
              <input id="acceptAgbs" type="checkbox" formControlName="acceptAgbs" />
              <div class="state p-primary">
                <iq-svg-check></iq-svg-check>
                <label></label>
              </div>
            </div>
          </div>
          <label class="col-11 col-sm-10 col-form-label" for="acceptAgbs">
            <span>
              {{ 'PASSWORT.INFO_AGB_PART_1' | translate }}
              <a [attr.href]="'PASSWORT.AGB_URL' | translate" target="_blank">{{ 'PASSWORT.AGB' | translate }}</a>
              {{ 'PASSWORT.INFO_AGB_PART_2' | translate }}
            </span>
          </label>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="modal-footer">
  <div class="ml-auto">
    <button type="button" class="btn btn-secondary" *ngIf="!mustChangePassword" (click)="cancel()">
      {{ 'ALLGEMEIN.ABBRECHEN' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-primary ml-2"
      data-style="slide-left"
      [disabled]="!controls['acceptAgbs'].value"
      [ladda]="submitting"
      (click)="submit()"
    >
      {{ 'ALLGEMEIN.UEBERNEHMEN' | translate }}
    </button>
  </div>
</div>
