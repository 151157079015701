<div class="widget-content-container h-100 info-widget">
  <div class="h-100">
    <div class="d-flex flex-row tabs-container h-100">
      <ul class="nav nav-tabs nav-tabs--vertical nav-tabs--left">
        <li id="news-list-element" class="nav-item">
          <a
            class="nav-link"
            role="tab"
            [class.active]="selectedButton === infoWidgetButtonEnum.Kurier"
            [tooltip]="'WIDGETS.INFO.NEWS.NEWS' | translate"
            [adaptivePosition]="false"
            [container]="'body'"
            [placement]="'right'"
            (click)="updateWidget(infoWidgetButtonEnum.Kurier)"
            ><i class="fa fa-newspaper-o fa-lg"></i
          ></a>
        </li>
        <li id="support-list-element" class="nav-item">
          <a
            class="nav-link"
            role="tab"
            [class.active]="selectedButton === infoWidgetButtonEnum.Support"
            [tooltip]="'WIDGETS.INFO.SUPPORT.SUPPORT' | translate"
            [adaptivePosition]="false"
            [container]="'body'"
            [placement]="'right'"
            (click)="updateWidget(infoWidgetButtonEnum.Support)"
            ><i class="fa fa-phone fa-lg" aria-hidden="true"></i
          ></a>
        </li>
        <li
          id="dateneinsicht-list-element"
          class="nav-item"
          *ngIf="dateneinsichtData && dateneinsichtData.length !== 0"
        >
          <a
            class="nav-link"
            role="tab"
            [class.active]="selectedButton === infoWidgetButtonEnum.Dateneinsicht"
            [tooltip]="'WIDGETS.INFO.DATENEINSICHT.DATENEINSICHT' | translate"
            [adaptivePosition]="false"
            [container]="'body'"
            [placement]="'right'"
            (click)="updateWidget(infoWidgetButtonEnum.Dateneinsicht)"
            ><i class="fa fa-eye fa-lg" aria-hidden="true"></i
          ></a>
        </li>
      </ul>
      <div class="tab-content h-100 w-100">
        <div slimScroll class="scroll-space h-100" [scrollEvents]="slimScrollEvents">
          <div [ngSwitch]="selectedButton">
            <ng-container *ngSwitchCase="infoWidgetButtonEnum.Kurier" [ngTemplateOutlet]="kurier"></ng-container>
            <ng-container
              *ngSwitchCase="infoWidgetButtonEnum.Dateneinsicht"
              [ngTemplateOutlet]="dateneinsicht"
            ></ng-container>
            <ng-container *ngSwitchCase="infoWidgetButtonEnum.Support" [ngTemplateOutlet]="support"></ng-container>
            <ng-container *ngSwitchDefault [ngTemplateOutlet]="kurier"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- The template for the courier view -->
<ng-template #kurier>
  <div *ngIf="!dataLoading; else loadingTemp" id="news">
    <div *ngIf="newsData.length === 0" class="text-center">
      <p class="text-bold">{{ 'WIDGETS.INFO.NEWS.HINWEIS' | translate }}</p>
      <p class="mt-3">{{ 'WIDGETS.INFO.NEWS.KEINE_NEWS_VORHANDEN' | translate }}</p>
    </div>
    <div *ngFor="let news of newsData">
      <div>
        <small class="text-bold">{{ news.Erstellungsdatum | date: 'dd.MM.yy' }}</small>
      </div>
      <div><h3 [innerHtml]="news.Ueberschrift"></h3></div>
      <p class="mb-2 text-muted" [innerHtml]="news.Inhalt"></p>
    </div>
  </div>
</ng-template>

<!-- The template for the data view -->
<ng-template #dateneinsicht>
  <div *ngIf="!dataLoading; else loadingTemp" id="dateneinsicht">
    <p>{{ 'WIDGETS.INFO.DATENEINSICHT.HINWEIS' | translate }}:</p>
    <div class="table-container">
      <table class="table table-hover table-bordered">
        <tbody>
          <tr *ngFor="let benutzer of dateneinsichtData">
            <td>{{ benutzer }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <button type="button" class="btn btn-primary mt-2 float-right" [routerLink]="['/portal/stammdaten/dateneinsicht']">
      <i class="fa fa-user mr-2" aria-hidden="true"></i>
      <span>{{ 'WIDGETS.INFO.DATENEINSICHT.DETAILS' | translate }}</span>
    </button>
  </div>
</ng-template>

<!-- The template for the support view -->
<ng-template #support>
  <div #supportContainer id="support-container" class="row" style="max-width: 500px">
    <div class="{{ maximizeCol ? 'col-12' : 'col-6 pr-0' }}">
      <div class="mb-2" [innerHTML]="'WIDGETS.INFO.SUPPORT.ADRESSE_HTML' | translate"></div>
      <div>
        <span>{{ 'WIDGETS.INFO.SUPPORT.TEL' | translate }}:</span>
        <a [attr.href]="'tel:' + ('WIDGETS.INFO.SUPPORT.TEL_NUMMER' | translate)">{{
          'WIDGETS.INFO.SUPPORT.TEL_NUMMER' | translate
        }}</a>
      </div>
      <div class="mb-2">
        {{ 'WIDGETS.INFO.SUPPORT.FAX' | translate }}: {{ 'WIDGETS.INFO.SUPPORT.FAX_NUMMER' | translate }}
      </div>
      <div>
        <span>{{ 'WIDGETS.INFO.SUPPORT.EMAIL' | translate }}: </span>
        <a [attr.href]="'mailto:' + ('WIDGETS.INFO.SUPPORT.EMAIL_ADRESSE' | translate)">{{
          'WIDGETS.INFO.SUPPORT.EMAIL_ADRESSE' | translate
        }}</a>
      </div>
      <div>
        <span>{{ 'WIDGETS.INFO.SUPPORT.WEB' | translate }}: </span>
        <a [attr.href]="'http://' + ('WIDGETS.INFO.SUPPORT.WEB_ADRESSE' | translate)" target="_blank">{{
          'WIDGETS.INFO.SUPPORT.WEB_ADRESSE' | translate
        }}</a>
      </div>
    </div>
    <div class="{{ maximizeCol ? 'col-12 mt-3' : 'col-6 pl-0' }}">
      <div class="text-bold">{{ 'WIDGETS.INFO.SUPPORT.GESCHÄFTSZEITEN' | translate }}:</div>
      <div [innerHTML]="'WIDGETS.INFO.SUPPORT.GESCHÄFTSZEITEN_HTML' | translate"></div>
    </div>
  </div>
</ng-template>

<ng-template #loadingTemp>
  <iq-loading-indicator *ngIf="dataLoading"></iq-loading-indicator>
</ng-template>

<!-- edit widget -->
<div class="widget-bearbeiten-container disabled"></div>
