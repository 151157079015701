import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'iq-server-error-modal',
  templateUrl: './server-error-modal.component.html',
  styleUrls: ['./server-error-modal.component.scss']
})
export class ServerErrorModalComponent {

  /**
   * constructor
   * @param bsModalRef reference to bootstrap modal service
   */
  constructor(private bsModalRef: BsModalRef) { }

  /**
   * Hide the modal
   */
  decline(): void {
    this.bsModalRef.hide();
  }
}
