<div class="iq-middle-box text-center animated fadeInDown">
  <h1>{{ 'FEHLER.FEHLERSEITE_TITEL' | translate }}</h1>
  <div class="error-desc">{{ 'FEHLER.FEHLERSEITE_HINWEIS' | translate }}</div>
  <div class="error-desc">
    <strong>
      <a routerLink="">
        <i class="fa fa-arrow-left mr-2" aria-hidden="true"> </i> {{ 'FEHLER.ZURUECK_ZUM_PORTAL' | translate }}
      </a>
    </strong>
  </div>
</div>
