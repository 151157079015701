import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CurrentUser } from '@core/user/user.interfaces';
import { FormGroup, FormControl } from '@angular/forms';
import { UserPropertiesBereiche } from '@core/user/user.enums';
import { UserService } from '@core/user/user.service';

@Component({
  selector: 'iq-details-aufrufen-modal',
  templateUrl: './details-aufrufen-modal.component.html',
  styleUrls: ['./details-aufrufen-modal.component.scss']
})
export class DetailsAufrufenModalComponent implements OnInit {
  /**
   * The currently logged in user
   */
  @Input()
  currentUser: CurrentUser;

  /**
   * The section title
   */
  @Input()
  rubrikTitel: string;

  /**
   * Stream, which informs the subscriber whether the modal dialog has
   * been closed by confirming or canceling changes.
   */
  onClose$: Subject<boolean>;

  /**
   * This property indicates whether the checkbox for hiding the hint message in the modal dialog is activated
   */
  hideMessage = false;

  /**
   * The form in modal
   */
  form: FormGroup;

  constructor(private userService: UserService, private bsModalRef: BsModalRef) {}

  /**
   * Initializes the form
   */
  ngOnInit(): void {
    this.onClose$ = new Subject<boolean>();
    this.form = new FormGroup({
      DetailNavigationHinweisAnzeigen: new FormControl(
        !this.currentUser.userProperties[UserPropertiesBereiche.ALLGEMEIN].DetailNavigationHinweisAnzeigen
      )
    });
    this.form.get('DetailNavigationHinweisAnzeigen').valueChanges.subscribe(value => {
      this.hideMessage = value;
    });
  }

  /**
   * Closes the modal dialog
   */
  cancel(): void {
    this.onClose$.next(false);
    this.onClose$.complete();
    this.bsModalRef.hide();
  }

  /**
   * Calls the passed function and saves the user settings, if the checkbox
   * in the modal dialog was activated.
   */
  ok(): void {
    if (this.hideMessage) {
      this.currentUser.userProperties[UserPropertiesBereiche.ALLGEMEIN].DetailNavigationHinweisAnzeigen = false;
      this.userService
        .postUserproperties(UserPropertiesBereiche.ALLGEMEIN, this.currentUser.userProperties)
        .subscribe();
    }
    this.onClose$.next(true);
    this.onClose$.complete();
    this.bsModalRef.hide();
  }
}
