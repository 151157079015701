import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomModalService } from '@share/custom-modal.service';
import { MindestanzahlModalComponent, VersteckteFelder } from '../mindestanzahl-modal/mindestanzahl-modal.component';
import { ModalOptions } from 'ngx-bootstrap/modal';

export class MindestanzahlChangedEvent {
  newTiere: number;
  newMinWuerfe: number;
  newMinNachkommen: number;

  constructor(newTiere: number, newMinWuerfe: number, newMinNachkommen: number) {
    this.newTiere = newTiere;
    this.newMinWuerfe = newMinWuerfe;
    this.newMinNachkommen = newMinNachkommen;
  }
}

@Component({
  selector: 'iq-mindestanzahl-button',
  templateUrl: './mindestanzahl-button.component.html'
})
export class MindestanzahlButtonComponent {
  /**
   * Hidden fields
   */
  @Input()
  versteckteFelder: VersteckteFelder;

  /**
   * Minimum number of animals
   */
  @Input()
  minTiere: number;

  /**
   * Minimum number of litters
   */
  @Input()
  minWuerfe: number;

  /**
   * Minimum number of descendants
   */
  @Input()
  minNachkommen: number;

  /**
   * EventEmitter that emits changes on the minimum numbers
   */
  @Output()
  mindestanzahlChanged: EventEmitter<MindestanzahlChangedEvent>;

  /**
   * Constructor.
   * @param modalService {@link CustomModalService}
   */
  constructor(private modalService: CustomModalService) {
    this.mindestanzahlChanged = new EventEmitter<MindestanzahlChangedEvent>();
  }

  /**
   * Opens the "minimum number" modal
   */
  openChooseMindestanzahlDialogue(): void {
    const modalOptions: ModalOptions<MindestanzahlModalComponent> = {
      backdrop: 'static',
      initialState: {
        versteckteFelder: this.versteckteFelder,
        minTiere: this.minTiere,
        minWuerfe: this.minWuerfe,
        minNachkommen: this.minNachkommen
      }
    };

    const modalRef = this.modalService.show(MindestanzahlModalComponent, modalOptions);
    modalRef.content.onClose$.asObservable().subscribe({
      next: mindestanzahl => {
        if (mindestanzahl) {
          this.emitMindestanzahlChanged(mindestanzahl.minTiere, mindestanzahl.minWuerfe, mindestanzahl.minNachkommen);
        }
      }
    });
  }

  /**
   * Fires the {@link MindestanzahlChangedEvent} containing the minimum numbers
   * for animals, litters and descendants
   */
  private emitMindestanzahlChanged(newTiere: number, newMinWuerfe: number, newMinNachkommen: number): void {
    const mindestanzahlChangedEvent = new MindestanzahlChangedEvent(newTiere, newMinWuerfe, newMinNachkommen);
    this.mindestanzahlChanged.emit(mindestanzahlChangedEvent);
  }
}
