import { Component, Input, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'iq-help-button-modal',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">
        <span class="float-left">{{ 'HILFE.HILFE' | translate }}</span>
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body help-modal-body" [innerHTML]="templateString"></div>
    <div class="modal-footer">
      <button class="btn btn-secondary ml-auto" (click)="closeModal()">{{ 'ALLGEMEIN.SCHLIESSEN' | translate }}</button>
    </div>
  `,
  styleUrls: ['./help-button-modal.component.scss']
})
export class HelpButtonModalComponent implements OnDestroy {
  /**
   * The template string which should be displayed
   */
  @Input()
  templateString;

  /**
   * Subject which closes the modal
   */
  onClose$: Subject<void>;

  /**
   * Constructor.
   * @param bsModalRef
   */
  constructor(private bsModalRef: BsModalRef) {
    this.onClose$ = new Subject<void>();
  }

  /**
   * Closes the modal
   */
  closeModal() {
    this.onClose$.next();
    this.onClose$.complete();
  }

  ngOnDestroy() {
    this.onClose$.complete();
  }
}
