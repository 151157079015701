import { Injectable } from '@angular/core';
import { UserService } from '@core/user/user.service';
import { Observable, Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { FilterBereiche } from '@share/filter/filter-bereiche';
import { UserPropertiesBereiche } from '@core/user/user.enums';

export interface ISegmentToggleService {
  getSegmentsToggledObservable(filterBereich: FilterBereiche): Observable<boolean>;
  reset(): void;
}

@Injectable({
  providedIn: 'root'
})
export class SegmentToggleService implements ISegmentToggleService {
  /**
   * Stream, which fires an event when toggling SO segments.
   */
  private soSegmentsToggled$: Subject<boolean>;

  /**
   * Stream, which fires an event while toggling ETM segments
   */
  private etmSegmentsToggled$: Subject<boolean>;

  /**
   * Stream, which fires an event while toggling ETZ segments.
   */
  private etzSegmentsToggled$: Subject<boolean>;

  /**
   * Retains the last state of the SO segments (segments activated or not).
   */
  private previousSoSegmentsActive: boolean | null;

  /**
   * Retains the last state of the ETM segments (segments activated or not)
   */
  private previousEtmSegmentsActive: boolean | null;

  /**
   * Retains the last state of the ETZ segments (segments activated or not).
   */
  private previousEtzSegmentsActive: boolean | null;

  /**
   * Constructor.
   * @param userService {@link UserService}
   */
  constructor(private userService: UserService) {
    this.soSegmentsToggled$ = new Subject<boolean>();
    this.etmSegmentsToggled$ = new Subject<boolean>();
    this.etzSegmentsToggled$ = new Subject<boolean>();

    this.previousSoSegmentsActive = null;
    this.previousEtmSegmentsActive = null;
    this.previousEtzSegmentsActive = null;

    this.subscribeToUserPropertiesChangedEvent();
  }

  /**
   * Returns the corresponding stream as Observable depending on the filter range.
   * @param filterBereich the filter section
   */
  getSegmentsToggledObservable(filterBereich: FilterBereiche): Observable<boolean> {
    let segmentToggled$: Subject<boolean>;

    switch (filterBereich) {
    case FilterBereiche.SO:
      segmentToggled$ = this.soSegmentsToggled$;
      break;

    case FilterBereiche.ETM:
      segmentToggled$ = this.etmSegmentsToggled$;
      break;

    case FilterBereiche.ETZ:
      segmentToggled$ = this.etzSegmentsToggled$;
      break;

    default:
      const subject = new Subject<boolean>();
      subject.complete();
      segmentToggled$ = subject;
    }

    return segmentToggled$.asObservable();
  }

  /**
   * Resets the stored values of the service
   */
  reset(): void {
    this.previousSoSegmentsActive = null;
    this.previousEtmSegmentsActive = null;
    this.previousEtzSegmentsActive = null;
  }

  /**
   * Subscribes to the UserPropertiesChanged stream.
   * Fires an event of the respective streams as soon as the SO, ETM or ETZ data
   * have changed.
   */
  private subscribeToUserPropertiesChangedEvent() {
    const userPropertiesChanged$ = this.userService.userPropertiesBehaviourSubject.asObservable().pipe(
      filter(event => (event.userProperties ? true : false)),
      filter(
        event =>
          event.userPropertiesBereich !== undefined &&
          event.userPropertiesBereich !== UserPropertiesBereiche.ALLGEMEIN &&
          event.userPropertiesBereich !== UserPropertiesBereiche.WIDGETS
      )
    );

    // SO
    userPropertiesChanged$
      .pipe(
        filter(event => event.userPropertiesBereich === UserPropertiesBereiche.SO),
        filter(event => this.previousSoSegmentsActive !== event.userProperties.so.SegmenteAktiviert),
        tap(event => {
          // synchronized areas: When the event for SO has come, we are in the SO area.
          // To prevent the event from being fired again for ETM, the previous value of ETM is also overwritten.
          this.previousSoSegmentsActive = event.userProperties.so.SegmenteAktiviert;
          this.previousEtmSegmentsActive = event.userProperties.so.SegmenteAktiviert;
          this.soSegmentsToggled$.next(event.userProperties.so.SegmenteAktiviert);
        })
      )
      .subscribe();

    // ETM
    userPropertiesChanged$
      .pipe(
        filter(event => event.userPropertiesBereich === UserPropertiesBereiche.ETM),
        filter(event => this.previousEtmSegmentsActive !== event.userProperties.etm.SegmenteAktiviert),
        tap(event => {
          // synchronized areas: When the event has come for ETM, we are in the ETM area.
          // To prevent the event from being fired again for SO, the previous value of SO is also overwritten.
          this.previousEtmSegmentsActive = event.userProperties.etm.SegmenteAktiviert;
          this.previousSoSegmentsActive = event.userProperties.etm.SegmenteAktiviert;
          this.etmSegmentsToggled$.next(event.userProperties.etm.SegmenteAktiviert);
        })
      )
      .subscribe();

    // ETZ
    userPropertiesChanged$
      .pipe(
        filter(event => event.userPropertiesBereich === UserPropertiesBereiche.ETZ),
        filter(event => this.previousEtzSegmentsActive !== event.userProperties.etz.SegmenteAktiviert),
        tap(event => {
          this.previousEtzSegmentsActive = event.userProperties.etz.SegmenteAktiviert;
          this.etzSegmentsToggled$.next(event.userProperties.etz.SegmenteAktiviert);
        })
      )
      .subscribe();
  }
}
