import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';

/**
 * This pipe checks if the given value is null or undefined.
 * If the value is null/undefined a optional translation key
 * will be translated. If not defined the pipe returns an empty
 * string.
 *
 * If the given value is defined it can be converted with a
 * passed convertFunction or the original value will be returned.
 */
@Pipe({
  name: 'null'
})
export class NullPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(
    value: any,
    nullTranslationKey?: string,
    convertFunction?: (value: any) => string | number
  ): string | number {
    if (isNil(value)) {
      return nullTranslationKey ? this.translate.instant(nullTranslationKey) : '';
    }

    if (convertFunction) {
      return convertFunction(value);
    }

    return value;
  }
}
