<table>
  <tbody>
    <tr>
      <td><strong>SG-Klasse</strong></td>
      <td>{{ bestimmeSgKlassenRangeForTooltip(model.data) }}</td>
    </tr>
    <tr>
      <td><strong>Diff. Not:</strong></td>
      <td>{{ model.value | number: '1.2-2' }} {{ 'MASSEINHEITEN.EURO_TIER' | translate }}</td>
    </tr>
  </tbody>
</table>
