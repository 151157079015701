import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { FORMAT } from '@core/constants';
import { SlimScrollEvent } from 'ngx-slimscroll';
import { Subject } from 'rxjs';
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { Widget, WidgetComponent } from '../share/startseite.interfaces';
import { Termine } from '../share/widgets.interfaces';
import { WidgetsService } from '../share/widgets.service';

@Component({
  selector: 'iq-termine-widget',
  templateUrl: './termine-widget.component.html',
  styleUrls: ['./termine-widget.component.scss']
})
export class TermineWidgetComponent implements OnInit, OnDestroy, WidgetComponent {
  /**
   * The structure information of the widget.
   */
  @Input()
  widget: Widget;

  /**
   * The DOM elements of the dates
   */
  @ViewChildren('terminRow', { read: ElementRef })
  private terminRowElements: QueryList<ElementRef>;

  /**
   * The constants for the formatted representation of date values.
   */
  FORMAT = FORMAT;

  /**
   * Last year's dates identified by the BE.
   */
  termine: Termine = [];

  /**
   * Indicates whether data is currently being loaded.
   */
  termineLoading = false;

  /**
   * The error message.
   */
  errMessage: string | null = null;

  /**
   * The event emitters for the slimscroll component.
   */
  slimScrollEvents = new EventEmitter<SlimScrollEvent>();

  /**
   * Unsusbscribe-Stream.
   */
  unsubscribe$ = new Subject<void>();

  /**
   * Constructor.
   * @param widgetsService {@link WidgetsService}
   * @param ngZone {@link NgZone}
   */
  constructor(private widgetsService: WidgetsService, private ngZone: NgZone) {}

  /**
   * Triggers the loading of the appointments.
   */
  ngOnInit() {
    this.getData();

    this.widgetsService
      .getWidgetItemResizedObservable()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(event => event.widget.id === this.widget.id)
      )
      .subscribe({
        next: () => {
          const event = new SlimScrollEvent({
            type: 'recalculate'
          });

          this.slimScrollEvents.emit(event);

          setTimeout(() => {
            this.skipOutdatedTermine();
          });
        }
      });
  }

  /**
   * Fires an event of the unsubscribe stream and closes it afterwards.
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Gets the appointments from the backend via the WidgetService.
   */
  getData(): void {
    this.termine = [];
    this.termineLoading = true;
    this.errMessage = null;

    this.widgetsService
      .getTermineWidgetData()
      .pipe(
        finalize(() => {
          this.termineLoading = false;
        })
      )
      .subscribe({
        next: (termine: Termine) => {
          this.termine = termine;
          setTimeout(() => {
            this.skipOutdatedTermine();
          });
        },
        error: (err: Error) => {
          this.errMessage = 'FEHLER.DATEN_NICHT_GELADEN';
        }
      });
  }

  private skipOutdatedTermine(): void {
    if (this.terminRowElements && this.terminRowElements.length > 0) {
      const elements = this.terminRowElements.filter((item: ElementRef) => !item.nativeElement.classList.contains('abgelaufen'));

      if (elements && elements.length > 0) {
        const firstElement = <HTMLTableRowElement>elements[0].nativeElement;
        const scrollToEvent = new SlimScrollEvent({
          type: 'scrollTo',
          y: firstElement.offsetTop, // scrollbar position bug: https://github.com/jkuri/ngx-slimscroll/issues/107
          duration: 0,
          easing: 'linear'
        });

        this.slimScrollEvents.emit(scrollToEvent);
      }
    }
  }
}
