import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  LoginEvent,
  PasswordForgottenEvent,
  ShowLoginViewEvent,
  ShowPasswordForgottenViewEvent,
  ShowPasswordRequestedViewEvent
} from '../share/login-events';
import { AuthService } from '@core/auth/auth.service';
import { BlockedIpService } from '../blocked-ip/blocked-ip.service';
import { catchError, filter, finalize, switchMap, takeWhile, tap } from 'rxjs/operators';
import { Observable, of, Subscription, timer } from 'rxjs';
import { CurrentUser, UserProperties } from '@core/user/user.interfaces';
import { SessionService } from '@core/session/session.service';
import {
  PasswordForgottenRequestData,
  PasswordForgottenService
} from '@share/password-forgotten/password-forgotten.service';
import { UebergeordneteFilterService } from '@share/filter/uebergeordnete-filter.service';
import { MENUPUNKTE } from '@core/constants';
import { FilterBereiche } from '@share/filter/filter-bereiche';
import { ThemeService } from '@iq-angular-libs/portal';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { loadUserTheme } from '@core/theme/theme-helpers';
import { PortalThemeColors } from '@core/theme/theme.interfaces';

/**
 * The interface that describes the LoginView states.
 */
interface LoginViewState {
  loginView: boolean;
  passwordForgottenView: boolean;
  passwordLinkRequestedView: boolean;
}

/**
 * The interface that describes the login error.
 */
interface LoginError {
  errorAnmeldung: boolean;
}

/**
 * Interface that describes the remaining time.
 */
interface RemainingTime {
  login: number;
  passwordForgotten: number;
}

/**
 * Interface that describes the subscriptions for expiration of the remaining time.
 */
interface RemainingTimeSubscriptions {
  login?: Subscription;
  passwordtForgotten?: Subscription;
}

/**
 * Component that is the basis for the three views (Login, PW Forgotten, PW Sent).
 * Handles communication with the backend. Checks if the user is locked.
 */
@Component({
  selector: 'iq-login-base-layout',
  templateUrl: './login-base-layout.component.html',
  styleUrls: ['./login-base-layout.component.scss']
})
export class LoginBaseLayoutComponent implements OnInit, OnDestroy {
  /**
   * Keeps the subscriptions for the locks (login, password forgetting)
   */
  remainingTimeSubscriptions: RemainingTimeSubscriptions;

  /**
   * Indicates whether the login data is currently being sent.
   */
  loginLoading = false;

  /**
   * Indicates if the login failed.
   */
  loginFailed = false;

  /**
   * Indicates whether the password forget data is currently being sent.
   */
  passwordForgottenLoading = false;

  /**
   * Indicates the current year
   */
  currentYear = new Date().getFullYear();

  /**
   * Saves the view state of the login page.
   */
  loginViewState: LoginViewState;

  /**
   * Keeps the data for the remaining time of the locks (login, password forgetting).
   */
  remainingTime: RemainingTime;

  /**
   * Constructor.
   * @param router {@link Router}
   * @param toastr {@link ToastrService}
   * @param authService {@link AuthService}
   * @param blockedIpService {@link BlockedIpService}
   * @param sessionService {@link SessionService}
   * @param passwordForgottenService {@link PasswordForgottenService}
   * @param uebergeordneteFilterService {@link UebergeordneteFilterService}
   * @param themeService {@link ThemeService}
   */
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthService,
    private blockedIpService: BlockedIpService,
    private sessionService: SessionService,
    private passwordForgottenService: PasswordForgottenService,
    private uebergeordneteFilterService: UebergeordneteFilterService,
    private themeService: ThemeService<PortalThemeColors>
  ) {}

  /**
   * Initializes the loading indicators
   * ([loginLoading]{@link LoginBaseLayoutComponent#loginLoading},
   * [passwordForgottenLoading]{@link LoginBaseLayoutComponent#passwordForgottenLoading}).
   * Initializes the  [loginViewState]{@link LoginBaseLayoutComponent#loginViewState}.
   * Queries the lockout times for login and password forgetting.
   */
  ngOnInit() {
    this.loginLoading = false;
    this.passwordForgottenLoading = false;

    this.loginViewState = {
      loginView: true,
      passwordForgottenView: false,
      passwordLinkRequestedView: false
    };

    this.remainingTime = {
      login: 0,
      passwordForgotten: 0
    };

    this.remainingTimeSubscriptions = {};
    this.remainingTimeSubscriptions.login = this.getRemainingTimeForLogin().subscribe();
    this.remainingTimeSubscriptions.passwordtForgotten = this.getRemainingTimeForPasswordForgotten().subscribe();
  }

  /**
   * Uses [unsubscribeFromRemainingTime]{@link LoginBaseLayoutComponent#unsubscribeFromRemainingTime}
   * to unsubscribe from RemainingTime observables when destroying the component.
   */
  ngOnDestroy(): void {
    this.unsubscribeFromRemainingTime();
  }

  /**
   * Handles the login event.
   * Performs login via the {@link AuthService}, loads the user theme and navigates to the home page.
   * @param event
   */
  handleLoginEvent(event: LoginEvent): void {
    if (this.remainingTime.login > 0) {
      return;
    }

    this.toastr.clear();
    const loginData = event.loginData;
    this.loginLoading = true;
    this.loginFailed = false;

    this.authService
      .login(loginData)
      .pipe(
        finalize(() => {
          this.loginLoading = false;
        })
      )
      .subscribe({
        next: (currentUser: CurrentUser) => {
          this.getUserTheme(currentUser.userProperties);

          this.router.navigate(['/portal/startseite']);
          this.sessionService.sessionActive.next(true);

          if (currentUser.Menuepunkte.indexOf(MENUPUNKTE.SCHLACHTDATEN.SCHLACHTDATEN) !== -1) {
            this.uebergeordneteFilterService.initAfterLogin(FilterBereiche.SO);
          }
          if (currentUser.Menuepunkte.indexOf(MENUPUNKTE.ETM.ETM) !== -1) {
            this.uebergeordneteFilterService.initAfterLogin(FilterBereiche.ETM);
          }
          if (currentUser.Menuepunkte.indexOf(MENUPUNKTE.ETZ.ETZ) !== -1) {
            this.uebergeordneteFilterService.initAfterLogin(FilterBereiche.ETZ);
          }
        },
        error: err => {
          this.unsubscribeFromRemainingTime();
          this.remainingTimeSubscriptions.login = this.getRemainingTimeForLogin().subscribe();
          this.loginFailed = true;
        }
      });
  }

  /**
   * Loads the selected theme of the user if it isn't already active.
   * @param userProperties {@link UserProperties}
   */
  getUserTheme(userProperties: UserProperties) {
    loadUserTheme(this.themeService, userProperties);
  }

  /**
   * Handles the passwordforget event.
   * @param event
   */
  handlePasswordForgottenEvent(event: PasswordForgottenEvent): void {
    if (this.remainingTime.passwordForgotten > 0) {
      return;
    }

    const data: PasswordForgottenRequestData = {
      BenutzerId: event.passwordForgottenData.userName
    };
    this.passwordForgottenLoading = true;
    this.passwordForgottenService
      .postPasswordForgotten(data)
      .pipe(
        catchError(err => of()),
        finalize(() => {
          const viewEvent = new ShowPasswordRequestedViewEvent();
          this.switchView(viewEvent);
          this.passwordForgottenLoading = false;
        })
      )
      .subscribe();
  }

  /**
   * Handles switching between the different views on the login page.
   * @param event The switch event that was emitted.
   */
  switchView(event): void {
    this.disableAllViews();
    this.unsubscribeFromRemainingTime();

    if (event instanceof ShowLoginViewEvent) {
      this.remainingTimeSubscriptions.login = this.getRemainingTimeForLogin().subscribe();
      this.loginViewState.loginView = true;
    } else if (event instanceof ShowPasswordForgottenViewEvent) {
      this.remainingTimeSubscriptions.passwordtForgotten = this.getRemainingTimeForPasswordForgotten().subscribe();
      this.loginViewState.passwordForgottenView = true;
    } else if (event instanceof ShowPasswordRequestedViewEvent) {
      this.loginViewState.passwordLinkRequestedView = true;
    } else {
      this.loginViewState.loginView = true;
    }
  }

  /**
   * Queries the lockout time for the login from the backend.
   * Starts a timer if the received lock time is greater than 0 seconds.
   */
  private getRemainingTimeForLogin(): Observable<number> {
    return this.blockedIpService.getRemainingTimeForLogin().pipe(
      tap(remainingTimeForLogin => (this.remainingTime.login = remainingTimeForLogin)),
      filter(remainingTimeForLogin => remainingTimeForLogin > 0),
      switchMap(remainingTimeForLogin => timer(1000, 1000).pipe(
        tap(() => this.remainingTime.login--),
        takeWhile(() => this.remainingTime.login > 0)
      ))
    );
  }

  /**
   * Queries the lock time for the "Forgot password" function from the backend.
   * Starts a timer if the received lock time is greater than 0 seconds.
   */
  private getRemainingTimeForPasswordForgotten(): Observable<number> {
    return this.blockedIpService.getRemainingTimeForPasswordForgotten().pipe(
      tap(remainingTimeForPasswordForgot => (this.remainingTime.passwordForgotten = remainingTimeForPasswordForgot)),
      filter(remainingTimeForPasswordForgot => remainingTimeForPasswordForgot > 0),
      switchMap(remainingTimeForPasswordForgot => timer(1000, 1000).pipe(
        tap(() => this.remainingTime.passwordForgotten--),
        takeWhile(() => this.remainingTime.passwordForgotten > 0)
      ))
    );
  }

  /**
   * Unsubscribed from the subscriptions for login or password forgetting, if existing.
   */
  private unsubscribeFromRemainingTime(): void {
    if (this.remainingTimeSubscriptions.login) {
      this.remainingTimeSubscriptions.login.unsubscribe();
    }

    if (this.remainingTimeSubscriptions.passwordtForgotten) {
      this.remainingTimeSubscriptions.passwordtForgotten.unsubscribe();
    }
  }

  /**
   * Sets all view states to false.
   */
  private disableAllViews(): void {
    this.loginViewState = {
      loginView: false,
      passwordForgottenView: false,
      passwordLinkRequestedView: false
    };
  }
}
