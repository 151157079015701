import { Observable, ReplaySubject } from 'rxjs';

/**
 * Creates a stream and directly fires a void event. Afterwards the stream is closed directly.
 */
export function next(): Observable<void> {
  const subject = new ReplaySubject<void>();
  subject.next();
  subject.complete();
  return subject.asObservable();
}
