/**
 * Describes the available sections of the user properties.
 * Can be used with dynamic access to the individual areas.
 */
export enum UserPropertiesBereiche {
  ALLGEMEIN = 'allgemein',
  SO = 'so',
  ETM = 'etm',
  ETZ = 'etz',
  WIDGETS = 'widgets'
}
