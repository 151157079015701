import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, NgZone, OnChanges, OnDestroy, PLATFORM_ID } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { BaseChartComponent } from '@swimlane/ngx-charts';
import { cloneDeep } from 'lodash-es';
import { ThemeService } from '@iq-angular-libs/portal';
import { PortalThemeColors } from '@core/theme/theme.interfaces';

@Component({
  selector: 'iq-base-chart',
  template: `
    <div></div>
  `
})
export class IqBaseChartComponent extends BaseChartComponent implements OnChanges, AfterViewInit, OnDestroy {
  constructor(
    chartElement: ElementRef,
    zone: NgZone,
    cd: ChangeDetectorRef,
    protected translate: TranslateService,
    protected themeService: ThemeService<PortalThemeColors>,
    @Inject(PLATFORM_ID) public platformId: any
  ) {
    super(chartElement, zone, cd, platformId);
  }

  update(): void {
    if (this.results) {
      this.results = this.cloneDataChild(this.results);
    } else {
      this.results = [];
    }

    if (this.view) {
      this.width = this.view[0];
      this.height = this.view[1];
    } else {
      const dims = this.getContainerDims();
      if (dims) {
        this.width = dims.width;
        this.height = dims.height;
      }
    }

    // default values if width or height are 0 or undefined
    if (!this.width) {
      this.width = 600;
    }

    if (!this.height) {
      this.height = 400;
    }

    this.width = Math.floor(this.width);
    this.height = Math.floor(this.height);

    if (this.cd) {
      this.cd.markForCheck();
    }
  }

  /**
   * Clones the data into a new object
   * @param data
   */
  private cloneDataChild(data): any {
    return cloneDeep(data);
  }
}
