import { AnimationEvent } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AnzahlTage } from '@core/constants';
import { getWidgetBearbeitenContainerAnimation } from '../widgets';

@Component({
  selector: 'iq-anzahl-tage-option',
  templateUrl: './anzahl-tage-option.component.html',
  styleUrls: ['./anzahl-tage-option.component.scss'],
  animations: [getWidgetBearbeitenContainerAnimation(40)]
})
export class AnzahlTageOptionComponent implements OnChanges, OnInit {
  /** The language key for the text in front of the radio buttons */
  @Input()
  textPraefixKey: string;

  /** The language key for the text behind the radio buttons */
  @Input()
  textSuffixKey: string;

  /** The initial value of the number of days */
  @Input()
  defaultAnzahlTage: AnzahlTage;

  /** Output event that emits a change in the number of days */
  @Output()
  anzahlTageChanged: EventEmitter<number>;

  /** Indicates whether the options are open */
  optionsOpen = false;

  /** Specifies whether the content of the option container is displayed. */
  showOptions = false;

  /** The model that stores the selected number of days */
  anzahlTageModel: AnzahlTage = AnzahlTage.Sechzig;

  /** Number of days enum to use in template */
  AnzahlTage: AnzahlTage[];

  /** Name of radio buttons inside one radio button group */
  radioButtonName: string;

  constructor() {
    this.anzahlTageChanged = new EventEmitter<number>();
    this.AnzahlTage = [AnzahlTage.Dreissig, AnzahlTage.Sechzig, AnzahlTage.Neunzig];
  }

  ngOnInit() {
    this.generateRadioButtonName();
  }

  /**
   * Sets the inital value for the number of days.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.defaultAnzahlTage) {
      this.anzahlTageModel = changes.defaultAnzahlTage.currentValue;
    }
  }

  /**
   * Sets the showOptions property depending on the animation state.
   * @param event The AnimationEvent
   */
  onOptionsAnimationDone(event: AnimationEvent) {
    if (event.toState === 'optionsClosed') {
      this.showOptions = false;
    } else {
      this.showOptions = true;
    }
  }

  /**
   * Toggles the optionsOpen Property
   */
  toggleOptions() {
    this.optionsOpen = !this.optionsOpen;
  }

  /**
   * Emits the selected number of days to the parent component
   * @param anzahlTage The newly selected number of days
   */
  emitAnzahlTageChange(anzahlTage: number) {
    this.anzahlTageChanged.emit(anzahlTage);
  }

  /** Generate name of radio buttons inside one radio button group */
  private generateRadioButtonName(): void {
    this.radioButtonName = 'RB_' + Math.random().toString(36).substring(0, 10);
  }
}
