import { PortalThemeColors } from '../theme.interfaces';
import { Colors, darkenColor, lightenColor, Theme, themeHighContrast } from '@iq-angular-libs/portal';

/**
 * Extends the high contrast theme with relavant colors for the portal.
 */
const themeColors: PortalThemeColors = {
  ...themeHighContrast.themeColors,
  segmentPieChartBackground: Colors.gray400,
  befundTypColor1: Colors.green,
  befundTypColor2: Colors.brightTurquoise,
  befundTypColor3: Colors.black,
  maskenvergleichGrundmaskeLineColor: Colors.gray600,
  maskenbetrachtungBalkenNeutralColor: Colors.gray600,
  maskenbetrachtungDiagrammDarkGruen: darkenColor(lightenColor(Colors.mountainMeadow2, 25), 9),
  tageszunahmeTachoGradientStartColor: lightenColor(Colors.green, 35),
  tageszunahmeTachoGradientEndColor: Colors.green
};

/**
 * The new high contrast theme extended with relevant colors.
 */
export const portalThemeHighContrast = new Theme<PortalThemeColors>(
  themeHighContrast.name,
  'THEME.HOHER_KONTRAST',
  themeHighContrast.class,
  themeColors
);
