import { isEmpty, isNil } from 'lodash-es';
import { UserProperties } from '@core/user/user.interfaces';
import { ThemeService } from '@iq-angular-libs/portal';
import { PortalThemeColors } from '@core/theme/theme.interfaces';

/**
 * Loads the selected theme of the user if it isn't already active.
 * @param themeService {@link ThemeService}
 * @param userProperties {@link UserProperties}
 */
export function loadUserTheme(themeService: ThemeService<PortalThemeColors>, userProperties: UserProperties) {
  if (isNil(themeService) || isNil(userProperties)) {
    return;
  }

  const storedTheme = userProperties.allgemein.AktivesTheme;
  if (!isNil(storedTheme) && !isEmpty(storedTheme)) {
    const currentTheme = themeService.getActiveThemeName();
    if (storedTheme !== currentTheme) {
      themeService.setActiveTheme(storedTheme);
    }
  }
}
