/**
 * Describes the widget categories into which the widgets are placed
 * in the "Select Widget" modal.
 */
export enum WidgetCategory {
  Allgemein = 0,
  Schlachtdaten = 1,
  Marktinformationen = 2,
  Qualitaetssicherung = 3
}
