<div class="widget-content-container register-unregister-vvvo">
  <iq-loading-indicator *ngIf="dataLoading"></iq-loading-indicator>
  <div *ngIf="errMessage && !dataLoading" class="mt-2 row">
    <h4>{{ errMessage | translate }}</h4>
  </div>

  <div *ngIf="!dataLoading && !errMessage" class="h-100">
    <div class="tabs-container h-100">
      <ul class="nav nav-tabs">
        <li class="nav-item" [class.active]="selectedTab === 0">
          <a
            class="nav-link"
            (click)="selectTab($event, 0)"
            href="#"
            title="{{ tabs[0] }}">
            {{ tabs[0] }}
          </a>
        </li>
        <li class="nav-item" [class.active]="selectedTab === 1">
          <a class="nav-link" (click)="selectTab($event, 1)" href="#">
            {{ tabs[1] }}
          </a>
        </li>
      </ul>
      <div class="tab-content-height">
        <div slimScroll class="h-100 scroll-space" [scrollEvents]="slimScrollEvents">
          <div class="mt-2">
            <p *ngIf="!data?.length" class="text-center mt-2 mb-3">
              {{ 'KEINE_DATENSAETZE_VORHANDEN' | translate }}
            </p>

            <div *ngIf="data?.length" class="table-container table-responsive mb-1">
              <table class="table table-hover">
                <tbody>
                  <tr *ngFor="let regNumber of data">
                    <td class="vertical-baseline">
                      <div class="text-bold">{{ regNumber.RegistrationNumber }}</div>
                    </td>
                    <td class="vertical-baseline">
                      <div class="text-muted">{{ regNumber.CommercialUnitName }}</div>
                    </td>
                    <td class="vertical-baseline">
                      <div class="text-muted">{{ regNumber.EventDateAtUtc | date }}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <iq-anzahl-tage-option
    [textPraefixKey]="'WIDGETS.REGISTERED_UNREGISTERED_VVVOS.CHANGE_BEFORE'"
    [textSuffixKey]="'WIDGETS.QS_ABLAUF_ZULASSUNG.TAGEN'"
    [defaultAnzahlTage]="anzahlTage"
    (anzahlTageChanged)="anzahlTageChanged($event)"
  ></iq-anzahl-tage-option>
</div>
