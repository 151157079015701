<button
  class="btn btn-sm btn-primary m-0"
  [ngClass]="{ 'p-1': !isCellComponent, 'btn-table-cell': isCellComponent }"
  (click)="startDetailsNavigation()"
  placement="{{ tooltipPlacement }}"
  tooltip="{{ tooltipText }}"
  container="body"
>
  <i *ngIf="showIcon" class="fa fa-line-chart" aria-hidden="true"></i>
  <span *ngIf="buttonText" [ngClass]="{ 'ml-1': showIcon }">
    {{ buttonText }}
  </span>
</button>
