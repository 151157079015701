import { GlobalFilterService } from './../global-filter/global-filter.service';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@mattlewis92/angular-2-local-storage';
import { LOCALSTORAGE } from '../local-storage/local-storage.constants';
import { UserService } from '@core/user/user.service';
import { WidgetsManagerService } from '../../startseite/share/widgets-manager.service';
import { NavigationStart, Router } from '@angular/router';
import { WidgetsService } from '../../startseite/share/widgets.service';
import { filter } from 'rxjs/operators';
import { WebAccessService } from '@core/web-access/web-access.service';
import { FilterService } from '@share/filter/filter.service';
import { UebergeordneteFilterService } from '@share/filter/uebergeordnete-filter.service';
import { SegmentService } from '@share/filter/segment.service';
import { SegmentToggleService } from '@share/filter/segment-toggle.service';

/**
 * This service resets the temporary data from other services.
 * Will be used if a user logs out and in.
 */
@Injectable()
export class ResetService {
  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private globalFilterService: GlobalFilterService,
    private widgetsManagerService: WidgetsManagerService,
    private widgetsService: WidgetsService,
    private webAccessService: WebAccessService,
    private filterService: FilterService,
    private uebergeordneteFilterService: UebergeordneteFilterService,
    private segmentService: SegmentService,
    private segmentToggleService: SegmentToggleService
  ) {
    this.subscribeOnRouterEvents();
  }

  /**
   * Resets every value that stores data for the current session.
   * Should be called when a user logs in or out.
   */
  reset(userId?: string): void {
    const currentUserId = this.localStorageService.get(LOCALSTORAGE.CURRENT_USER_ID);

    // If the user id didn't changed, the entry will not be removed from the localstorage
    // to prevent unnecessary events.
    const regex = currentUserId === userId ? '^(?!.*' + LOCALSTORAGE.CURRENT_USER_ID + ').*$' : undefined;
    this.localStorageService.clearAll(regex);

    this.userService.resetCurrentUser();
    this.webAccessService.reset();

    this.widgetsManagerService.reset();

    this.globalFilterService.reset();
    this.filterService.reset();
    this.uebergeordneteFilterService.reset();
    this.segmentService.reset();
    this.segmentToggleService.reset();
  }

  /**
   * Will be called when navigated to a new route.
   * Checks if the new route is the startpage and if so resets the WidgetsService data.
   * Else nothing will be executed.
   * @param event {@link NavigationStart}
   */
  private onNavigationStart(event: NavigationStart): void {
    if (event.url === '/portal/startseite') {
      this.widgetsService.reset();
    }
  }

  /**
   * Subscribes to the router events and filters the NavigationStart event.
   * Calls the `onNavigationStart` function.
   */
  private subscribeOnRouterEvents(): void {
    this.router.events
      .pipe(
        filter(event => {
          if (event instanceof NavigationStart) {
            return true;
          }
          return false;
        })
      )
      .subscribe({
        next: (event: NavigationStart) => this.onNavigationStart(event)
      });
  }
}
