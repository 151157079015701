import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges
} from '@angular/core';
import { formatLabel, LineSeriesComponent } from '@swimlane/ngx-charts';
import { area, line } from 'd3-shape';
import { forEach, isNil } from 'lodash-es';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'g[iq-charts-line-series]',
  templateUrl: './iq-line-series.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IqLineSeriesComponent extends LineSeriesComponent implements OnChanges {
  @Input()
    data = undefined;
  @Input()
    xScale = undefined;
  @Input()
    yScale = undefined;
  @Input()
    colors = undefined;
  @Input()
    scaleType = undefined;
  @Input()
    curve: any = undefined;
  @Input()
    activeEntries: any[] = undefined;
  @Input()
    rangeFillOpacity: number = undefined;
  @Input()
    hasRange: boolean = undefined;
  @Input()
    animations = true;
  @Input()
    tooltipDisabled = false;
  @Input()
    tooltipTemplate;
  @Input()
    strokeDashArray = 0;
  @Input()
    chartLineData: any[];

  path: string = undefined;
  paths: string[] = [];
  outerPath: string = undefined;
  areaPath: string = undefined;
  areaPaths: any[] = [];
  gradientId: string = undefined;
  gradientUrl: string = undefined;
  hasGradient: boolean = undefined;
  gradientStops: any[] = undefined;
  areaGradientStops: any[] = undefined;
  stroke: any = undefined;
  sortedData: any;
  circles: any = [];
  tooltipAreaX: number;
  tooltipAreaY: number;

  ngOnChanges(): void {
    this.update();
  }

  update(): void {
    let data = [];
    if (this.data?.series?.length) {
      data = this.sortData(this.data?.series) || [];
      this.sortedData = data;
    } else {
      this.sortedData = [];
    }
    this.circles = [];

    forEach(this.sortedData, (dataEntry, index: number) => {
      if (!isNil(dataEntry.value)) {
        this.circles.push(this.mapDataPointToCircle(dataEntry, index));
      }
    });

    const lineGen = this.getLineGenerator();
    this.path = lineGen(data) || '';

    const areaGen = this.getAreaGenerator();
    this.areaPath = areaGen(data) || '';

    this.createLines(data, lineGen);

    if (this.hasRange) {
      const range = this.getRangeGenerator();
      this.outerPath = range(data) || '';
    }

    if (this.hasGradient) {
      this.stroke = this.gradientUrl;
      const values = this.data?.series.map(d => d.value);
      const max = Math.max(...values);
      const min = Math.min(...values);
      if (max === min) {
        this.stroke = this.colors.getColor(max);
      }
    } else {
      this.stroke = this.data?.color;
    }
  }

  createLines(data, lineGen) {
    this.paths = [];
    let currentArray = [];
    for (let i = 0; i < data.length; i++) {
      if (isNil(data[i].value) && currentArray.length > 0) {
        this.paths.push(lineGen(currentArray));
        currentArray = [];
      } else if (!isNil(data[i].value)) {
        currentArray.push(data[i]);
        if (i === data.length - 1) {
          this.paths.push(lineGen(currentArray));
        }
      }
    }
  }

  getLineGenerator(): any {
    return line<any>()
      .x(d => {
        const label = d.name;
        let value;
        if (!isNil(d.value)) {
          if (this.scaleType === 'time') {
            value = this.xScale(label);
          } else if (this.scaleType === 'linear') {
            value = this.xScale(Number(label));
          } else {
            value = this.xScale(label);
          }
        } else {
          value = d.value;
        }
        return value;
      })
      .y(d => !isNil(d.value) ? this.yScale(d.value) : null)
      .curve(this.curve);
  }

  getRangeGenerator(): any {
    return area<any>()
      .x(d => {
        const label = d.name;
        let value;
        if (this.scaleType === 'time') {
          value = this.xScale(label);
        } else if (this.scaleType === 'linear') {
          value = this.xScale(Number(label));
        } else {
          value = this.xScale(label);
        }
        return value;
      })
      .y0(d => this.yScale(typeof d.min === 'number' ? d.min : d.value))
      .y1(d => this.yScale(typeof d.max === 'number' ? d.max : d.value))
      .curve(this.curve);
  }

  /**
   * Maps the line data to a circle.
   * @param d One line data point
   * @param i The index of the data point
   */
  mapDataPointToCircle(d: any, i: number): any {
    const seriesName = this.data.name;

    const value = d.value;
    const label = d.name;
    const tooltipLabel = formatLabel(label);

    let cx;
    if (this.scaleType === 'time') {
      cx = this.xScale(label);
    } else if (this.scaleType === 'linear') {
      cx = this.xScale(Number(label));
    } else {
      cx = this.xScale(label);
    }

    const cy = this.yScale(value);
    const radius = 3;
    const height = this.yScale.range()[0] - cy;
    const opacity = 1;

    const color = this.data.color;

    const data = {
      series: seriesName,
      value,
      color,
      name: label,
      data: d.data
    };

    let highestValue = true;
    let lowestValue = true;

    forEach(this.chartLineData, entry => {
      if (entry.series[i].value > d.value) {
        highestValue = false;
      } else if (entry.series[i].value < d.value) {
        lowestValue = false;
      }
    });

    return {
      classNames: [`circle-data-${i}`],
      value,
      label,
      data,
      cx,
      cy,
      radius,
      height,
      tooltipLabel,
      tooltipPosition: this.getTooltipAreaCoordinates(highestValue, lowestValue),
      color,
      fill: 'none',
      opacity,
      seriesName,
      min: d.min,
      max: d.max
    };
  }

  activateCircle(circle) {
    circle.fill = circle.color;
    circle.radius = 5;
  }

  deactivateCircle(circle) {
    circle.fill = 'none';
    circle.radius = 3;
  }

  private getTooltipAreaCoordinates(
    highestValue: boolean,
    lowestValue: boolean
  ): { x: number; y: number; middle?: boolean } {
    if (highestValue && lowestValue) {
      return { x: 21, y: 10, middle: true };
    }
    if (highestValue) {
      return { x: 12, y: 25 };
    }
    if (lowestValue) {
      return { x: 12, y: 5 };
    }
    return { x: 21, y: 10, middle: true };
  }
}
