import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
  ElementRef,
  Renderer2,
  ViewChild,
  AfterViewInit
} from '@angular/core';

@Component({
  selector: 'iq-salmo-tacho-chart',
  templateUrl: './salmo-tacho-chart.component.html',
  styleUrls: ['./salmo-tacho-chart.component.scss']
})
export class SalmoTachoChartComponent implements OnInit, OnChanges, AfterViewInit {
  /**
   * The salmonella score
   */
  @Input()
  score: number;

  /**
   * The arc1 element ref used to draw the green area
   */
  @ViewChild('arc1', { read: ElementRef, static: true })
  arc1: ElementRef;

  /**
   * The arc2 element ref used to draw the yellow area
   */
  @ViewChild('arc2', { read: ElementRef, static: true })
  arc2: ElementRef;

  /**
   * The arc3 element ref used to draw the red area
   */
  @ViewChild('arc3', { read: ElementRef, static: true })
  arc3: ElementRef;

  /**
   * The degree number for the tacho needle
   */
  deg: number;

  constructor(private renderer: Renderer2) {}

  /**
   * Triggers the rendering of the tacho
   */
  ngOnInit() {
    this.redrawTacho();
  }

  /**
   * Triggers a redraw on every new score
   */
  ngOnChanges() {
    this.redrawTacho();
  }

  /**
   * Determines the three colored areas after the tacho was rendered.
   */
  ngAfterViewInit() {
    this.renderer.setAttribute(this.arc1.nativeElement, 'd', this.describeArc(200, 150, 100, -90, -30));
    this.renderer.setAttribute(this.arc2.nativeElement, 'd', this.describeArc(200, 150, 100, -30, 30));
    this.renderer.setAttribute(this.arc3.nativeElement, 'd', this.describeArc(200, 150, 100, 30, 90));
  }

  private polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians)
    };
  }

  private describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number) {
    const start = this.polarToCartesian(x, y, radius, endAngle);
    const end = this.polarToCartesian(x, y, radius, startAngle);
    const arcSweep = endAngle - startAngle <= 180 ? '0' : '1';
    const d = ['M', start.x, start.y, 'A', radius, radius, 0, arcSweep, 0, end.x, end.y].join(' ');
    return d;
  }

  /**
   * Converts the iqScore to transform it later to a degree number
   * @param score percentage of positive samples
   */
  private convertIqScore(score: number): number {
    if (!score || score <= 0) {
      return 0;
    }

    if (score >= 100) {
      return 100;
    }

    if (score <= 40) {
      return (5 / 3) * score;
    } else {
      return (5 / 9) * score + 400 / 9;
    }
  }

  /**
   * Converts the calculated score into a dagree number
   * @param iqScore the calculated iqScore
   */
  private iqScoreToDeg(iqScore: number): number {
    return (iqScore * 180) / 100;
  }

  /**
   * Redraws the tacho
   */
  private redrawTacho() {
    const iqScore = this.convertIqScore(this.score);
    this.deg = Math.round(this.iqScoreToDeg(iqScore));
  }
}
