import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  VergleichOptionen,
  VergleichswerteBearbeitenOptionen
} from '@share/vergleichswerte-bearbeiten/vergleichswerte-bearbeiten-modal.interfaces';
import { switchMap } from 'rxjs/operators';
import { VergleichswerteService } from '@share/vergleichswerte/vergleichswerte.service';
import { SegmentService } from '@share/filter/segment.service';
import { FilterBereiche } from '@share/filter/filter-bereiche';
import { throwError } from 'rxjs';

export class VergleichOptionenEvent {
  newVergleichOptionen: VergleichOptionen;

  constructor(vergleichOptionen: VergleichOptionen) {
    this.newVergleichOptionen = vergleichOptionen;
  }
}

@Component({
  selector: 'iq-vergleichswerte-bearbeiten-button',
  templateUrl: './vergleichswerte-bearbeiten-button.component.html'
})
export class VergleichswerteBearbeitenButtonComponent {
  /**
   * The current filter section
   */
  @Input()
  filterBereich: FilterBereiche;

  /**
   * The comparison options
   */
  @Input()
  vergleichOptionen: VergleichOptionen;

  /**
   * The edit comparision options. Indicates which field are set,
   * locked or hidden.
   */
  @Input()
  vergleichswerteBearbeitenOptionen: VergleichswerteBearbeitenOptionen;

  /**
   * The output event to emit the new comparison options
   */
  @Output()
  newVergleichswerte: EventEmitter<VergleichOptionenEvent>;

  constructor(private segmentService: SegmentService, private vergleichswerteService: VergleichswerteService) {
    this.newVergleichswerte = new EventEmitter<VergleichOptionenEvent>();
  }

  /**
   * Specifies the selected time span, the selected genders and
   * opens the edit comparison modal with the edit comparison options.
   */
  editVergleichswerte() {
    let geschlechterNames;

    this.segmentService
      .getSelectedZeitraum(this.filterBereich)
      .pipe(
        switchMap(selectedZeitraum => {
          this.vergleichswerteBearbeitenOptionen.gesetzteFelder.zeitraum = selectedZeitraum;
          return this.segmentService.getSelectedGeschlechter(this.filterBereich);
        }),
        switchMap(selectedGeschlechter => {
          geschlechterNames = selectedGeschlechter.map(obj => obj.displayName);
          this.vergleichswerteBearbeitenOptionen.gesetzteFelder.geschlecht = geschlechterNames.join(', ');

          switch (this.filterBereich) {
          case FilterBereiche.ETM:
          case FilterBereiche.ETZ:
            return this.vergleichswerteService.showEtkVergleichswerteModal(
              this.vergleichswerteBearbeitenOptionen.gesperrteFelder,
              this.vergleichswerteBearbeitenOptionen.versteckteFelder,
              this.vergleichswerteBearbeitenOptionen.gesetzteFelder,
              this.filterBereich
            );
            break;

          case FilterBereiche.SO:
            return this.vergleichswerteService.showSoVergleichswerteModal(
              this.vergleichswerteBearbeitenOptionen.gesperrteFelder,
              this.vergleichswerteBearbeitenOptionen.versteckteFelder,
              this.vergleichswerteBearbeitenOptionen.gesetzteFelder
            );
            break;

          default:
            return throwError(new Error('VergleichswerteBearbeitenButton: No Filterbereich'));
          }
        })
      )
      .subscribe(vergleichOptionen => {
        const vergleichOptionenEvent = new VergleichOptionenEvent(vergleichOptionen);
        this.newVergleichswerte.emit(vergleichOptionenEvent);
      });
  }
}
