import { Injectable } from '@angular/core';
import { DataService } from '../data/data.service';
import { BehaviorSubject, forkJoin, Observable, throwError } from 'rxjs';
import { catchError, concatMap, map, take, tap } from 'rxjs/operators';
import { COLUMNS, COMMON, DEMOUSERS } from '../constants';
import { forIn, isNil } from 'lodash-es';
import {
  ColumnTableInfo,
  CurrentUser,
  NewEmailRequestData,
  NewPasswordRequestData,
  TablesConfig,
  TablesConfigBackend,
  UserProperties,
  UserService as IUserService,
} from './user.interfaces';
import { WebAccessService } from '../web-access/web-access.service';
import { LOCALSTORAGE } from '../local-storage/local-storage.constants';
import { LocalStorageService } from '@mattlewis92/angular-2-local-storage';
import { UserPropertiesChangedEvent } from '@core/user/user.events';
import { UserPropertiesBereiche } from '@core/user/user.enums';
import { TableService, ColumnInformation, Categories } from '@iq-angular-libs/portal';

/** This service is used to fetch user specific data from the backend. */
@Injectable()
export class UserService implements IUserService {
  /** Stores the base url for all request of this service */
  serviceBase = COMMON.API_ROOT + '/portaldaten/user';

  /** BehaviourSubject which stores the current user if already fetched */
  currentUserBehaviourSubject: BehaviorSubject<CurrentUser>;

  /** BehaviourSubject which stores the user properties */
  userPropertiesBehaviourSubject: BehaviorSubject<UserPropertiesChangedEvent>;

  /** Stores the logged in user */
  private currentUser: CurrentUser;

  constructor(
    private dataService: DataService,
    private webAccessService: WebAccessService,
    private localStorageService: LocalStorageService,
    private taleService: TableService
  ) {
    this.currentUserBehaviourSubject = new BehaviorSubject(null);
    this.userPropertiesBehaviourSubject = new BehaviorSubject(new UserPropertiesChangedEvent(null));

    this.subscribeOnTableServiceStreams();
  }

  /**
   * Fetches the user data (K0-number, UserProperties etc.) from the backend and returns them.
   * @return the CurrentUser object when all data was succesfully fetched.
   */
  receiveCurrentUser(): Observable<CurrentUser> {
    return this.dataService.getData<CurrentUser>(this.serviceBase).pipe(
      concatMap((user: CurrentUser) => {
        this.currentUser = user;
        return forkJoin([this.getUserProperties(), this.getTablesConfig()]);
      }),
      map((data) => {
        this.currentUser.userProperties = data[0];
        this.currentUser.tablesConfig = this.parseTablesConfig(data[1]);
        return this.currentUser;
      }),
      tap((user: CurrentUser) => {
        this.webAccessService.setMenupunkte(this.currentUser.Menuepunkte);
        if (this.currentUser.userProperties.allgemein.SpaltenMerken) {
          this.saveTableConfigToLocalStorage(this.currentUser.tablesConfig);
        }

        const userPropertiesChangedEvent = new UserPropertiesChangedEvent(this.currentUser.userProperties);
        this.currentUserBehaviourSubject.next(user);
        this.userPropertiesBehaviourSubject.next(userPropertiesChangedEvent);
      }),
      catchError((err) => throwError(err))
    );
  }

  /**
   * Returns the current user data. If the data wasn't fetched already,
   * they will be fetch over the @link{iqPortalApp.data.UserService:receiveCurrentUser}
   * function. Otherwise the data will be directly emitted.
   * @return the data of the current user
   */
  getCurrentUser(): Observable<CurrentUser> {
    if (!this.currentUserBehaviourSubject.value) {
      return this.receiveCurrentUser();
    }
    return this.currentUserBehaviourSubject.asObservable();
  }

  /**
   * Saves the user properties of the current user for the given section {@link UserPropertiesBereiche}
   * @param bereich the section {@link UserPropertiesBereiche} of the properties
   * @param userproperties the user user properties
   * @param broadcast describes if the "UserPropertiesChangedEvent" should be fired
   */
  postUserproperties(
    bereich: UserPropertiesBereiche,
    userproperties: UserProperties,
    broadcast: boolean = true
  ): Observable<void> {
    return this.dataService
      .postDataWithParameters<void>(this.serviceBase + '/properties/' + bereich, userproperties[bereich])
      .pipe(
        tap(() => {
          this.currentUser.userProperties = userproperties;

          if (broadcast) {
            const userPropertiesChangedEvent = new UserPropertiesChangedEvent(userproperties, bereich);
            this.userPropertiesBehaviourSubject.next(userPropertiesChangedEvent);
          }
        })
      );
  }

  /**
   * Saves the table configuration of the current user for the given section (e.g. antibiotics overview).
   * @param tablesConfig the table configuration
   * @param rubrikKey the key which describes the section in the a setting has been changed
   * @return an observable which emits when the config was posted
   */
  postTablesConfig(tablesConfig: TablesConfig, rubrikKey: string): Observable<void> {
    const config = {};
    config[rubrikKey] = JSON.stringify(tablesConfig[rubrikKey]);
    return this.dataService.postDataWithParameters<void>(this.serviceBase + '/columns', config);
  }

  /**
   * Posts the new password of the current user to the backend.
   * @param passwordData the password data containg the new and old password
   */
  postUserPassword(passwordData: NewPasswordRequestData): Observable<void> {
    return this.dataService.postDataWithParameters<void>(this.serviceBase + '/password', passwordData);
  }

  /**
   * Posts the new e-mail adress of the current user to the backend.
   * @param emailData the new e-mail data containing the password and the new password
   */
  postUserEmailToSetNewEmail(emailData: NewEmailRequestData) {
    return this.dataService.postDataWithParameters<void>(this.serviceBase + '/email/validate/initial', emailData);
  }

  /**
   * Posts the changed e-mail adress of the current user to the backend.
   * @param emailData the e-mail data containing the password and the changed password
   */
  postUserEmailToChangeEmail(emailData: NewEmailRequestData) {
    return this.dataService.postDataWithParameters<void>(this.serviceBase + '/email/validate', emailData);
  }

  /** Deletes the data of the current user. */
  resetCurrentUser() {
    if (!isNil(this.currentUserBehaviourSubject.getValue())) {
      this.currentUserBehaviourSubject.complete();
    }
    this.currentUserBehaviourSubject = new BehaviorSubject<CurrentUser>(null);
    this.currentUser = null;
  }

  /**
   * Checks if current user is test user
   * @returns true if it is a tes user
   */
  isTestUser(): boolean {
    const currentUser: string = this.localStorageService.get(LOCALSTORAGE.CURRENT_USER_ID);
    return [DEMOUSERS.DEMO_USER_IQ_1, DEMOUSERS.DEMO_USER_IQ_2, DEMOUSERS.DEMO_USER_TOENNIES].includes(currentUser);
  }

  /**
   * Stores the table configuration received from the backend inside the local storage.
   * @param tablesConfig the parsed table config received from the backend
   */
  private saveTableConfigToLocalStorage(tablesConfig: TablesConfig) {
    const localStorageService = this.localStorageService;
    forIn(tablesConfig, (value, key) => {
      if (value) {
        switch (key) {
        case COLUMNS.SCHLACHTDATEN.LIEFERPARTIEN.OVERVIEW:
          localStorageService.set(LOCALSTORAGE.SCHLACHTDATEN.LIEFERPARTIEN.OVERVIEW.COLUMNS, value.columns);
          break;
        case COLUMNS.SCHLACHTDATEN.WIEGELISTE:
          localStorageService.set(LOCALSTORAGE.SCHLACHTDATEN.WIEGELISTE.GENERAL.COLUMNS, value.columns);
          localStorageService.set(LOCALSTORAGE.SCHLACHTDATEN.WIEGELISTE.GENERAL.CATEGORIES, value.categories);
          break;
        case COLUMNS.SCHLACHTDATEN.BETRIEBSVERGLEICH.IXP:
          localStorageService.set(LOCALSTORAGE.SCHLACHTDATEN.BETRIEBSVERGLEICH.IXP.COLUMNS, value.columns);
          break;
        case COLUMNS.SCHLACHTDATEN.BETRIEBSVERGLEICH.MFA:
          localStorageService.set(LOCALSTORAGE.SCHLACHTDATEN.BETRIEBSVERGLEICH.MFA.COLUMNS, value.columns);
          break;
        case COLUMNS.SCHLACHTDATEN.BEFUNDDATEN.OVERVIEW:
          localStorageService.set(LOCALSTORAGE.SCHLACHTDATEN.BEFUNDDATEN.OVERVIEW.CATEGORIES, value.categories);
          localStorageService.set(LOCALSTORAGE.SCHLACHTDATEN.BEFUNDDATEN.OVERVIEW.COLUMNS, value.columns);
          break;
        case COLUMNS.SCHLACHTDATEN.BEFUNDDATEN.KUPIERVERZICHT:
          localStorageService.set(LOCALSTORAGE.SCHLACHTDATEN.BEFUNDDATEN.KUPIERVERZICHT.CATEGORIES, value.categories);
          localStorageService.set(LOCALSTORAGE.SCHLACHTDATEN.BEFUNDDATEN.KUPIERVERZICHT.COLUMNS, value.columns);
          break;
        case COLUMNS.MASTGRUPPEN.OVERVIEW:
          localStorageService.set(LOCALSTORAGE.MASTGRUPPEN.OVERVIEW.COLUMNS, value.columns);
          break;
        case COLUMNS.MASTGRUPPEN.CATEGORIES:
          localStorageService.set(LOCALSTORAGE.MASTGRUPPEN.OVERVIEW.CATEGORIES, value.categories);
          break;
        case COLUMNS.MASTGRUPPEN.DETAILS.LIEFERPARTIEN:
          localStorageService.set(LOCALSTORAGE.MASTGRUPPEN.DETAILS.LIEFERPARTIEN.CATEGORIES, value.categories);
          localStorageService.set(LOCALSTORAGE.MASTGRUPPEN.DETAILS.LIEFERPARTIEN.COLUMNS, value.columns);
          break;
        case COLUMNS.MASTGRUPPEN.FUTTERVORLAGEN.OVERVIEW:
          localStorageService.set(LOCALSTORAGE.FOODTEMPLATES.OVERVIEW.COLUMNS, value.columns);
          break;
        case COLUMNS.SALMONELLENMONITORING.OVERVIEW:
          localStorageService.set(LOCALSTORAGE.SALMONELLEN.OVERVIEW.COLUMNS, value.columns);
          localStorageService.set(LOCALSTORAGE.SALMONELLEN.OVERVIEW.CATEGORIES, value.categories);
          break;
        case COLUMNS.ANTIBIOTIKA.OVERVIEW:
          localStorageService.set(LOCALSTORAGE.ANTIBIOTIKA.OVERVIEW.COLUMNS, value.columns);
          break;
        case COLUMNS.QUALITAETSSICHERUNG.OVERVIEW:
          localStorageService.set(LOCALSTORAGE.QUALITAETSSICHERUNG.OVERVIEW.COLUMNS, value.columns);
          break;
        case COLUMNS.BLACKBOX.OVERVIEW:
          localStorageService.set(LOCALSTORAGE.BLACKBOX.OVERVIEW.COLUMNS, value.columns);
          break;
        case COLUMNS.QS_MANAGEMENT.BETRIEBSLISTE:
          localStorageService.set(LOCALSTORAGE.QS_MANAGEMENT.BETRIEBSLISTE.COLUMNS, value.columns);
          localStorageService.set(LOCALSTORAGE.QS_MANAGEMENT.BETRIEBSLISTE.CATEGORIES, value.categories);
          break;
        case COLUMNS.QS_MANAGEMENT.MAENGELLISTE:
          localStorageService.set(LOCALSTORAGE.QS_MANAGEMENT.MAENGELLISTE.COLUMNS, value.columns);
          localStorageService.set(LOCALSTORAGE.QS_MANAGEMENT.MAENGELLISTE.CATEGORIES, value.categories);
          break;
        case COLUMNS.BETRIEBSSTAETTEN_UEBERSICHT:
          localStorageService.set(LOCALSTORAGE.BETRIEBSSTAETTEN.OVERVIEW.COLUMNS, value.columns);
          break;
        case COLUMNS.UNTERBERATER_UEBERSICHT:
          localStorageService.set(LOCALSTORAGE.UNTERBERATER.COLUMNS, value.columns);
          break;
        case COLUMNS.ITW.OVERVIEW:
          localStorageService.set(LOCALSTORAGE.ITW.OVERVIEW.COLUMNS, value.columns);
          break;
        case COLUMNS.BENACHRICHTIGUNG.OVERVIEW:
          localStorageService.set(LOCALSTORAGE.BENACHRICHTIGUNGEN.OVERVIEW.COLUMNS, value.columns);
          break;
        case COLUMNS.SCHLACHTDATEN.AGGREGATIONS.IXP:
          localStorageService.set(LOCALSTORAGE.SCHLACHTDATEN.AGGREGATION.GEWICHT.TABLE.IXP, value.columns);
          localStorageService.set(LOCALSTORAGE.SCHLACHTDATEN.AGGREGATION.ZEITRAUM.TABLE.IXP, value.columns);
          break;
        case COLUMNS.SCHLACHTDATEN.AGGREGATIONS.MFA:
          localStorageService.set(LOCALSTORAGE.SCHLACHTDATEN.AGGREGATION.GEWICHT.TABLE.MFA, value.columns);
          localStorageService.set(LOCALSTORAGE.SCHLACHTDATEN.AGGREGATION.ZEITRAUM.TABLE.MFA, value.columns);
          break;
        case COLUMNS.VERTRAGSVERWALTUNG.OVERVIEW:
          localStorageService.set(LOCALSTORAGE.VERTRAGSVERWALTUNG.OVERVIEW.COLUMNS, value.columns);
          break;
        default:
          break;
        }
      }
    });
  }

  /**
   * Analyzes the loaded table configurations and converts them into an object structure (JSON.parse)
   * @param tablesConfig the table config received from the backend
   */
  private parseTablesConfig(tablesConfig: TablesConfigBackend): TablesConfig {
    const parsedTablesConfig: TablesConfig = {};
    forIn(tablesConfig, (value, key) => {
      if (value) {
        try {
          parsedTablesConfig[key] = JSON.parse(value);
          if (parsedTablesConfig[key]?.columns.length) {
            parsedTablesConfig[key].columns.forEach((col: ColumnTableInfo) => {
              delete col.itemFilter; // Just ignore filters received from backend (legacy logic)
            });
          }
        } catch (e) {
          console.error('UserService - Parse tablesConfig.' + key + ' (value: "' + value + '"): ' + e);
          parsedTablesConfig[key] = null;
        }
      }
    });

    return parsedTablesConfig;
  }

  /**
   * Gets the settings of the current user.
   * @return observable which emits when the settings are fetched
   */
  private getUserProperties(): Observable<UserProperties> {
    const properties$ = forkJoin([
      this.dataService.getData<any>(this.serviceBase + '/properties/allgemein'),
      this.dataService.getData<any>(this.serviceBase + '/properties/so'),
      this.dataService.getData<any>(this.serviceBase + '/properties/etm'),
      this.dataService.getData<any>(this.serviceBase + '/properties/etz'),
      this.dataService.getData<any>(this.serviceBase + '/properties/widgets'),
    ]).pipe(
      map((data): UserProperties => ({
        allgemein: data[0],
        so: data[1],
        etm: data[2],
        etz: data[3],
        widgets: data[4],
      }))
    );
    return properties$;
  }

  /**
   * Gets the table configurations for the current user.
   * @return observable which emits the table config
   */
  private getTablesConfig(): Observable<TablesConfigBackend> {
    return this.dataService.getData<TablesConfigBackend>(this.serviceBase + '/columns');
  }

  /**
   * Saves the column information (visibility) in the user profile. Sends the changes to the backend.
   * @param backendKey the table columns key used in the backend
   * @param columns the column information array
   * @param filterChanged Indicates whether the filters have changed
   */
  private saveColumnDataInCurrentUser(backendKey: string, columns: ColumnInformation[]) {
    this.getCurrentUser()
      .pipe(take(1))
      .subscribe((currentUser) => {
        const spaltenMerken = this.currentUser.userProperties.allgemein.SpaltenMerken;

        if (!currentUser.tablesConfig) {
          currentUser.tablesConfig = {};
        }
        if (!currentUser.tablesConfig[backendKey]) {
          currentUser.tablesConfig[backendKey] = {};
        }

        const columnData: ColumnInformation[] = columns.map((item) => ({
          field: item.field,
          headerTranslationKey: item.headerTranslationKey,
          hidden: item.hidden,
          categoryHidden: item.categoryHidden,
        }));
        currentUser.tablesConfig[backendKey].columns = columnData;

        if (spaltenMerken) {
          this.postTablesConfig(currentUser.tablesConfig, backendKey).pipe(take(1)).subscribe();
        }
      });
  }

  /**
   * Stores the category information (visibility, column span) in the user profile. Sends the changes to the backend.
   * @param backendKey the table category key used in the backend
   * @param categoryData the filtered category data array
   * @param doNotSend Indicates if the data should be send to the backend
   */
  private saveCategoryDataInCurrentUser(backendKey: string, categoryData: Categories, doNotSend: boolean) {
    this.getCurrentUser()
      .pipe(take(1))
      .subscribe((currentUser) => {
        if (!currentUser.tablesConfig) {
          currentUser.tablesConfig = {};
        }
        if (!currentUser.tablesConfig[backendKey]) {
          currentUser.tablesConfig[backendKey] = {};
        }

        currentUser.tablesConfig[backendKey].categories = categoryData;

        if (!doNotSend) {
          this.postTablesConfig(currentUser.tablesConfig, backendKey).pipe(take(1)).subscribe();
        }
      });
  }

  /**
   * It listens for changes in the columns or in the categories of the tables in order to save them in the settings if necessary.
   */
  private subscribeOnTableServiceStreams() {
    this.taleService.getSaveColumnsInBackendObservable().subscribe({
      next: (event) => this.saveColumnDataInCurrentUser(event.backendKey, event.columns),
      error: () => {},
    });

    this.taleService.getSaveCategoriesInBackendObservable().subscribe({
      next: (event) => this.saveCategoryDataInCurrentUser(event.backendKey, event.categories, event.doNotSend),
      error: () => {},
    });
  }
}
