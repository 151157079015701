import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Zeitraum } from '@share/filter/uebergeordnete-filter.interfaces';
import { FormGroup } from '@angular/forms';
import { DateChangedEvent } from '@share/date/date.component';
import { DatePipe } from '@angular/common';
import { FORMAT } from '@core/constants';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'iq-zeitraum',
  templateUrl: './zeitraum.component.html',
  styleUrls: ['./zeitraum.component.scss']
})
export class ZeitraumComponent implements OnChanges {
  /**
   * The configuration object for the last x days
   */
  @Input()
  zeitraum: Zeitraum;

  /**
   * Indicates whether the (Form){@link ZeitraumComponent#form} was already tried to submit.
   */
  @Input()
  submitted: boolean;

  /**
   * The form of the date modal.
   */
  @Input()
  form: FormGroup;

  /**
   * The lowest limit of the minimum date.
   */
  minDate: Date;

  /**
   * The upper limit of the maximum date.
   */
  maxDate: Date;

  /**
   * The current minimum.
   */
  currentMinDate: Date | null;

  /**
   * The current maximum.
   */
  currentMaxDate: Date | null;

  /**
   * The date pipe to format a date into a string.
   */
  private datePipe: DatePipe;

  /**
   * Constructor.
   */
  constructor() {
    this.datePipe = new DatePipe('de');
  }

  /**
   * Resets the values of the inputs when changes are made.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    // set the values in ngOnChanges and not in the constructor, otherwise
    if (!this.minDate) {
      this.minDate = new Date(2000, 0, 1);
    }

    if (!this.maxDate) {
      this.maxDate = new Date();
    }

    if (changes.zeitraum && this.zeitraum && this.form) {
      this.currentMinDate = this.zeitraum.from ? this.zeitraum.from : this.minDate;
      this.currentMaxDate = this.zeitraum.to ? this.zeitraum.to : this.maxDate;

      this.form.controls['From'].setValue(this.datePipe.transform(this.zeitraum.from, FORMAT.DATE_INPUT));
      this.form.controls['To'].setValue(this.datePipe.transform(this.zeitraum.to, FORMAT.DATE_INPUT));
    }
  }

  /**
   * Sets the new date of the (Event){@link DateChangedEvent} to the (Period){@link PeriodComponent#zeitraum} in the place 'from'.
   * Sets the new date for the (currentMinDate){@link ZeitraumComponent#currentMinDate}.
   * @param event {@link DateChangedEvent}
   */
  onFromDateChanged(event: DateChangedEvent) {
    this.zeitraum.from = event.date;
    this.currentMinDate = event.date ? cloneDeep(event.date) : this.minDate;
  }

  /**
   * Sets the new date of the (Event){@link DateChangedEvent} to the (Period){@link ZeitraumComponent#zeitraum} in place of 'to'.
   * Sets the new date for the (currentMaxDate){@link ZeitraumComponent#currentMaxDate}.
   * @param event {@link DateChangedEvent}
   */
  onToDateChanged(event: DateChangedEvent) {
    this.zeitraum.to = event.date;
    this.currentMaxDate = event.date ? cloneDeep(event.date) : this.maxDate;
  }
}
