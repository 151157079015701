import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FilterRangesModalBaseComponent } from '../../filter-ranges-modal-base.component';

@Component({
  selector: 'iq-tier-alter-filter-modal',
  templateUrl: './tier-alter-filter-modal.component.html',
  styleUrls: ['./tier-alter-filter-modal.component.scss']
})
export class TierAlterFilterModalComponent extends FilterRangesModalBaseComponent implements AfterViewInit {
  /**
   * The element reference to the first input field to focus it.
   */
  @ViewChild('tierAlterFocusInput', { static: true })
  tierAlterMinInput: ElementRef;

  /**
   *
   * @param bsModalRef
   */
  constructor(bsModalRef: BsModalRef) {
    super(bsModalRef);
  }

  /**
   * Focuses the first input when the modal is opened after the view is initialized.
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.tierAlterMinInput && this.tierAlterMinInput.nativeElement) {
        this.tierAlterMinInput.nativeElement.focus();
        this.tierAlterMinInput.nativeElement.select();
      }
    });
  }
}
