<button
  type="button"
  class="btn btn-primary"
  (click)="startNavigation()"
  [placement]="tooltipPlacement"
  [tooltip]="tooltipText"
>
  <span *ngIf="buttonText">{{ buttonText }}</span>
  <span>
    <i
      *ngIf="buttonText"
      class="iq-icon-font iq-icon-font-betriebsstaetten-group focus-registrierungsnummern ml-1"
      aria-hidden="true"
    ></i>
    <i
      *ngIf="!buttonText"
      class="iq-icon-font iq-icon-font-betriebsstaetten-group focus-registrierungsnummern"
      aria-hidden="true"
    ></i>
    <i class="fa fa-arrow-right ml-1" aria-hidden="true"></i>
  </span>
</button>
