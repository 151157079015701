import { Injectable } from '@angular/core';
import { COMMON } from '@core/constants';
import { DataService } from '@core/data/data.service';
import { Observable } from 'rxjs';

/**
 * Interface describing the {@link ValidationService}.
 */
export interface IValidationService {
  postEmailValidationToken(data: { Token: string }): Observable<void>;
}

@Injectable({
  providedIn: 'root'
})
export class ValidationService implements IValidationService {
  /**
   * The basic path of the end point
   */
  private serviceBase = COMMON.API_ROOT + '/portaldaten';

  /**
   * Constructor
   * @param dataService {@link DataService}
   */
  constructor(private dataService: DataService) {}

  /**
   * Sends the validation token from the email to the backend.
   * @param data An object that contains the validation token.
   */
  postEmailValidationToken(data: { Token: string }): Observable<void> {
    return this.dataService.postDataWithParameters<void>(this.serviceBase + '/email/validate', data);
  }
}
