import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FilterComponent } from '../filter-component.interfaces';
import { SelectedFilterItemsChangedEvent } from '../filter-component.events';
import { Schlachtbetriebe } from '@share/filter/filter.interfaces';
import { MultiselectChangedEvent, MultiselectClosedEvent, MultiselectSettings } from '@iq-angular-libs/portal';
import { Subject } from 'rxjs';
import { FilterService } from '@share/filter/filter.service';
import { GlobalFilterService } from '@core/global-filter/global-filter.service';
import { takeUntil } from 'rxjs/operators';
import { FilterBereiche } from '@share/filter/filter-bereiche';

@Component({
  selector: 'iq-schlachtbetriebe-filter',
  templateUrl: './schlachtbetriebe-filter.component.html'
})
export class SchlachtbetriebeFilterComponent implements OnInit, OnDestroy, FilterComponent {
  /**
   * The selected slaughterhouses
   */
  @Input()
  selectedItems: Schlachtbetriebe;

  /**
   * The filter section.
   */
  @Input()
  filterBereich: FilterBereiche;

  /**
   * Indicates whether the filter is disabled or not.
   */
  @Input()
  filterDisabled: boolean;

  /**
   * Indicator whether an event should be emitted with each selection.
   */
  @Input()
  updateOnChange: boolean;

  /**
   * Event emitter that fires an event when the filter has changed.
   */
  @Output()
  selectedItemsChanged: EventEmitter<SelectedFilterItemsChangedEvent>;

  /**
   * The settings of the (Multiselect Component){@link MultiselectButtonComponent}
   */
  settings: MultiselectSettings;

  /**
   * All existing slaughterhouses
   */
  schlachtbetriebe: Schlachtbetriebe;

  /**
  * The active slaughterhouses
  */
  activeSchlachtbetriebe: Schlachtbetriebe;


  /**
   * Unsubscribe-Stream.
   * Used in (ngOnDestroy){@link SchlachtbetriebeFilterComponent#ngOnDestroy}
   * to close all open subscriptions.
   */
  private unsubscribe$: Subject<void>;

  /**
   * Constructor
   * @param filterService filter service
   * @param globalFilterService globale filter service
   */
  constructor(private filterService: FilterService, private globalFilterService: GlobalFilterService) {
    this.schlachtbetriebe = [];
    this.activeSchlachtbetriebe = [];

    this.settings = {
      id: 'schlachtbetriebe-filter-multiselect',
      sortProp: 'displayName',
      enableDisplayFirstSelectedItem: true,
      enableDisplaySelectedShortnames: false,
      buttonClasses: 'btn btn-secondary iq-select-button iq-btn-filter',
      buttonMaxWidth: 250,
      dropdownAppendTo: 'body'
    };

    this.selectedItemsChanged = new EventEmitter<SelectedFilterItemsChangedEvent>();
    this.unsubscribe$ = new Subject<void>();
  }

  /**
   * Loads all and all active slaughterhouses at the beginning.
   * Registers to the GlobalFilterService to be informed about changes in the facilities.
   */
  ngOnInit() {
    this.getSchlachtbetriebe();
    this.getActiveSchlachtbetriebe();
    this.subscribeOnGlobalFilterChangedEvents();
  }

  /**
   * Closes the FilterComponentChanged event emitter.
   * Fires an event of the unsubscribe stream and closes it afterwards.
   */
  ngOnDestroy(): void {
    this.selectedItemsChanged.complete();

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Is called as soon as the multiselect selection has changed and the dropdown has closed.
   * @param event {@link MultiselectClosedEvent}
   */
  onMultiselectClosed(event: MultiselectClosedEvent) {
    if (!this.updateOnChange) {
      const filterComponentChangedEvent = new SelectedFilterItemsChangedEvent(event.selectedItems);
      this.selectedItemsChanged.emit(filterComponentChangedEvent);
    }
  }

  /**
   * Is called as soon as the multiselect selection has changed.
   * @param event {@link MultiselectChangedEvent}
   */
  onMultiselectChanged(event: MultiselectChangedEvent) {
    if (this.updateOnChange) {
      const selectedFilterItemsChangedEvent = new SelectedFilterItemsChangedEvent(event.selectedItems);
      this.selectedItemsChanged.emit(selectedFilterItemsChangedEvent);
    }
  }

  /**
   * Queries all existing slaughterhouses of the user from {@link FilterService}.
   */
  private getSchlachtbetriebe(): void {
    this.filterService.getSchlachtbetriebe(this.filterBereich).subscribe({
      next: (schlachtbetriebe: Schlachtbetriebe) => {
        this.schlachtbetriebe = schlachtbetriebe;
      }
    });
  }

  /**
   * Queries all active slaughterhouses of the user from {@link FilterService}.
   */
  private getActiveSchlachtbetriebe(): void {
    this.filterService.getActiveSchlachtbetriebe(this.filterBereich).subscribe({
      next: (activeSchlachtbetriebe: Schlachtbetriebe) => {
        this.activeSchlachtbetriebe = activeSchlachtbetriebe;
      }
    });
  }

  /**
   * Registers to the comercial units changes stream of the (GlobalFilterServices){@link GlobalFilterService}
   * to reload the active slaughterhouses in case of changes.
   */
  private subscribeOnGlobalFilterChangedEvents(): void {
    this.globalFilterService
      .getBetriebsstaetteChangedObservable()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.getActiveSchlachtbetriebe();
      });
  }
}
