<svg:g>
  <defs>
    <svg:g
      ngx-charts-svg-linear-gradient
      *ngIf="hasGradient"
      orientation="vertical"
      [name]="gradientId"
      [stops]="gradientStops"
    />
  </defs>
  <svg:g
    iq-charts-line
    *ngFor="let path of paths"
    class="line-series"
    [path]="path"
    [stroke]="stroke"
    [strokeDashArray]="strokeDashArray"
    [animations]="animations"
    [class.active]="true"
    [class.inactive]="isInactive(data)"
  />
  <svg:g *ngFor="let circle of circles">
    <svg:g
      ngx-charts-circle
      class="circle"
      [cx]="circle.cx"
      [cy]="circle.cy"
      [r]="circle.radius"
      [fill]="circle.fill"
      [stroke]="circle.color"
      [class.inactive]="isInactive(data)"
      stroke-width="2.0px"
    />
    <svg:rect
      [attr.x]="circle.cx - circle.tooltipPosition.x"
      [attr.y]="circle.cy - circle.tooltipPosition.y"
      [attr.width]="3 * (circle.tooltipPosition.middle ? 12 : 8)"
      [attr.height]="3 * (circle.tooltipPosition.middle ? 6 : 12)"
      fill-opacity="0.0"
      ngx-tooltip
      [tooltipDisabled]="tooltipDisabled"
      [tooltipPlacement]="'top'"
      [tooltipType]="'tooltip'"
      [tooltipTitle]="tooltipTemplate ? undefined : 'Test'"
      [tooltipTemplate]="tooltipTemplate"
      [tooltipContext]="circle.data"
      (show)="activateCircle(circle)"
      (hide)="deactivateCircle(circle)"
    />
  </svg:g>
  <svg:g
    ngx-charts-area
    *ngIf="hasRange"
    class="line-series-range"
    [data]="data"
    [path]="outerPath"
    [fill]="hasGradient ? gradientUrl : colors.getColor(data.name)"
    [class.active]="isActive(data)"
    [class.inactive]="isInactive(data)"
    [opacity]="rangeFillOpacity"
    [animations]="animations"
  />
</svg:g>
