import { LocalStorageService } from '@mattlewis92/angular-2-local-storage';
import { LOCALSTORAGE } from './../local-storage/local-storage.constants';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

/**
 * Interface which should be used by components who want to use the `CanDeactivateGuard`
 */
export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

/**
 * Guard which determines if a component including the corresponding route can be leaved.
 */
@Injectable()
export class CanDeactivateGuard {
  constructor(private localStorageService: LocalStorageService) {}

  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // check if user data inside localstorage. If not permit deactivation
    if (!this.localStorageService.get(LOCALSTORAGE.CURRENT_USER_ID)) {
      return true;
    }
    component = component ?? currentRoute.component as unknown as CanComponentDeactivate;
    console.log(component.canDeactivate);
    return component?.canDeactivate ? component.canDeactivate() : true;
  }
}
