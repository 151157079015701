import { Component, OnChanges, OnInit } from '@angular/core';
import { CellComponent } from '@iq-angular-libs/portal';
import { filter } from 'lodash-es';
import { QsUebersichtData } from '../../../qualitaetssicherung/auditing/share/qualitaetssicherung.interfaces';

@Component({
  selector: 'iq-qs-status',
  templateUrl: './qs-status.component.html'
})
export class QsStatusComponent implements OnInit, OnChanges, CellComponent {
  /**
   * The data for the table rows
   */
  data: QsUebersichtData;

  /**
   * The status list
   */
  options?: any;

  /**
   * The QS status which should be displayed
   */
  qsStatus: string;

  constructor() {}

  /**
   * Determines the correct displayName corresponding to the QS status
   */
  ngOnInit() {
    if (this.data && this.options && this.options.qsStatusList && this.data.QsStatus) {
      const filterResult = filter(this.options.qsStatusList, entry => entry.id === this.data.QsStatus);
      this.qsStatus = filterResult[0].displayName;
    }
  }

  /**
   * Redefine correct displayName on input change
   */
  ngOnChanges() {
    if (this.data && this.options && this.options.qsStatusList && this.data.QsStatus) {
      const filterResult = filter(this.options.qsStatusList, entry => entry.id === this.data.QsStatus);
      this.qsStatus = filterResult[0].displayName;
    }
  }
}
