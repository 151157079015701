/**
 * Defines the filter ranges (SO, ETM, ETZ)
 */
export enum FilterBereiche {
  SO = 'SO',
  ETM = 'ETM',
  ETZ = 'ETZ',
  MG_R = 'MG-R',
  MG_W = 'MG-W',
}
