<div class="modal-header">
  <h4 *ngIf="!versteckteFelder.saveButton" class="modal-title float-left">
    {{ 'SCHLACHTDATEN.VERGLEICHSWERTE.VERGLEICHSWERTE_BEARBEITEN' | translate }}
  </h4>
  <h4 *ngIf="versteckteFelder.saveButton" class="modal-title float-left">
    {{ 'SCHLACHTDATEN.VERGLEICHSWERTE.VERGLEICHSWERTE' | translate }}
  </h4>
  <button type="button" class="close float-right" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <iq-loading-indicator *ngIf="filterLoading"></iq-loading-indicator>
  <form
    *ngIf="!filterLoading"
    name="vergleichswerteForm"
    [formGroup]="vergleichswerteForm"
    class="form-horizontal"
    (keyup)="$event.keyCode === 13 && submit()"
    role="form"
    novalidate=""
    style=""
  >
    <div
      *ngIf="!versteckteFelder.prozent"
      class="form-group row"
      [ngClass]="{
        'has-error': getControl('prozentzahl').invalid && (getControl('prozentzahl').dirty || submitted)
      }"
    >
      <label class="control-label text-left text-sm-right col-sm-4 col-12" for="prozentzahl"
        >{{ 'SCHLACHTDATEN.VERGLEICHSWERTE.VERGLEICHSGRUPPE' | translate }}:</label
      >
      <div class="col-sm-8 gesperrtes-feld" *ngIf="gesperrteFelder.prozent || gesetzteFelder.prozent">
        <div *ngIf="gesperrteFelder.prozent || !gesetzteFelder.prozent">{{ vergleichsoptionen.prozent }}%</div>
        <div *ngIf="gesetzteFelder.prozent">{{ gesetzteFelder.prozent }}</div>
      </div>
      <div class="col-sm-8 col-12" *ngIf="!gesperrteFelder.prozent && !gesetzteFelder.prozent">
        <select id="prozentzahl" formControlName="prozentzahl" class="form-control bottom-buffer" required>
          <option [value]="prozentzahl.value" *ngFor="let prozentzahl of prozentzahlen">
            {{ prozentzahl.displayName }}
          </option>
        </select>
      </div>
    </div>

    <div
      *ngIf="!versteckteFelder.schlachtbetriebid"
      class="form-group row"
      [ngClass]="{
        'has-error': getControl('schlachtbetrieb').invalid && (getControl('schlachtbetrieb').dirty || submitted)
      }"
    >
      <label class="control-label text-left text-sm-right col-sm-4 col-12" for="schlachtbetrieb"
        >{{ 'SCHLACHTDATEN.VERGLEICHSWERTE.SCHLACHTBETRIEB' | translate }}:</label
      >

      <div
        class="col-sm-8 col-12 gesperrtes-feld"
        *ngIf="gesperrteFelder.schlachtbetriebid || gesetzteFelder.schlachtbetriebName"
      >
        <div *ngIf="gesperrteFelder.schlachtbetriebid || !gesetzteFelder.schlachtbetriebName">
          {{ selectedSchlachtBetriebName }}
        </div>
        <div *ngIf="gesetzteFelder.schlachtbetriebName">{{ gesetzteFelder.schlachtbetriebName }}</div>
      </div>
      <div class="col-sm-8 col-12" *ngIf="!gesperrteFelder.schlachtbetriebid && !gesetzteFelder.schlachtbetriebName">
        <select id="schlachtbetrieb" formControlName="schlachtbetrieb" class="form-control bottom-buffer" required>
          <option [value]="schlachtbetrieb.id" *ngFor="let schlachtbetrieb of schlachtbetriebe">{{
            schlachtbetrieb.displayName
          }}</option>
        </select>
      </div>
      <div
        *ngIf="
          !gesperrteFelder.schlachtbetriebid &&
          !gesetzteFelder.schlachtbetriebName &&
          !hideVergleichSchlachtbetriebHinweis
        "
        class="col-sm-8 offset-sm-4 col-12 text-muted mt-1"
      >
        {{ 'SCHLACHTDATEN.VERGLEICHSWERTE.SCHLACHTBETRIEB_MEISTE_DATENSAETZE' | translate }}
      </div>
    </div>

    <div *ngIf="!versteckteFelder.schlachttag" class="form-group row">
      <label class="control-label text-left text-sm-right col-sm-4 col-12"
        >{{ 'SCHLACHTDATEN.VERGLEICHSWERTE.SCHLACHTTAG' | translate }}:</label
      >
      <div class="col-sm-8 col-12 gesperrtes-feld">
        <div *ngIf="gesperrteFelder.datum || !gesetzteFelder.datum">{{ gesperrteFelder.datum | date: 'dd.MM.yy' }}</div>
        <div *ngIf="gesetzteFelder.datum">{{ gesetzteFelder.datum | date: 'dd.MM.yy' }}</div>
      </div>
    </div>

    <div *ngIf="!versteckteFelder.activeMaske" class="form-group row">
      <label class="control-label text-left text-sm-right col-sm-4 col-12"
        >{{ 'SCHLACHTDATEN.VERGLEICHSWERTE.AKTIVE_MASKE' | translate }}:</label
      >
      <div *ngIf="gesetzteFelder.activeMaske" class="col-sm-8 col-12 gesperrtes-feld">
        {{ gesetzteFelder.activeMaske.MaskenName }}
      </div>
      <div *ngIf="!gesetzteFelder.activeMaske" class="col-sm-8 col-12 gesperrtes-feld">-</div>
    </div>

    <div *ngIf="!versteckteFelder.geschlecht && !gesetzteFelder.geschlechterTranslationKey" class="form-group row">
      <label class="control-label text-left text-sm-right col-sm-4 col-12"
        >{{ 'SCHLACHTDATEN.VERGLEICHSWERTE.GESCHLECHT' | translate }}:</label
      >
      <div class="col-sm-8 col-12 gesperrtes-feld">
        <div *ngIf="gesetzteFelder.geschlecht">{{ gesetzteFelder.geschlecht }}</div>
        <div *ngIf="!gesetzteFelder.geschlecht">{{ 'SCHLACHTDATEN.VERGLEICHSWERTE.ALLE' | translate }}</div>
      </div>
    </div>

    <div *ngIf="!versteckteFelder.geschlecht && gesetzteFelder.geschlechterTranslationKey" class="form-group row">
      <label class="control-label text-left text-sm-right col-sm-4 col-12"
        >{{ 'SCHLACHTDATEN.VERGLEICHSWERTE.GESCHLECHT' | translate }}:</label
      >
      <div class="col-sm-8 gesperrtes-feld">{{ gesetzteFelder.geschlechterTranslationKey | translate }}</div>
    </div>

    <div *ngIf="!versteckteFelder.zeitraum && gesetzteFelder.zeitraum" class="form-group row">
      <label class="control-label text-left text-sm-right col-sm-4 col-12"
        >{{ 'SCHLACHTDATEN.VERGLEICHSWERTE.ZEITRAUM' | translate }}:</label
      >
      <div class="col-sm-8 col-12 gesperrtes-feld">
        <span *ngIf="gesetzteFelder.zeitraum.from">{{ gesetzteFelder.zeitraum.from | date: FORMAT.DATE }}</span>
        <span *ngIf="!gesetzteFelder.zeitraum.from">{{ 'SCHLACHTDATEN.VERGLEICHSWERTE.OFFEN' | translate }}</span>
        <span> - </span>
        <span *ngIf="gesetzteFelder.zeitraum.to">{{ gesetzteFelder.zeitraum.to | date: FORMAT.DATE }}</span>
        <span *ngIf="!gesetzteFelder.zeitraum.to">{{ 'SCHLACHTDATEN.VERGLEICHSWERTE.OFFEN' | translate }}</span>
      </div>
    </div>

    <div *ngIf="gesetzteFelder.mfaKlassifizierungTranslationKey" class="form-group row">
      <label class="control-label text-left text-sm-right col-sm-4 col-12"
        >{{ 'SCHLACHTDATEN.MFA_CLASSIFICATION.CLASSIFICATION' | translate }}:</label
      >
      <div class="col-sm-8 col-12 gesperrtes-feld">
        <span>{{ gesetzteFelder.mfaKlassifizierungTranslationKey | translate }}</span>
      </div>
    </div>

    <div
      *ngIf="!versteckteFelder.vergleichswerteAusblenden"
      class="form-group row"
      [ngClass]="{
        'has-error':
          getControl('vergleichswerteAusblenden').invalid &&
          (getControl('vergleichswerteAusblenden').dirty || submitted)
      }"
    >
      <label class="control-label text-left text-sm-right col-sm-4 col-12" for="vergleichswerteAusblenden">
        {{ 'SCHLACHTDATEN.VERGLEICHSWERTE.VERGLEICHSWERTE_AUSBLENDEN' | translate }}:
      </label>
      <div class="col-sm-8 col-12 mt-3">
        <div class="float-right float-sm-none pretty p-svg">
          <input
            id="vergleichswerteAusblenden"
            formControlName="vergleichswerteAusblenden"
            class="form-control"
            type="checkbox"
          />
          <div class="state p-primary">
            <iq-svg-check></iq-svg-check>
            <label></label>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer" *ngIf="!filterLoading">
  <div class="ml-auto">
    <button type="button" class="btn btn-secondary" *ngIf="versteckteFelder.saveButton" (click)="cancel()">
      {{ 'ALLGEMEIN.SCHLIESSEN' | translate }}
    </button>
    <button type="button" class="btn btn-secondary" *ngIf="!versteckteFelder.saveButton" (click)="cancel()">
      {{ 'ALLGEMEIN.ABBRECHEN' | translate }}
    </button>
    <button type="submit" class="btn btn-primary" *ngIf="!versteckteFelder.saveButton" (click)="submit()" autofocus>
      {{ 'ALLGEMEIN.UEBERNEHMEN' | translate }}
    </button>
  </div>
</div>
