import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ServerErrorModalComponent } from './server-error-modal/server-error-modal.component';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private modalService: BsModalService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err?.status === 0) {
          return of();
        }
        return throwError(() => err);
      }),
      tap({
        error: err => {
          if (err?.status === 500) {
            if (req.url.indexOf('portaldaten/widgets') === -1) {
              this.modalService.show(ServerErrorModalComponent);
            }
          }
        }
      })
    );
  }
}
