import { isToennies } from '@core/is-toennies';
import { portalThemeDefault } from '@core/theme/themes/portal-theme-default';
import { portalThemeHighContrast } from '@core/theme/themes/portal-theme-high-contrast';
import { portalThemeToennies } from '@core/theme/themes/portal-theme-toennies';
import { PortalThemeColors } from '@core/theme/theme.interfaces';
import { Theme } from '@iq-angular-libs/portal';

/** The themes which should be available for the IQ-Agrar Portal */
export const iqPortalThemes = [portalThemeDefault, portalThemeHighContrast];

/** The themes which should be available for the Tönnies-Agrar Portal*/
export const toenniesPortalThemes = [portalThemeToennies];

/** Factory function which provides the registered themes depending on the portal type (iq or toennies). */
export function getPortalThemes(): Theme<PortalThemeColors>[] {
  return isToennies() ? toenniesPortalThemes : iqPortalThemes;
}

/** Factory function to provide the active theme depending on the portal type (iq or toennies), which is then used initially. */
export function getActivePortalThemeName(): string {
  return getPortalThemes()[0].name;
}
