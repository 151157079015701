<div class="tooltip-wrapper"
  [tooltip]="tooltipText"
  [isDisabled]="!tooltipEnabled"
  container="body"
>
  <button
    [ladda]="generierungGestartet"
    [ngStyle]="{ 'pointer-events': exportWarning ? 'none' : '' }"
    data-spinner-color="Black"
    class="btn btn-primary"
    (click)="downloadFile()"
    [disabled]="!downloadAvailable || exportWarning || generierungGestartet"
  >
    <strong>{{ translationKey | translate }}</strong> <i class="ml-1 fa fa-download"></i>
  </button>
</div>
