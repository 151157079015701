import { Component, ChangeDetectionStrategy, Inject, PLATFORM_ID } from '@angular/core';
import { CircleSeriesComponent, formatLabel } from '@swimlane/ngx-charts';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'g[iq-circle-series]',
  templateUrl: './iq-circle-series.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animationState', [
      transition(':enter', [
        style({
          opacity: 0
        }),
        animate(150, style({ opacity: 1 }))
      ])
    ])
  ]
})
export class IqCircleSeriesComponent extends CircleSeriesComponent {
  constructor(
    @Inject(PLATFORM_ID) platformId: any
  ) {
    super(platformId);
  }

  mapDataPointToCircle(d: any, i: number) {
    const seriesName = this.data.name.toString();

    const value = d.value;
    const label = d.name;
    const customData = d.data;
    const tooltipLabel = formatLabel(label);

    let cx;
    if (this.scaleType === 'time') {
      cx = this.xScale(label);
    } else if (this.scaleType === 'linear') {
      cx = this.xScale(Number(label));
    } else {
      cx = this.xScale(label);
    }

    const cy = this.yScale(this.type === 'standard' ? value : d.d1);
    const radius = 5;
    const height = this.yScale.range()[0] - cy;
    const opacity = 1;

    let color;
    if (this.colors.scaleType === 'linear') {
      if (this.type === 'standard') {
        color = this.colors.getColor(value);
      } else {
        color = this.colors.getColor(d.d1);
      }
    } else {
      color = this.colors.getColor(seriesName);
    }

    const data = {
      series: seriesName,
      value,
      name: label,
      data: customData
    };

    return {
      classNames: [`circle-data-${i}`],
      value,
      label,
      data,
      cx,
      cy,
      radius,
      height,
      tooltipLabel,
      color,
      opacity,
      seriesName,
      gradientStops: this.getGradientStops(color),
      min: d.min,
      max: d.max
    };
  }
}
