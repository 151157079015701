<div class="iq-antibiotika-tacho" *ngIf="tachoData">
  <svg viewBox="40 -10 340 170">
    <g>
      <path #arc1 class="arc" id="arc1" d="" />
      <path #arc2 class="arc" id="arc2" d="" />
      <path #arc3 class="arc" id="arc3" d="" />

      <g [attr.transform]="'rotate(' + deg + ' 200 150)'">
        <circle class="needle-center" cx="195" cy="150" r="5"></circle>
        <path class="needle" id="needle" d="M 195 145 L 80 150 L 195 155"></path>
      </g>

      <text x="55" y="150" fill="black">0</text>
      <text x="180" y="5" fill="black">{{ 'ANTIBIOTIKA.TACHO.MEDIAN' | translate }}</text>
      <text x="190" y="20" fill="black">{{ tachoData.ErsteGrenze | number: '1.2-2' }}</text>
      <text x="280" y="40" fill="black">{{ 'ANTIBIOTIKA.TACHO.3_QUARTIL' | translate }}</text>
      <text x="295" y="60" fill="black">{{ tachoData.ZweiteGrenze | number: '1.2-2' }}</text>
      <text x="330" y="150" fill="black">> {{ tachoData.DritteGrenze | number: '1.2-2' }}</text>
    </g>
  </svg>
</div>
