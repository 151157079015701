<form
  id="formLogin"
  class="form needs-validation"
  novalidate
  [formGroup]="loginForm"
  [class.was-validated]="!loginForm.valid"
>
  <div class="form-group">
    <input
      id="usernameInput"
      name="usernameInput"
      class="form-control iq-login"
      [class.is-invalid]="loginForm.controls['userName'].errors"
      type="text"
      placeholder="{{ 'WELCOME.BENUTZERNAME' | translate }}"
      required
      autofocus
      formControlName="userName"
    />
    <div class="invalid-feedback" *ngIf="submitted && loginForm.controls['userName'].errors">
      <span *ngIf="loginForm.controls['userName'].errors.required">
        {{ 'FEHLER.WERT_WIRD_BENOETIGT' | translate }}
      </span>
    </div>
  </div>
  <div class="form-group">
    <input
      id="passwordInput"
      name="passwordInput"
      class="form-control iq-login"
      [class.is-invalid]="loginForm.controls['userName'].errors"
      type="password"
      placeholder="{{ 'WELCOME.PASSWORT' | translate }}"
      required
      formControlName="password"
    />
    <div class="invalid-feedback" *ngIf="submitted && loginForm.controls['password'].errors">
      <span *ngIf="loginForm.controls['password'].errors.required">
        {{ 'FEHLER.WERT_WIRD_BENOETIGT' | translate }}
      </span>
    </div>
  </div>
  <div [class.mt-4]="!loginFailed && !remainingTimeBlocked" [class.mt-3]="loginFailed || remainingTimeBlocked > 0">
    <div>
      <p id="helpBlockLogin" class="text-danger" *ngIf="loginFailed">
        {{ 'FEHLER.ANMELDUNG_FEHLGESCHLAGEN' | translate }}
      </p>
      <p id="timerLogin" class="text-danger" *ngIf="remainingTimeBlocked > 0">
        {{ 'WELCOME.NACHRICHT_IP_GESPERRT' | translate }} {{ remainingTimeBlocked | timer }}
      </p>
    </div>
    <button
      type="submit"
      class="iq-login-submit"
      data-style="slide-left"
      (click)="emitLogin($event)"
      [disabled]="remainingTimeBlocked > 0"
      [ladda]="loading"
    >
      {{ 'WELCOME.ANMELDEN' | translate }}
    </button>
    <div class="text-center mt-4">
      <a href id="showPasswordForgottonViewLink" (click)="showPasswordForgottenView($event)">
        {{ 'WELCOME.PASSWORT_VERGESSEN.PASSWORT_VERGESSEN' | translate }}
      </a>
    </div>
  </div>
</form>
