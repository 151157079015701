<button
  class="btn btn-table-cell btn-sm"
  [class]="btnClass"
  (click)="onButtonClick()"
  tooltip="{{ btnTooltip | translate }}"
  placement="bottom"
  container="body"
>
  <i class="p-1 fa" [ngClass]="btnIcon"></i>
</button>
