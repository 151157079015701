<div class="modal-header">
  <h4 class="modal-title">
    <span class="float-left">{{ 'SCHLACHTDATEN.BASISVERGLEICH.DIAGRAMMWERT_AUSWAEHLEN' | translate }}</span>
  </h4>
  <button type="button" class="close float-right" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form
    [formGroup]="form"
    name="diagrammWerteWaehlenForm"
    class="ng-pristine ng-valid form-horizontal"
    (keyup.enter)="ok()"
    role="form"
    novalidate
  >
    <div class="form-group row">
      <label class="control-label col-sm-6" for="diagrammWerte">{{
        'SCHLACHTDATEN.BASISVERGLEICH.DIAGRAMMWERTE' | translate
      }}</label>
      <div class="col-sm-5">
        <iq-multiselect-button
          id="diagrammWerte"
          [items]="diagrammValues"
          [selectedItems]="selectedDiagrammValues"
          [settings]="filterSettings"
          (multiselectChanged)="onMultiselectChanged($event)"
        >
        </iq-multiselect-button>
        <div class="help-block m-b-none">
          {{ 'SCHLACHTDATEN.BASISVERGLEICH.HINWEIS_DIAGRAMMENEINSTELLUNGEN' | translate }}
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="ml-auto">
    <button type="button" class="btn btn-secondary" (click)="cancel()">{{ 'ALLGEMEIN.ABBRECHEN' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="ok()">{{ 'ALLGEMEIN.UEBERNEHMEN' | translate }}</button>
  </div>
</div>
