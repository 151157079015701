import { Component, Input, OnInit } from '@angular/core';
import { CellComponent } from '@iq-angular-libs/portal';
import { find } from 'lodash-es';
import {
  LieferberechtigungList,
  QsUebersichtData
} from '../../../qualitaetssicherung/auditing/share/qualitaetssicherung.interfaces';

@Component({
  selector: 'iq-lieferberechtigung',
  templateUrl: './lieferberechtigung.component.html'
})
export class LieferberechtigungComponent implements OnInit, CellComponent {
  /**
   * The table row data for the QS overview table
   */
  @Input()
  data: QsUebersichtData;

  /**
   * The options of the component, includes the delivery authorization list
   */
  @Input()
  options?: {
    lieferberechtigungList: LieferberechtigungList[];
  };

  /**
   * The display text for the delivery authorization
   */
  lieferberechtigung: string;

  constructor() {}

  /**
   * Determines the correct displayName which should be displayed for the delivery authorization
   */
  ngOnInit() {
    if (this.data && this.options && this.options.lieferberechtigungList) {
      const item: LieferberechtigungList = find(
        this.options.lieferberechtigungList,
        (entry: LieferberechtigungList) => entry.id === this.data.Lieferberechtigung
      );

      this.lieferberechtigung = item.displayName;
    }
  }
}
