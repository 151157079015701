import { ToastrService } from 'ngx-toastr';
import { LOCALSTORAGE } from '@core/local-storage/local-storage.constants';
import { LocalStorageService } from '@mattlewis92/angular-2-local-storage';
import { finalize, switchMap, take } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ValidationService } from '../../validation/validation.service';

@Component({
  selector: 'iq-email-validation',
  template: ''
})
export class EmailValidationComponent implements OnInit {
  /**
   * The e-mail validation token.
   */
  token: string;

  /**
   * Constructor
   * @param route {@link ActivatedRoute}
   * @param router {@link Router}
   * @param translate {@link TranslateService}
   * @param toastr {@link ToastrService}
   * @param localStorageService {@link LocalStorageService}
   * @param validationService {@link ValidationService}
   */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrService,
    private localStorageService: LocalStorageService,
    private validationService: ValidationService
  ) {}

  /**
   * Gets the token from the URL when initializing the component and sends it to the backend.
   */
  ngOnInit() {
    this.getParams().subscribe(token => {
      this.token = token;
      if (token) {
        this.submitActivationToken();
      } else {
        const message = 'EMAIL_VALIDIEREN.TOASTS.FEHLER_EMAIL_VALIDIERUNG';
        const title = 'ALLGEMEIN.TOASTS.FEHLER';

        this.router.navigate(['']);
        this.translate
          .get([message, title])
          .pipe(take(1))
          .subscribe(translations => {
            this.toastr.error(translations[message], translations[title]);
          });
      }
    });
  }

  /**
   * Transmits the activation token to the backend to validate the user's email.
   */
  submitActivationToken() {
    const tokenData = {
      Token: this.token
    };
    this.validationService
      .postEmailValidationToken(tokenData)
      .pipe(
        finalize(() => {
          this.router.navigate(['/portal/startseite']);
        })
      )
      .subscribe({
        next: () => {
          const message = 'EMAIL_VALIDIEREN.TOASTS.EMAIL_ERFOLGREICH_VALIDIERT';
          const title = 'EMAIL_VALIDIEREN.TOASTS.EMAIL_ERFOLGREICH_VALIDIERT_TITEL';

          this.localStorageService.remove(LOCALSTORAGE.MUST_VALIDATE_EMAIL);
          this.localStorageService.remove(LOCALSTORAGE.MODAL.EMAIL_VALIDIEREN);

          this.translate
            .get([message, title])
            .pipe(take(1))
            .subscribe(translations => {
              this.toastr.success(translations[message], translations[title]);
            });
        },
        error: (err: HttpErrorResponse) => {
          const title = 'ALLGEMEIN.TOASTS.FEHLER';
          let message = '';
          if (err && err.headers && err.headers.get('ErrorCode') === '0016') {
            message = 'EXCEPTIONS.0016';
            this.translate
              .get([message, title])
              .pipe(take(1))
              .subscribe(translations => {
                this.toastr.error(translations[message], translations[title]);
              });
          } else {
            message = 'EMAIL_VALIDIEREN.TOASTS.FEHLER_EMAIL_VALIDIERUNG';
            this.translate
              .get([message, title])
              .pipe(take(1))
              .subscribe(translations => {
                this.toastr.error(translations[message], translations[title]);
              });
          }
        }
      });
  }

  /**
   * Gets the routing parameters
   */
  private getParams(): Observable<string> {
    return this.route.paramMap.pipe(
      take(1),
      switchMap((params: ParamMap) => of(params.get('token')))
    );
  }
}
