import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { merge } from 'lodash-es';
import { DataService as IDataService } from './data.service.interface';

@Injectable()
export class DataService implements IDataService {
  /**
   * The header parameter which determine that nothing should be cached
   */
  private noCacheParameters = {
    headers: {
      'Cache-Control': 'no-cache, no-store',
      Expires: '-1',
      Pragma: 'no-cache'
    }
  };

  /**
   * The current backend version
   */
  private backendVersion: string | null;

  private newBackendVersion$: Subject<void> = new Subject<void>();

  constructor(private http: HttpClient) {
    this.backendVersion = null;
  }

  /**
   * Performs a GET-Request without additional parameters
   * @param url the requested endpoint url
   */
  getData<T>(url: string): Observable<T> {
    return this.getDataWithParameters<T>(url, {});
  }

  /**
   * Performs a GET-Request with additional query parameters.
   * @param url the requested endpoint url
   * @param parameter specific parameter in form of an object which should be used for the request
   */
  getDataWithParameters<T>(url: string, parameter: any): Observable<T> {
    const httpOptions = merge({}, this.noCacheParameters, parameter, { observe: 'response' });
    return this.http.get<T>(url, httpOptions).pipe(
      tap((response: HttpResponse<T>) => {
        this.compareBackendVersion<T>(response);
      }),
      map((response: HttpResponse<T>) => response.body)
    );
  }

  /**
   * Performs a POST-Request with empty body
   * @param url the endpoint url
   */
  postData<T>(url: string): Observable<T> {
    return this.postDataWithParameters<T>(url, null, {});
  }

  /**
   * Performs a POST-Request with body data
   * @param url the endpoint url
   * @param data the body data which should be send to the backend
   * @param options the HttpOptions for the request (header, params..)
   */
  postDataWithParameters<T>(url: string, data: any, options: any = {}): Observable<T> {
    const httpOptions = merge({}, this.noCacheParameters, options, { observe: 'response' });
    return this.http.post<T>(url, data, httpOptions).pipe(
      tap((response: HttpResponse<T>) => {
        this.compareBackendVersion<T>(response);
      }),
      map((response: HttpResponse<T>) => response.body)
    );
  }

  /**
   * Performs a PUT-Request with empty body
   * @param url the endpoint url
   */
  putData<T>(url: string): Observable<T> {
    return this.putDataWithParameters<T>(url, null, {});
  }

  /**
   * Performs a PUT-Request with body data
   * @param url the endpoint url
   * @param data the body data which should be send to the backend
   * @param options the HttpOptions for the request (header, params..)
   */
  putDataWithParameters<T>(url: string, data: any, options: any = {}): Observable<T> {
    const httpOptions = merge({}, this.noCacheParameters, options, { observe: 'response' });
    return this.http.put<T>(url, data, httpOptions).pipe(
      tap((response: HttpResponse<T>) => {
        this.compareBackendVersion<T>(response);
      }),
      map((response: HttpResponse<T>) => response.body)
    );
  }

  /**
   * Performs a DELETE-Request without additional data
   * @param url the endpoint url
   */
  deleteData<T>(url: string): Observable<T> {
    return this.deleteDataWithParameters<T>(url, {});
  }

  /**
   * Performs a DELETE-Request with additional data
   * @param url the endpoint url
   * @param options the http-options for the request
   */
  deleteDataWithParameters<T>(url: string, options: any): Observable<T> {
    const httpOptions = merge(options, { observe: 'response' });
    return this.http.delete<T>(url, httpOptions).pipe(
      tap((response: HttpResponse<T>) => {
        this.compareBackendVersion<T>(response);
      }),
      map((response: HttpResponse<T>) => response.body)
    );
  }

  /**
   * Performs a DELETE-request with bdoy data and additional http-options
   * @param url the endpoint url
   * @param data the body data
   * @param options the http-options
   */
  deleteDataWithBody<T>(url: string, data: any, options: any = {}): Observable<T> {
    const httpOptions = merge(options, { observe: 'response' }, { body: data });
    return this.http.delete<T>(url, httpOptions).pipe(map((response: HttpResponse<T>) => response.body));
  }

  /**
   * Return the `newBackendVersion`-Subject as observable
   */
  isNewBackendVersion(): Observable<void> {
    return this.newBackendVersion$.asObservable();
  }

  /**
   * Compares the backend version contained in the response with the stored backend version.
   * If the versions does not match, the `newBackendVersion` event will be emitted.
   * @param response a HttpResponse
   */
  private compareBackendVersion<T>(response: HttpResponse<T>): void {
    const responseBackendVersion = response.headers.get('Version');

    if (!this.backendVersion) {
      this.backendVersion = responseBackendVersion;
    } else if (this.backendVersion !== responseBackendVersion) {
      this.newBackendVersion$.next();
      this.newBackendVersion$.complete();
    }
  }
}
