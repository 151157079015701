import { CurrentUser } from '@core/user/user.interfaces';
import { UserService } from '@core/user/user.service';
import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { UserPropertiesBereiche } from '@core/user/user.enums';

@Component({
  selector: 'iq-focus-betriebsstaetten-modal',
  templateUrl: './focus-betriebsstaetten-modal.component.html',
  styleUrls: ['./focus-betriebsstaetten-modal.component.scss']
})
export class FocusBetriebsstaettenModalComponent implements OnInit {
  @Input()
  currentUser: CurrentUser;

  /**
   * Object, which describes the section and attribute of the user properties
   */
  @Input()
  propertiesHint: { bereich: UserPropertiesBereiche; field: string };

  /**
   * The attribute of the user properties
   */
  @Input()
  rubrikTitel;

  /**
   * The commercial units registrationsnumbers to be selected
   */
  @Input()
  registrierungsnummern;

  form: FormGroup;

  /**
   * Stream that informs the subscriber if the dialog was closed.
   * Returns true when modal was expected, else false.
   */
  onClose$: Subject<boolean>;

  /**
   * This property indicates whether the checkbox for hiding the hint message in the modal dialog is activated
   */
  hideMessage = false;

  constructor(private userService: UserService, private bsModalRef: BsModalRef) {}

  ngOnInit() {
    this.onClose$ = new Subject<boolean>();
    this.form = new FormGroup({
      RegnummernFokussierenHinweisAnzeigen: new FormControl(
        !this.currentUser.userProperties.allgemein.RegnummernFokussierenHinweisAnzeigen
      )
    });
    this.form.get('RegnummernFokussierenHinweisAnzeigen').valueChanges.subscribe(value => {
      this.hideMessage = value;
    });
  }

  /**
   * Closes the modal dialog
   */
  cancel() {
    this.onClose$.next(false);
    this.onClose$.complete();
    this.bsModalRef.hide();
  }

  /**
   * Calls the passed function and saves the
   * User settings, if the checkbox in the modal dialog has been activated.
   */
  ok() {
    if (this.hideMessage) {
      this.currentUser.userProperties[this.propertiesHint.bereich][this.propertiesHint.field] = false;
      this.userService.postUserproperties(this.propertiesHint.bereich, this.currentUser.userProperties).subscribe();
    }
    this.onClose$.next(true);
    this.onClose$.complete();
    this.bsModalRef.hide();
  }
}
