import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { COMMON } from '@core/constants';
import { DataService } from '@core/data/data.service';

@Injectable()
export class BlockedIpService {
  private serviceBase: string;

  /**
   * Constructor.
   * @param dataService
   */
  constructor(private dataService: DataService) {
    this.serviceBase = COMMON.API_ROOT + '/ip';
  }

  /**
   * Queries the outstanding time a user is locked for the login.
   * If the user is not locked, 0 is returned - otherwise the number of seconds.
   */
  getRemainingTimeForLogin(): Observable<number> {
    return this.dataService.getData<number>(this.serviceBase + '/login/time');
  }

  /**
   * Queries the outstanding time a user is locked for the password forgotten function.
   * If the user is not locked, 0 is returned - otherwise the number of seconds.
   */
  getRemainingTimeForPasswordForgotten(): Observable<number> {
    return this.dataService.getData<number>(this.serviceBase + '/password/time');
  }
}
