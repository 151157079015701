<div *ngIf="dataLeft || dataRight" class="row">
  <div class="col-12">
    <div *ngIf="dataLeft" class="float-left">
      <ng-container *ngFor="let entry of dataLeft; let entryIndex = index">
        <div class="legend-element mr-1">
          <svg height="10" width="10">
            <defs>
              <linearGradient [id]="entry?.id + '-' + entryIndex" x1="0%" y1="0%" x2="100%" y2="0%">
                <ng-container
                  *ngFor="let colors of getGradientElements(entry?.color); let index = index; let count = count"
                >
                  <stop
                    [attr.offset]="(100 / count) * index + '%'"
                    [style.stop-color]="colors"
                    [style.stop-opacity]="1"
                  />
                  <stop
                    [attr.offset]="(100 / count) * (index + 1) + '%'"
                    [style.stop-color]="colors"
                    [style.stop-opacity]="1"
                  />
                </ng-container>
              </linearGradient>
            </defs>
            <rect width="10" height="10" [attr.fill]="'url(' + router?.url + '#' + entry?.id + '-' + entryIndex + ')'" />
          </svg>
        </div>
        <div class="text mr-2">{{ entry?.name }}</div>
      </ng-container>
    </div>
    <div *ngIf="dataRight" class="float-right">
      <ng-container *ngFor="let entry of dataRight; let entryIndex = index">
        <div class="legend-element mr-1">
          <svg height="10" width="10">
            <defs>
              <linearGradient [id]="entry?.id + '-' + entryIndex" x1="0%" y1="0%" x2="100%" y2="0%">
                <ng-container
                  *ngFor="let colors of getGradientElements(entry?.color); let index = index; let count = count"
                >
                  <stop
                    [attr.offset]="(100 / count) * index + '%'"
                    [style.stop-color]="colors"
                    [style.stop-opacity]="1"
                  />
                  <stop
                    [attr.offset]="(100 / count) * (index + 1) + '%'"
                    [style.stop-color]="colors"
                    [style.stop-opacity]="1"
                  />
                </ng-container>
              </linearGradient>
            </defs>
            <circle cx="5" cy="5" r="5" [attr.fill]="'url(' + router.url + '#' + entry?.id + '-' + entryIndex + ')'" />
          </svg>
        </div>
        <div class="text mr-2">{{ entry?.name }}</div>
      </ng-container>
    </div>
  </div>
</div>
