<div #basispreisContainerDiv class="chart-container">
  <iq-loading-indicator *ngIf="dataLoading"></iq-loading-indicator>
  <div *ngIf="errMessage && !dataLoading">
    <h4>{{ errMessage | translate }}</h4>
  </div>
  <iq-basispreis-diagramm
    *ngIf="!dataLoading && lineChartOptions.multi.length > 0"
    [chartId]="lineChartOptions.chartId"
    [scheme]="lineChartOptions.colorScheme"
    [results]="lineChartOptions.multi"
    [legendItems]="legendItems"
    [gradient]="lineChartOptions.gradient"
    [xAxis]="lineChartOptions.showXAxis"
    [xAxisTicks]="lineChartOptions.xAxisTicks"
    [yAxis]="lineChartOptions.showYAxis"
    [xAxisTickFormatting]="lineChartOptions.xAxisTickFormatting"
    [yAxisTickFormatting]="lineChartOptions.yAxisTickFormatting"
    [legend]="lineChartOptions.showLegend"
    [legendTitle]="lineChartOptions.legendTitle"
    [showXAxisLabel]="lineChartOptions.showXAxisLabel"
    [showYAxisLabel]="lineChartOptions.showYAxisLabel"
    [xAxisLabel]="lineChartOptions.xAxisLabel"
    [yAxisLabel]="lineChartOptions.yAxisLabel"
    [yScaleMin]="lineChartOptions.yScaleMin"
    [yScaleMax]="lineChartOptions.yScaleMax"
    [autoScale]="lineChartOptions.autoScale"
  >
    <ng-template #seriesTooltipTemplate let-model="model">
      <table class="tooltip-table">
        <tbody>
          <tr *ngFor="let series of model; let index = index">
            <td><div class="basispreis-circle" [style.background]="series.color"></div></td>
            <td>
              <strong class="ml-1" *ngIf="index === 2"
                >{{ series.data.from | date: dateFormat }} - {{ series.data.to | date: dateFormat }}:</strong
              >
              <span class="ml-1" *ngIf="index !== 2"
                >{{ series.data.from | date: dateFormat }} - {{ series.data.to | date: dateFormat }}:</span
              >
            </td>
            <td>
              <span class="ml-1">{{ series.value | currency: 'EUR':'symbol' }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-template>
    <ng-template #tooltipTemplate let-model="model">
      <table class="tooltip-table">
        <tbody>
          <tr>
            <td>
              <span>{{ model.data.from | date: dateFormat }} - {{ model.data.to | date: dateFormat }}:</span>
            </td>
            <td>
              <span class="ml-1">{{ model.value | currency: 'EUR':'symbol' }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  </iq-basispreis-diagramm>
  <div *ngIf="lineChartOptions.multi.length === 0 && !dataLoading">
    <p class="text-center mt-2 mb-3">{{ 'KEINE_DATENSAETZE_VORHANDEN' | translate }}</p>
  </div>
</div>
<!-- edit widget -->
<div class="widget-bearbeiten-container disabled"></div>
