import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { indexOf } from 'lodash-es';
import {
  QsZusammenfassungData,
  QsZusammenfassungEntry,
  BetriebsstaettenSalmonellenKategorie,
  BetriebsstaetteSalmonellenKategorie
} from 'src/app/startseite/share/widgets.interfaces';

/**
 * The SelectionChangedEvent. Expects the selected comerical units for the constructor.
 */
export class SelectionChangedEvent {
  selectedBetriebsstaette: QsZusammenfassungEntry | BetriebsstaetteSalmonellenKategorie;

  constructor(selectedBetriebsstaette: QsZusammenfassungEntry | BetriebsstaetteSalmonellenKategorie) {
    this.selectedBetriebsstaette = selectedBetriebsstaette;
  }
}

/**
 * The data for the select input. Accepts the {@link QsZusammenfassungData}
 * and SalmoTachoWidget data.
 */
export interface SingleSelectData {
  betriebsstaetten: Array<QsZusammenfassungEntry | BetriebsstaetteSalmonellenKategorie>;
  selectedBetriebsstaette: QsZusammenfassungEntry | BetriebsstaetteSalmonellenKategorie;
  selectedBetriebsstaetteIndex: number;
}

@Component({
  selector: 'iq-single-select-navigation',
  templateUrl: './single-select-navigation.component.html',
  styleUrls: ['./single-select-navigation.component.scss']
})
export class SingleSelectNavigationComponent implements OnInit {
  /**
   * The data for the select input.
   */
  @Input()
  selectData: SingleSelectData;

  /**
   * The output event for the changes of the selected comerical units
   */
  @Output()
  selectionChanged: EventEmitter<SelectionChangedEvent>;

  constructor() {
    this.selectionChanged = new EventEmitter<SelectionChangedEvent>();
  }

  /**
   * Sets the selected comerical units and the index, if not already set.
   */
  ngOnInit() {
    if (this.selectData && !this.selectData.selectedBetriebsstaette) {
      this.selectData.selectedBetriebsstaette = this.selectData.betriebsstaetten[0];
      this.selectData.selectedBetriebsstaetteIndex = 0;
    }
  }

  /**
   * Loads the next comerical unit starting from the current comercial unit.
   */
  nextBetriebsstaette() {
    this.selectBetriebsstaette(this.selectData.selectedBetriebsstaetteIndex + 1);
  }

  /**
   * Loads the previous comerical unit starting from the current comercial unit.
   */
  prevBetriebsstaette() {
    this.selectBetriebsstaette(this.selectData.selectedBetriebsstaetteIndex - 1);
  }

  /**
   * Will be called when a new comercial unit is selected.
   * @param event the ngModelChanged event (the selected comercial units)
   */
  onSelectionChanged(event) {
    const selectedBetriebsstaetteIndex = indexOf(this.selectData.betriebsstaetten, event);
    if (selectedBetriebsstaetteIndex) {
      this.selectData.selectedBetriebsstaetteIndex = selectedBetriebsstaetteIndex;
      this.emitSelectionChanged();
    }
  }

  /**
   * Selects a new comercial unit using the passed index.
   * @param index the index of the comercial unit which should be selected
   */
  private selectBetriebsstaette(index: number) {
    this.selectData.selectedBetriebsstaette = this.selectData.betriebsstaetten[index];
    this.selectData.selectedBetriebsstaetteIndex = index;
    this.emitSelectionChanged();
  }

  /**
   * Emits an event that informs that the selected comercial units have changed.
   */
  private emitSelectionChanged() {
    const selectionChangedEvent = new SelectionChangedEvent(this.selectData.selectedBetriebsstaette);
    this.selectionChanged.emit(selectionChangedEvent);
  }
}
