import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

/**
 * The fields which should be hidden inside the modal.
 */
export interface VersteckteFelder {
  minTiere?: boolean;
  minWuerfe?: boolean;
  minNachkommen?: boolean;
}

@Component({
  selector: 'iq-mindestanzahl-modal',
  templateUrl: './mindestanzahl-modal.component.html',
  styleUrls: ['./mindestanzahl-modal.component.scss']
})
export class MindestanzahlModalComponent implements OnInit, AfterViewInit, OnDestroy {
  /**
   * Minimum number of animals
   */
  @Input()
  minTiere: number;

  /**
   * Minimum number of litters
   */
  @Input()
  minWuerfe: number;

  /**
   * Minimum number of descendants
   */
  @Input()
  minNachkommen: number;

  /**
   * The in the form hidden fields
   */
  @Input()
  versteckteFelder: VersteckteFelder;

  /**
   * Element ref of the input of the minimum number of animals.
   */
  @ViewChild('minTiere')
  minTiereElementRef: ElementRef;

  /**
   * Element ref of the input of the minimum number of litters.
   */
  @ViewChild('minWuerfe')
  minWuerfeElementRef: ElementRef;

  /**
   * Element ref of the input of the minimum number of descendants.
   */
  @ViewChild('minNachkommen')
  minNachkommenElementRef: ElementRef;

  /**
   * Form group
   */
  mindstanzahlForm: FormGroup;

  /**
   * Subject which is used to send events to the modal caller.
   */
  onClose$: Subject<{ minTiere: number; minWuerfe: number; minNachkommen: number } | null>;

  /**
   * Indicates if the formular was tried to submit.
   */
  submitted: boolean;

  /**
   * Constructor.
   * @param bsModalRef {@link BsModalRef}
   */
  constructor(private bsModalRef: BsModalRef) {
    this.onClose$ = new Subject<{ minTiere: number; minWuerfe: number; minNachkommen: number } | null>();
  }

  /**
   * Sets the available form elements.
   */
  ngOnInit(): void {
    this.submitted = false;

    this.mindstanzahlForm = new FormGroup({
      minTiere: new FormControl(
        this.minTiere,
        !this.versteckteFelder.minTiere ? [Validators.required, Validators.min(1)] : [Validators.min(1)]
      ),
      minWuerfe: new FormControl(
        this.minWuerfe,
        !this.versteckteFelder.minWuerfe ? [Validators.required, Validators.min(1)] : [Validators.min(1)]
      ),
      minNachkommen: new FormControl(
        this.minNachkommen,
        !this.versteckteFelder.minNachkommen ? [Validators.required, Validators.min(1)] : [Validators.min(1)]
      )
    });
  }

  /**
   * Focuses the first non hidden input field.
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.versteckteFelder) {
        if (!this.versteckteFelder.minTiere) {
          this.minTiereElementRef.nativeElement.select();
        } else if (!this.versteckteFelder.minWuerfe) {
          this.minWuerfeElementRef.nativeElement.select();
        } else if (!this.versteckteFelder.minNachkommen) {
          this.minNachkommenElementRef.nativeElement.select();
        }
      }
    });
  }

  /**
   * Completes the onClose$ stream
   */
  ngOnDestroy(): void {
    this.onClose$.complete();
  }

  /**
   * Submits the values and closes the modal if
   * the form is valid.
   */
  submit(): void {
    this.submitted = true;
    if (this.mindstanzahlForm.valid) {
      this.onClose$.next(this.mindstanzahlForm.value);
      this.bsModalRef.hide();
      this.onClose$.complete();
    }
  }

  /**
   * Cancels the filtering of the optimal range and closes the dialog.
   */
  cancel(): void {
    this.bsModalRef.hide();
    this.onClose$.complete();
  }

  /**
   * Returns the FormControl with the given name.
   * @param name the name of the form control
   */
  getControl(name: string): AbstractControl {
    return this.mindstanzahlForm.get(name);
  }
}
