import { Component, Input, OnInit } from '@angular/core';
import { next } from '@core/next-observable';
import { catchError, finalize, map } from 'rxjs/operators';
import { Widget, WidgetComponent } from '../share/startseite.interfaces';
import { BlogPost } from '../share/widgets.interfaces';
import { WidgetsService } from '../share/widgets.service';

@Component({
  selector: 'iq-toennies-blog-widget',
  templateUrl: './toennies-agrarblog-widget.component.html',
  styleUrls: ['./toennies-agrarblog-widget.component.scss'],
})
export class ToenniesAgrarBlogWidgetComponent implements WidgetComponent, OnInit {
  /**
   * The structure information of the widget.
   */
  @Input()
  widget: Widget;

  /**
   * Indicates whether posts are currently loaded.
   */
  dataLoading = false;

  /**
   * List of posts to display
   */
  posts: BlogPost[] = [];

  /**
   * Indicates if blog posts are loaded without errors
   */
  isBlogPostsLoaded = true;

  constructor(private widgetsService: WidgetsService) {}

  ngOnInit() {
    this.getBlogPosts();
  }

  /**
   * Get list of blog posts from backend
   */
  getBlogPosts(): void {
    this.dataLoading = true;
    this.widgetsService
      .getAgrarportalBlogPosts()
      .pipe(
        map((blogPosts: any[]): BlogPost[] => {
          this.isBlogPostsLoaded = true;
          const visiblePosts = blogPosts.slice(0, 2);
          return visiblePosts.map((post) => ({
            date: post.date,
            link: post.link,
            title: post.title.rendered,
            content: this.preparePostContent(post.excerpt.rendered),
          }));
        }),
        catchError(() => {
          this.isBlogPostsLoaded = false;
          return next();
        }),
        finalize(() => (this.dataLoading = false))
      )
      .subscribe((posts: BlogPost[]) => (this.posts = posts));
  }

  /** Cut content to the needed length */
  preparePostContent(content: string): string {
    const post = content.replace('<p>', '');
    return post.replace(/^(.{110}[^\s]*).*/, '$1') + ' [...]';
  }
}
