<table>
  <tbody class="text-left">
    <tr>
      <td style="width: 90px;"><strong>SG-Klasse</strong></td>
      <td>{{ bestimmeSgKlassenRangeForTooltip(model.data) }}</td>
    </tr>
    <tr>
      <td style="width: 90px;"><strong>Anteil Tiere:</strong></td>
      <td>{{ model.value | number: '1.2-2' }} {{ 'MASSEINHEITEN.PROZENT' | translate }}</td>
    </tr>
    <tr>
      <td style="width: 90px;"><strong>Anzahl Tiere:</strong></td>
      <td>{{ model.data.anzahlTiere }}</td>
    </tr>
  </tbody>
</table>
