<div class="form-group text-center">
  <h3>{{ 'WELCOME.PASSWORT_VERGESSEN.LINK_ANGEFORDERT' | translate }}</h3>
  <div class="mt-4">
    {{ 'WELCOME.PASSWORT_VERGESSEN.LINK_GESENDET_HINWEIS' | translate }}
    <a target="_blank" [attr.href]="'WELCOME.PASSWORT_VERGESSEN.SUPPORT_URL' | translate">
      {{ 'WELCOME.PASSWORT_VERGESSEN.LINK_GESENDET_HINWEIS_SUPPORT' | translate }}</a
    >.
  </div>
</div>
<div>
  <div class="text-center mt-2">
    <a href (click)="showLoginView($event)"> {{ 'WELCOME.PASSWORT_VERGESSEN.ZURUECK_ZUM_LOGIN' | translate }} </a>
  </div>
</div>
