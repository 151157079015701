import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup, ValidatorFn } from '@angular/forms';
import { isEqual, isNil, trim } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateComponentValitators } from '@share/date/date-component-valitators';
import { dateStringToDate } from '@share/helper-functions';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';
import { FORMAT } from '@core/constants';
import * as dayjs from 'dayjs';

/**
 * The possible selection blocks of a date field.
 */
enum DateInputSelectionBlocks {
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year',
  END = 'end',
}

/**
 * Class for sending a new date to the parent class via event emitter
 */
export class DateChangedEvent {
  /**
   * The new date.
   */
  date: Date | null;

  /**
   * Constructor.
   * @param newDate
   */
  constructor(newDate: Date | null) {
    this.date = newDate;
  }
}

/**
 * The component that controls the IQ-Date input including reset button and datepicker.
 */
@Component({
  selector: 'iq-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
})
export class DateComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  /**
   * The FormGroup in which the input is located.
   */
  @Input()
  inputFormGroup: FormGroup;

  /**
   * The FormControlName under which the input is registered in the FormGroup.
   */
  @Input()
  inputFormControlName: string;

  /**
   * Indicates whether a valid input is required.
   */
  @Input()
  inputRequired?: boolean;

  /**
   * Holds the minimum allowed date.
   */
  @Input()
  minDate?: Date | null;

  /**
   * Holds the maximum allowed date.
   */
  @Input()
  maxDate?: Date | null;

  /**
   * Specifies whether the Reset button should be displayed.
   */
  @Input()
  showResetButton?: boolean;

  /**
   * Specifies whether the Date Picker button should be displayed.
   */
  @Input()
  showDatePickerButton?: boolean;

  /**
   * Specifies whether the input field should get the focus.
   */
  @Input()
  focusOnInit?: boolean;

  /**
   * Defines the tab index of the field.
   */
  @Input()
  tabIndex?: boolean;

  /**
   * Indicates whether a one-time submit action has been performed to display
   * a non-valid input field. If no value is given, the field is validated directly.
   */
  @Input()
  submitted?: boolean;

  /**
   * Fires an event once she has changed the input.
   */
  @Output()
  inputChanged: EventEmitter<DateChangedEvent>;

  /**
   * Element reference to the input field of this component.
   */
  @ViewChild('dateInput', { static: true })
  dateInput: ElementRef;

  /**
   * The date which represents the selected day for the DatePicker.
   */
  datePickerDate: Date | null;

  /**
   * The options for the datepicker.
   */
  datePickerOptions: Partial<BsDatepickerConfig>;

  /**
   * The currently selected and marked block of the date.
   */
  private selectionBlock?: DateInputSelectionBlocks;

  /**
   * Indicates whether the input is continued in the previously selected block.
   * Depends on the input.
   * For example, the input of '1' in the 'day' block can be continued, but the input of a '4' cannot.
   */
  private editingSameBlock: boolean;

  /**
   * Holds the previous input value.
   */
  private previousValue: string;

  /**
   * Holds the placeholder text 'DD.MM.YY'
   */
  private placeholderText: string;

  /**
   * The datepipe to format a date.
   */
  private datePipe: DatePipe;

  /**
   * Unsubscribe stream, which fires an event when the component is destroyed.
   */
  private unsubscribe$: Subject<void>;

  /**
   * Constructor.
   */
  constructor(private localeService: BsLocaleService) {
    this.placeholderText = 'DD.MM.YY';
    this.editingSameBlock = false;

    this.datePipe = new DatePipe('de');
    this.datePickerOptions = {
      containerClass: 'theme-blue',
      selectFromOtherMonth: true,
      customTodayClass: 'today',
    };

    this.inputChanged = new EventEmitter<DateChangedEvent>();
    this.unsubscribe$ = new Subject<void>();
  }

  /**
   * Sets the placeholder text if the input field is empty.
   * Subscribes to the input of the input field.
   */
  ngOnInit() {
    this.localeService.use('de');
    const inputControl = this.inputFormGroup.controls[this.inputFormControlName];
    const inputValue = inputControl.value;

    if (isNil(inputValue) || trim(inputValue) === '') {
      this.datePickerDate = null;
      this.previousValue = this.placeholderText;
      inputControl.setValue(this.placeholderText);
    } else {
      this.datePickerDate = dateStringToDate(inputValue);
      this.previousValue = inputValue;
    }

    this.subscribeOnInputChangeEvents();
  }

  /**
   * Resets the validators when changes are made.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.setValidators();
    this.inputFormGroup.controls[this.inputFormControlName].updateValueAndValidity();
  }

  /**
   * Focuses the input, if (focusOnInit){@link DateComponent#focusOnInit} is true.
   */
  ngAfterViewInit(): void {
    if (this.focusOnInit === true) {
      setTimeout(() => {
        this.dateInput.nativeElement.focus();
      });
    }
  }

  /**
   * Unsubscribes from the change event of the input and closes all open streams.
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.inputChanged.complete();
  }

  /**
   * Resets the input field
   */
  resetInput(): void {
    if (this.showResetButton !== false) {
      if (this.inputFormGroup && this.inputFormGroup.controls[this.inputFormControlName]) {
        const dateChangedEvent = new DateChangedEvent(null);
        this.inputFormGroup.controls[this.inputFormControlName].setValue(null);
        this.inputChanged.emit(dateChangedEvent);
        this.datePickerDate = null;
      }
    }
  }

  /**
   * Puts the date selected by the datepicker into the input field.
   * @param date
   */
  onDatePickerValueChanged(date: Date) {
    if (!isEqual(date, this.datePickerDate) && !dayjs(date).isSame(this.datePickerDate, 'day')) {
      const dateString = this.datePipe.transform(date, FORMAT.DATE_INPUT);
      this.selectionBlock = undefined; // to set the full date
      this.inputFormGroup.controls[this.inputFormControlName].setValue(dateString);
      this.datePickerDate = date;

      this.updateFormAndEmitNewDate();
    }
  }

  /**
   * When focusing the element, sets the (selectionBlock){@link DateComponent#selectionBlock}
   * to 'day' and sets the corresponding selection.
   * @param event
   */
  onFocus(event: FocusEvent) {
    event.preventDefault();
    this.selectionBlock = DateInputSelectionBlocks.DAY;
    this.setSelection();
  }

  /**
   * Informs the parent component that a new date has been entered.
   * @param event
   */
  onBlur(event: FocusEvent) {
    this.updateFormAndEmitNewDate();
  }

  /**
   * Reacts to a mouse click and sets the selection based on the cursor position
   * @param event
   */
  onClick(event: MouseEvent) {
    const doc = document as any;
    const textInput = event.target as HTMLInputElement;
    const previousSelectionBlock = this.selectionBlock;
    let cursorPosition = 0;

    event.preventDefault();

    // IE Support
    if (doc.selection) {
      textInput.focus();
      const newSelection = doc.selection.createRange();
      newSelection.moveStart('character', -textInput.value.length);
      cursorPosition = newSelection.text.length;
    } else if (textInput.selectionStart || textInput.selectionStart === 0) {
      // Firefox Support
      cursorPosition = textInput.selectionStart;
    }

    if (cursorPosition < 3) {
      this.selectionBlock = DateInputSelectionBlocks.DAY;
    } else if (cursorPosition < 6) {
      this.selectionBlock = DateInputSelectionBlocks.MONTH;
    } else if (cursorPosition >= 6) {
      this.selectionBlock = DateInputSelectionBlocks.YEAR;
    }

    if (previousSelectionBlock !== this.selectionBlock) {
      this.editingSameBlock = false;
    }

    this.setSelection();
  }

  /**
   * Is called up as soon as the Enter key is pressed.
   * Causes the component to perform input validation via (onBlur){@link DateComponent#onBlur}.
   * @param event
   */
  onEnter(event: Event): void {
    this.updateFormAndEmitNewDate();
  }

  /**
   * Is called up as soon as the user presses the TAB key.
   * Selects the next block.
   * @param event
   */
  onTab(event: Event): void {
    if (this.selectionBlock === DateInputSelectionBlocks.END) {
      return;
    }

    this.selectNextBlock(event);
  }

  /**
   * Called when the user presses the SHIFT+TAB keyboard command.
   * Selects the previous block.
   * @param event
   */
  onShiftTab(event: Event): void {
    if (this.selectionBlock === DateInputSelectionBlocks.DAY) {
      return;
    }

    this.selectPreviousBlock(event);
  }

  /**
   * Called as soon as the user presses CTRL + A or CMD + A.
   * Prevents all text from being selected.
   * @param event
   */
  onMetaA(event: Event): void {
    this.selectFirstBlock(event);
  }

  /**
   * Resets the selected block to the placeholder string and jumps one block further forward.
   * @param event
   */
  onBackspace(event: Event): void {
    const inputControl = this.inputFormGroup.controls[this.inputFormControlName];
    let outputValue = '';
    event.preventDefault();

    switch (this.selectionBlock) {
    case DateInputSelectionBlocks.DAY:
      outputValue = 'DD' + this.previousValue.substring(2);
      this.selectionBlock = DateInputSelectionBlocks.END;
      break;

    case DateInputSelectionBlocks.MONTH:
      outputValue = this.previousValue.substring(0, 3) + 'MM' + this.previousValue.substring(5);
      this.selectionBlock = DateInputSelectionBlocks.DAY;
      break;

    case DateInputSelectionBlocks.YEAR:
      outputValue = this.previousValue.substring(0, 6) + 'YY';
      this.selectionBlock = DateInputSelectionBlocks.MONTH;
      break;

    case DateInputSelectionBlocks.END:
      outputValue = this.previousValue;
      this.selectionBlock = DateInputSelectionBlocks.YEAR;
      break;

    default:
      return;
    }

    this.editingSameBlock = false;
    this.previousValue = outputValue;
    inputControl.setValue(outputValue);

    this.setSelection();
  }

  /**
   * Is called as soon as the up arrow key is pressed.
   * Prevents the default behavior and sets the selected block to 'day'.
   * @param event
   */
  onArrowUp(event: Event) {
    this.selectFirstBlock(event);
  }

  /**
   * Is called as soon as the up arrow key is pressed.
   * Prevents the default behavior and sets the selected block to 'end'.
   * @param event
   */
  onArrowDown(event: Event) {
    event.preventDefault();
    this.selectionBlock = DateInputSelectionBlocks.END;
    this.editingSameBlock = false;
    this.setSelection();
  }

  /**
   * The form is re-validated and the parent component is notified.
   */
  updateFormAndEmitNewDate() {
    const input = this.inputFormGroup.controls[this.inputFormControlName];
    input.updateValueAndValidity();

    const newDate = dateStringToDate(input.value);
    const dateChangedEvent = new DateChangedEvent(newDate);
    this.inputChanged.emit(dateChangedEvent);
    this.datePickerDate = newDate;
  }

  /**
   * Sets the validators for the input field.
   */
  setValidators(): void {
    const validators: ValidatorFn[] = [];
    validators.push(DateComponentValitators.iqRealDate);

    if (this.inputRequired) {
      validators.push(DateComponentValitators.iqRequiredDate);
    }

    if (this.minDate) {
      validators.push(DateComponentValitators.iqMinDate(this.minDate));
    }

    if (this.maxDate) {
      validators.push(DateComponentValitators.iqMaxDate(this.maxDate));
    }

    this.inputFormGroup.controls[this.inputFormControlName].setValidators(validators);
  }

  /**
   * Sets the selection according to the selectionBlock property.
   */
  private setSelection(): void {
    const el = this.dateInput.nativeElement as HTMLInputElement;
    setTimeout(() => {
      switch (this.selectionBlock) {
      case DateInputSelectionBlocks.DAY:
        el.setSelectionRange(0, 2);
        break;
      case DateInputSelectionBlocks.MONTH:
        el.setSelectionRange(3, 5);
        break;
      case DateInputSelectionBlocks.YEAR:
        el.setSelectionRange(6, 8);
        break;
      case DateInputSelectionBlocks.END:
        el.setSelectionRange(8, 8);
        break;
      default:
        return;
      }
    });
  }

  /**
   * Selects the block "day".
   * @param event
   */
  private selectFirstBlock(event: Event) {
    event.preventDefault();
    this.selectionBlock = DateInputSelectionBlocks.DAY;
    this.editingSameBlock = false;
    this.setSelection();
  }

  /**
   * Sets the selection to the next block.
   * Accordingly, the editingSameBlock property is set to false
   * because the input for the current block is terminated.
   * @param event The Next Event
   */
  private selectNextBlock(event: Event): void {
    event.preventDefault();

    switch (this.selectionBlock) {
    case DateInputSelectionBlocks.DAY:
      this.selectionBlock = DateInputSelectionBlocks.MONTH;
      break;

    case DateInputSelectionBlocks.MONTH:
      this.selectionBlock = DateInputSelectionBlocks.YEAR;
      break;

    case DateInputSelectionBlocks.YEAR:
      this.selectionBlock = DateInputSelectionBlocks.END;
      break;

    default:
      return;
    }

    this.editingSameBlock = false;
    this.setSelection();
  }

  /**
   * Sets the selection to the previous block.
   * Accordingly, the editingSameBlock property is set to false because
   * the input for the current block is terminated.
   * @param event The Next Event
   */
  private selectPreviousBlock(event: Event): void {
    event.preventDefault();

    switch (this.selectionBlock) {
    case DateInputSelectionBlocks.MONTH:
      this.selectionBlock = DateInputSelectionBlocks.DAY;
      break;

    case DateInputSelectionBlocks.YEAR:
      this.selectionBlock = DateInputSelectionBlocks.MONTH;
      break;

    case DateInputSelectionBlocks.END:
      this.selectionBlock = DateInputSelectionBlocks.YEAR;
      break;

    default:
      return;
    }

    this.editingSameBlock = false;
    this.setSelection();
  }

  /**
   * Formats the current input for the 'day' block.
   * Either appends it to the previous input or replaces it.
   * Invalid entries (everything except the numbers 0-9) are ignored.
   * @param inputValue The current input. It can only be one character.
   * @param previousValue The previous input.
   */
  private formatInputDay(inputValue: string, previousValue: string): string {
    const currentInputSegment = inputValue.substring(0, 1);
    let currentInputNumber = parseInt(currentInputSegment, 10);
    let outputValue = '';

    if (isNaN(currentInputNumber)) {
      outputValue = previousValue;
    } else if (this.editingSameBlock) {
      // The input in block 'dd' is not yet completed
      let secondDigit = parseInt(previousValue.substring(1, 2), 10);
      if (isNaN(secondDigit)) {
        secondDigit = 0;
      }

      // If the previous input was > 3, the new input overwrites the old one
      if (secondDigit > 3) {
        outputValue = '0' + currentInputNumber + previousValue.substring(2);
      } else if (secondDigit === 3) {
        // A maximum input of 31 is allowed
        if (currentInputNumber > 1) {
          currentInputNumber = 1;
        }
        outputValue = secondDigit.toString() + currentInputNumber + previousValue.substring(2);
      } else if (secondDigit === 0 && currentInputNumber === 0) {
        outputValue = '01' + previousValue.substring(2);
      } else {
        // If the previous input was < 3 and > 0, the new input is appended to the old one
        outputValue = secondDigit.toString() + currentInputNumber + previousValue.substring(2);
      }

      this.selectionBlock = DateInputSelectionBlocks.MONTH;
      this.editingSameBlock = false;
    } else if (!this.editingSameBlock) {
      // A new input in block 'dd'
      // If the input > 3, the value for the day can be set directly and jump to the next block
      if (currentInputNumber > 3) {
        this.selectionBlock = DateInputSelectionBlocks.MONTH;
        this.editingSameBlock = false;
        outputValue = '0' + currentInputNumber + previousValue.substring(2);
      } else {
        // If the input is < 3, the input can be continued in block 'dd'
        this.selectionBlock = DateInputSelectionBlocks.DAY;
        this.editingSameBlock = true;
        outputValue = '0' + currentInputNumber + previousValue.substring(2);
      }
    }

    return outputValue;
  }

  /**
   * Formats the current input for the 'month' block.
   * Either appends or replaces the previous input.
   * Invalid entries (everything except the numbers 0-9) are ignored.
   * @param inputValue The current input. It can only be one character.
   * @param previousValue The previous input.
   */
  private formatInputMonth(inputValue: string, previousValue: string): string {
    const currentInputSegment = inputValue.substring(3, 4);
    let currentInputNumber = parseInt(currentInputSegment, 10);
    let outputValue = '';

    outputValue = previousValue.substring(0, 3);

    if (isNaN(currentInputNumber)) {
      outputValue = previousValue;
    } else if (this.editingSameBlock) {
      let secondDigit = parseInt(previousValue.substring(4, 5), 10);

      if (isNaN(secondDigit) || (secondDigit !== 1 && secondDigit !== 0)) {
        secondDigit = 0;
      } else if (secondDigit === 1 && currentInputNumber > 2) {
        currentInputNumber = 2;
      } else if (secondDigit === 0 && currentInputNumber === 0) {
        currentInputNumber = 1;
      }

      this.selectionBlock = DateInputSelectionBlocks.YEAR;
      this.editingSameBlock = false;
      outputValue += secondDigit.toString() + currentInputNumber + previousValue.substring(5);
    } else if (!this.editingSameBlock) {
      // new entry of a month
      // Month 01 - 12: If greater than 1, put 0 before the value.
      if (currentInputNumber > 1) {
        this.selectionBlock = DateInputSelectionBlocks.YEAR;
        this.editingSameBlock = false;
        outputValue += '0' + currentInputNumber + previousValue.substring(5);
      } else {
        this.selectionBlock = DateInputSelectionBlocks.MONTH;
        this.editingSameBlock = true;
        outputValue += '0' + currentInputNumber + previousValue.substring(5);
      }
    }

    return outputValue;
  }

  /**
   * Formats the current input for the 'year' block.
   * Either appends or replaces the previous input.
   * Invalid entries (everything except the numbers 0-9) are ignored.
   * @param inputValue The current input. It can only be one character.
   * @param previousValue The previous input.
   */
  private formatInputYear(inputValue: string, previousValue: string): string {
    const currentInputSegment = inputValue.substring(6, 7);
    const currentInputNumber = parseInt(currentInputSegment, 10);
    let outputValue = '';

    outputValue = previousValue.substring(0, 6);

    if (isNaN(currentInputNumber)) {
      outputValue = previousValue;
    } else if (this.editingSameBlock) {
      let secondDigit = parseInt(previousValue.substring(7, 8), 10);
      if (isNaN(secondDigit)) {
        secondDigit = 0;
      }
      this.selectionBlock = DateInputSelectionBlocks.END;
      this.editingSameBlock = false;
      outputValue += secondDigit.toString() + currentInputNumber;
    } else if (!this.editingSameBlock) {
      this.selectionBlock = DateInputSelectionBlocks.YEAR;
      this.editingSameBlock = true;
      outputValue += '0' + currentInputNumber;
    }

    return outputValue;
  }

  /**
   * Subscribes to changes in the input field.
   * Depending on the selected block, formats the input and returns it to the input field.
   */
  private subscribeOnInputChangeEvents(): void {
    this.inputFormGroup.controls[this.inputFormControlName].valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (inputValue) => {
        const inputControl = this.inputFormGroup.controls[this.inputFormControlName];
        let outputValue = '';

        if (inputValue === this.placeholderText) {
          this.previousValue = this.placeholderText;
          return;
        } else if (inputValue === null) {
          outputValue = this.placeholderText;
        } else if (inputValue !== this.previousValue) {
          switch (this.selectionBlock) {
          case DateInputSelectionBlocks.DAY:
            outputValue = this.formatInputDay(inputValue, this.previousValue);
            break;

          case DateInputSelectionBlocks.MONTH:
            outputValue = this.formatInputMonth(inputValue, this.previousValue);
            break;

          case DateInputSelectionBlocks.YEAR:
            outputValue = this.formatInputYear(inputValue, this.previousValue);
            break;

          case DateInputSelectionBlocks.END:
            outputValue = this.previousValue;
            break;

          default:
            this.previousValue = inputValue;
            return;
          }
        } else {
          return;
        }

        this.previousValue = outputValue;
        inputControl.setValue(outputValue);

        this.setSelection();
      },
    });
  }
}
