<div class="widget-content-container qs-maengelbeseitigung-widget">
  <div slimScroll class="scroll-space h-100" [scrollEvents]="slimScrollEvents">
    <div *ngIf="dataLoading"><iq-loading-indicator></iq-loading-indicator></div>
    <div class="h-100" *ngIf="!dataLoading">
      <div *ngIf="errMessage; else hinweisTexte" class="text-center">
        {{ errMessage | translate }}
      </div>
      <ng-template #hinweisTexte>
        <div
          *ngIf="qsMaengelbeseitigungData.OffeneMaengelImZeitraum.length === 0; else maengelbeseitigungTable"
          class="text-center"
        >
          <span *ngIf="qsMaengelbeseitigungData.AnzahlOffeneMaengelGesamt > 0">{{
            'WIDGETS.QS_MAENGELBESEITIGUNG.KEINE_MAENGEL_IM_ZEITRAUM'
              | translate: { maengelGesamt: qsMaengelbeseitigungData.AnzahlOffeneMaengelGesamt | number: '1.0-0' }
          }}</span>
          <span *ngIf="qsMaengelbeseitigungData.AnzahlOffeneMaengelGesamt === 0">{{
            'WIDGETS.QS_MAENGELBESEITIGUNG.KEINE_MAENGEL' | translate
          }}</span>
        </div>
      </ng-template>
      <ng-template #maengelbeseitigungTable>
        <div
          class="mb-2"
          [innerHTML]="'WIDGETS.QS_MAENGELBESEITIGUNG.ANZAHL_MAENGEL_HINWEIS' | translate: translateValues"
        ></div>
        <div class="table-container table-responsive mb-1">
          <table class="table table-hover">
            <tbody>
              <tr
                *ngFor="let item of qsMaengelbeseitigungData.OffeneMaengelImZeitraum"
                [class.ist-abgelaufen]="item.IstAbgelaufen"
              >
                <td class="align-top">
                  <div class="text-bold">{{ item.Registrierungsnummer }}</div>
                  <div class="text-muted">{{ item.BetriebsstaettenName }}</div>
                  <div class="text-muted">
                    {{ getTranslationKeyForProduktgruppe(item.ProduktIdErstesProdukt) | translate }}
                  </div>
                </td>
                <td class="align-top" style="min-width: 170px">
                  <div class="text-bold">
                    {{ 'WIDGETS.QS_MAENGELBESEITIGUNG.KUERZESTE_FRIST' | translate }} {{ item.KuerzesteFrist | date }}
                  </div>
                  <div class="text-muted">
                    {{ 'WIDGETS.QS_MAENGELBESEITIGUNG.OFFENE_MAENGEL' | translate }}
                    {{ item.OffeneMaengel | number: '1.0-0' }}
                  </div>
                </td>
                <td class="btn-column text-center">
                  <iq-details-aufrufen-button
                    [routeInformation]="[
                      'portal/qualitaetssicherung/auditierung/betriebsstaette',
                      item.Registrierungsnummer,
                      item.ProduktIdErstesProdukt,
                      sortConfig.sortFields[0].sortType,
                      sortConfig.sortFields[0].sortOrder,
                      'maengel'
                    ]"
                    [selectedRegistrierungsnummer]="item.Registrierungsnummer"
                    rubrikTitelTranslationKey="WIDGETS.QS_MAENGELBESEITIGUNG.QUALITAETSSICHERUNG"
                    tooltip="{{ 'ALLGEMEIN.DETAILS' | translate }}"
                    placement="left"
                    container="body"
                  >
                  </iq-details-aufrufen-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text-right mb-3">
          <iq-focus-betriebsstaetten-nav-button
            [betriebsstaetten]="betriebsstaettenRegistrierungsnummern"
            [rubrikTitelTranslationKey]="'WIDGETS.QS_MAENGELBESEITIGUNG.QUALITAETSSICHERUNG'"
            [toRoute]="'portal/qualitaetssicherung/auditierung'"
            [buttonText]="'FOKUS.ALLE_UEBERNEHMEN' | translate"
          >
          </iq-focus-betriebsstaetten-nav-button>
        </div>
      </ng-template>
    </div>
  </div>
  <!-- edit widget -->
  <iq-anzahl-tage-option
    [textPraefixKey]="'WIDGETS.QS_MAENGELBESEITIGUNG.ABLAUFENDE_MAENGEL_IN'"
    [textSuffixKey]="'WIDGETS.QS_MAENGELBESEITIGUNG.TAGEN'"
    [defaultAnzahlTage]="anzahlTage"
    (anzahlTageChanged)="onAnzahlTageChange($event)"
  ></iq-anzahl-tage-option>
</div>
