<form
  id="formPasswordForgot"
  name="formPasswordForgot"
  class="form needs-validation"
  novalidate
  [formGroup]="form"
  [class.was-validated]="!form.valid"
>
  <div class="form-group">
    <div class="text-center">
      <h3>{{ 'WELCOME.PASSWORT_VERGESSEN.BRAUCHEN_SIE_HILFE' | translate }}</h3>
      <div class="mt-4">{{ 'WELCOME.PASSWORT_VERGESSEN.BENUTZERNAHME_ANGEBEN_HINWEIS' | translate }}</div>
    </div>
  </div>
  <div class="form-group">
    <input
      id="passwordForgotInput"
      name="passwordForgotInput"
      class="form-control iq-login"
      type="text"
      placeholder="{{ 'WELCOME.BENUTZERNAME' | translate }}"
      required
      formControlName="userName"
    />
    <div class="invalid-feedback" *ngIf="submitted && form.controls['userName'].errors">
      <span *ngIf="form.controls['userName'].errors.required"> {{ 'FEHLER.WERT_WIRD_BENOETIGT' | translate }} </span>
    </div>
  </div>
  <div [class.mt-3]="!remainingTimeBlocked" [class.mt-2]="isBlocked()">
    <div>
      <p id="timerPasswordForgot" class="text-danger" *ngIf="isBlocked()">
        {{ 'WELCOME.NACHRICHT_IP_GESPERRT' | translate }} {{ remainingTimeBlocked | timer }}
      </p>
    </div>
    <button
      type="submit"
      class="iq-login-submit"
      data-style="slide-left"
      (click)="emitPasswordForgotten($event)"
      [disabled]="isBlocked()"
      [ladda]="loading"
    >
      {{ 'ALLGEMEIN.WEITER' | translate }}
    </button>
    <div class="text-center mt-4">
      <a href id="showLoginViewLink" (click)="showLoginView($event)">
        {{ 'WELCOME.PASSWORT_VERGESSEN.ZURUECK_ZUM_LOGIN' | translate }}
      </a>
    </div>
  </div>
</form>
