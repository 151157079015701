import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { HelpButtonModalComponent } from '../help-button-modal/help-button-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomModalService } from '@share/custom-modal.service';

@Component({
  selector: 'iq-help-button',
  templateUrl: './help-button.component.html'
})
export class HelpButtonComponent {
  /**
   * The key of the section in which the help button is present
   */
  @Input()
  helpTextKey: string;

  /**
   * The help text template string
   */
  private templateString: string;

  /**
   * The modal instance
   */
  private modalInstance: BsModalRef;

  /**
   * Constructor.
   * @param translate
   * @param modalService
   * @param domSanitizer
   */
  constructor(
    private translate: TranslateService,
    private modalService: CustomModalService,
    public domSanitizer: DomSanitizer
  ) {}

  /**
   * Returns the code string of the current state or the state key in uppercase
   * when no code string for the current state exists.
   *
   * @return  the code string, when available, else the state key in uppercase.
   */
  getTranslation(): string {
    return this.translate.instant(this.helpTextKey);
  }

  /**
   * Checks if a code string exists for the current state
   *
   * @return {Boolean} true, if translation exists, else false
   */
  translationExists(): boolean {
    this.templateString = this.getTranslation();
    if (this.templateString !== this.helpTextKey) {
      return true;
    }
    return false;
  }

  /**
   * Opens the modal with the help informations
   */
  openHelpModal() {
    const modalOptions: ModalOptions<HelpButtonModalComponent> = {
      backdrop: 'static',
      class: 'help-modal',
      initialState: {
        templateString: this.domSanitizer.bypassSecurityTrustHtml(this.templateString)
      }
    };
    this.modalInstance = this.modalService.show(HelpButtonModalComponent, modalOptions);
    this.modalInstance.content.onClose$.asObservable().subscribe({
      next: () => {
        this.modalInstance.hide();
      },
      error: () => {
        this.modalInstance.hide();
      },
      component: () => {
        this.modalInstance.hide();
      }
    });
  }
}
