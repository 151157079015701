import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PasswordForgottenEvent, ShowLoginViewEvent } from '../share/login-events';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PasswordForgottenData } from '../share/login-entities';

@Component({
  selector: 'iq-password-forgotten',
  templateUrl: './password-forgotten.component.html',
  styleUrls: ['./password-forgotten.component.scss']
})
export class PasswordForgottenComponent implements OnInit {
  /**
   * Indicates whether the login data is currently sent.
   */
  @Input() loading = true;

  /**
   * Indicates the remaining time the user is blocked.
   */
  @Input() remainingTimeBlocked: number;

  /**
   * The event to log in the user
   */
  @Output() passwordForgotten = new EventEmitter<PasswordForgottenEvent>();

  /**
   * The event to display the login view
   */
  @Output() showLogin = new EventEmitter<ShowLoginViewEvent>();

  /**
   * The data of the {@link FormGroup}.
   */
  public form: FormGroup;

  /**
  * Indicates that the login button was pressed after entering the login data.
  */
  public submitted = false;

  /**
   * Constructor.
   */
  constructor() {}

  /**
   * Initializes the FormGroup.
   */
  ngOnInit() {
    this.form = new FormGroup({
      userName: new FormControl(null, [Validators.required])
    });
  }

  /**
   * Emits the event to display the "Password Requested" view.
   * @param event the click event
   */
  emitPasswordForgotten(event): void {
    event.preventDefault();
    this.submitted = true;

    if (this.form.valid) {
      const passwordForgottenData: PasswordForgottenData = this.form.value;
      const showPasswordRequestedEvent = new PasswordForgottenEvent(passwordForgottenData);
      this.passwordForgotten.emit(showPasswordRequestedEvent);
    }
  }

  /**
   * Emits the event to display the login view.
   * @param event click event
   */
  showLoginView(event): void {
    event.preventDefault();
    const showLoginEvent = new ShowLoginViewEvent();
    this.showLogin.emit(showLoginEvent);
  }

  isBlocked(): boolean {
    return this.remainingTimeBlocked > 0;
  }
}
