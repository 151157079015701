import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnChanges,
  PLATFORM_ID,
  SimpleChanges,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { calculateViewDimensions, ColorHelper, ViewDimensions } from '@swimlane/ngx-charts';
import { curveLinear, line } from 'd3-shape';
import { scaleBand, scaleLinear, scalePoint } from 'd3-scale';
import * as d3 from 'd3';
import { IqBaseChartComponent } from '@share/chart-helper/iq-base-chart.component';
import { cloneDeep, isNil } from 'lodash-es';
import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '@iq-angular-libs/portal';
import { PortalThemeColors } from '@core/theme/theme.interfaces';

@Component({
  selector: 'iq-line-bar-chart',
  templateUrl: './line-bar-chart.component.html',
  styleUrls: ['./line-bar-chart.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LineBarChartComponent extends IqBaseChartComponent implements OnChanges, AfterViewInit, AfterViewChecked {
  @Input()
  chartId: string;
  @Input()
  curve: any = curveLinear;
  @Input()
  legend = false;
  @Input()
  legendTitle = 'Legend';
  @Input()
  xAxis;
  @Input()
  yAxis;
  @Input()
  showXAxisLabel;
  @Input()
  showYAxisLabel;
  @Input()
  showRightYAxisLabel;
  @Input()
  xAxisLabelTranslationKey = '';
  @Input()
  xAxisLabelUnitTranslationKey;
  @Input()
  optimalbereich;
  @Input()
  yAxisLabelTranslationKey = '';
  @Input()
  yAxisLabelUnitTranslationKey;
  @Input()
  yAxisLabelRightTranslationKey = '';
  @Input()
  yAxisLabelRightUnitTranslationKey;
  @Input()
  tooltipDisabled = false;
  @Input()
  gradient: boolean;
  @Input()
  showGridLines = true;
  @Input()
  activeEntries: any[] = [];
  @Input()
  xAxisTickFormatting: any;
  @Input()
  yAxisTickFormatting: any;
  @Input()
  yAxisRightTickFormatting: any;
  @Input()
  roundDomains = true;
  @Input()
  colorSchemeLine: unknown;
  @Input()
  autoScale;
  @Input()
  lineChart: any;
  @Input()
  yLeftAxisScaleAbsolute: boolean;
  @Input()
  yRightAxisScaleAbsolute: boolean;
  @Input()
  rangeFillOpacity: number;
  @Input()
  animations = false;
  @Input()
  customColorsBars: any;
  @Input()
  barPadding = 5;

  @Input()
  disableXAchsenVerschiebung = false;
  @Input()
  rotateXAxisTicks = false;
  /**
   * The highlightProperty can be set, if a highlighting over multiple bars should occur.
   * The corresponding property must be inside the data object of the bars.
   */
  @Input()
  highlightProperty?: string = null;
  /**
   * The highlightColor can be set additionally to the highlightProperty to specify the
   * color of the highlighted bars.
   */
  @Input()
  highlightColor?: string;

  @ContentChild('tooltipTemplateLine', { static: true })
  tooltipTemplateLine: TemplateRef<any>;
  @ContentChild('seriesTooltipTemplate', { static: true })
  seriesTooltipTemplate: TemplateRef<any>;
  @ContentChild('tooltipTemplateBar', { static: true })
  tooltipTemplateBar: TemplateRef<any>;

  dims: ViewDimensions;
  xScale: any;
  yScale: any;
  xDomain: any;
  yDomain: any;
  transform: string;
  colors: ColorHelper;
  colorsLine: ColorHelper;
  margin: any[] = [10, 80, 10, -10];
  xAxisHeight = 0;
  yAxisWidth = 0;
  legendOptions: any;
  scaleType = 'linear';
  xScaleLine;
  yScaleLine;
  xDomainLine;
  yDomainLine;
  seriesDomain;
  scaledAxis;
  combinedSeries;
  xSet;
  filteredDomain;
  hoveredVertical;
  yOrientLeft = 'left';
  yOrientRight = 'right';
  bandwidth;
  xAxisTicks;
  nullLinePath;
  y1AxisLinePath;
  y2AxisLinePath;
  oldViewWidth: number;

  // fields for the translations of the translation keys
  xAxisLabel = '';
  yAxisLabel = '';
  yAxisLabelRight = '';

  constructor(
    chartElement: ElementRef,
    zone: NgZone,
    cd: ChangeDetectorRef,
    translate: TranslateService,
    theme: ThemeService<PortalThemeColors>,
    private decimalPipe: DecimalPipe,
    @Inject(PLATFORM_ID) platformId: any
  ) {
    super(chartElement, zone, cd, translate, theme, platformId);
  }

  trackBy(index, item): string {
    return item.name;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.prepareLabels();
    super.ngOnChanges(changes);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngAfterViewChecked() {
    if (!this.oldViewWidth || this.oldViewWidth !== this.dims.width) {
      this.oldViewWidth = this.dims.width;
      setTimeout(() => {
        if (!this.disableXAchsenVerschiebung) {
          this.verschiebeXAchsenBeschriftungen();
        }
      });
    }
  }

  /**
   * Moves the tick label of the x axis between the bars.
   */
  verschiebeXAchsenBeschriftungen() {
    let balkenBreite = '0';
    const diagramm = d3.select('ngx-charts-chart#' + this.chartId).select('svg.ngx-charts');
    const bar = diagramm.select('g[ngx-charts-bar]');
    if (bar && bar.node()) {
      balkenBreite = (bar.node() as any).getBBox().width;

      // get and move x axis ticks
      const ticks = diagramm
        .select('g[iq-charts-x-axis]')
        .selectAll('.tick')
        .each(function(d, i) {
          const textElement = d3.select(this).select('text');
          const offset = (parseFloat(balkenBreite) / 2) * 1.2 * -1;
          textElement.attr('transform', 'translate(' + offset + ', 0)');
        });

      // remove all previously generated ticks
      diagramm
        .select('g[iq-charts-x-axis-ticks]')
        .selectAll('.customtick')
        .remove();

      // get last tick
      const lastTick = d3.select(ticks.nodes()[ticks.nodes().length - 1]);

      // add the last tick element with the coordinates of the last ticks
      // and move the text to the right
      d3.select('ngx-charts-chart#' + this.chartId)
        .select('g[iq-charts-x-axis-ticks] g')
        .append('g')
        .attr('class', 'tick')
        .attr('class', 'customtick')
        .attr('transform', lastTick.attr('transform'))
        .append('text')
        .text(' ' + '\u221E' + ' ')
        .attr('transform', 'translate(' + (parseFloat(balkenBreite) / 2) * 1.0 + ', 0)');
    }
  }

  /**
   * Generate the y axis lines
   */
  generateAxisLines() {
    const lineGen = line<any>()
      .x(d => {
        if (d.x === 0) {
          return 0;
        } else {
          return this.dims.width;
        }
      })
      .y(d => {
        if (d.y === 0) {
          return 0;
        } else {
          return this.dims.height;
        }
      })
      .curve(this.curve);
    this.y1AxisLinePath = lineGen([
      { x: 0, y: 0 },
      { x: 0, y: 1 }
    ]);
    this.y2AxisLinePath = lineGen([
      { x: 1, y: 0 },
      { x: 1, y: 1 }
    ]);
  }

  /**
   * Generates the null line of y axis 2
   */
  generateNullLinePath() {
    const lineGen = line<any>()
      .x(d => {
        const label = d.name;
        if (label === '0') {
          return 0;
        } else {
          return this.dims.width;
        }
      })
      .y(d => {
        if (!isNil(d.value)) {
          return this.yScaleLine(d.value);
        }
        return null;
      })
      .curve(this.curve);
    if (this.lineChart && this.lineChart.length) {
      const lineData = cloneDeep(this.lineChart[0].series);
      const nullLineData = [lineData[0], lineData[lineData.length - 1]];
      nullLineData[0].value = 0;
      nullLineData[1].value = 0;
      if (this.yDomainLine && this.yDomainLine[0] <= 0 && this.yDomainLine[1] >= 0) {
        this.nullLinePath = lineGen(nullLineData);
      }
    }
  }

  update(): void {
    super.update();
    this.dims = calculateViewDimensions({
      width: this.width,
      height: this.height,
      margins: this.margin,
      showXAxis: this.xAxis,
      showYAxis: this.yAxis,
      xAxisHeight: this.xAxisHeight,
      yAxisWidth: this.yAxisWidth,
      showXLabel: this.showXAxisLabel,
      showYLabel: this.showYAxisLabel,
      showLegend: this.legend,
      legendType: this.schemeType
    });

    this.xScale = this.getXScale();
    this.yScale = this.getYScale();

    // line chart
    this.xDomainLine = this.getXDomainLine();
    if (this.filteredDomain) {
      this.xDomainLine = this.filteredDomain;
    }

    this.yDomainLine = this.getYDomainLine();
    this.seriesDomain = this.getSeriesDomain();

    this.xScaleLine = this.getXScaleLine(this.xDomainLine, this.dims.width);
    this.yScaleLine = this.getYScaleLine(this.yDomainLine, this.dims.height);

    this.setColors();
    this.legendOptions = this.getLegendOptions();

    this.transform = `translate(${this.dims.xOffset} , ${this.margin[0]})`;

    this.generateNullLinePath();
    this.generateAxisLines();
  }

  updateHoveredVertical(item): void {
    this.hoveredVertical = item.value;
  }

  updateDomain(domain): void {
    this.filteredDomain = domain;
    this.xDomainLine = this.filteredDomain;
    this.xScaleLine = this.getXScaleLine(this.xDomainLine, this.dims.width);
  }

  getSeriesDomain(): any[] {
    this.combinedSeries = this.lineChart.slice(0);
    this.combinedSeries.push({
      name: this.yAxisLabelTranslationKey,
      series: this.results
    });
    return this.combinedSeries.map(d => d.name);
  }

  isDate(value): boolean {
    if (value instanceof Date) {
      return true;
    }

    return false;
  }

  getScaleType(values): string {
    let date = true;
    let num = true;

    for (const value of values) {
      if (!this.isDate(value)) {
        date = false;
      }

      if (typeof value !== 'number') {
        num = false;
      }
    }

    if (date) {
      return 'time';
    }
    if (num) {
      return 'linear';
    }
    return 'ordinal';
  }

  getXDomainLine(): any[] {
    let values = [];

    for (const results of this.lineChart) {
      for (const d of results.series) {
        if (!values.includes(d.name)) {
          values.push(d.name);
        }
      }
    }

    this.scaleType = this.getScaleType(values);
    let domain = [];

    if (this.scaleType === 'time') {
      const min = Math.min(...values);
      const max = Math.max(...values);
      domain = [min, max];
    } else if (this.scaleType === 'linear') {
      values = values.map(v => Number(v));
      const min = Math.min(...values);
      const max = Math.max(...values);
      domain = [min, max];
    } else {
      domain = values;
    }

    this.xSet = values;
    return domain;
  }

  getYDomainLine(): any[] {
    const domain = [];

    for (const results of this.lineChart) {
      for (const d of results.series) {
        if (isNil(d.value)) {
          continue;
        }

        if (domain.indexOf(d.value) < 0) {
          domain.push(d.value);
        }
        if (d.min !== undefined) {
          if (domain.indexOf(d.min) < 0) {
            domain.push(d.min);
          }
        }
        if (d.max !== undefined) {
          if (domain.indexOf(d.max) < 0) {
            domain.push(d.max);
          }
        }
      }
    }

    const min = Math.min(...domain, ...this.yDomainLine || []);
    const max = Math.max(...domain, ...this.yDomainLine || []);

    if (this.yRightAxisScaleAbsolute) {
      return [Math.min(0, min), max];
    }
    return [min, max];
  }

  getXScaleLine(domain, width): any {
    const barWidth = this.xScale.bandwidth();
    const scale = scalePoint()
      .range([15 + barWidth / 2, width - barWidth / 2 - 15])
      .domain(domain);

    return scale;
  }

  getYScaleLine(domain, height): any {
    const scale = scaleLinear()
      .range([height, 0])
      .domain(domain);

    return this.roundDomains ? scale.nice(5) : scale;
  }

  getXScale(): any {
    this.xDomain = this.getXDomain();
    this.xAxisTicks = this.xDomain;
    return scaleBand()
      .range([0 + 15, this.dims.width - 15])
      .paddingInner(0.1)
      .domain(this.xDomain);
  }

  getYScale(): any {
    this.yDomain = this.getYDomain();
    const scale = scaleLinear()
      .range([this.dims.height, 0])
      .domain(this.yDomain);
    return this.roundDomains ? scale.nice(5) : scale;
  }

  getXDomain(): any[] {
    return this.results.map(d => d.name);
  }

  getYDomain() {
    const values = [];
    for (const d of this.results) {
      if (isNil(d.value)) {
        continue;
      }
      values.push(d.value);
    }
    const min = Math.min(...values);
    const max = Math.max(...values);
    if (this.yLeftAxisScaleAbsolute) {
      return [Math.min(0, min), max];
    } else {
      return [min, max];
    }
  }

  setColors(): void {
    let domain;
    if (this.schemeType === 'ordinal') {
      domain = this.xDomain;
    } else {
      domain = this.yDomain;
    }
    this.colors = new ColorHelper(this.scheme, this.schemeType, domain, this.customColorsBars);
  }

  getLegendOptions() {
    const opts = {
      scaleType: this.schemeType,
      colors: undefined,
      domain: [],
      title: undefined
    };
    if (opts.scaleType === 'ordinal') {
      opts.domain = this.seriesDomain;
      opts.colors = this.colorsLine;
      opts.title = this.legendTitle;
    } else {
      opts.domain = this.seriesDomain;
      opts.colors = this.colors.scale;
    }
    return opts;
  }

  updateLineWidth(width): void {
    this.bandwidth = width;
  }

  updateYAxisWidth({ width }): void {
    this.yAxisWidth = width + 20;
    this.update();
  }

  updateXAxisHeight({ height }): void {
    this.xAxisHeight = height;
    this.update();
  }

  /**
   * Generates the axis labels
   */
  private prepareLabels() {
    this.xAxisLabel = '';
    this.yAxisLabel = '';
    this.yAxisLabelRight = '';

    if (this.xAxisLabelTranslationKey) {
      this.xAxisLabel = this.translate.instant(this.xAxisLabelTranslationKey);
    }
    if (this.xAxisLabelTranslationKey && this.xAxisLabelUnitTranslationKey) {
      this.xAxisLabel += ' [' + this.translate.instant(this.xAxisLabelUnitTranslationKey) + ']';
    }

    if (this.optimalbereich) {
      this.xAxisLabel +=
        ' (' +
        this.translate.instant('SCHLACHTDATEN.OPTIMALBEREICH') +
        ': ' +
        this.decimalPipe.transform(this.optimalbereich.from, '1.2-2', 'de-DE') +
        ' ' +
        this.translate.instant('MASSEINHEITEN.KG') +
        ' - ' +
        this.decimalPipe.transform(this.optimalbereich.to, '1.2-2', 'de-DE') +
        ' ' +
        this.translate.instant('MASSEINHEITEN.KG') +
        ')';
    }

    if (this.yAxisLabelTranslationKey) {
      this.yAxisLabel = this.translate.instant(this.yAxisLabelTranslationKey);
    }
    if (this.yAxisLabelTranslationKey && this.yAxisLabelUnitTranslationKey) {
      this.yAxisLabel += ' [' + this.translate.instant(this.yAxisLabelUnitTranslationKey) + ']';
    }
    this.yAxisLabel += ' (' + this.translate.instant('ALLGEMEIN.SAEULE') + ')';

    if (this.yAxisLabelRightTranslationKey) {
      this.yAxisLabelRight = this.translate.instant(this.yAxisLabelRightTranslationKey);
    }
    if (this.yAxisLabelRightTranslationKey && this.yAxisLabelRightUnitTranslationKey) {
      this.yAxisLabelRight += ' [' + this.translate.instant(this.yAxisLabelRightUnitTranslationKey) + ']';
    }
    this.yAxisLabelRight += ' (' + this.translate.instant('ALLGEMEIN.LINIE') + ')';
  }
}
