import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthGuard } from '@core/auth/auth.guard';
import { LocalStorageNotSupportedComponent } from './local-storage-not-supported/local-storage-not-supported.component';
import { LocalStorageGuard } from '@core/local-storage/local-storage.guard';
import { LoginBaseLayoutComponent } from './login/login-base-layout/login-base-layout.component';
import { ErrorComponent } from './error/error.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginBaseLayoutComponent,
    canActivate: [LocalStorageGuard, AuthGuard]
  },
  {
    path: 'portal',
    loadChildren: () => import('./portal/portal.module').then(m => m.PortalModule)
  },
  {
    path: 'modal',
    loadChildren: () => import('./modal/modal.module').then(m => m.ModalModule)
  },
  {
    path: 'validation',
    loadChildren: () => import('./validation/validation.module').then(m => m.ValidationModule)
  },
  { path: 'localStorageNotSupported', component: LocalStorageNotSupportedComponent },
  { path: '404', component: PageNotFoundComponent },
  { path: 'error', component: ErrorComponent },
  { path: '**', redirectTo: '404', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
