import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FilterComponent } from '../filter-component.interfaces';
import { SelectedFilterItemsChangedEvent } from '../filter-component.events';
import { MultiselectChangedEvent, MultiselectClosedEvent, MultiselectSettings } from '@iq-angular-libs/portal';
import { Subject } from 'rxjs';
import { FilterService } from '@share/filter/filter.service';
import { GlobalFilterService } from '@core/global-filter/global-filter.service';
import { takeUntil } from 'rxjs/operators';
import { Betriebsstaetten } from '@share/filter/filter.interfaces';
import { FilterBereiche } from '@share/filter/filter-bereiche';

@Component({
  selector: 'iq-betriebsstaetten-filter',
  templateUrl: './betriebsstaetten-filter.component.html'
})
export class BetriebsstaettenFilterComponent implements OnInit, OnDestroy, FilterComponent {
  /**
   * The selected comerical units
   */
  @Input()
  selectedItems: Betriebsstaetten;

  /**
   * The filter section.
   */
  @Input()
  filterBereich: FilterBereiche;

  /**
   * Indicates whether the filter is disabled or not.
   */
  @Input()
  filterDisabled: boolean;

  /**
   * Indicator whether an event should be emitted with each selection.
   */
  @Input()
  updateOnChange: boolean;

  /**
   * Event emitter that fires an event when the filter has changed.
   */
  @Output()
  selectedItemsChanged: EventEmitter<SelectedFilterItemsChangedEvent>;

  /**
   * The settings of the (multiselect component){@link MultiselectButtonComponent}
   */
  settings: MultiselectSettings;

  /**
   * All existing comerical units
   */
  betriebsstaetten: Betriebsstaetten;

  /**
   * The active comerical units
   */
  activeBetriebsstaetten: Betriebsstaetten;

  /**
   * Unsubscribe-Stream.
   * Is used in (ngOnDestroy){@link BetriebsstaettenFilterComponent#ngOnDestroy} to filter all
   * to close open subscriptions.
   */
  private unsubscribe$: Subject<void>;

  /**
   * Constructor
   * @param filterService filter service
   * @param globalFilterService globaler filter service
   */
  constructor(private filterService: FilterService, private globalFilterService: GlobalFilterService) {
    this.betriebsstaetten = [];
    this.activeBetriebsstaetten = [];

    this.settings = {
      id: 'betriebsstaetten-filter-multiselect',
      sortProp: 'name',
      enableSearch: true,
      enableDisplayFirstSelectedItem: true,
      enableDisplaySelectedShortnames: false,
      buttonClasses: 'btn btn-secondary iq-select-button iq-btn-filter',
      buttonMaxWidth: 250,
      dropdownAppendTo: 'body'
    };

    this.selectedItemsChanged = new EventEmitter<SelectedFilterItemsChangedEvent>();
    this.unsubscribe$ = new Subject<void>();
  }

  /**
   * Initially loads all comerical units and all active comerical units.
   * Registers with GlobalFilterService to be informed about changes of the comercial units.
   */
  ngOnInit() {
    this.getBetriebsstaetten();
    this.getActiveBetriebsstaetten();
    this.subscribeOnGlobalFilterChangedEvents();
  }

  /**
   * Closes the FilterComponentChanged event emitter.
   * Fires an event of the unsubscribe stream and closes it afterwards.
   */
  ngOnDestroy(): void {
    this.selectedItemsChanged.complete();

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Is called as soon as the multiselect selection has changed and the dropdown has closed.
   * @param event {@link MultiselectClosedEvent}
   */
  onMultiselectClosed(event: MultiselectClosedEvent) {
    if (!this.updateOnChange) {
      const selectedFilterItemsChangedEvent = new SelectedFilterItemsChangedEvent(event.selectedItems);
      this.selectedItemsChanged.emit(selectedFilterItemsChangedEvent);
    }
  }

  /**
   * Is called as soon as the multiselect selection has changed.
   * @param event {@link MultiselectChangedEvent}
   */
  onMultiselectChanged(event: MultiselectChangedEvent) {
    if (this.updateOnChange) {
      const selectedFilterItemsChangedEvent = new SelectedFilterItemsChangedEvent(event.selectedItems);
      this.selectedItemsChanged.emit(selectedFilterItemsChangedEvent);
    }
  }

  /**
   * Queries all existing premises of the user from {@link FilterService}.
   */
  private getBetriebsstaetten(): void {
    this.filterService.getBetriebsstaetten(this.filterBereich).subscribe({
      next: (betriebsstaetten: Betriebsstaetten) => {
        this.betriebsstaetten = betriebsstaetten;
      }
    });
  }

  /**
   * Queries all active sites of the user from {@link FilterService}.
   */
  private getActiveBetriebsstaetten(): void {
    this.filterService.getActiveBetriebsstaetten(this.filterBereich).subscribe({
      next: (activeBetriebsstaetten: Betriebsstaetten) => {
        this.activeBetriebsstaetten = activeBetriebsstaetten;
      }
    });
  }

  /**
   * Registers to the comercial units changed stream of (GlobalFilterServices){@link GlobalFilterService}
   * to reload the active comerical units when the global comerical units change change.
   */
  private subscribeOnGlobalFilterChangedEvents(): void {
    this.globalFilterService
      .getBetriebsstaetteChangedObservable()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.getActiveBetriebsstaetten();
      });
  }
}
