import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { LetzteXTageMode } from '@share/filter/datum-filter.enums';
import { LetzteXTage } from '@share/filter/uebergeordnete-filter.interfaces';
import { FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { find, isNil } from 'lodash-es';

/**
 * Interface and type declaration, which describe the period/periods for the lastXdays range of the date filter.
 */
interface Zeitraum {
  mode: LetzteXTageMode;
  translationKey: string;
}
declare type Zeitraeume = Zeitraum[];

/**
 * Component that allows the selection of the period (LastXdays).
 */
@Component({
  selector: 'iq-letzte-x-tage',
  templateUrl: './letzte-x-tage.component.html',
  styleUrls: ['./letzte-x-tage.component.scss']
})
export class LetzteXTageComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  /**
   * The configuration object for the last x days
   */
  @Input()
  letzteXTage: LetzteXTage;

  /**
   * Indicates whether the (Form){@link LastXDayComponent#form} was already tried to submit.
   */
  @Input()
  submitted: boolean;

  /**
   * The form of the date modal.
   */
  @Input()
  form: FormGroup;

  /**
   * The reference to the number of days input.
   */
  @ViewChild('letzteXTageAnzahlTageInput', { static: true })
  anzahlTageInput: ElementRef;

  /**
   * The available time periods.
   */
  zeitraeume: Zeitraeume;

  /**
   * Provision of the modes in the template
   */
  letzteXTageMode = LetzteXTageMode;

  /**
   * Unsubscribe-Stream
   */
  private unsubscribe$: Subject<void>;

  /**
   * Constructor.
   */
  constructor() {
    this.zeitraeume = [
      { mode: LetzteXTageMode.LETZTE_30_TAGE, translationKey: 'FILTER.DATUM_FILTER.LETZTE_30_TAGE' },
      { mode: LetzteXTageMode.LETZTE_90_TAGE, translationKey: 'FILTER.DATUM_FILTER.LETZTE_90_TAGE' },
      { mode: LetzteXTageMode.LETZTE_180_TAGE, translationKey: 'FILTER.DATUM_FILTER.LETZTE_180_TAGE' },
      { mode: LetzteXTageMode.LETZTE_365_TAGE, translationKey: 'FILTER.DATUM_FILTER.LETZTE_365_TAGE' },
      { mode: LetzteXTageMode.FREIE_EINGABE, translationKey: 'FILTER.DATUM_FILTER.FREIE_EINGABE' }
    ];

    this.unsubscribe$ = new Subject<void>();
  }

  /**
   * Subscribed to the select field and the input field
   */
  ngOnInit() {
    this.subscribeOnSelectedZeitraumChangeEvents();
    this.subscribeOnAnzahlTageChangeEvents();
  }

  /**
   * Resets the values of the Select and Input on changes.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.letzteXTage && this.form) {
      const foundZeitraum = find(this.zeitraeume, (zeitraum: Zeitraum) => zeitraum.mode === this.letzteXTage.mode);

      if (foundZeitraum) {
        this.form.controls['SelectedLetzteXTage'].setValue(foundZeitraum.mode, { emitEvent: false });
      } else {
        this.form.controls['SelectedLetzteXTage'].setValue(this.zeitraeume[0].mode, { emitEvent: false });
      }

      if (this.letzteXTage.mode === LetzteXTageMode.FREIE_EINGABE) {
        this.form.controls['AnzahlTage'].setValue(this.letzteXTage.anzahlTage, { emitEvent: false });
      }

      this.setValidatorForAnzahlTage(this.letzteXTage.mode);
    }
  }

  /**
   * Focuses the first input when the modal is opened after the view is initialized.
   */
  ngAfterViewInit(): void {
    this.focusAnzahlTageInput();
  }

  /**
   * Fires the unsubscribe event and then closes the stream.
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Focuses the input field for number of days
   */
  private focusAnzahlTageInput(): void {
    setTimeout(() => {
      if (this.anzahlTageInput && this.anzahlTageInput.nativeElement) {
        this.anzahlTageInput.nativeElement.focus();
        this.anzahlTageInput.nativeElement.select();
      }
    });
  }

  /**
   * Sets the validators for the number of days input based on the selected lastXdays mode.
   * @param mode The active mode
   */
  private setValidatorForAnzahlTage(mode: LetzteXTageMode): void {
    const anzahlTageControl = this.form.controls['AnzahlTage'];

    if (mode === LetzteXTageMode.FREIE_EINGABE) {
      this.letzteXTage.anzahlTage = 0;
      anzahlTageControl.setValidators([Validators.min(0), Validators.required]);
      this.focusAnzahlTageInput();
    } else {
      anzahlTageControl.setValidators([Validators.min(0)]);
    }

    anzahlTageControl.updateValueAndValidity({ emitEvent: false });
  }

  /**
   * Subscribes to the Select-Changed-Event of the period selection and sets the selected period
   */
  private subscribeOnSelectedZeitraumChangeEvents(): void {
    this.form.controls['SelectedLetzteXTage'].valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((letzteXTageMode: LetzteXTageMode) => {
        this.letzteXTage.mode = letzteXTageMode;
        this.setValidatorForAnzahlTage(this.letzteXTage.mode);
      });
  }

  /**
   * Subscribes to the number-days-changed-event and sets the selected days
   */
  private subscribeOnAnzahlTageChangeEvents(): void {
    this.form.controls['AnzahlTage'].valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((anzahlTage: number) => this.letzteXTage.mode === LetzteXTageMode.FREIE_EINGABE),
        filter((anzahlTage: number | null) => !isNil(anzahlTage) && anzahlTage >= 0)
      )
      .subscribe((anzahlTage: number) => {
        this.letzteXTage.anzahlTage = anzahlTage;
      });
  }
}
