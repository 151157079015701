import { cloneDeep, forEach, isArray, isNil, isString } from 'lodash-es';
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import { Programme } from '@core/constants';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { FilterBetriebsstaette } from '@share/betriebsstaette.interface';
import { map, take } from 'rxjs/operators';
import { UserService } from '@core/user/user.service';
import { RoutingHelpers } from '@core/routing/routing-helpers';
import { CurrentUser } from '@core/user/user.interfaces';
import { QsUebersichtData } from '../qualitaetssicherung/auditing/share/qualitaetssicherung.interfaces';
import { BetriebslistenData, MaengellistenData } from '../qs-management/share/qs-management.interfaces';
import { TranslateService } from '@ngx-translate/core';
import { GroupedBetriebsstaette, MultiSelectItem } from '@iq-angular-libs/portal';

dayjs.extend(customParseFormat);

/**
 * Creates a deep copy of the passed date, if defined.
 * Then deletes the time of the date and returns it as ISO string.
 * @param date
 */
export function toISODateString(date: Date | null | undefined): string | null {
  if (isNil(date)) {
    return null;
  }

  const formattingDate = dayjs(cloneDeep(date));

  if (!formattingDate.isValid()) {
    console.warn('Invalid Date in toISODateString');
    return null;
  }

  return formattingDate.format('YYYY-MM-DDT00:00:00.000') + 'Z';
}

/**
 * Transforms the input string into a date object, if defined.
 * @param inputString The string to be converted in the form 'DD.MM.YY'.
 * @param checkDateValidity Indicates whether the validity of the date should be checked
 */
export function dateStringToDate(
  inputString: string | null | undefined,
  checkDateValidity: boolean = true
): Date | null {
  if (isString(inputString)) {
    const regex = new RegExp('^([0-2][0-9]|(3)[0-1])(\\.)(((0)[0-9])|((1)[0-2]))(\\.)\\d{2}$');

    if (regex.test(inputString)) {
      const dateFormat = 'DD.MM.YY';
      const dayjsObj = dayjs(inputString, dateFormat);
      const isValidDate = dayjsObj.format(dateFormat) === inputString;

      if (!checkDateValidity || (checkDateValidity && isValidDate)) {
        return dayjsObj.toDate();
      }
    }
  }

  return null;
}

/**
 * Extracts the comercial units from the grouped comercial units.
 * @param filteredData the grouped comercial units
 */
export function extractBetriebstaettenFromGroupedBetriebsstaetten<T>(filteredData: GroupedBetriebsstaette<T>[]): T[] {
  if (isNil(filteredData)) {
    return [];
  }

  let filteredBetriebsstaetten: T[] = [];

  filteredData.forEach((betriebsstaette) => {
    if (betriebsstaette['betriebsstaetten']) {
      filteredBetriebsstaetten = filteredBetriebsstaetten.concat(betriebsstaette['betriebsstaetten'] as T[]);
    } else {
      throw new Error('No valid filteredData');
    }
  });

  return filteredBetriebsstaetten;
}

/**
 * Determines the active registration numbers based on the current state of the application.
 * @param snapshot the {@link ActivatedRouteSnapshot}, which represents the current route
 * @param userService {@link UserService}
 * @param items all comercial units
 */
export function extractActiveItems(
  snapshot: ActivatedRouteSnapshot,
  userService: UserService,
  items: FilterBetriebsstaette[]
): Observable<FilterBetriebsstaette[] | null> {
  return userService.getCurrentUser().pipe(
    take(1),
    map((currentUser: CurrentUser) => {
      if (currentUser.IstMassenzugang) {
        return null;
      }

      const programme = findProgramme(snapshot);
      if (programme.length === 0) {
        return cloneDeep<FilterBetriebsstaette[]>(items);
      } else {
        const activeItems = [];
        forEach(items, (item: FilterBetriebsstaette) => {
          if (Array.isArray(item.Programme)) {
            let programmIndex = 0;
            let itemFound = false;
            while (programmIndex < item.Programme.length && !itemFound) {
              if (programme.indexOf(item.Programme[programmIndex]) !== -1) {
                itemFound = true;
                activeItems.push(item);
              }
              programmIndex++;
            }
          }
        });
        return activeItems;
      }
    })
  );
}

/**
 * Gets the translation key of the product group of the given product.
 * @param produktId the product id
 */
export function getTranslationKeyForProduktgruppe(produktId: string): string {
  let firstCharOf = '';
  let translationKey = '';

  if (!isNil(produktId)) {
    firstCharOf = produktId.charAt(0) || '';
  }

  switch (firstCharOf) {
  case '1':
    translationKey = 'PRODUKTIONSART.RINTERHALTUNG';
    break;
  case '2':
    translationKey = 'PRODUKTIONSART.SCHWEINEHALTUNG';
    break;
  case '3':
    translationKey = 'PRODUKTIONSART.GEFLUEGELHALTUNG';
    break;
  case '4':
    translationKey = 'PRODUKTIONSART.OBST_UND_GEMUESEHBAU';
    break;
  case '5':
    translationKey = 'PRODUKTIONSART.KARTOFFELANBAU';
    break;
  default:
    translationKey = 'PRODUKTIONSART.SONSTIGES';
  }

  return translationKey;
}

/**
 * Determines the statuses of the comerical units.
 * Is used for the multiselect filter of the status column in the table.
 */
export function getAllStatus(
  translate: TranslateService,
  betriebsstaetten: (QsUebersichtData | BetriebslistenData | MaengellistenData)[],
  translationPrefix: string,
  columnKey: string
): MultiSelectItem[] {
  let uniqueStatusItems: MultiSelectItem[] = [];
  let allStatusElements: any[] = [];

  if (
    Array.isArray(betriebsstaetten) &&
    betriebsstaetten.length > 0 &&
    columnKey &&
    !isNil(betriebsstaetten[0][columnKey])
  ) {
    // combine all statuses into an array -> flatten
    allStatusElements = betriebsstaetten.reduce((prev, betriebsstaette) => prev.concat(betriebsstaette[columnKey]), allStatusElements);

    // sort the statuses
    allStatusElements.sort();

    // add first element
    if (allStatusElements.length > 0) {
      const translation = translate.instant(translationPrefix + allStatusElements[0]);
      if (translation === translationPrefix + allStatusElements[0]) {
        uniqueStatusItems.push({ id: allStatusElements[0], displayName: allStatusElements[0] });
      } else {
        uniqueStatusItems.push({ id: allStatusElements[0], displayName: translation });
      }
    }
    // reduce the statuses -> no duplicates
    uniqueStatusItems = allStatusElements.reduce((prev, status) => {
      if (prev[prev.length - 1].id !== status) {
        const translation = translate.instant(translationPrefix + status);
        if (translation === translationPrefix + status) {
          prev.push({ id: status, displayName: status });
        } else {
          prev.push({ id: status, displayName: translation });
        }
      }
      return prev;
    }, uniqueStatusItems);
  }
  return uniqueStatusItems;
}

/**
 * Determines the programs for the transferred state
 * Also the programs of the parent states are considered.
 * @param snapshot the {@link ActivatedRouteSnapshot}, which represents the current route
 */
function findProgramme(snapshot: ActivatedRouteSnapshot): Programme {
  const programme = RoutingHelpers.getDataOfRouteSnapshot<Programme>('programme', snapshot);
  return !Array.isArray(programme) ? [] : programme;
}
