import { Injectable } from '@angular/core';
import { COMMON } from '@core/constants';
import { DataService } from '@core/data/data.service';
import { Observable } from 'rxjs';

/**
 * Describes the data structure for the passwort forgotten request.
 */
export interface PasswordForgottenRequestData {
  BenutzerId: string;
}

/**
 * Describes the data structure for the request that checks the token validity.
 */
export interface CheckTokenRequestData {
  params: {
    token: string;
  };
}

/**
 * Describes the data structure for the new password request.
 */
export interface NewPasswordRequestData {
  Passwort: string;
  Token: string;
}

/**
 * Describes the public functions of the PasswortForgottenServices.
 */
export interface IPasswordForgottenService {
  postPasswordForgotten(data: PasswordForgottenRequestData): Observable<void>;
  checkToken(data: CheckTokenRequestData): Observable<void>;
  postNewPassword(data: NewPasswordRequestData): Observable<void>;
}

/**
 * This service grants access to the "password forgotten" endpoints of the backend.
 */
@Injectable({
  providedIn: 'root'
})
export class PasswordForgottenService implements IPasswordForgottenService {
  private serviceBase;

  /**
   * Constructor.
   * Sets the [serviceBase]{@link PasswordForgottenService#serviceBase}
   */
  constructor(private dataService: DataService) {
    this.serviceBase = COMMON.API_ROOT + '/portaldaten/password';
  }

  /**
   * Sends the user name to backend for which the password was forgotten.
   * @param data the data which should be send to the backend.
   */
  postPasswordForgotten(data: PasswordForgottenRequestData): Observable<void> {
    return this.dataService.postDataWithParameters<void>(this.serviceBase + '/forgot', data);
  }

  /**
   * Validates the token for setting the new password.
   * @param data the check token data which should be send to the backend.
   */
  checkToken(data: CheckTokenRequestData): Observable<void> {
    return this.dataService.getDataWithParameters<void>(this.serviceBase + '/token/validate', data);
  }

  /**
   * Sends the new password and the validation token to the backend.
   * @param data the new password and validation token.
   */
  postNewPassword(data: NewPasswordRequestData): Observable<void> {
    return this.dataService.postDataWithParameters<void>(this.serviceBase + '/new', data);
  }
}
