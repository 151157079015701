import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MultiselectChangedEvent, MultiselectSettings } from '@iq-angular-libs/portal';
import { Schlachttage } from '@share/filter/filter.interfaces';

@Component({
  selector: 'iq-schlachttage',
  templateUrl: './schlachttage.component.html'
})
export class SchlachttageComponent {
  /**
   * The selectable slaughter days.
   */
  @Input()
  schlachttage: Schlachttage;

  /**
   * The selected slaughter days.
   */
  @Input()
  selectedSchlachttage: Schlachttage;

  /**
   * The globally selected slaughter days.
   */
  @Input()
  activeSchlachttage: Schlachttage;

  /**
   * Event emitter, which carries the changes of the selected
   * slaughter days to the parent component.
   */
  @Output()
  selectedSchlachttageChanged: EventEmitter<Schlachttage>;

  /**
   * Emits the EnterPressed Event of the Multiselect-Component
   */
  @Output()
  emitEnterKeyPressed: EventEmitter<boolean>;

  /**
   * The filter settings for the multiselect element.
   */
  settings: MultiselectSettings;

  /**
   * Constructor.
   */
  constructor() {
    this.schlachttage = [];
    this.selectedSchlachttage = [];
    this.activeSchlachttage = [];

    this.settings = {
      id: 'schlachttag-filter-multiselect',
      enableSearch: true,
      sortProp: 'date',
      sortOrder: 'desc',
      enableDisplayFirstSelectedItem: true
    };

    this.selectedSchlachttageChanged = new EventEmitter<Schlachttage>();
    this.emitEnterKeyPressed = new EventEmitter<boolean>();
  }

  /**
   * Takes the new selected slaughter days and forwards them to the parent component.
   * @param event {@link MultiselectClosedEvent}
   */
  onMultiselectChanged(event: MultiselectChangedEvent): void {
    this.selectedSchlachttageChanged.emit(event.selectedItems as Schlachttage);
  }

  /**
   * Emits the EnterKeyPressed event.
   */
  onEnterKeyPressed(event: boolean) {
    this.emitEnterKeyPressed.emit(event);
  }
}
