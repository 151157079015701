<div class="modal-header">
  <div class="float-left">
    <h4 class="modal-title">{{ 'FILTER.DATUM_FILTER.DATUM_WAEHLEN' | translate }}</h4>
  </div>
  <button type="button" class="close float-right" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body datum-filter-modal">
  <form
    id="datumFilterForm"
    name="datumFilterForm"
    class="form needs-validation"
    novalidate
    [formGroup]="form"
    (keyup.enter)="submit()"
  >
    <div class="filter-mode-menu">
      <div>
        <input
          id="datumfilter-letzte-x-tage-radio"
          type="radio"
          formControlName="SelectedFilterMode"
          [value]="datumFilterMode.LETZTE_X_TAGE"
        />
        <label
          for="datumfilter-letzte-x-tage-radio"
          class="text-bold ml-1"
          [class.text-danger]="submitted && form.invalid && controls['SelectedFilterMode']?.invalid"
        >
          {{ 'FILTER.DATUM_FILTER.LETZTE_X_TAGE' | translate }}
        </label>
      </div>
      <div>
        <input
          id="datumfilter-zeitraum-radio"
          type="radio"
          formControlName="SelectedFilterMode"
          [value]="datumFilterMode.ZEITRAUM"
        />
        <label
          for="datumfilter-zeitraum-radio"
          class="text-bold ml-1"
          [class.text-danger]="submitted && form.invalid && controls['SelectedFilterMode']?.invalid"
        >
          {{ 'FILTER.DATUM_FILTER.ZEITRAUM' | translate }}
        </label>
      </div>
      <div *ngIf="!hideSchlachttage">
        <input
          id="datumfilter-schlachttag-radio"
          type="radio"
          formControlName="SelectedFilterMode"
          [value]="datumFilterMode.SCHLACHTTAGE"
        />
        <label
          for="datumfilter-schlachttag-radio"
          class="text-bold ml-1"
          [class.text-danger]="submitted && form.invalid && controls['SelectedFilterMode']?.invalid"
        >
          {{ 'FILTER.DATUM_FILTER.SCHLACHTTAGE' | translate }}
        </label>
      </div>
    </div>
    <div class="filter-mode-content">
      <div *ngIf="datumInfo.mode === datumFilterMode.LETZTE_X_TAGE">
        <iq-letzte-x-tage [form]="form" [letzteXTage]="datumInfo.letzteXTage" [submitted]="submitted">
        </iq-letzte-x-tage>
      </div>
      <div *ngIf="datumInfo.mode === datumFilterMode.ZEITRAUM">
        <iq-zeitraum [form]="form" [zeitraum]="datumInfo.zeitraum" [submitted]="submitted"> </iq-zeitraum>
      </div>
      <div *ngIf="datumInfo.mode === datumFilterMode.SCHLACHTTAGE && !hideSchlachttage">
        <iq-schlachttage
          [schlachttage]="schlachttage"
          [selectedSchlachttage]="datumInfo.schlachttage"
          [activeSchlachttage]="activeSchlachttage"
          (selectedSchlachttageChanged)="onSelectedSchlachttageChanged($event)"
          (emitEnterKeyPressed)="submit()"
        >
        </iq-schlachttage>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div>
    <button type="button" class="btn iq-reset-filter-button" (click)="resetFilter()">
      {{ 'ALLGEMEIN.FILTER_ZURUECKSETZEN' | translate }}
    </button>
  </div>
  <div class="ml-auto">
    <button type="button" class="btn btn-secondary" (click)="cancel()">{{ 'ALLGEMEIN.ABBRECHEN' | translate }}</button>
    <button type="button" class="btn btn-primary ml-2" (click)="submit()">
      {{ 'ALLGEMEIN.UEBERNEHMEN' | translate }}
    </button>
  </div>
</div>
