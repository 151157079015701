<div class="modal-header">
  <h4 class="modal-title float-left">{{ 'DISCARD_CHANGES_MODAL.DISCARD_TITLE' | translate }}</h4>
  <button type="button" class="close float-right" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div>{{ 'DISCARD_CHANGES_MODAL.DISCARD_NOTE' | translate }}</div>
  <div class="mt-3">{{ 'DISCARD_CHANGES_MODAL.DISCARD_QUESTION' | translate }}</div>
</div>
<div class="modal-footer">
  <div class="ml-auto">
    <button type="button" class="btn btn-secondary" (click)="cancel()">{{ 'ALLGEMEIN.ABBRECHEN' | translate }}</button>
    <button type="button" class="btn btn-primary ml-2" (click)="confirm()">
      {{ 'ALLGEMEIN.VERWERFEN' | translate }}
    </button>
  </div>
</div>
