<button
  type="button"
  class="btn btn-primary btn-sm focus-betriebsstaette"
  (click)="focusBetriebsstaette()"
  [placement]="options.tooltipPlacement"
  [tooltip]="options.tooltipText"
  [container]="'body'"
>
  <i class="iq-icon-font iq-icon-font-betriebsstaetten-add"></i>
</button>
