import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FilterComponent } from '@share/filter/filter-components/filter-component.interfaces';
import { MultiselectChangedEvent, MultiselectClosedEvent, MultiselectSettings } from '@iq-angular-libs/portal';
import { FilterService } from '@share/filter/filter.service';
import { Geschlechter } from '@share/filter/filter.interfaces';
import { GlobalFilterService } from '@core/global-filter/global-filter.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SelectedFilterItemsChangedEvent } from '@share/filter/filter-components/filter-component.events';
import { FilterBereiche } from '@share/filter/filter-bereiche';

@Component({
  selector: 'iq-geschlechter-filter',
  templateUrl: './geschlechter-filter.component.html'
})
export class GeschlechterFilterComponent implements OnInit, OnDestroy, FilterComponent {
  /**
   * The selected genders
   */
  @Input()
  selectedItems: Geschlechter;

  /**
   * The filter section.
   */
  @Input()
  filterBereich: FilterBereiche;

  /**
   * Indicates whether the filter is disabled or not.
   */
  @Input()
  filterDisabled: boolean;

  /**
   * Indicator whether an event should be emitted with each selection.
   */
  @Input()
  updateOnChange: boolean;

  /**
   * Event emitter that fires an event when the filter has changed.
   */
  @Output()
  selectedItemsChanged: EventEmitter<SelectedFilterItemsChangedEvent>;

  /**
   * All available genders.
   */
  geschlechter: Geschlechter;

  /**
   * The active genders.
   */
  activeGeschlechter: Geschlechter;

  /**
   * The settings of the (multiselect component){@link MultiselectButtonComponent}
   */
  settings: MultiselectSettings;

  /**
   * Unsubscribe-Stream.
   * Used in (ngOnDestroy){@link GenderFilterComponent#ngOnDestroy} to close all open subscriptions.
   */
  private unsubscribe$: Subject<void>;

  /**
   * Constructor.
   * @param filterService {@link FilterService}
   */
  constructor(private filterService: FilterService, private globalFilterService: GlobalFilterService) {
    this.geschlechter = [];
    this.activeGeschlechter = [];

    this.settings = {
      id: 'geschlecht-filter-multiselect',
      sortProp: 'displayName',
      enableDisplayFirstSelectedItem: true,
      enableDisplaySelectedShortnames: true,
      buttonClasses: 'btn btn-secondary iq-select-button iq-btn-filter',
      buttonMaxWidth: 250,
      dropdownAppendTo: 'body'
    };

    this.selectedItemsChanged = new EventEmitter<SelectedFilterItemsChangedEvent>();
    this.unsubscribe$ = new Subject<void>();
  }

  /**
   * Initially loads all and all active genders.
   * Registers with GlobalFilterService to be informed about changes of the comercial units.
   */
  ngOnInit() {
    this.getGeschlechter();
    this.getActiveGeschlechter();
    this.subscribeOnGlobalFilterChangedEvents();
  }

  /**
   * Closes the FilterComponentChanged event emitter.
   * Fires an event of the unsubscribe stream and closes it afterwards.
   */
  ngOnDestroy(): void {
    this.selectedItemsChanged.complete();

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Is called as soon as the multiselect selection has changed and the dropdown has closed.
   * @param event {@link MultiselectClosedEvent}
   */
  onMultiselectClosed(event: MultiselectClosedEvent) {
    if (!this.updateOnChange) {
      const selectedFilterItemsChangedEvent = new SelectedFilterItemsChangedEvent(event.selectedItems);
      this.selectedItemsChanged.emit(selectedFilterItemsChangedEvent);
    }
  }

  /**
   * Is called as soon as the multiselect selection has changed.
   * @param event {@link MultiselectChangedEvent}
   */
  onMultiselectChanged(event: MultiselectChangedEvent) {
    if (this.updateOnChange) {
      const selectedFilterItemsChangedEvent = new SelectedFilterItemsChangedEvent(event.selectedItems);
      this.selectedItemsChanged.emit(selectedFilterItemsChangedEvent);
    }
  }

  /**
   * Queries all existing genders of the user from {@link FilterService}.
   */
  private getGeschlechter(): void {
    this.filterService.getGeschlechter(this.filterBereich).subscribe({
      next: (geschlechter: Geschlechter) => {
        this.geschlechter = geschlechter;
      }
    });
  }

  /**
   * Queries all active genders of the user from {@link FilterService}.
   */
  private getActiveGeschlechter(): void {
    this.filterService.getActiveGeschlechter(this.filterBereich).subscribe({
      next: (activeGeschlechter: Geschlechter) => {
        this.activeGeschlechter = activeGeschlechter;
      }
    });
  }

  /**
   * Registers to the comercial units changes stream of the (GlobalFilterServices){@link GlobalFilterService}
   * to reload the active genders in case of changes.
   */
  private subscribeOnGlobalFilterChangedEvents(): void {
    this.globalFilterService
      .getBetriebsstaetteChangedObservable()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.getActiveGeschlechter();
      });
  }
}
