import { Injectable, RendererFactory2, TemplateRef } from '@angular/core';
import { SessionService } from '@core/session/session.service';
import { ComponentLoaderFactory } from 'ngx-bootstrap/component-loader';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { filter } from 'rxjs/operators';

/**
 * This service extends the {@link BsModalService} and is used for
 * modal dialogs in lazy loaded modules. This service was created
 * to create a workaround for this issue:
 * https://github.com/angular/angular/issues/14324#issuecomment-444557664
 *
 * The service still is used to close all modal dialogs created with this service
 * when the session expires.
 */
@Injectable()
export class CustomModalService extends BsModalService {
  constructor(
    private rendererFactory: RendererFactory2,
    clf: ComponentLoaderFactory,
    private sessionService: SessionService
  ) {
    super(rendererFactory, clf, { backdrop: 'static' });

    // Listens for inactive session and closes the modals
    this.sessionService.sessionActive
      .asObservable()
      .pipe(filter(session => session !== null))
      .subscribe({
        next: sessionActive => {
          if (!sessionActive) {
            this.closeAllModals();
          }
        }
      });
  }

  /**
   * Shows the modal dialog with the given component and options.
   * Uses the show function of the BsModalService.
   * @param component the component which should be rendered inside the modal
   * @param modalOptions the modal options
   */
  show(component: string | TemplateRef<any> | any, modalOptions?: ModalOptions): BsModalRef {
    return super.show(component, modalOptions);
  }

  /**
   * Closes all opened modals attached to the service
   */
  private closeAllModals() {
    this.hide(this.config.id);
  }
}
