import { AnimationEvent } from '@angular/animations';
import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { AnzahlTage, FORMAT } from '@core/constants';
import { UserPropertiesBereiche } from '@core/user/user.enums';
import { CurrentUser } from '@core/user/user.interfaces';
import { UserService } from '@core/user/user.service';
import { extractUniqueRegistrierungsnummern, orderCaseUnsensitiv, SortConfig } from '@iq-angular-libs/portal';
import { TranslateService } from '@ngx-translate/core';
import { forEach, orderBy } from 'lodash-es';
import { ISlimScrollEvent, SlimScrollEvent } from 'ngx-slimscroll';
import { Subject } from 'rxjs';
import { filter, finalize, take, takeUntil } from 'rxjs/operators';
import { Widget, WidgetComponent } from '../share/startseite.interfaces';
import { getWidgetBearbeitenContainerAnimation } from '../share/widgets';
import { QsZulassungData } from '../share/widgets.interfaces';
import { WidgetsService } from '../share/widgets.service';

@Component({
  selector: 'iq-qs-zulassung-widget',
  templateUrl: './qs-zulassung-widget.component.html',
  styleUrls: ['./qs-zulassung-widget.component.scss'],
  animations: [getWidgetBearbeitenContainerAnimation(40)]
})
export class QsZulassungWidgetComponent implements OnInit, OnDestroy, WidgetComponent {
  @Input()
  widget: Widget;

  /**
   * Indicates whether the data will be loaded.
   */
  dataLoading = false;

  /**
   * The error message in case of incorrect loading of the data.
   */
  errMessage: string;

  /**
   * Holds QS approval data
   */
  qsZulassungData: QsZulassungData;

  /**
   * Specifies whether the options should be displayed.
   */
  showOptions = false;

  /**
   * The Date Format.
   */
  dateFormat = FORMAT.DATE;

  /**
   * Used for the animation of the options area.
   */
  optionsOpen = false;

  /**
   * The selectable lists with data sets.
   */
  produktProgrammLists = [
    {
      field: 'AblaufendeRegistrierungsnummern',
      title: this.translate.instant('WIDGETS.QS_ABLAUF_ZULASSUNG.ABLAUFENDE_LIST_TITLE')
    },
    {
      field: 'AbgelaufendeRegistrierungsnummern',
      title: this.translate.instant('WIDGETS.QS_ABLAUF_ZULASSUNG.ABGELAUFENE_LIST_TITLE')
    }
  ];

  /**
   * The currently selected list of records.
   */
  selectedProduktProgrammList = this.produktProgrammLists[0];

  /**
   * The unique registration numbers per product program
   */
  produktProgrammListRegistrierungsnummern = {};

  /**
   * Specifies the number of days in the span of which the QA approvals expire.
   */
  anzahlTage: AnzahlTage = AnzahlTage.Dreissig;

  /**
   * Indicates which tab is selected.
   */
  selectedTab: number;

  /**
   * Unsusbscribe-Stream.
   */
  unsubscribe$ = new Subject<void>();

  /**
   * Event emitter for the SlimScroll component.
   */
  slimScrollEvents = new EventEmitter<ISlimScrollEvent>();

  /**
   * The Sort-Config for the tables.
   */
  sortConfig: SortConfig;

  /**
   * The current user.
   */
  private currentUser: CurrentUser;

  /**
   * Constructor
   * @param widgetsService {@link WidgetsService}
   * @param translate {@link TranslateService}
   * @param userService {@link UserService}
   */
  constructor(
    private widgetsService: WidgetsService,
    private translate: TranslateService,
    private userService: UserService
  ) {
    this.selectedTab = 0;
    this.sortConfig = {
      fixSortFields: ['BesitzerId', 'Registrierungsnummer', 'ProduktId'],
      singleSort: true,
      sortFields: [
        {
          sortType: 'ZugelassenBis',
          sortOrder: 'asc'
        }
      ]
    };
    userService
      .getCurrentUser()
      .pipe(take(1))
      .subscribe((currentUser: CurrentUser) => {
        if (
          currentUser &&
          currentUser.userProperties.widgets &&
          currentUser.userProperties.widgets.QSZulassungWidgetAnzahlTage
        ) {
          this.currentUser = currentUser;
          this.anzahlTage = currentUser.userProperties.widgets.QSZulassungWidgetAnzahlTage;
        }
      });
  }

  /**
   * Triggers obtaining the data.
   */
  ngOnInit() {
    this.getData();

    this.widgetsService
      .getWidgetItemResizedObservable()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(event => event.widget.id === this.widget.id)
      )
      .subscribe({
        next: () => {
          const event = new SlimScrollEvent({
            type: 'recalculate'
          });

          this.slimScrollEvents.emit(event);
        }
      });
  }

  /**
   * Next's and completes the Unsubscribe-Stream
   */
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Called as soon as in the options change the number of days.
   * @param anzahlTage The selected number of days
   */
  anzahlTageChanged(anzahlTage: AnzahlTage) {
    this.anzahlTage = anzahlTage;
    if (this.currentUser) {
      this.currentUser.userProperties.widgets.QSZulassungWidgetAnzahlTage = anzahlTage;
      this.userService
        .postUserproperties(UserPropertiesBereiche.WIDGETS, this.currentUser.userProperties)
        .pipe(take(1))
        .subscribe();
    }
    this.getData();
  }

  /**
   * Sets the selected tab and adjusts the selected ProductProgramList accordingly.
   * @param event Click-Event
   * @param selectedTab The selected tab (0 or 1)
   */
  selectTab(event: Event, selectedTab: number) {
    event.preventDefault();
    this.selectedTab = selectedTab;
    this.selectedProduktProgrammList = this.produktProgrammLists[selectedTab];
  }

  /**
   * Called as soon as the animation of the options is finished.
   * Sets the {@link showOptions} accordingly whether the options are now open or closed.
   * @param event AnimationEvent
   */
  onOptionsAnimationDone(event: AnimationEvent) {
    if (event.toState === 'optionsClosed') {
      this.showOptions = false;
    } else {
      this.showOptions = true;
    }
  }

  /**
   * Opens and closes the options area of the widget and shows the options elements.
   */
  toggleOptions() {
    this.optionsOpen = !this.optionsOpen;
  }

  /**
   * Determines the unique registration numbers per product program from the respective comercial units.
   */
  private extractUniqueRegistrierungsummern() {
    forEach(this.produktProgrammLists, (produktPorgramm, index) => {
      this.produktProgrammListRegistrierungsnummern[produktPorgramm.field] = extractUniqueRegistrierungsnummern(
        this.qsZulassungData[produktPorgramm.field]
      );
    });
  }

  /**
   * Sorts the registration data according to the fields 'Zugelassen_Bis' and 'Registrierungsnummer'.
   */
  private orderZulassungsData() {
    forEach(this.produktProgrammLists, (produktProgramm, index) => {
      if (this.qsZulassungData[produktProgramm.field]) {
        this.qsZulassungData[produktProgramm.field] = orderBy(
          this.qsZulassungData[produktProgramm.field],
          [
            this.sortConfig.sortFields[0].sortType,
            ...this.sortConfig.fixSortFields.map(field => orderCaseUnsensitiv(field))
          ],
          new Array(this.sortConfig.fixSortFields.length + 1).fill(
            this.sortConfig.sortFields[0].sortOrder,
            0,
            this.sortConfig.fixSortFields.length + 1
          )
        );
      }
    });
  }

  /**
   * Obtains the data for the widget from the BE.
   */
  private getData() {
    this.errMessage = null;
    this.dataLoading = true;
    this.qsZulassungData = null;

    this.widgetsService
      .getQsAblaufZulassungWidgetData(this.anzahlTage)
      .pipe(
        finalize(() => {
          this.dataLoading = false;
        })
      )
      .subscribe({
        next: (data: QsZulassungData) => {
          if (data) {
            this.qsZulassungData = data;
            this.extractUniqueRegistrierungsummern();
            this.orderZulassungsData();
          }
        },
        error: errMessage => {
          this.errMessage = 'FEHLER.DATEN_NICHT_GELADEN';
        }
      });
  }
}
