import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FilterComponent } from '../../filter-component.interfaces';
import { MinMax } from '../../../uebergeordnete-filter.interfaces';
import { SelectedFilterItemsChangedEvent } from '../../filter-component.events';
import { GlobalFilterService } from '../../../../../core/global-filter/global-filter.service';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, isEqual, isNil } from 'lodash-es';
import { DecimalPipe } from '@angular/common';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { filter as rxjsFilter, take } from 'rxjs/operators';
import { SchlachtgewichtFilterModalComponent } from '../schlachtgewicht-filter-modal/schlachtgewicht-filter-modal.component';
import { FilterBereiche } from '@share/filter/filter-bereiche';

@Component({
  selector: 'iq-schlachtgewicht-filter',
  templateUrl: './schlachtgewicht-filter-button.component.html'
})
export class SchlachtgewichtFilterButtonComponent implements OnInit, OnChanges, OnDestroy, FilterComponent {
  /**
   * The selected slaughter weights
   */
  @Input()
  selectedItems: MinMax;

  /**
   * The filter section.
   */
  @Input()
  filterBereich: FilterBereiche;

  /**
   * Indicates whether the filter is disabled or not.
   */
  @Input()
  filterDisabled: boolean;

  /**
   * Event emitter that fires an event when the filter has changed.
   */
  @Output()
  selectedItemsChanged: EventEmitter<SelectedFilterItemsChangedEvent>;

  /**
   * The button text to be displayed.
   */
  buttonText: string;

  /**
   * The decimal pipe for formatting numbers.
   */
  private decimalPipe: DecimalPipe;

  /**
   * Constructor.
   * @param translate {@link TranslateService}
   * @param globalFilterService {@link GlobalFilterService}
   */
  constructor(
    private translate: TranslateService,
    private modalService: BsModalService,
    private globalFilterService: GlobalFilterService
  ) {
    this.selectedItemsChanged = new EventEmitter<SelectedFilterItemsChangedEvent>();
    this.decimalPipe = new DecimalPipe(this.translate.getDefaultLang());
  }

  /**
   * Initializes the button text with an empty string.
   */
  ngOnInit() {
    this.buttonText = this.formatButtonText(this.selectedItems);
  }

  /**
   * If the filter item is changed, the button text is reformatted.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.buttonText = this.formatButtonText(this.selectedItems);
  }

  /**
   * Closes the FilterComponentChanged event emitter.
   */
  ngOnDestroy(): void {
    this.selectedItemsChanged.complete();
  }

  /**
   * Opens a modal dialog that is used to set the carcass weight.
   */
  openSchlachtgewichtModal(): void {
    const modalOptions: ModalOptions<SchlachtgewichtFilterModalComponent> = {
      backdrop: 'static',
      initialState: {
        ranges: cloneDeep<MinMax>(this.selectedItems)
      }
    };
    const modalRef = this.modalService.show(SchlachtgewichtFilterModalComponent, modalOptions);

    modalRef.content.onClose$
      .asObservable()
      .pipe(
        take(1),
        rxjsFilter((newSchlachtgewicht: MinMax | null) => !isNil(newSchlachtgewicht))
      )
      .subscribe({
        next: (newSchlachtgewicht: MinMax) => {
          const hasChanged = !isEqual(this.selectedItems, newSchlachtgewicht);
          if (hasChanged) {
            const event = new SelectedFilterItemsChangedEvent(newSchlachtgewicht);
            this.selectedItemsChanged.emit(event);
          }
        }
      });
  }

  /**
   * Formats the button text.
   */
  private formatButtonText(schlachtgewicht: MinMax): string {
    let text = '';
    if (!schlachtgewicht || (isNil(schlachtgewicht.min) && isNil(schlachtgewicht.max))) {
      text = this.translate.instant('FILTER.PLACEHOLDER');
    } else {
      if (!isNil(schlachtgewicht.min)) {
        text +=
          this.decimalPipe.transform(schlachtgewicht.min, '1.0-5') + ' ' + this.translate.instant('MASSEINHEITEN.KG');
      } else {
        text += this.translate.instant('FILTER.SCHLACHTGEWICHT_FILTER.OFFEN');
      }

      text += ' - ';

      if (!isNil(schlachtgewicht.max)) {
        text +=
          this.decimalPipe.transform(schlachtgewicht.max, '1.0-5') + ' ' + this.translate.instant('MASSEINHEITEN.KG');
      } else {
        text += this.translate.instant('FILTER.SCHLACHTGEWICHT_FILTER.OFFEN');
      }
    }
    return text;
  }
}
