import { PortalThemeColors } from '@core/theme/theme.interfaces';
import { find, isEmpty, isNil } from 'lodash-es';
import {
  ConvertedDiagrammData,
  DiagrammData,
  DiagrammOptionsSchlachtgewicht,
  DiagrammValue,
  Grenzen,
  LinieValues
} from './chart.interfaces';

/**
 * Determines whether a bar should be colored green, red, green -> red or red -> green.
 * @param value An object that contains slaughter weight classes
 * @param grenzen The optimum range limits
 */
export const getCustomColorForGewichtsklassen = (value: any, grenzen: Grenzen, colors: PortalThemeColors): string => {
  if (isNil(value)) {
    return colors.secondarySchmuckfarbe;
  }
  if (!grenzen.Min && !grenzen.Max) {
    return colors.secondarySchmuckfarbe;
  }
  if (value.from && value.from < grenzen.Min && value.to && value.to > grenzen.Min) {
    return 'gradientMin';
  } else if (value.from && value.from < grenzen.Max && value.to && value.to > grenzen.Max) {
    return 'gradientMax';
  } else if (value.from && value.from >= grenzen.Min && value.to && value.to <= grenzen.Max) {
    return colors.ampelGruenDiagramm;
  }
  return colors.ampelRotDiagramm;
};

/**
 * Converts the diagram data received from the BE into a format understandable by the diagram.
 * @param diagrammData The diagram data obtained from the BE
 */
export const convertDiagrammDatenWithSchlachtgewichtsklassen = (diagrammData: DiagrammData): ConvertedDiagrammData => {
  if (!diagrammData) {
    return;
  }
  const convertedData: ConvertedDiagrammData = {
    balkenData: [],
    linienData: [],
    grenzen: {
      Min: null,
      Max: null,
      Interval1: null,
      Interval2: null,
      Interval3: null
    }
  };

  if (diagrammData.balkendiagramm.Key && diagrammData.balkendiagramm.Values) {
    for (let i = 0; i < diagrammData.balkendiagramm.Values.length; i++) {
      const balken: DiagrammValue = {
        name: diagrammData.balkendiagramm.Values[i].Von ? diagrammData.balkendiagramm.Values[i].Von : '0',
        value: diagrammData.balkendiagramm.Values[i].Wert
      };
      balken.data = {
        from: diagrammData.balkendiagramm.Values[i].Von,
        to: diagrammData.balkendiagramm.Values[i].Bis,
        anzahlTiere: diagrammData.balkendiagramm.Values[i].Anzahl
      };
      convertedData.balkenData.push(balken);

      for (let j = 0; j < diagrammData.liniendiagramm.length; j++) {
        if (isEmpty(convertedData.linienData[j]) || isNil(convertedData.linienData[j])) {
          convertedData.linienData.push({
            name: '',
            color: diagrammData.liniendiagramm[j].Color,
            series: []
          });
        }

        const line: any = find<LinieValues[]>(diagrammData.liniendiagramm[j].Values, (linie: LinieValues) => diagrammData.balkendiagramm.Values[i].Von === linie.Von);

        if (line) {
          convertedData.linienData[j].series.push({
            name: diagrammData.balkendiagramm.Values[i].Von ? diagrammData.balkendiagramm.Values[i].Von : '0',
            value: line.Wert,
            data: {
              from: diagrammData.balkendiagramm.Values[i].Von ? diagrammData.balkendiagramm.Values[i].Von : '0',
              to: diagrammData.balkendiagramm.Values[i].Bis
            }
          });
        } else {
          convertedData.linienData[j].series.push({
            name: diagrammData.balkendiagramm.Values[i].Von ? diagrammData.balkendiagramm.Values[i].Von : '0',
            value: null
          });
        }
      }
    }
  }

  if (diagrammData.grenzen && diagrammData.grenzen.Min && diagrammData.grenzen.Max) {
    convertedData.grenzen = diagrammData.grenzen;
  }

  return convertedData;
};

/**
 * Sets the optimal range in the diagram options if there are limits in the diagram data
 * @param diagrammOptions The diagram options
 * @param diagrammData The converted diagram data
 */
export const setGrenzenInDiagrammOptions = (
  diagrammOptions: DiagrammOptionsSchlachtgewicht,
  diagrammData: ConvertedDiagrammData
) => {
  if (diagrammOptions && diagrammData && diagrammData.grenzen.Min && diagrammData.grenzen.Max) {
    diagrammOptions.optimalbereich = {
      from: diagrammData.grenzen.Min,
      to: diagrammData.grenzen.Max
    };
  }
};

/**
 * Creates the TooltipText for the SG classes
 * @param data The from/to data of a bar
 */
export const bestimmeSgKlassenRangeForTooltip = (data: {
  [key: string]: string | number | boolean | null;
  from: string | number | null;
  to: string | number | null;
}) => {
  let stringValue = '';
  if (data) {
    if (data.from || data.to) {
      stringValue += data.from ? '> ' + data.from : '0';
      stringValue += data.to ? ' - \u2264 ' + data.to : '';
    }
  }
  return stringValue;
};
