import { HttpBackend } from '@angular/common/http';
import { isToennies } from '@core/is-toennies';
import { ITranslationResource, MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

/**
 * Creates a new instance of the {@link MultiTranslateHttpLoader}.
 * @param httpClient
 */
export function HttpTranslateLoaderFactory(_httpBackend: HttpBackend) {
  const resources: ITranslationResource[] = [
    { prefix: '../../assets/i18n/common-lib-multiselect/', suffix: '.json' },
    { prefix: '../../assets/i18n/common-lib-table/', suffix: '.json' },
    { prefix: '../../assets/i18n/stammdatenverwaltung-lib/', suffix: '.json' },
    { prefix: '../../assets/i18n/standard/help/', suffix: '.json' },
    { prefix: '../../assets/i18n/standard/', suffix: '.json' } // overwrite external resources if necessary
  ];

  if (isToennies()) {
    resources.push({ prefix: '../../assets/i18n/toennies/help/', suffix: '.json' });
    resources.push({ prefix: '../../assets/i18n/toennies/', suffix: '.json' });
  }

  return new MultiTranslateHttpLoader(_httpBackend, resources);
}
