<div class="widget-content-container toennies-blog-widget">
  <iq-loading-indicator *ngIf="dataLoading"></iq-loading-indicator>
  <div class="blog-content" *ngIf="!dataLoading">
    <div class="content-wrapper" *ngIf="isBlogPostsLoaded">
      <div class="posts-container">
        <div *ngFor="let post of posts" class="post">
          <div class="post-date">
            <span>{{ post.date | date: 'd MMM' | uppercase }}</span>
          </div>
          <div>
            <a class="post-title" [href]="post.link" target="_blank">{{ post.title }}</a>
            <p class="post-content">{{ post.content }}</p>
          </div>
        </div>
      </div>
      <div class="to-blog">
        <a class="btn blog-btn" href="https://toennies-agrarblog.de/aktuelle-nachrichten" target="_blank">{{
          'WIDGETS.TOENNIES_AGRARBLOG.BUTTON_TEXT' | translate | uppercase
        }}</a>
      </div>
    </div>
    <div class="blog-unavailable" *ngIf="!isBlogPostsLoaded">
      <div class="message-container">
        <span>{{ 'WIDGETS.TOENNIES_AGRARBLOG.BLOG_UNAVAILABLE' | translate }}</span>
      </div>
    </div>
  </div>
</div>
