import { AbstractControl } from '@angular/forms';
import * as dayjs from 'dayjs';
import { dateStringToDate } from '../helper-functions';

/**
 * Validators for the DateComponent.
 */
export class DateComponentValitators {
  /**
   * Checks if the value of the passed input field is in a real date.
   * If no, an error object is returned, otherwise zero.
   * @param control The input field
   */
  public static iqRealDate(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value || control.value === 'DD.MM.YY') {
      return null;
    }

    const date = dateStringToDate(control.value);

    if (!date) {
      return { iqRealDate: control.value };
    }

    return null;
  }

  /**
   * Checks if the value of the passed input field can be converted to a date.
   * If no, an error object is returned, otherwise zero.
   * @param control The input field
   */
  public static iqRequiredDate(control: AbstractControl): { [key: string]: any } | null {
    const isDate = dateStringToDate(control.value);
    return isDate ? null : { iqRequiredDate: control.value };
  }

  /**
   * Returns the Min-Validator function.
   * This checks whether the current value of the passed input field is less than the passed minimum.
   * If yes, an error object is returned, otherwise zero.
   * @param min
   */
  public static iqMinDate(min: Date | null) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (min) {
        const date = dateStringToDate(control.value);

        if (date) {
          const compareDate = dayjs(date);
          const minDate = dayjs(min);

          if (compareDate.isBefore(minDate)) {
            return { iqMinDate: control.value };
          }
        }
      }

      return null;
    };
  }

  /**
   * Returns the Max-Validotor function.
   * This checks whether the current value of the passed input field is greater than the passed maximum.
   * If yes, an error object is returned, otherwise zero.
   * @param max
   */
  public static iqMaxDate(max: Date | null) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (max) {
        const date = dateStringToDate(control.value);

        if (date) {
          const compareDate = dayjs(date);
          const maxDate = dayjs(max);

          if (compareDate.isAfter(maxDate)) {
            return { iqMaxDate: control.value };
          }
        }
      }

      return null;
    };
  }
}
