<div class="iq-middle-box text-center animated fadeInDown">
  <h1>{{ 'FEHLER.LOCALSTORAGE_NICHT_UNTERSTUETZT_TITLE' | translate }}</h1>
  <h3>{{ 'FEHLER.LOCALSTORAGE_NICHT_UNTERSTUETZT_SUBTITLE' | translate }}</h3>
  <div class="mt-4">
    <div class="error-desc"><div [innerHTML]="'FEHLER.LOCALSTORAGE_NICHT_UNTERSTUETZT_HINWEIS' | translate"></div></div>
  </div>
  <div class="mt-4">
    <div class="error-desc">
      <strong>
        <a href (click)="reloadPage($event)">
          <i class="fa fa-refresh mr-2" aria-hidden="true"> </i>
          <span>{{ 'FEHLER.PORTAL_NEU_LADEN' | translate }}</span>
        </a>
      </strong>
    </div>
  </div>
</div>
