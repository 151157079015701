<div class="input-group" [formGroup]="inputFormGroup">
  <input
    #dateInput
    type="text"
    class="form-control"
    [tabIndex]="tabIndex"
    [formControlName]="inputFormControlName"
    [class.is-invalid]="
      submitted !== false && inputFormGroup.invalid && inputFormGroup.controls[inputFormControlName].invalid
    "
    (click)="onClick($event)"
    (focus)="onFocus($event)"
    (blur)="onBlur($event)"
    (keydown.tab)="onTab($event)"
    (keydown.shift.tab)="onShiftTab($event)"
    (keydown.enter)="onEnter($event)"
    (keydown.backspace)="onBackspace($event)"
    (keydown.meta.a)="onMetaA($event)"
    (keydown.arrowleft)="onShiftTab($event)"
    (keydown.arrowright)="onTab($event)"
    (keydown.arrowup)="onArrowUp($event)"
    (keydown.arrowdown)="onArrowDown($event)"
  />
  <div
    class="input-group-append"
    id="button-addon"
    *ngIf="showResetButton !== false || showDatePickerButton !== false"
    [class.is-invalid]="
      submitted !== false && inputFormGroup.invalid && inputFormGroup.controls[inputFormControlName].invalid
    "
  >
    <span
      #dp="bsDatepicker"
      bsDatepicker
      id="hiddenDatepickerSpan"
      [bsConfig]="datePickerOptions"
      [bsValue]="datePickerDate"
      [minDate]="minDate"
      [maxDate]="maxDate"
      (bsValueChange)="onDatePickerValueChanged($event)"
    >
    </span>
    <button
      class="btn btn-secondary reset-btn"
      type="button"
      tabindex="-1"
      *ngIf="showResetButton !== false"
      (click)="resetInput()"
    >
      &times;
    </button>
    <button
      class="btn btn-secondary datepicker-btn"
      type="button"
      tabindex="-1"
      *ngIf="showDatePickerButton !== false"
      [attr.aria-expanded]="dp.isOpen"
      (click)="dp.toggle()"
    >
      <i class="fa fa-calendar" aria-hidden="true"></i>
    </button>
  </div>
</div>
