import {
  AfterContentChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ISlimScrollEvent, SlimScrollEvent } from 'ngx-slimscroll';
import { Subject } from 'rxjs';
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { Widget, WidgetComponent } from '../share/startseite.interfaces';
import { InfoWidgetButtons } from '../share/widgets.enums';
import { InfoWidgetData, NewsItems } from '../share/widgets.interfaces';
import { WidgetsService } from '../share/widgets.service';

@Component({
  selector: 'iq-info-widget',
  templateUrl: './info-widget.component.html',
  styleUrls: ['./info-widget.component.scss']
})
export class InfoWidgetComponent implements OnInit, OnDestroy, AfterContentChecked, WidgetComponent {
  /**
   * The structure information of the widget.
   */
  @Input()
  widget: Widget;

  /**
   * The Dashboard-Conainer
   */
  @ViewChild('supportContainer')
  private supportDiv: ElementRef;

  /**
   * The news of the news widget determined by the BE.
   */
  newsData: NewsItems = [];

  /**
   * The file view data determined by the BE
   */
  dateneinsichtData: string[] | null = [];

  /**
   * Indicates whether the data will be loaded.
   */
  dataLoading = false;

  /**
   * The Widget Button Enum.
   */
  infoWidgetButtonEnum = InfoWidgetButtons;

  /**
   * Indicates which button is selected
   */
  selectedButton: InfoWidgetButtons = InfoWidgetButtons.Kurier;

  /**
   * Helper variable for the support view, indicates whether the business hours are displayed next to or below the contact data.
   */
  maximizeCol = false;

  /**
   * Event emitter for the SlimScroll component.
   */
  slimScrollEvents = new EventEmitter<ISlimScrollEvent>();

  /**
   * Unsusbscribe-Stream.
   */
  unsubscribe$ = new Subject<void>();

  /**
   * Stores the current width of the dashboard container
   */
  private currentSupportDivWidth: number;

  /**
   * Constructor.
   * @param widgetsService {@link WidgetsService}
   */
  constructor(private widgetsService: WidgetsService) {}

  /**
   * Triggers the loading of the news widget data
   */
  ngOnInit() {
    this.getData();

    this.widgetsService
      .getWidgetItemResizedObservable()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(event => event.widget.id === this.widget.id)
      )
      .subscribe({
        next: () => {
          const event = new SlimScrollEvent({
            type: 'recalculate'
          });

          this.slimScrollEvents.emit(event);
        }
      });
  }

  /**
   * Checks if the container width of the support tab has changed after Angular performed a ContentCheck.
   * If yes, save the current width.
   */
  ngAfterContentChecked() {
    if (this.supportDiv && this.supportDiv.nativeElement) {
      const divElement: HTMLDivElement = this.supportDiv.nativeElement;

      if (this.currentSupportDivWidth !== divElement.clientWidth) {
        this.currentSupportDivWidth = divElement.clientWidth;
        this.maximizeCol = this.currentSupportDivWidth < 400;
      }
    }
  }

  /**
   * Fires an event of the unsubscribe stream and closes it afterwards.
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Updates the selected button of the widget
   */
  updateWidget(selectedButton: InfoWidgetButtons) {
    this.selectedButton = selectedButton;
  }

  /**
   * Gets the news of the news widget from the WidgetService.
   */
  private getData(): void {
    this.dataLoading = true;
    this.newsData = [];
    this.dateneinsichtData = [];

    this.widgetsService
      .getInfoWidgetData()
      .pipe(
        finalize(() => {
          this.dataLoading = false;
        })
      )
      .subscribe({
        next: (data: InfoWidgetData) => {
          if (data) {
            this.newsData = data.News;
            this.dateneinsichtData = data.DateneinsichtBenutzernamen;
          }
        },
        error: () => {}
      });
  }
}
