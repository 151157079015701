<div class="widget-content-container salmonellen-kategorie-widget">
  <iq-loading-indicator *ngIf="dataLoading"></iq-loading-indicator>
  <div *ngIf="errMessage && !dataLoading">
    <h4>{{ errMessage | translate }}</h4>
  </div>
  <div *ngIf="!dataLoading">
    <iq-single-select-navigation
      class="mb-1"
      *ngIf="selectData"
      [selectData]="selectData"
      (selectionChanged)="onSelectionChanged($event)"
    >
    </iq-single-select-navigation>
    <div class="tacho-data" *ngIf="selectedBetriebsstaette">
      <iq-salmo-tacho-chart [score]="selectedBetriebsstaette.ProzentPositiveProben"></iq-salmo-tacho-chart>
      <div class="tacho-information-main mb-1">
        <div class="text-center">
          <strong>{{ 'WIDGETS.SALMO_TACHO.KATEGORIE' | translate }}: </strong>
          {{ selectedBetriebsstaette.QsKategorie | qsCategory }}
        </div>
      </div>
      <div class="text-center">
        <iq-details-aufrufen-button
          [routeInformation]="[
            'portal/salmonellen/betriebsstaette',
            selectedBetriebsstaette.Registrierungsnummer,
            selectedBetriebsstaette.Einstufungsdatum,
            selectedBetriebsstaette.ProduktId,
            'BesitzerName',
            'asc'
          ]"
          [selectedRegistrierungsnummer]="selectedBetriebsstaette.Registrierungsnummer"
          rubrikTitelTranslationKey="WIDGETS.SALMO_TACHO.SALMONELLENMONITORING"
          buttonText="{{ 'WIDGETS.SALMO_TACHO.DETAILS' | translate }}"
        >
        </iq-details-aufrufen-button>
      </div>
    </div>
  </div>
</div>

<!-- Edit widget -->
<div class="widget-bearbeiten-container disabled"></div>
