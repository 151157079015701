import { Component } from '@angular/core';
import { CellComponent } from '@iq-angular-libs/portal';

@Component({
  selector: 'iq-befund-table-cell',
  templateUrl: './befund-table-cell.component.html'
})
export class BefundTableCellComponent implements CellComponent {
  /**
   * The comercial units with the findings data
   */
  data: { Befunde: string[]; InSoVorhanden: boolean };

  constructor() {}
}
