import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { select } from 'd3-selection';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'g[iq-charts-line]',
  template: `
    <svg:path
      class="line"
      [attr.d]="initialPath"
      [attr.fill]="fill"
      [attr.stroke]="stroke"
      [attr.stroke-width]="strokeWidth"
      [attr.stroke-dasharray]="strokeDashArray"
    />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IqLineComponent implements OnChanges {
  @Input()
  path;
  @Input()
  stroke;
  @Input()
  strokeWidth = '1.5px';
  @Input()
  strokeDashArray = 0;
  @Input()
  fill = 'none';
  @Input()
  animations = true;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  select = new EventEmitter();

  initialized = false;
  initialPath: string;

  constructor(private element: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.initialized) {
      this.initialized = true;
      this.initialPath = this.path;
    } else {
      this.updatePathEl();
    }
  }

  updatePathEl(): void {
    const node = select(this.element.nativeElement).select('.line');

    if (this.animations) {
      node
        .transition()
        .duration(750)
        .attr('d', this.path);
    } else {
      node.attr('d', this.path);
    }
  }
}
