import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '@core/user/user.service';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { finalize, takeUntil } from 'rxjs/operators';
import { LocalStorageService } from '@mattlewis92/angular-2-local-storage';
import { LOCALSTORAGE } from '@core/local-storage/local-storage.constants';
import { NewEmailRequestData } from '@core/user/user.interfaces';

@Component({
  selector: 'iq-change-email-modal',
  templateUrl: './change-email-modal.component.html',
  styleUrls: ['./change-email-modal.component.scss']
})
export class ChangeEmailModalComponent implements OnInit, OnDestroy {
  /**
   * Specifies whether the setting of an e-mail address is forced or not.
   */
  @Input()
  mustValidateEmail: boolean;

  submitted: boolean;

  submitting: boolean;

  /**
   * Specifies if the confirmation link was already requested
   */
  linkRequested = false;

  /**
   * Defines the seconds a user has to wait till the dialog closes
   */
  seconds: number;

  /**
   * Subject which informs if the dialog was confirmed or canceled
   */
  onClose$: Subject<boolean>;

  form: FormGroup;

  /**
   * Stores the regular expression for the e-mail validation
   */
  private readonly regex;

  /**
   * Stores the instance of the timer, which is counting down the seconds
   */
  private timerSubscription: Subscription | null;

  /**
   * Subject which stops the timer when an event is emitted
   */
  private timerTakeUntil$: Subject<void>;

  constructor(
    private bsModalRef: BsModalRef,
    private translate: TranslateService,
    private toastr: ToastrService,
    private localStorageService: LocalStorageService,
    private userService: UserService
  ) {
    const part1 = '(?:[a-z0-9!#$%&\\\'*+\\/=?^_\`{|}~-]+(?:\\\\.[a-z0-9!#$%&\\\'*+\\/=?^_\`{|}~-]+)*|"';
    const part2 =
      '(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@';
    const part3 = '(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9]';
    const part4 = '(?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}';
    const part5 = '(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:';
    const part6 =
      '(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1fx21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\\\])';

    this.regex = new RegExp(part1 + part2 + part3 + part4 + part5 + part6);
  }

  /**
   * Convenience-Getter, for easier form control access.
   */
  get controls() {
    return this.form.controls;
  }

  ngOnInit() {
    const controls = {
      password: new FormControl('', this.getPasswordFormControlValidators()),
      email: new FormControl('', [Validators.required, Validators.pattern(this.regex)])
    };
    this.form = new FormGroup(controls);

    this.timerSubscription = null;
    this.timerTakeUntil$ = new Subject<void>();

    this.submitted = false;
    this.submitting = false;
    this.linkRequested = false;
    this.seconds = 0;
    this.onClose$ = new Subject<boolean>();

    this.checkForStoredEmail();
  }

  ngOnDestroy(): void {
    this.onClose$.complete();
  }

  /**
   * Forwards the request for the new email validation to the UserService.
   * Afterwards the view will be changed to display additional informations beside the entered e-mail.
   * After 30 seconds the user can close the dialog. If case of an invalid e-mail an error toast will
   * be displayed.
   */
  submit(): void {
    this.submitted = true;

    if (this.submitted && this.linkRequested) {
      this.localStorageService.remove(LOCALSTORAGE.MODAL.EMAIL_VALIDIEREN);
      this.closeModal();
    } else if (this.form.valid) {
      let postEmail$: Observable<void>;
      this.submitting = true;

      if (this.mustValidateEmail) {
        const data: NewEmailRequestData = {
          Email: this.form.controls['email'].value
        };

        postEmail$ = this.userService.postUserEmailToSetNewEmail(data);
      } else {
        const data: NewEmailRequestData = {
          Passwort: this.form.controls['password'].value,
          Email: this.form.controls['email'].value
        };

        postEmail$ = this.userService.postUserEmailToChangeEmail(data);
      }

      postEmail$
        .pipe(
          finalize(() => {
            this.submitting = false;
          })
        )
        .subscribe({
          next: () => {
            this.linkRequested = true;
            this.startTimer();
            this.localStorageService.set(LOCALSTORAGE.MODAL.EMAIL_VALIDIEREN, this.form.controls['email'].value);
          },
          error: err => {
            this.localStorageService.remove(LOCALSTORAGE.MODAL.EMAIL_VALIDIEREN);

            if (err && err.headers && err.headers.get('ErrorCode')) {
              switch (err.headers.get('ErrorCode')) {
              case '0014':
                this.toastr.error(
                  this.translate.instant('EXCEPTIONS.0014'),
                  this.translate.instant('ALLGEMEIN.TOASTS.FEHLER')
                );
                this.form.controls['password'].setErrors({ password: true });
                this.linkRequested = false;
                break;
              case '0015':
                this.toastr.error(
                  this.translate.instant('EXCEPTIONS.0015'),
                  this.translate.instant('ALLGEMEIN.TOASTS.FEHLER')
                );
                this.form.controls['email'].setErrors({ email: true });
                this.linkRequested = false;
                break;
              case '0025':
                this.toastr.error(
                  this.translate.instant('EXCEPTIONS.0025'),
                  this.translate.instant('ALLGEMEIN.TOASTS.FEHLER')
                );
                this.closeModal();
                break;
              default:
                this.toastr.error(
                  this.translate.instant('EMAIL_VALIDIEREN.TOASTS.FEHLER_EMAIL_VALIDIERUNG'),
                  this.translate.instant('ALLGEMEIN.TOASTS.FEHLER')
                );
                this.resetForm();
              }
            } else {
              this.toastr.error(
                this.translate.instant('EMAIL_VALIDIEREN.TOASTS.FEHLER_EMAIL_VALIDIERUNG'),
                this.translate.instant('ALLGEMEIN.TOASTS.FEHLER')
              );
              this.resetForm();

              if (!this.mustValidateEmail) {
                this.closeModal(true);
              }
            }
          }
        });
    } else {
      this.toastr.error(
        this.translate.instant('EMAIL_VALIDIEREN.TOASTS.BITTE_EINGABE_UEBERPRUEFEN'),
        this.translate.instant('ALLGEMEIN.TOASTS.FEHLER')
      );
    }
  }

  /**
   * Closes the dialog if the user isn't forced to validate his
   * e-mail address first.
   */
  cancel() {
    if (!this.mustValidateEmail) {
      this.closeModal(true);
    }
  }

  /**
   * Resets the form and stops the timer.
   */
  private resetForm(): void {
    this.submitted = false;
    this.linkRequested = false;
    this.seconds = 0;

    this.form.reset({ password: '', email: '' });
    this.stopTimer();
  }

  /**
   * Starts the timer and sets the seconds to 30.
   */
  private startTimer(): void {
    if (!this.timerSubscription && this.mustValidateEmail) {
      this.seconds = 30;
      this.timerTakeUntil$ = new Subject<void>();
      this.timerSubscription = timer(1000, 1000)
        .pipe(takeUntil(this.timerTakeUntil$))
        .subscribe(value => {
          this.seconds--;

          if (this.seconds === 0) {
            this.stopTimer();
          }
        });
    }
  }

  private stopTimer(): void {
    if (this.timerSubscription) {
      this.timerTakeUntil$.next();
      this.timerTakeUntil$.complete();
      this.timerSubscription = null;
    }
  }

  /**
   * Checks if an valid e-mail address is stored inside the localstage and sets it into the form.
   * Afterwards sets the flags (submitted){@link ValidateEmailModalComponent#submitted} and
   * (linkRequested){@link ValidateEmailModalComponent#linkRequested} to true.
   * Starts the timer.
   */
  private checkForStoredEmail(): void {
    const storedEmail = this.localStorageService.get(LOCALSTORAGE.MODAL.EMAIL_VALIDIEREN);
    if (storedEmail && this.regex.test(storedEmail)) {
      this.form.controls['email'].setValue(storedEmail);
      this.submitted = true;
      this.linkRequested = true;
      this.startTimer();
    }
  }

  /**
   * Closes the modal dialog.
   * @param dismiss Specifies if the modal was canceled or dismissed
   */
  private closeModal(dismiss: boolean = false): void {
    this.onClose$.next(!dismiss);
    this.bsModalRef.hide();
  }

  /**
   * Returns the validators for the password FormControl.
   * If the attribute mustValidateEmail true, there is no need to return validators,
   * because the password field is hidden - else the "required" validator will be returned.
   */
  private getPasswordFormControlValidators(): ValidatorFn[] {
    return !this.mustValidateEmail ? [Validators.required] : [];
  }
}
