import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FilterComponent } from '../filter-component.interfaces';
import { SelectedFilterItemsChangedEvent } from '../filter-component.events';
import { MultiselectChangedEvent, MultiselectClosedEvent, MultiselectSettings } from '@iq-angular-libs/portal';
import { Subject } from 'rxjs';
import { FilterService } from '@share/filter/filter.service';
import { GlobalFilterService } from '@core/global-filter/global-filter.service';
import { takeUntil } from 'rxjs/operators';
import { FilterBereiche } from '@share/filter/filter-bereiche';
import { WurfNummern } from '@share/filter/filter.interfaces';

@Component({
  selector: 'iq-wurf-nummern-filter',
  templateUrl: './wurf-nummern-filter.component.html'
})
export class WurfNummernFilterComponent implements OnInit, OnDestroy, FilterComponent {
  /**
   * The selected litter numbers
   */
  @Input()
  selectedItems: WurfNummern;

  /**
   * The filter section.
   */
  @Input()
  filterBereich: FilterBereiche;

  /**
   * Indicates whether the filter is disabled or not.
   */
  @Input()
  filterDisabled: boolean;

  /**
   * Indicator whether an event should be emitted with each selection.
   */
  @Input()
  updateOnChange: boolean;

  /**
   * Event emitter that fires an event when the filter has changed.
   */
  @Output()
  selectedItemsChanged: EventEmitter<SelectedFilterItemsChangedEvent>;

  /**
   * The settings of the (multiselect component){@link MultiselectButtonComponent}
   */
  settings: MultiselectSettings;

  /**
   * All available litter numbers
   */
  wurfNummern: WurfNummern;

  /**
   * The active litter numbers
   */
  activeWurfNummern: WurfNummern;

  /**
   * Unsubscribe-Stream.
   * Used in (ngOnDestroy){@link WurfNummernFilterComponent#ngOnDestroy} to
   * close all open subscriptions.
   */
  private unsubscribe$: Subject<void>;

  /**
   * Constructor
   * @param filterService filter service
   * @param globalFilterService globale filter service
   */
  constructor(private filterService: FilterService, private globalFilterService: GlobalFilterService) {
    this.wurfNummern = [];
    this.activeWurfNummern = [];

    this.settings = {
      id: 'wurfnummern-filter-multiselect',
      sortProp: 'displayName',
      enableDisplayFirstSelectedItem: true,
      enableDisplaySelectedShortnames: false,
      buttonClasses: 'btn btn-secondary iq-select-button iq-btn-filter',
      buttonMaxWidth: 250,
      dropdownAppendTo: 'body'
    };

    this.selectedItemsChanged = new EventEmitter<SelectedFilterItemsChangedEvent>();
    this.unsubscribe$ = new Subject<void>();
  }

  /**
   * Initially loads all and all active throw numbers.
   * Registers with GlobalFilterService to be informed about changes at the sites.
   */
  ngOnInit() {
    this.getWurfNummern();
    this.getActiveWurfNummern();
    this.subscribeOnGlobalFilterChangedEvents();
  }

  /**
   * Closes the FilterComponentChanged event emitter.
   * Fires an event of the unsubscribe stream and closes it afterwards.
   */
  ngOnDestroy(): void {
    this.selectedItemsChanged.complete();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Is called as soon as the multiselect selection has changed and the dropdown has closed.
   * @param event {@link MultiselectClosedEvent}
   */
  onMultiselectClosed(event: MultiselectClosedEvent) {
    if (!this.updateOnChange) {
      const filterComponentChangedEvent = new SelectedFilterItemsChangedEvent(event.selectedItems);
      this.selectedItemsChanged.emit(filterComponentChangedEvent);
    }
  }

  /**
   * Is called as soon as the multiselect selection has changed.
   * @param event {@link MultiselectChangedEvent}
   */
  onMultiselectChanged(event: MultiselectChangedEvent) {
    if (this.updateOnChange) {
      const selectedFilterItemsChangedEvent = new SelectedFilterItemsChangedEvent(event.selectedItems);
      this.selectedItemsChanged.emit(selectedFilterItemsChangedEvent);
    }
  }

  /**
   * Queries all existing litter numbers of the user from {@link FilterService}.
   */
  private getWurfNummern(): void {
    this.filterService.getWurfNummern(this.filterBereich).subscribe({
      next: (wurfNummern: WurfNummern) => {
        this.wurfNummern = wurfNummern;
      }
    });
  }

  /**
   * Queries all active litter numbers of the user from {@link FilterService}.
   */
  private getActiveWurfNummern(): void {
    this.filterService.getActiveWurfNummern(this.filterBereich).subscribe({
      next: (activeWurfNummern: WurfNummern) => {
        this.activeWurfNummern = activeWurfNummern;
      }
    });
  }

  /**
   * Registers to the (GlobalFilterServices){@link GlobalFilterService} site changed
   * stream to reload the active throw numbers in case of site changes.
   */
  private subscribeOnGlobalFilterChangedEvents(): void {
    this.globalFilterService
      .getBetriebsstaetteChangedObservable()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.getActiveWurfNummern();
      });
  }
}
