<button
  type="button"
  class="btn btn-primary"
  (click)="focusBetriebsstaetten()"
  [class.focus-betriebsstaetten-text]="options.buttonText && options.buttonText.length > 0"
  [class.disabled]="options.disable"
  [isDisabled]="options.tooltipDisabled"
  [placement]="options.tooltipPlacement"
  [tooltip]="options.tooltipText"
>
  <span
    [class.focus-betriebsstaetten-text]="options.buttonText && options.buttonText.length > 0"
    *ngIf="options.buttonText"
    >{{ options.buttonText }}</span
  >
  <i
    *ngIf="options.buttonText"
    class="iq-icon-font iq-icon-font-betriebsstaetten-group focus-betriebsstaetten
         button-text ml-2"
    aria-hidden="true"
  ></i>
  <i
    *ngIf="!options.buttonText"
    class="iq-icon-font iq-icon-font-betriebsstaetten-group focus-betriebsstaetten"
    aria-hidden="true"
  ></i>
</button>
