import { ModuleWithProviders, NgModule } from '@angular/core';
import { NewBackendModalComponent } from './lockscreen/new-backend-modal/new-backend-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { ChangePasswordModalComponent } from './lockscreen/change-password-modal/change-password-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LaddaModule } from 'angular2-ladda';
import { SharedModule } from '@share/shared.module';
import { ChangeEmailModalComponent } from './lockscreen/change-email-modal/change-email-modal.component';
import { LicencesService } from '@core/licences/licences.service';
import { SessionService } from '@core/session/session.service';
import { AuthService } from '@core/auth/auth.service';
import { AuthGuard } from '@core/auth/auth.guard';
import { CanDeactivateGuard } from '@core/can-deactivate/can-deactivate-guard.service';
import { CredentialGuard } from '@core/credential/credential.guard';
import { DataService } from '@core/data/data.service';
import { GlobalFilterService } from '@core/global-filter/global-filter.service';
import { LocalStorageGuard } from '@core/local-storage/local-storage.guard';
import { LocalStorageService } from '@mattlewis92/angular-2-local-storage';
import { LoggingService } from '@core/logging/logging.service';
import { ResetService } from '@core/reset/reset.service';
import { UserService } from '@core/user/user.service';
import { WebAccessGuard } from '@core/web-access/web-access.guard';
import { WebAccessService } from '@core/web-access/web-access.service';
import { LockscreenService } from '@core/lockscreen/lockscreen.service';
import { ServerErrorModalComponent } from './server-error-modal/server-error-modal.component';

@NgModule({
  imports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule, LaddaModule, SharedModule],
  declarations: [NewBackendModalComponent, ChangePasswordModalComponent, ChangeEmailModalComponent, ServerErrorModalComponent]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        AuthService,
        DataService,
        GlobalFilterService,
        LicencesService,
        LocalStorageService,
        LockscreenService,
        LoggingService,
        ResetService,
        SessionService,
        UserService,
        WebAccessService,
        AuthGuard,
        CanDeactivateGuard,
        CredentialGuard,
        LocalStorageGuard,
        WebAccessGuard
      ]
    };
  }
}
