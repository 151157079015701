import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FilterRangesModalBaseComponent } from '../../filter-ranges-modal-base.component';

@Component({
  selector: 'iq-tz-kalk-filter-modal',
  templateUrl: './tz-kalk-filter-modal.component.html',
  styleUrls: ['./tz-kalk-filter-modal.component.scss']
})
export class TzKalkFilterModalComponent extends FilterRangesModalBaseComponent implements AfterViewInit {
  /**
   * The element reference to the first input field to focus it.
   */
  @ViewChild('tzKalkFocusInput', { static: true })
  tzKalkMinInput: ElementRef;

  constructor(bsModalRef: BsModalRef) {
    super(bsModalRef);
  }

  /**
   * Focuses the first input when the modal is opened after the view is initialized.
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.tzKalkMinInput && this.tzKalkMinInput.nativeElement) {
        this.tzKalkMinInput.nativeElement.focus();
        this.tzKalkMinInput.nativeElement.select();
      }
    });
  }
}
