import { Injectable } from '@angular/core';
import {
  EtzSegment,
  Segment,
  SegmentData,
  Segmente,
  SegmentEditedEvent,
  SegmentsChangedEvent,
  SoEtmSegment
} from '@share/filter/segment.interfaces';
import {
  DatumInfo,
  IqFilterItem,
  IqFilterItems,
  MinMax,
  UebergeordneteFilterChangedEvent,
  Zeitraum
} from '@share/filter/uebergeordnete-filter.interfaces';
import { LOCALSTORAGE } from '@core/local-storage/local-storage.constants';
import { COMMON, MENUPUNKTE } from '@core/constants';
import { Observable, of, Subject, throwError } from 'rxjs';
import { FilterBereiche } from '@share/filter/filter-bereiche';
import { next } from '@core/next-observable';
import { cloneDeep, filter, forEach, isArray, isEqual, isNil } from 'lodash-es';
import { GlobalFilterService } from '@core/global-filter/global-filter.service';
import { catchError, filter as rxjsFilter, switchMap, tap } from 'rxjs/operators';
import { FilterBetriebsstaette } from '@share/betriebsstaette.interface';
import { LocalStorageService } from '@mattlewis92/angular-2-local-storage';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';
import { SegmentQueryService } from '@share/filter/segment-query.service';
import { FilterQuery, SegmentsQuery } from '@share/filter/segment-query.interfaces';
import { FilterService } from '@share/filter/filter.service';
import { SegmentTotal, SegmentTotals } from '@share/filter/filter-backend.interfaces';
import { UserService } from '@core/user/user.service';
import { CurrentUser } from '@core/user/user.interfaces';
import { take } from 'rxjs/operators';
import { FilterListTypes, Geschlechter, Schlachtbetriebe } from '@share/filter/filter.interfaces';
import { WebAccessService } from '@core/web-access/web-access.service';
import { UebergeordneteFilterService } from '@share/filter/uebergeordnete-filter.service';
import { ActivatedRoute, IsActiveMatchOptions, NavigationEnd, Router, Event } from '@angular/router';
import { RoutingHelpers } from '@core/routing/routing-helpers';
import { SegmentToggleService } from '@share/filter/segment-toggle.service';
import { UserPropertiesBereiche } from '@core/user/user.enums';
import { createRandomColor, ThemeService } from '@iq-angular-libs/portal';
import { PortalThemeColors } from '@core/theme/theme.interfaces';

export interface ISegmentService {
  getSegmentsChangedObservable(): Observable<SegmentsChangedEvent>;
  getActiveSegmentChangedObservable(): Observable<FilterBereiche>;
  getSegmentEditedObservable(): Observable<SegmentEditedEvent>;
  getSegments(filterBereich: FilterBereiche): Observable<Segmente>;
  getActiveSegment(filterBereich): Observable<Segment | null>;
  getActiveSegments(filterBereich: FilterBereiche): Observable<Segmente | null>;
  getFilterQueryForActiveSegment(filterBereich: FilterBereiche): Observable<FilterQuery>;
  getFilterQueryForSegment(segment: Segment, filterBereich: FilterBereiche): FilterQuery;
  toggleActiveSegment(segment: Segment, filterBereich: FilterBereiche): void;
  createEmptySegment(filterBereich): Segment | null;
  addSegment(segment: Segment, filterBereich: FilterBereiche, broadcast: boolean): Observable<void>;
  editSegment(segment: Segment, filterBereich: FilterBereiche, broadcast: boolean): Observable<Segment>;
  deleteSegment(segment: Segment, filterBereich: FilterBereiche, broadcast: boolean): void;
  setMultiselectMode(multiselect: boolean, filterBereich: FilterBereiche): void;
  resetSegmentPropertiesForFilters(newSelectedFilters: IqFilterItems, filterBereich: FilterBereiche): void;
  setSegments(newSegments: Segmente, filterBereich: FilterBereiche, broadcast: boolean): Observable<void>;
  deactivateSegmente(filterBereich: FilterBereiche): void;
  getSelectedZeitraum(filterBereich: FilterBereiche): Observable<Zeitraum>;
  getSelectedGeschlechter(filterBereich: FilterBereiche): Observable<Geschlechter>;
  getSelectedSchlachtbetriebe(filterBereich: FilterBereiche): Observable<Schlachtbetriebe>;
  resetSegment(segment: Segment, filterBereich: FilterBereiche): void;
  reset(): void;
}

@Injectable({
  providedIn: 'root'
})
export class SegmentService implements ISegmentService {
  /**
   * Holds the data for the respective area under consideration
   */
  private currentData: SegmentData | null;

  /**
   * Template for an empty filter with min-max values.
   */
  private emptyMinMaxObject: MinMax;

  /**
   * Template for an empty date filter.
   */
  private emptyDatumInfo: DatumInfo;

  /**
   * Holds the data for the slaughter data area
   */
  private soData: SegmentData;

  /**
   * Holds the data for the ETM area
   */
  private etmData: SegmentData;

  /**
   * Holds the data for the ETC area
   */
  private etzData: SegmentData;

  /**
   * Subject, which fires an internal event if the segments have changed.
   */
  private segmentsChanged$: Subject<SegmentsChangedEvent>;

  /**
   * Subject that fires a public event if the active segments have changed.
   */
  private activeSegmentChanged$: Subject<FilterBereiche>;

  /**
   * Subject that fires a public event if a segment was edited.
   */
  private segmentEdited$: Subject<SegmentEditedEvent>;

  /**
   * Holds the URL of the previous route.
   */
  private previousUrl: string;

  /**
   * Holds the URL of the current route.
   */
  private currentUrl: string;

  /**
   * Constructor.
   * @param router
   * @param activatedRoute
   * @param translate
   * @param localStorageService
   * @param userService
   * @param globalFilterService
   * @param filterService
   * @param uebergeordneteFilterService
   * @param segmentQueryService
   * @param segmentToggleService
   * @param webAccessService
   * @param themeService
   */
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private globalFilterService: GlobalFilterService,
    private filterService: FilterService,
    private uebergeordneteFilterService: UebergeordneteFilterService,
    private segmentQueryService: SegmentQueryService,
    private segmentToggleService: SegmentToggleService,
    private webAccessService: WebAccessService,
    private themeService: ThemeService<PortalThemeColors>
  ) {
    this.currentData = null;

    this.emptyMinMaxObject = {
      min: null,
      max: null
    };

    this.emptyDatumInfo = {
      mode: null,
      schlachttage: null,
      zeitraum: null,
      letzteXTage: null
    };

    this.segmentsChanged$ = new Subject<SegmentsChangedEvent>();
    this.activeSegmentChanged$ = new Subject<FilterBereiche>();
    this.segmentEdited$ = new Subject<SegmentEditedEvent>();

    this.currentUrl = '';

    this.initSoData();
    this.initEtmData();
    this.initEtzData();

    this.subscribeOnGlobalFilterChangedEvents();
    this.subscribeOnUebergeordneteFilterChangedEvents();
    this.subscribeOnSegmentsChangedEvent();
    this.subscribeOnSegmentToggleEvents();
    this.subscribeOnRouterEvents();
    this.subscribeOnThemeChangedEvents();
  }

  /**
   * Returns the event stream for changing the segments as
   * observable.
   */
  getSegmentsChangedObservable(): Observable<SegmentsChangedEvent> {
    return this.segmentsChanged$.asObservable().pipe(rxjsFilter(event => event.public));
  }

  /**
   * Returns the event stream for changing the active segment as
   * observable back.
   */
  getActiveSegmentChangedObservable(): Observable<FilterBereiche> {
    return this.activeSegmentChanged$.asObservable();
  }

  /**
   * Returns the event stream for editing a segment as
   * observable back.
   */
  getSegmentEditedObservable(): Observable<SegmentEditedEvent> {
    return this.segmentEdited$.asObservable();
  }

  /**
   * Returns a promise for the segments. If the segments have not been loaded yet, they will be
   * fetched from localstorage and enriched with count values from the backend
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  getSegments(filterBereich: FilterBereiche): Observable<Segmente> {
    return this.setVariablesForFilterBereich(filterBereich).pipe(
      // TODO: remove getRegistrierungsnummern ???
      switchMap(() => this.globalFilterService.getRegistrierungsnummern()),
      switchMap((betriebsstaetten: FilterBetriebsstaette[]) => {
        this.setVariablesForFilterBereich(filterBereich);

        if (this.currentData.segments !== null) {
          return next();
        } else {
          this.currentData.segments = this.localStorageService.get(this.currentData.localStorageSegmentsKey);

          if (!this.currentData.segments) {
            return this.addDefaultSegment(filterBereich, true);
          } else {
            return this.queryTotalsForSegments(this.currentData.segments, filterBereich);
          }
        }
      }),
      switchMap(() => {
        this.setVariablesForFilterBereich(filterBereich);
        return of(this.currentData.segments);
      })
    );
  }

  /**
   * Gibt das aktive (ausgewählte) Segment zurück
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  getActiveSegment(filterBereich): Observable<Segment | null> {
    return this.setVariablesForFilterBereich(filterBereich).pipe(
      switchMap(() => this.getDeactivatedSegment(filterBereich)),
      switchMap((deactivatedSegment: Segment) => {
        this.setVariablesForFilterBereich(filterBereich);

        if (deactivatedSegment) {
          return of(deactivatedSegment);
        } else {
          const activeSegments = filter(this.currentData.segments, { selected: true });
          if (Array.isArray(activeSegments) && activeSegments.length === 1) {
            return of(activeSegments[0]);
          } else {
            return of(null);
          }
        }
      })
    );
  }

  /**
   * Returns the active (selected) segments
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  getActiveSegments(filterBereich: FilterBereiche): Observable<Segmente | null> {
    return this.setVariablesForFilterBereich(filterBereich).pipe(
      switchMap(() => this.getDeactivatedSegment(filterBereich)),
      switchMap((deactivatedSegment: Segment) => {
        this.setVariablesForFilterBereich(filterBereich);

        if (deactivatedSegment) {
          return of([deactivatedSegment]);
        } else {
          const activeSegments = filter(this.currentData.segments, { selected: true });
          if (Array.isArray(activeSegments) && activeSegments.length > 0) {
            return of(activeSegments);
          } else {
            return of(null);
          }
        }
      }),
      catchError((err: Error) => of(null))
    );
  }

  /**
   * Creates a filter query for the active segment.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  getFilterQueryForActiveSegment(filterBereich: FilterBereiche): Observable<FilterQuery> {
    return this.setVariablesForFilterBereich(filterBereich).pipe(
      switchMap(() => this.getActiveSegment(filterBereich)),
      switchMap((activeSegment: Segment) => {
        const filterQuery = this.segmentQueryService.getQueryForSegment(activeSegment, filterBereich);
        return of(filterQuery);
      })
    );
  }

  /**
   * Creates a filter query for the passed segment
   */
  getFilterQueryForSegment(segment: Segment, filterBereich: FilterBereiche): FilterQuery {
    return this.segmentQueryService.getQueryForSegment(segment, filterBereich);
  }

  /**
   * Sets the active (selected) segment
   * @param segment New Active Segment
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   * @param broadcast Specifies whether an event should be fired after saving the changed segments.
   */
  toggleActiveSegment(segment: Segment, filterBereich: FilterBereiche, broadcast: boolean = true): void {
    this.setVariablesForFilterBereich(filterBereich);

    if (this.currentData.segments) {
      const segmentIndex = (this.currentData.segments as any[]).indexOf(segment);

      if (this.currentData.multiselectMode === true) {
        const selectedSegmentsWithoutSegmentToToggle = filter(this.currentData.segments, (tmpSegment: Segment) => tmpSegment.id !== segment.id && tmpSegment.selected === true);

        if (selectedSegmentsWithoutSegmentToToggle.length > 0) {
          this.currentData.segments[segmentIndex].selected = !this.currentData.segments[segmentIndex].selected;
        } else {
          this.currentData.segments[segmentIndex].selected = true;
        }

        this.activeSegmentChanged$.next(filterBereich);
      } else {
        if (this.currentData.segments.length > 1) {
          (this.currentData.segments as any[]).forEach((tmpSegment: Segment) => {
            tmpSegment.selected = false;
          });
        }

        if (segmentIndex > -1) {
          this.currentData.segments[segmentIndex].selected = true;
          this.activeSegmentChanged$.next(filterBereich);
        }
      }
      this.saveSegments(filterBereich, broadcast, false);
    }
  }

  /**
   * Adds the passed segment to the internal array segments, enriches the new segment with number values (totals)
   * and stores the segments in localstorage
   * @param segment The new segment
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   * @param broadcast Specifies whether an event should be fired after saving the changed segments.
   */
  addSegment(segment: Segment, filterBereich: FilterBereiche, broadcast: boolean = true): Observable<void> {
    return this.setVariablesForFilterBereich(filterBereich).pipe(
      switchMap(() => {
        this.setVariablesForFilterBereich(filterBereich);

        if (isNil(this.currentData.segments)) {
          this.currentData.segments = [];
        }

        if (this.currentData.segments.length < COMMON.MAX_SEGMENTE_ANZAHL) {
          segment.id = this.generateSegmentId();
          (this.currentData.segments as any[]).push(segment);

          return this.queryTotalsForSegments(segment, filterBereich);
        } else {
          return throwError('SegmentService->addSegment: Too many segments in list');
        }
      }),
      switchMap(() => {
        this.setVariablesForFilterBereich(filterBereich);

        if (this.currentData.segments.length === 1 && !this.currentData.segments[0].selected) {
          this.toggleActiveSegment(segment, filterBereich, false);
        }

        this.saveSegments(filterBereich, broadcast, false);

        return next();
      })
    );
  }

  /**
   * Replaces the corresponding segment in the internal array segments with the transferred segment,
   * Enriches the new segment with totals and stores the segments in localstorage
   * @param segment The segment to edit.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   * @param broadcast Specifies whether an event should be fired after saving the changed segments.
   */
  editSegment(segment: Segment, filterBereich: FilterBereiche, broadcast: boolean = true): Observable<Segment> {
    return this.setVariablesForFilterBereich(filterBereich).pipe(
      switchMap(() => {
        const segmentToEdit = this.findSegment(segment.id, filterBereich);
        if (segmentToEdit) {
          this.adoptSegmentValues(segment, segmentToEdit, filterBereich);
          const index = (this.currentData.segments as any[]).indexOf(segmentToEdit);
          if (index > -1) {
            this.currentData.segments[index] = segmentToEdit;
            return this.queryTotalsForSegments(segmentToEdit, filterBereich);
          } else {
            return throwError('SegmentService->editSegment: No Segment found to edit');
          }
        }
      }),
      switchMap(() => {
        const segmentEditedEvent: SegmentEditedEvent = {
          filterBereich: filterBereich,
          segment: segment
        };

        this.saveSegments(filterBereich, broadcast, false);
        this.segmentEdited$.next(segmentEditedEvent);
        return of(segment);
      })
    );
  }

  /**
   * Removes the passed segment from the internal array segments. Stores the segments in localstorage.
   * If the deleted segment was the only one selected, the first existing segment is selected.
   * Consider that in multiselect mode the selection does not need to be changed.
   * @param segment The segment to be deleted.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   * @param broadcast Specifies whether an event should be fired after saving the changed segments.
   */
  deleteSegment(segment: Segment, filterBereich: FilterBereiche, broadcast: boolean = true): void {
    if (!segment) {
      return;
    }

    this.setVariablesForFilterBereich(filterBereich);
    const segmentsWithoutSegmentToDelete = filter(this.currentData.segments, (tmpSegment: Segment) => tmpSegment.id !== segment.id);

    if (segment.selected) {
      const selectedSegments = filter(segmentsWithoutSegmentToDelete, { selected: true });
      if (selectedSegments.length === 0 && segmentsWithoutSegmentToDelete.length > 0) {
        this.toggleActiveSegment(segmentsWithoutSegmentToDelete[0], filterBereich, false);
      }
    }

    const segmentEditedEvent: SegmentEditedEvent = {
      filterBereich: filterBereich,
      segment: segment
    };

    this.currentData.segments = segmentsWithoutSegmentToDelete;
    this.saveSegments(filterBereich, broadcast, false);
    this.segmentEdited$.next(segmentEditedEvent);
  }

  /**
   * Enables or disables the multiselect mode for segments.
   * If the multiselect mode is deactivated, all segments except one are deselected.
   * @param multiselect - If true the multiselect mode is activated.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  setMultiselectMode(multiselect: boolean, filterBereich: FilterBereiche): void {
    if (filterBereich) {
      this.setVariablesForFilterBereich(filterBereich);

      if (multiselect) {
        this.currentData.multiselectMode = true;
      } else {
        this.getActiveSegments(filterBereich)
          .pipe(
            tap((activeSegments: Segmente) => {
              this.setVariablesForFilterBereich(filterBereich);

              if (activeSegments) {
                if (activeSegments.length > 0) {
                  for (let i = activeSegments.length - 1; i >= 1; i--) {
                    activeSegments[i].selected = false;
                  }
                } else {
                  if (Array.isArray(this.currentData.segments) && this.currentData.segments.length > 0) {
                    this.currentData.segments[0].selected = true;
                  }
                }
              } else {
                if (Array.isArray(this.currentData.segments) && this.currentData.segments.length > 0) {
                  this.currentData.segments[0].selected = true;
                }
              }

              this.currentData.multiselectMode = false;
              this.saveSegments(filterBereich, true, false);
            })
          )
          .subscribe();
      }
    }
  }

  /**
   * Sets the properties that are selected by the parent filters,
   * for all segments.
   * Determines the new totals of the segments.
   * @param filterItems The new selected filters of the respective filter area.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  resetSegmentPropertiesForFilters(filterItems: IqFilterItems, filterBereich: FilterBereiche): void {
    forEach(filterItems, (selectedFilter: IqFilterItem) => {
      this.resetProperty(selectedFilter.segmentProperty, filterBereich);
    });

    this.saveSegments(filterBereich, true, false);
    this.queryTotalsForSegments(this.currentData.segments, filterBereich).subscribe();
  }

  /**
   * Accepts the passed filter sets.
   * @param newSegments The new segments of the respective filter area.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   * @param broadcast Specifies whether an event should be fired after setting the new segments.
   */
  setSegments(
    newSegments: Segmente,
    filterBereich: FilterBereiche,
    broadcast: boolean = true,
    selectFirstSegment: boolean = true
  ): Observable<void> {
    return this.setVariablesForFilterBereich(filterBereich).pipe(
      switchMap(() => this.getDeactivatedSegment(filterBereich)),
      switchMap((deactivatedSegment: Segment) => {
        this.setVariablesForFilterBereich(filterBereich);

        if (!deactivatedSegment) {
          const splicedSegments = cloneDeep(newSegments).splice(0, COMMON.MAX_SEGMENTE_ANZAHL);
          this.currentData.segments = splicedSegments;

          if (selectFirstSegment && isArray(this.currentData.segments) && this.currentData.segments.length > 0) {
            this.currentData.segments[0].selected = true;
          }

          this.queryTotalsForSegments(splicedSegments, filterBereich)
            .pipe(
              tap(() => {
                this.saveSegments(filterBereich, true, true);
              })
            )
            .subscribe();
        }

        return next();
      })
    );
  }

  /**
   * Deactivates the existing segments and sets
   * the default segment.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  deactivateSegmente(filterBereich: FilterBereiche): void {
    this.setVariablesForFilterBereich(filterBereich)
      .pipe(
        switchMap(() => {
          this.currentData.segments = null;
          return this.addDefaultSegment(filterBereich, true);
        })
      )
      .subscribe();
  }

  /**
   * Determines the time period that includes the active segments and the parent filters.
   * @param filterBereich The respective area (SO, ETM, ...) in which the segments are used
   */
  getSelectedZeitraum(filterBereich: FilterBereiche): Observable<Zeitraum> {
    return this.getActiveSegments(filterBereich).pipe(
      switchMap((activeSegments: Segmente) => of(this.segmentQueryService.getZeitraumForSegmente(activeSegments, filterBereich)))
    );
  }

  /**
   * Determines the genders that comprise the active segments and the parent filters.
   * @param filterBereich The respective area (SO, ETM, ...) in which the segments are used
   */
  getSelectedGeschlechter(filterBereich: FilterBereiche): Observable<Geschlechter> {
    return this.getActiveSegments(filterBereich).pipe(
      switchMap((activeSegments: Segmente) => of(this.segmentQueryService.getGeschlechterForSegmente(activeSegments, filterBereich)))
    );
  }

  /**
   * Determines the slaughterhouses for the transferred segments
   * @param filterBereich The respective area (SO, ETM, ...) in which the segment is used.
   */
  getSelectedSchlachtbetriebe(filterBereich: FilterBereiche): Observable<Schlachtbetriebe> {
    return this.getActiveSegments(filterBereich).pipe(
      switchMap((activeSegments: Segmente) => of(this.segmentQueryService.getSchlachtbetriebeForSegmente(activeSegments, filterBereich)))
    );
  }

  /**
   * Creates an empty segment depending on the filter area and returns it.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  createEmptySegment(filterBereich): Segment | null {
    let emptySegment: Segment | null;

    switch (filterBereich) {
    case FilterBereiche.ETZ:
      emptySegment = <EtzSegment>{
        id: null,
        name: '',
        color: createRandomColor(),
        geschlechter: [],
        betriebsstaetten: [],
        sauRassen: [],
        sauNummern: [],
        wurfNummern: [],
        eberRassen: [],
        eberNummern: [],
        schlachtgewichte: cloneDeep(this.emptyMinMaxObject),
        tzKalk: cloneDeep(this.emptyMinMaxObject),
        tierAlter: cloneDeep(this.emptyMinMaxObject),
        datumInfo: cloneDeep(this.emptyDatumInfo),
        total: 0,
        filteredTotal: 0,
        selected: false
      };
      break;

    case FilterBereiche.SO:
    case FilterBereiche.ETM:
      emptySegment = <SoEtmSegment>{
        id: null,
        name: '',
        color: createRandomColor(),
        geschlechter: [],
        betriebsstaetten: [],
        schlachtbetriebe: [],
        einsenderzeichen: [],
        schlachtgewichte: cloneDeep(this.emptyMinMaxObject),
        tzKalk: cloneDeep(this.emptyMinMaxObject),
        datumInfo: cloneDeep(this.emptyDatumInfo),
        total: 0,
        filteredTotal: 0,
        selected: false
      };
      break;

    default:
      emptySegment = null;
    }

    return emptySegment;
  }

  /**
   * Resets the passed segment to the values of the default segment.
   * Gets the number of pigs. Saves the segments.
   * Fires an event that the segment was edited.
   * @param segment The segment to be reset.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  resetSegment(segment: Segment, filterBereich: FilterBereiche): void {
    if (segment) {
      const defaultSegment = this.getDefaultSegment(filterBereich);
      this.adoptSegmentValues(defaultSegment, segment, filterBereich);

      this.queryTotalsForSegments(segment, filterBereich)
        .pipe(
          tap(() => {
            const segmentEditedEvent: SegmentEditedEvent = {
              filterBereich: filterBereich,
              segment: segment
            };

            this.saveSegments(filterBereich, true, false);
            this.segmentEdited$.next(segmentEditedEvent);
          })
        )
        .subscribe();
    }
  }

  /**
   * Sets the segment array to null and the multiselect mode to false
   */
  reset(): void {
    // general data
    this.currentData = null;

    // slaughter data
    this.soData.segments = null;
    this.soData.multiselectMode = false;

    // ETM data
    this.etmData.segments = null;
    this.etmData.multiselectMode = false;

    // ETZ data
    this.etzData.segments = null;
    this.etzData.multiselectMode = false;
  }

  /**
   * Initializes the data for the slaughter data area.
   */
  private initSoData(): void {
    this.soData = {
      segments: null,
      deactivatedSegment: null,
      multiselectMode: false,
      defaultSegmentColor: this.themeService.getActiveThemeColors().secondarySchmuckfarbe,
      localStorageSegmentsKey: LOCALSTORAGE.FILTER.SEGMENT.SO,
      userPropertiesKey: UserPropertiesBereiche.SO
    };
  }

  /**
   * Initializes the data for the ETM area.
   */
  private initEtmData(): void {
    this.etmData = {
      segments: null,
      deactivatedSegment: null,
      multiselectMode: false,
      defaultSegmentColor: this.themeService.getActiveThemeColors().secondarySchmuckfarbe,
      localStorageSegmentsKey: LOCALSTORAGE.FILTER.SEGMENT.ETM,
      userPropertiesKey: UserPropertiesBereiche.ETM
    };
  }

  /**
   * Initializes the data for the ETC area.
   */
  private initEtzData(): void {
    this.etzData = {
      segments: null,
      deactivatedSegment: null,
      multiselectMode: false,
      defaultSegmentColor: this.themeService.getActiveThemeColors().primarySchmuckfarbe,
      localStorageSegmentsKey: LOCALSTORAGE.FILTER.SEGMENT.ETZ,
      userPropertiesKey: UserPropertiesBereiche.ETZ
    };
  }

  /**
   * Checks if the passed section is included in the (filter sections){@link FilterBereiche}
   * and assigns the associated data to the general data.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  private setVariablesForFilterBereich(filterBereich: FilterBereiche): Observable<void> {
    switch (filterBereich) {
    case FilterBereiche.SO:
      this.currentData = this.soData;
      break;

    case FilterBereiche.ETM:
      this.currentData = this.etmData;
      break;

    case FilterBereiche.ETZ:
      this.currentData = this.etzData;
      break;

    default:
      this.currentData = null;
      const errorMessage = 'SegmentService: Wrong Filter Bereich';
      console.error(errorMessage);
      return throwError(errorMessage);
    }

    return next();
  }

  /**
   * Synchronizes the related filters for the passed filter range, provided that
   * exist for this related filter.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  private synchronizeCoherentSegments(filterBereich: FilterBereiche): void {
    switch (filterBereich) {
    case FilterBereiche.SO:
      this.etmData.segments = cloneDeep(this.soData.segments);
      this.saveSegments(FilterBereiche.ETM, false, false);
      break;

    case FilterBereiche.ETM:
      this.soData.segments = cloneDeep(this.etmData.segments);
      this.saveSegments(FilterBereiche.SO, false, false);
      break;

    default:
    }
  }

  /**
   * Searches the passed segment in the internal segments array
   * @param segmentId Segment ID to be searched for
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  private findSegment(segmentId: string, filterBereich: FilterBereiche): Segment | null {
    this.setVariablesForFilterBereich(filterBereich);

    const foundSegmente = <Segmente>filter(this.currentData.segments, (segment: Segment) => segment.id === segmentId);

    if (Array.isArray(foundSegmente) && foundSegmente.length > 0) {
      return foundSegmente[0];
    }

    return null;
  }

  /**
   * Stores the segments in localstorage
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   * @param broadcast Specifies whether a {@link SegmentsChangedEvent} is fired.
   */
  private saveSegments(
    filterBereich: FilterBereiche,
    broadcast: boolean = true,
    publicBroadcast: boolean = false
  ): void {
    this.setVariablesForFilterBereich(filterBereich);
    this.localStorageService.set(this.currentData.localStorageSegmentsKey, this.currentData.segments);

    if (broadcast) {
      const segmentsChangedEvent: SegmentsChangedEvent = {
        filterBereich: filterBereich,
        segments: this.currentData.segments,
        public: publicBroadcast
      };
      this.segmentsChanged$.next(segmentsChangedEvent);
    }
  }

  /**
   * Returns a default segment if the segment option
   * was deactivated in the user settings.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  private getDeactivatedSegment(filterBereich: FilterBereiche): Observable<Segment> | Observable<null> {
    return this.setVariablesForFilterBereich(filterBereich).pipe(
      switchMap(() => this.userService.getCurrentUser()),
      take(1),
      switchMap((currentUser: CurrentUser) => {
        this.setVariablesForFilterBereich(filterBereich);

        if (currentUser.userProperties[this.currentData.userPropertiesKey].SegmenteAktiviert) {
          this.currentData.deactivatedSegment = null;
          return of(null);
        } else {
          if (!this.currentData.deactivatedSegment) {
            this.currentData.deactivatedSegment = this.createEmptySegment(filterBereich);
            this.currentData.deactivatedSegment.id = this.generateSegmentId();
            this.currentData.deactivatedSegment.name = this.translate.instant('SEGMENTE.BETRIEBSDATEN');
            this.currentData.deactivatedSegment.color = this.currentData.defaultSegmentColor;
            this.currentData.deactivatedSegment.selected = true;
          }
          return of(this.currentData.deactivatedSegment);
        }
      })
    );
  }

  /**
   * Enriches the already existing segments with number values (totals)
   * @param totals The totals for the respective segment of the respective filter range.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  private applyTotals(totals: SegmentTotals | null, filterBereich: FilterBereiche): void {
    this.setVariablesForFilterBereich(filterBereich);

    if (totals && isArray(totals)) {
      totals.forEach((total: SegmentTotal) => {
        const segment = this.findSegment(total.Id, filterBereich);

        if (segment) {
          const segmentIndex = (this.currentData.segments as any[]).indexOf(segment);
          if (segmentIndex > -1) {
            this.currentData.segments[segmentIndex].total = total.Total;
            this.currentData.segments[segmentIndex].filteredTotal = total.FilteredTotal;
          }
        } else {
          console.error('Could not find segment with id: ' + total.Id + ' to apply totals');
        }
      });
    }
  }

  /**
   * Calls the method (getTotals){@link FilterService#getTotals}, which retrieves totals from the backend.
   * Sets the values afterwards.
   * @param segment the segments
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  private queryTotalsForSegments(segment: Segmente | Segment, filterBereich: FilterBereiche): Observable<void> {
    if (!isNil(segment)) {
      const segmentsQuery: SegmentsQuery = this.segmentQueryService.getQueryForSegments(segment, filterBereich);

      return this.filterService.getTotals(segmentsQuery, filterBereich).pipe(
        switchMap((totals: SegmentTotals) => {
          this.applyTotals(totals, filterBereich);
          return next();
        })
      );
    } else {
      return next();
    }
  }

  /**
   * Generates a new segment ID as uuid v4 with timestamp
   */
  private generateSegmentId(): string {
    return uuidv4() + '-' + Math.floor(Date.now() / 1000);
  }

  /**
   * Adds a new default segment to the internal array 'segments',
   * calls addSegment({name: 'Default'}) for that.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   * @param broadcast Specifies whether an event should be fired after saving the changed segments.
   */
  private addDefaultSegment(filterBereich, broadcast: boolean = true): Observable<void> {
    const defaultSegment = this.getDefaultSegment(filterBereich);
    return this.addSegment(defaultSegment, filterBereich, broadcast);
  }

  /**
   * Returns a new default segment.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  private getDefaultSegment(filterBereich): Segment | null {
    this.setVariablesForFilterBereich(filterBereich);

    const emptySegment = this.createEmptySegment(filterBereich);
    if (emptySegment) {
      emptySegment.name = this.translate.instant('SEGMENTE.BETRIEBSDATEN');
      emptySegment.color = this.currentData.defaultSegmentColor;
      emptySegment.selected = true;
    }

    return emptySegment;
  }

  /**
   * Transfers all data of a segment except the id to another segment.
   * @param fromSegment The segment from which the data is to be read.
   * @param toSegment  The segment to which the data is to be transferred.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  private adoptSegmentValues(fromSegment: Segment, toSegment: Segment, filterBereich: FilterBereiche): void {
    toSegment.name = fromSegment.name;
    toSegment.color = fromSegment.color;
    toSegment.total = fromSegment.total;
    toSegment.filteredTotal = fromSegment.filteredTotal;
    toSegment.selected = fromSegment.selected;
    toSegment.geschlechter = fromSegment.geschlechter;
    toSegment.schlachtgewichte = cloneDeep(fromSegment.schlachtgewichte);
    toSegment.betriebsstaetten = fromSegment.betriebsstaetten;
    toSegment.datumInfo = fromSegment.datumInfo;
    toSegment.tzKalk = fromSegment.tzKalk;

    switch (filterBereich) {
    case FilterBereiche.ETZ:
      (toSegment as EtzSegment).tierAlter = (fromSegment as EtzSegment).tierAlter;
      (toSegment as EtzSegment).sauRassen = (fromSegment as EtzSegment).sauRassen;
      (toSegment as EtzSegment).sauNummern = (fromSegment as EtzSegment).sauNummern;
      (toSegment as EtzSegment).wurfNummern = (fromSegment as EtzSegment).wurfNummern;
      (toSegment as EtzSegment).eberRassen = (fromSegment as EtzSegment).eberRassen;
      (toSegment as EtzSegment).eberNummern = (fromSegment as EtzSegment).eberNummern;
      break;

    case FilterBereiche.SO:
    case FilterBereiche.ETM:
      (toSegment as SoEtmSegment).schlachtbetriebe = (fromSegment as SoEtmSegment).schlachtbetriebe;
      (toSegment as SoEtmSegment).einsenderzeichen = (fromSegment as SoEtmSegment).einsenderzeichen;
      break;

    default:
    }
  }

  /**
   * Resets the passed property for all segments.
   * @param resetProperty - the property.
   * @param filterBereich The respective area (SO, ETM, ...) where the filters are used.
   */
  private resetProperty(resetProperty: string, filterBereich: FilterBereiche): void {
    this.setVariablesForFilterBereich(filterBereich);

    forEach(this.currentData.segments, (segment: Segment) => {
      let resetValue: FilterListTypes | DatumInfo | MinMax;
      switch (resetProperty) {
      case 'datumInfo':
        resetValue = cloneDeep(this.emptyDatumInfo);
        break;
      case 'schlachtgewichte':
      case 'tierAlter':
      case 'tzKalk':
        resetValue = cloneDeep(this.emptyMinMaxObject);
        break;
      default:
        resetValue = [];
      }
      segment[resetProperty] = resetValue;
    });
  }

  /**
   * Subscribes to the event stream of the (GlobalFilterServices){@link GlobalFilterService}.
   * Queries for the filter areas (depending on web access) the totals of the existing segments
   * and sets these.
   */
  private subscribeOnGlobalFilterChangedEvents(): void {
    this.globalFilterService.getBetriebsstaetteChangedObservable().subscribe(() => {
      this.webAccessService
        .hasWebAccess(MENUPUNKTE.SCHLACHTDATEN.SCHLACHTDATEN)
        .pipe(
          rxjsFilter((hasWebAccess: boolean) => hasWebAccess),
          tap(() => {
            this.setVariablesForFilterBereich(FilterBereiche.SO);
            this.queryTotalsForSegments(this.currentData.segments, FilterBereiche.SO).subscribe();
          })
        )
        .subscribe();

      this.webAccessService
        .hasWebAccess(MENUPUNKTE.ETM.ETM)
        .pipe(
          rxjsFilter((hasWebAccess: boolean) => hasWebAccess),
          tap(() => {
            this.setVariablesForFilterBereich(FilterBereiche.ETM);
            this.queryTotalsForSegments(this.currentData.segments, FilterBereiche.ETM).subscribe();
          })
        )
        .subscribe();

      this.webAccessService
        .hasWebAccess(MENUPUNKTE.ETZ.ETZ)
        .pipe(
          rxjsFilter((hasWebAccess: boolean) => hasWebAccess),
          tap(() => {
            this.setVariablesForFilterBereich(FilterBereiche.ETZ);
            this.queryTotalsForSegments(this.currentData.segments, FilterBereiche.ETZ).subscribe();
          })
        )
        .subscribe();
    });
  }

  /**
   * Subscribes to the event stream of the (UebergeordneteFilterServices){@link Parent UebergeordneteFilterServices}.
   * Queries and sets the totals of the existing segments for the passed filter range.
   */
  private subscribeOnUebergeordneteFilterChangedEvents(): void {
    this.uebergeordneteFilterService
      .getUebergeordneteFilterChangedObservable()
      .subscribe((event: UebergeordneteFilterChangedEvent) => {
        this.setVariablesForFilterBereich(event.filterBereich);
        this.queryTotalsForSegments(this.currentData.segments, event.filterBereich).subscribe();
      });
  }

  /**
   * Subscribes to the (Segment Changed Event Stream){@link SegmentsChangedEvent}.
   * Synchronizes the SO and ETM segments with each other depending on the filter range as soon as they are different.
   */
  private subscribeOnSegmentsChangedEvent(): void {
    const params: IsActiveMatchOptions = {
      paths: 'subset',
      queryParams: 'subset',
      fragment: 'ignored',
      matrixParams: 'ignored'
    };

    this.segmentsChanged$
      .asObservable()
      .pipe(
        rxjsFilter((event: SegmentsChangedEvent) => event.filterBereich === FilterBereiche.SO),
        rxjsFilter((event: SegmentsChangedEvent) => this.router.isActive('portal/schlachtdaten', params)),
        rxjsFilter((event: SegmentsChangedEvent) => !isEqual(this.soData.segments, this.etmData.segments)),
        tap((event: SegmentsChangedEvent) => this.synchronizeCoherentSegments(event.filterBereich))
      )
      .subscribe();

    this.segmentsChanged$
      .asObservable()
      .pipe(
        rxjsFilter((event: SegmentsChangedEvent) => event.filterBereich === FilterBereiche.ETM),
        rxjsFilter((event: SegmentsChangedEvent) => this.router.isActive('portal/etm', params)),
        rxjsFilter((event: SegmentsChangedEvent) => !isEqual(this.soData.segments, this.etmData.segments)),
        tap((event: SegmentsChangedEvent) => this.synchronizeCoherentSegments(event.filterBereich))
      )
      .subscribe();
  }

  /**
   * Subscribes to the segment toggled event stream of (SegmentToggleSerivces){@link SegmentToggleService}.
   * Deletes all active segments and adds for a default segment when the segments are disabled.
   */
  private subscribeOnSegmentToggleEvents(): void {
    this.segmentToggleService
      .getSegmentsToggledObservable(FilterBereiche.SO)
      .pipe(
        rxjsFilter((segmentsActivated: boolean) => !segmentsActivated),
        switchMap(() => this.webAccessService.hasWebAccess(MENUPUNKTE.SCHLACHTDATEN.SCHLACHTDATEN)),
        rxjsFilter(hasWebzugriff => hasWebzugriff),
        switchMap(() => {
          this.setVariablesForFilterBereich(FilterBereiche.SO);
          this.currentData.segments = null;
          return this.addDefaultSegment(FilterBereiche.SO);
        }),
        tap(() => {
          this.setVariablesForFilterBereich(FilterBereiche.SO);

          const event: SegmentsChangedEvent = {
            filterBereich: FilterBereiche.SO,
            segments: this.currentData.segments,
            public: true
          };
          this.segmentsChanged$.next(event);
        })
      )
      .subscribe();

    this.segmentToggleService
      .getSegmentsToggledObservable(FilterBereiche.ETM)
      .pipe(
        rxjsFilter((segmentsActivated: boolean) => !segmentsActivated),
        switchMap(() => this.webAccessService.hasWebAccess(MENUPUNKTE.ETM.ETM)),
        rxjsFilter(hasWebzugriff => hasWebzugriff),
        switchMap(() => {
          this.setVariablesForFilterBereich(FilterBereiche.ETM);
          this.currentData.segments = null;
          return this.addDefaultSegment(FilterBereiche.ETM);
        }),
        tap(() => {
          this.setVariablesForFilterBereich(FilterBereiche.ETM);

          const event: SegmentsChangedEvent = {
            filterBereich: FilterBereiche.ETM,
            segments: this.currentData.segments,
            public: true
          };
          this.segmentsChanged$.next(event);
        })
      )
      .subscribe();

    this.segmentToggleService
      .getSegmentsToggledObservable(FilterBereiche.ETZ)
      .pipe(
        rxjsFilter((segmentsActivated: boolean) => !segmentsActivated),
        switchMap(() => this.webAccessService.hasWebAccess(MENUPUNKTE.ETZ.ETZ)),
        rxjsFilter(hasWebzugriff => hasWebzugriff),
        switchMap(() => {
          this.setVariablesForFilterBereich(FilterBereiche.ETZ);
          this.currentData.segments = null;
          return this.addDefaultSegment(FilterBereiche.ETZ);
        }),
        tap(() => {
          this.setVariablesForFilterBereich(FilterBereiche.ETZ);

          const event: SegmentsChangedEvent = {
            filterBereich: FilterBereiche.ETZ,
            segments: this.currentData.segments,
            public: true
          };
          this.segmentsChanged$.next(event);
        })
      )
      .subscribe();
  }

  /**
   * Subscribes to the (RouterEvents)[@link Router].
   * Uses (checkUrlIsBasePriceRoot)[@link SettingsComponent#checkUrlIsBasePriceRoot].
   * Is called at (ngOnInit)[@link SettingsComponent#ngOnInit].
   */
  private subscribeOnRouterEvents(): void {
    const navigationEnd$ = this.router.events.pipe(rxjsFilter((event: Event) => event instanceof NavigationEnd));

    const params: IsActiveMatchOptions = {
      paths: 'subset',
      queryParams: 'subset',
      fragment: 'ignored',
      matrixParams: 'ignored'
    };

    navigationEnd$
      .pipe(
        tap((event: NavigationEnd) => {
          this.previousUrl = this.currentUrl;
          this.currentUrl = event.url;
        })
      )
      .subscribe();

    navigationEnd$
      .pipe(
        rxjsFilter((event: NavigationEnd) => this.router.isActive('portal/schlachtdaten', params)),
        tap((event: NavigationEnd) => {
          const lastActivatedRouteElement = RoutingHelpers.determineLastActivatedRoute(this.activatedRoute);
          const multiselect =
            RoutingHelpers.getDataOfRouteSnapshot<boolean | undefined>(
              'multiselect',
              lastActivatedRouteElement.snapshot
            ) || false;

          this.setMultiselectMode(multiselect, FilterBereiche.SO);
        }),
        rxjsFilter((event: NavigationEnd) => !this.previousUrl.includes('portal/schlachtdaten')),
        tap((event: NavigationEnd) => {
          this.queryTotalsForSegments(this.soData.segments, FilterBereiche.SO).subscribe();
        })
      )
      .subscribe();

    navigationEnd$
      .pipe(
        rxjsFilter((event: NavigationEnd) => this.router.isActive('portal/etm', params)),
        tap((event: NavigationEnd) => {
          const lastActivatedRouteElement = RoutingHelpers.determineLastActivatedRoute(this.activatedRoute);
          const multiselect =
            RoutingHelpers.getDataOfRouteSnapshot<boolean | undefined>(
              'multiselect',
              lastActivatedRouteElement.snapshot
            ) || false;

          this.setMultiselectMode(multiselect, FilterBereiche.ETM);
        }),
        rxjsFilter((event: NavigationEnd) => !this.previousUrl.includes('portal/etm')),
        tap((event: NavigationEnd) => {
          this.queryTotalsForSegments(this.etmData.segments, FilterBereiche.ETM).subscribe();
        })
      )
      .subscribe();

    navigationEnd$
      .pipe(
        rxjsFilter((event: NavigationEnd) => this.router.isActive('portal/etz', params)),
        tap((event: NavigationEnd) => {
          const lastActivatedRouteElement = RoutingHelpers.determineLastActivatedRoute(this.activatedRoute);
          const multiselect =
            RoutingHelpers.getDataOfRouteSnapshot<boolean | undefined>(
              'multiselect',
              lastActivatedRouteElement.snapshot
            ) || false;

          this.setMultiselectMode(multiselect, FilterBereiche.ETZ);
        })
      )
      .subscribe();
  }

  /**
   * Subscribes to the Theme-Changed Event and resets the default SegmentColor.
   */
  private subscribeOnThemeChangedEvents() {
    this.themeService.getThemeChangedObservable().subscribe(() => {
      const themeColors = this.themeService.getActiveThemeColors();
      this.soData.defaultSegmentColor = themeColors.secondarySchmuckfarbe;
      this.etmData.defaultSegmentColor = themeColors.secondarySchmuckfarbe;
      this.etzData.defaultSegmentColor = themeColors.primarySchmuckfarbe;
    });
  }
}
