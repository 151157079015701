import { Component, Input, OnInit } from '@angular/core';
import { GlobalFilterService } from '@core/global-filter/global-filter.service';
import { CellComponent } from '@iq-angular-libs/portal';
import { FocusBetriebsstaettenData, FocusBetriebsstaettenOptions } from '../table-cell-components.interfaces';

@Component({
  selector: 'iq-focus-betriebsstaetten',
  templateUrl: './focus-betriebsstaetten.component.html',
  styleUrls: ['./focus-betriebsstaetten.component.scss']
})
export class FocusBetriebsstaettenComponent implements OnInit, CellComponent {
  /**
   * The filtered comerical units registrationsnumbers
   * {
   *  filteredRegistrierungsnummern: []
   * }
   */
  @Input()
  data: FocusBetriebsstaettenData;

  /**
   * The component options
   */
  @Input()
  options?: FocusBetriebsstaettenOptions;

  constructor(private globalFilterService: GlobalFilterService) {}

  /**
   * Sets the intial options if they are not set.
   */
  ngOnInit() {
    if (this.options) {
      if (!this.options.tooltipText) {
        this.options.tooltipText = '';
      }
      if (!this.options.disable) {
        this.options.disable = false;
      }
      if (!this.options.tooltipDisabled) {
        this.options.tooltipDisabled = true;
      }
      if (!this.options.tooltipPlacement) {
        this.options.tooltipPlacement = 'bottom';
      }
    } else {
      this.options = {
        tooltipText: '',
        tooltipPlacement: 'bottom',
        tooltipDisabled: true,
        disable: false
      };
    }
  }

  /**
   * Focus the comerical units of the group
   */
  focusBetriebsstaetten() {
    if (this.data && this.data.filteredRegistrierungsnummern) {
      this.globalFilterService.focusBetriebstaetten(this.data.filteredRegistrierungsnummern);
    }
  }
}
