<div class="iq-middle-box text-center animated fadeInDown">
  <h1>404</h1>
  <h3>{{ 'FEHLER.SEITE_NICHT_GEFUNDEN' | translate }}</h3>

  <div class="mt-4">
    <div class="error-desc">{{ 'FEHLER.SEITE_NICHT_GEFUNDEN_HINWEIS' | translate }}</div>
  </div>
  <div class="mt-4">
    <div class="error-desc">
      <strong>
        <a routerLink="">
          <i class="fa fa-arrow-left mr-2" aria-hidden="true"> </i> {{ 'FEHLER.ZURUECK_ZUM_PORTAL' | translate }}
        </a>
      </strong>
    </div>
  </div>
</div>
