import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FORMAT } from '@core/constants';
import { TranslateService } from '@ngx-translate/core';
import {
  SelectionChangedEvent,
  SingleSelectData
} from '@share/single-select-navigation/single-select-navigation.component';
import { forEach } from 'lodash-es';
import { SlimScrollEvent } from 'ngx-slimscroll';
import { finalize } from 'rxjs/operators';
import { Widget, WidgetComponent } from '../share/startseite.interfaces';
import { Ampel } from '../share/widgets.enums';
import {
  AccordionSections,
  QsZusammenfassungData,
  QsZusammenfassungEntry,
  QsZusammenfassungTgiBefundgruppe
} from '../share/widgets.interfaces';
import { WidgetsService } from '../share/widgets.service';

@Component({
  selector: 'iq-qs-zusammenfassung-widget',
  templateUrl: './qs-zusammenfassung-widget.component.html',
  styleUrls: ['./qs-zusammenfassung-widget.component.scss']
})
export class QsZusammenfassungWidgetComponent implements OnInit, WidgetComponent {
  @Input()
  widget: Widget;

  /**
   * Indicates whether the data is loaded
   */
  dataLoading: boolean;

  /**
   * The error message in case of incorrect loading of the data
   */
  errMessage: string;

  /**
   * The selected operating site
   */
  selectedBetriebsstaette: QsZusammenfassungEntry;

  /**
   * The scroll event for the SlimScroll Component
   */
  slimScrollEvents: EventEmitter<SlimScrollEvent>;

  /**
   * The Date format to use in the DatePipe
   */
  dateFormat = FORMAT.DATE;

  /**
   * The data for the SingleSelectnNavigation Component
   */
  selectData: SingleSelectData;

  /**
   * Defines the Accordion sections
   */
  accordionSections: AccordionSections;

  /**
   * The AHI (animal health index) info text, which contains the relevant groups of findings.
   */
  tgiInfoBefundgruppenText: string;

  /**
   * Constructor
   * @param widgetsService {@link WidgetsService}
   */
  constructor(private translate: TranslateService, private widgetsService: WidgetsService) {
    this.slimScrollEvents = new EventEmitter<SlimScrollEvent>();
    this.accordionSections = {
      QsProdukte: {
        isOpen: false,
        produktKey: 'QsProdukte'
      },
      SalmonellenProdukte: {
        isOpen: false,
        produktKey: 'SalmonellenProdukte'
      },
      TgiProdukte: {
        isOpen: false,
        produktKey: 'TgiProdukte'
      },
      AntibiotikaProdukte: {
        isOpen: false,
        produktKey: 'AntibiotikaProdukte'
      }
    };

    const atemwege = this.translate.instant('WIDGETS.QS_ZUSAMMENFASSUNG.ATEMWEGE');
    const organe = this.translate.instant('WIDGETS.QS_ZUSAMMENFASSUNG.ORGAME');
    const gliedmassen = this.translate.instant('WIDGETS.QS_ZUSAMMENFASSUNG.GLIEDMASSEN');
    const unversehrtheit = this.translate.instant('WIDGETS.QS_ZUSAMMENFASSUNG.UNVERSEHRTHEIT');

    this.tgiInfoBefundgruppenText = `${atemwege} / ${organe} / ${gliedmassen} / ${unversehrtheit}`;
  }

  /**
   * Triggers the data requests
   */
  ngOnInit() {
    this.getData();
  }

  /**
   * Called when the selected comerical units changes in the
   * SingleSelectNavigation Component changes.
   * @param event SelectionChangedEvent that contains the selected site.
   */
  onSelectionChanged(event: SelectionChangedEvent) {
    this.selectedBetriebsstaette = <QsZusammenfassungEntry>event.selectedBetriebsstaette;
  }

  /**
   * Called when an Accordion opens or closes.
   * @param openState the open-state of the accordions.
   * @param bereich The Accordion area that was clicked
   */
  accordionOpenChange(openState: boolean, bereich: string) {
    setTimeout(() => {
      const event = new SlimScrollEvent({ type: 'recalculate' });
      this.slimScrollEvents.emit(event);
    });
    this.accordionSections[bereich].isOpen = openState;
  }

  /**
   * Sets the color of the circle of the Accordion header when the Accordion is closed.
   * @param bereich The area for which the color is to be determined.
   */
  getAccordionCircleColor(bereich: string) {
    let colorIndex = 0;
    let color = '';
    forEach(this.selectedBetriebsstaette[bereich], produkt => {
      if (produkt.Ampel > colorIndex) {
        color = this.getCircleColor(produkt.Ampel);
        colorIndex = produkt.Ampel;
      }
    });
    if (colorIndex === 0 && this.selectedBetriebsstaette[bereich]) {
      color = this.getCircleColor(0);
    }
    return color;
  }

  /**
   * Sets the color of the circle of the Accordion header for AHI products when the Accordion is closed.
   */
  getAccordionTgiCircleColor() {
    let colorIndex = Ampel.Grau;
    let color = '';
    const befundgruppen = ['Atemwegsgesundheit', 'Organgesundheit', 'Gliedmassengesundheit', 'Unversehrtheit'];
    forEach(this.selectedBetriebsstaette.TgiProdukte, produkt => {
      forEach(produkt.Schlachtbetriebe, schlachtbetrieb => {
        forEach(befundgruppen, gruppenKey => {
          if (schlachtbetrieb[gruppenKey] && schlachtbetrieb[gruppenKey].Ampel > colorIndex) {
            color = this.getCircleColor(schlachtbetrieb[gruppenKey].Ampel);
            colorIndex = schlachtbetrieb[gruppenKey].Ampel;
          }
        });
      });
    });

    if (colorIndex === Ampel.Grau && this.selectedBetriebsstaette.TgiProdukte) {
      color = this.getCircleColor(Ampel.Grau);
    }
    return color;
  }

  /**
   * Determines the color of the AHI finding group
   * @param befundGruppe
   */
  getTgiBefundgruppenColor(befundGruppe: QsZusammenfassungTgiBefundgruppe) {
    if (!befundGruppe) {
      return '';
    }

    switch (befundGruppe.Ampel) {
    case Ampel.Gruen:
      return 'ampel-gruen';
    case Ampel.Gelb:
      return 'ampel-gelb';
    case Ampel.Rot:
      return 'ampel-rot';
    default:
      return '';
    }
  }

  /**
   * Determines the color of the circle.
   * @param colorValue The color value of the comerial unit
   */
  getCircleColor(colorValue: number) {
    switch (colorValue) {
    case Ampel.Grau:
      return 'rang-ampel-grau';
    case Ampel.Gruen:
      return 'rang-ampel-gruen';
    case Ampel.Gelb:
      return 'rang-ampel-gelb';
    case Ampel.Rot:
      return 'rang-ampel-rot';
    default:
      return '';
    }
  }

  /**
   * Fetches the data from the BE
   */
  private getData() {
    this.errMessage = null;
    this.dataLoading = true;
    this.selectedBetriebsstaette = null;

    this.widgetsService
      .getQsZusammenfassungWidgetData()
      .pipe(
        finalize(() => {
          this.dataLoading = false;
        })
      )
      .subscribe({
        next: (data: QsZusammenfassungData) => {
          if (data) {
            this.selectData = {
              betriebsstaetten: data,
              selectedBetriebsstaette: data[0],
              selectedBetriebsstaetteIndex: 0
            };
            this.selectedBetriebsstaette = <QsZusammenfassungEntry>this.selectData.selectedBetriebsstaette;
          }
        },
        error: errMessage => {
          this.errMessage = 'FEHLER.DATEN_NICHT_GELADEN';
        }
      });
  }
}
