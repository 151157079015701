<div class="iq-salmonellen-tacho">
  <svg viewBox="40 0 330 160">
    <g>
      <path #arc1 class="arc" id="arc1" d="" />
      <path #arc2 class="arc" id="arc2" d="" />
      <path #arc3 class="arc" id="arc3" d="" />

      <g [attr.transform]="'rotate(' + deg + ' 200 150)'">
        <circle class="needle-center" cx="195" cy="150" r="5"></circle>
        <path class="needle" id="needle" d="M 195 145 L 80 150 L 195 155"></path>
      </g>

      <text x="50" y="150" fill="black">0%</text>
      <text x="105" y="40" fill="black">20%</text>
      <text x="265" y="40" fill="black">40%</text>
      <text x="330" y="150" fill="black">100%</text>
    </g>
  </svg>
</div>
