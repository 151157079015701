import { DatumInfo, IqFilterItem, MinMax } from '../uebergeordnete-filter.interfaces';
import { FilterListTypes } from '@share/filter/filter.interfaces';

/**
 * Event class, which is called when a iq filter is changed.
 */
export class IqFilterItemChangedEvent {
  filterItem: IqFilterItem;

  constructor(filterItem: IqFilterItem) {
    this.filterItem = filterItem;
  }
}

/**
 * Event class, which is called when the selected filters change.
 */
export class SelectedFilterItemsChangedEvent {
  selectedItems: FilterListTypes | DatumInfo | MinMax;

  constructor(selectedItems: FilterListTypes | DatumInfo | MinMax) {
    this.selectedItems = selectedItems;
  }
}
