<div class="widget-content-container letzte-lieferpartien">
  <div slimScroll class="scroll-space h-100" [scrollEvents]="slimScrollEvents">
    <div *ngIf="betriebsstaettenLoading"><iq-loading-indicator></iq-loading-indicator></div>
    <div *ngIf="errMessage && !betriebsstaettenLoading">
      <h4>{{ errMessage | translate }}</h4>
    </div>
    <div *ngIf="!betriebsstaettenLoading">
      <p *ngIf="betriebsstaetten.length === 0" class="text-center">{{ 'KEINE_DATENSAETZE_VORHANDEN' | translate }}</p>
      <div *ngIf="betriebsstaetten.length > 0" class="table-container table-responsive">
        <table class="table table-hover">
          <tbody>
            <tr *ngFor="let betriebsstaette of betriebsstaetten">
              <td>
                <div class="text-bold">
                  <span>{{ betriebsstaette.Schlachtdatum | date: FORMAT.DATE }}</span> <span> / </span>
                  <span>{{ betriebsstaette.AnzahlTiere }} </span>
                  <span>{{ 'WIDGETS.LETZTE_LIEFERPARTIEN.ANZAHL_TIERE' | translate }}</span> <span> / </span>
                  <span>{{ betriebsstaette.SchlachtbetriebNameKurz }}</span>
                </div>
                <div class="text-muted">
                  <span>{{ betriebsstaette.Registrierungsnummer }}</span> <span> / </span>
                  <span>{{ betriebsstaette.BetriebsstaettenName }}</span>
                </div>
              </td>
              <td class="btn-column">
                <iq-details-aufrufen-button
                  [routeInformation]="[
                    'portal/schlachtdaten/lieferpartien/lieferpartie',
                    betriebsstaette.Registrierungsnummer,
                    betriebsstaette.Schlachtdatum,
                    betriebsstaette.SchlachtbetriebId,
                    'null',
                    'null',
                    sort.sortType,
                    sort.sortOrder
                  ]"
                  [skipLocationChange]="true"
                  [selectedRegistrierungsnummer]="betriebsstaette.Registrierungsnummer"
                  rubrikTitelTranslationKey="WIDGETS.LETZTE_LIEFERPARTIEN.LIEFERPARTIEN"
                  tooltip="{{ 'WIDGETS.LETZTE_LIEFERPARTIEN.DETAILS' | translate }}"
                  placement="left"
                  container="body"
                >
                </iq-details-aufrufen-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<!-- edit widget -->
<div class="widget-bearbeiten-container disabled"></div>
