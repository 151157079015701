<div id="wrapper" class="welcome">
  <main role="main" id="page-wrapper" class="bg-color">
    <div class="container pr-0 pl-0">
      <section id="one">
        <img src="../../../assets/images/bg_home.jpg" class="img-fluid" />
        <div class="wrapper">
          <div class="form-container">
            <div class="form-login-heading"></div>
            <iq-login
              *ngIf="loginViewState.loginView"
              [loading]="loginLoading"
              [loginFailed]="loginFailed"
              [remainingTimeBlocked]="remainingTime.login"
              (showPasswordForgotten)="switchView($event)"
              (login)="handleLoginEvent($event)"
            >
            </iq-login>
            <iq-password-forgotten
              *ngIf="loginViewState.passwordForgottenView"
              [loading]="passwordForgottenLoading"
              [remainingTimeBlocked]="remainingTime.passwordForgotten"
              (showLogin)="switchView($event)"
              (passwordForgotten)="handlePasswordForgottenEvent($event)"
            >
            </iq-password-forgotten>
            <iq-password-link-requested
              *ngIf="loginViewState.passwordLinkRequestedView"
              (showLogin)="switchView($event)"
            >
            </iq-password-link-requested>
          </div>
        </div>
      </section>

      <section id="two">
        <div class="ibox">
          <div class="ibox-title">
            <h1>{{ 'WELCOME.WELCOME_NACHRICHT_TITEL' | translate }}</h1>
          </div>
          <div [innerHTML]="'WELCOME.WELCOME_NACHRICHT' | translate" class="ibox-content welcome-message"></div>
        </div>
      </section>
      <div class="vertical-filler bg-color"></div>
    </div>
  </main>
  <footer class="footer">
    <div class="float-right">
      <span>
        <strong>{{ 'WELCOME.COPYRIGHT' | translate }}</strong>
        <a class="ml-1" target="_blank" href="https://www.iq-agrar.de/">IQ-Agrar</a> &copy; 2017 - {{ currentYear }}
      </span>
      <span class="ml-2">
        <a target="_blank" href="{{ 'WELCOME.DATENSCHUTZERKLAERUNG_URL' | translate }}">
          {{ 'WELCOME.DATENSCHUTZERKLAERUNG' | translate }}
        </a>
      </span>
      <span class="ml-2">
        <a target="_blank" href="{{ 'WELCOME.IMPRESSUM_URL' | translate }}">
          {{ 'WELCOME.IMPRESSUM' | translate }}
        </a>
      </span>
    </div>
  </footer>
</div>
