import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { FORMAT } from '@core/constants';
import { extractUniqueRegistrierungsnummern, orderCaseUnsensitiv, SortConfig } from '@iq-angular-libs/portal';
import { isArray, orderBy } from 'lodash-es';
import { ISlimScrollEvent, SlimScrollEvent } from 'ngx-slimscroll';
import { of, Subject, throwError } from 'rxjs';
import { filter, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Widget, WidgetComponent } from '../share/startseite.interfaces';
import { BetriebsstaetteLetzteLieferpartie, BetriebsstaettenLetzeLieferpartien } from '../share/widgets.interfaces';
import { WidgetsService } from '../share/widgets.service';

@Component({
  selector: 'iq-letzte-lieferpartien',
  templateUrl: './letzte-lieferpartien.component.html',
  styleUrls: ['./letzte-lieferpartien.component.scss']
})
export class LetzteLieferpartienComponent implements OnInit, OnDestroy, WidgetComponent {
  /**
   * The structure information of the widget.
   */
  @Input()
  widget: Widget;

  /**
   * The constants for the formatted representation of date values.
   */
  FORMAT = FORMAT;

  /**
   * The last delivery lots identified by the BE.
   */
  betriebsstaetten: BetriebsstaettenLetzeLieferpartien;

  /**
   * Indicates whether data is currently being loaded.
   */
  betriebsstaettenLoading: boolean;

  /**
   * The error message.
   */
  errMessage: string | null;

  /**
   * The unique registration numbers of the delivery lot data.
   */
  registrierungsnummern: string[];

  /**
   * Event emitter for the SlimScroll component.
   */
  slimScrollEvents = new EventEmitter<ISlimScrollEvent>();

  /**
   * Unsusbscribe-Stream.
   */
  unsubscribe$ = new Subject<void>();

  /**
   * Specifies by which field and whether to sort in descending order.
   */
  sort: SortConfig;

  /**
   * Constructor.
   * @param widgetsService {@link WidgetsService}
   */
  constructor(private widgetsService: WidgetsService) {
    this.betriebsstaetten = [];
    this.betriebsstaettenLoading = false;
    this.errMessage = null;
    this.registrierungsnummern = [];

    this.sort = {
      sortType: 'Schlachtdatum',
      sortOrder: 'desc'
    };
  }

  /**
   * Triggers the loading of the last delivery batches from the backend.
   */
  ngOnInit() {
    this.getData();

    this.widgetsService
      .getWidgetItemResizedObservable()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(event => event.widget.id === this.widget.id)
      )
      .subscribe({
        next: () => {
          const event = new SlimScrollEvent({
            type: 'recalculate'
          });

          this.slimScrollEvents.emit(event);
        }
      });
  }

  /**
   * Fires an event of the unsubscribe stream and closes it afterwards.
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Fetches the data of the last delivery lots from the backend via the {@link WidgetsService}.
   */
  private getData(): void {
    this.betriebsstaetten = [];
    this.betriebsstaettenLoading = true;
    this.errMessage = null;
    this.registrierungsnummern = [];

    this.widgetsService
      .getBetriebsstaettenLetzteLieferpartien()
      .pipe(
        switchMap((data: BetriebsstaettenLetzeLieferpartien) => {
          if (data && isArray(data)) {
            this.betriebsstaetten = orderBy<BetriebsstaetteLetzteLieferpartie>(
              data,
              [orderCaseUnsensitiv(this.sort.sortType)],
              [this.sort.sortOrder]
            );
            return of(this.betriebsstaetten);
          } else {
            return throwError('Letze Lieferpartien: Fehlerhaftes Datenformat');
          }
        }),
        tap((data: BetriebsstaettenLetzeLieferpartien) => {
          this.registrierungsnummern = extractUniqueRegistrierungsnummern(data);
        }),
        finalize(() => {
          this.betriebsstaettenLoading = false;
        })
      )
      .subscribe({
        error: (err: Error) => {
          this.errMessage = 'FEHLER.DATEN_NICHT_GELADEN';
        }
      });
  }
}
