<div class="modal-header">
  <h4 class="modal-title">{{ 'MODAL.MINDESTANZAHL_BESTIMMEN.MINDESTANZAHL_FESTLEGEN' | translate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form
    id="mindestanzahlForm"
    name="mindestanzahlForm"
    class="form needs-validation"
    novalidate
    (keyup.enter)="submit()"
    [formGroup]="mindstanzahlForm"
  >
    <div class="form-group" *ngIf="!versteckteFelder?.minTiere">
      <div class="row" [class.has-error]="submitted && getControl('minTiere').invalid">
        <label class="mt-2 col-sm-4 col-12 control-label text-right">
          {{ 'MODAL.MINDESTANZAHL_BESTIMMEN.TIERE' | translate }}
        </label>
        <div class="col-sm-8 col-12">
          <input #minTiere id="minTiere" class="form-control" type="number" formControlName="minTiere" />
          <span *ngIf="submitted && getControl('minTiere').errors?.required">
            {{ 'MODAL.MINDESTANZAHL_BESTIMMEN.LEERES_FELD' | translate }}
          </span>
          <span *ngIf="submitted && getControl('minTiere').errors?.min">
            {{ 'MODAL.MINDESTANZAHL_BESTIMMEN.HINWEIS_KLEINER_EINS' | translate }}
          </span>
        </div>
      </div>
    </div>
    <div class="form-group" *ngIf="!versteckteFelder?.minWuerfe">
      <div class="row" [class.has-error]="submitted && getControl('minWuerfe').invalid">
        <label class="mt-2 col-sm-4 col-12 control-label text-right">
          {{ 'MODAL.MINDESTANZAHL_BESTIMMEN.WUERFE' | translate }}
        </label>
        <div class="col-sm-8 col-12">
          <input #minWuerfe id="minWuerfe" class="form-control" type="number" formControlName="minWuerfe" />
          <span *ngIf="submitted && getControl('minWuerfe').errors?.required">
            {{ 'MODAL.MINDESTANZAHL_BESTIMMEN.LEERES_FELD' | translate }}
          </span>
          <span *ngIf="submitted && getControl('minWuerfe').errors?.min">
            {{ 'MODAL.MINDESTANZAHL_BESTIMMEN.HINWEIS_KLEINER_EINS' | translate }}
          </span>
        </div>
      </div>
    </div>
    <div class="form-group" *ngIf="!versteckteFelder?.minNachkommen">
      <div class="row" [class.has-error]="submitted && getControl('minNachkommen').invalid">
        <label class="mt-2 col-sm-4 col-12 control-label text-right">
          {{ 'MODAL.MINDESTANZAHL_BESTIMMEN.NACHKOMMEN' | translate }}
        </label>
        <div class="col-sm-8 col-12">
          <input #minNachkommen id="minNachkommen" class="form-control" type="number" formControlName="minNachkommen" />
          <span *ngIf="submitted && getControl('minNachkommen').errors?.required">
            {{ 'MODAL.MINDESTANZAHL_BESTIMMEN.LEERES_FELD' | translate }}
          </span>
          <span *ngIf="submitted && getControl('minNachkommen').errors?.min">
            {{ 'MODAL.MINDESTANZAHL_BESTIMMEN.HINWEIS_KLEINER_EINS' | translate }}
          </span>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="ml-auto">
    <button type="button" class="btn btn-secondary" (click)="cancel()">{{ 'ALLGEMEIN.ABBRECHEN' | translate }}</button>
    <button type="submit" class="btn btn-primary" (click)="submit()">
      {{ 'ALLGEMEIN.UEBERNEHMEN' | translate }}
    </button>
  </div>
</div>
