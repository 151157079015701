import { LoginData, PasswordForgottenData } from './login-entities';

/**
 * Event class for the password forgotten event.
 */
export class PasswordForgottenEvent {
  constructor(readonly passwordForgottenData: PasswordForgottenData) {}
}

/**
 * Event class for the login event.
 */
export class LoginEvent {
  constructor(readonly loginData: LoginData) {}
}

/**
 * Event class for the show login event.
 */
export class ShowLoginViewEvent {
  constructor() {}
}

/**
 * Event class for the show password forgotten view event.
 */
export class ShowPasswordForgottenViewEvent {
  constructor() {}
}

/**
 * Event class for the show password requested view event.
 */
export class ShowPasswordRequestedViewEvent {
  constructor() {}
}
