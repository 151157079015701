import { UserProperties } from '@core/user/user.interfaces';
import { UserPropertiesBereiche } from '@core/user/user.enums';

/**
 * Event class, which describes the data of the event, that will be fired if the user properties change.
 */
export class UserPropertiesChangedEvent {
  userProperties: UserProperties | null;

  userPropertiesBereich?: UserPropertiesBereiche;

  constructor(userProperties: UserProperties | null, userPropertiesBereich?: UserPropertiesBereiche) {
    this.userProperties = userProperties;
    this.userPropertiesBereich = userPropertiesBereich;
  }
}
