import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { DatendownloadService } from 'src/app/schlachtdaten/datendownload/datendownload.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter, finalize, take } from 'rxjs/operators';
import { SortConfig, TableOptions } from '@iq-angular-libs/portal';
import { Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { DatendownloadBodyData } from 'src/app/schlachtdaten/datendownload/datendownload.interfaces';
import { Zeitraum } from 'src/app/schlachtdaten/lieferpartien/share/lieferpartien.interfaces';
import { FILETYPES } from '@core/constants';
import { ListFilterQuery } from '@share/filter/segment-query.interfaces';

@Component({
  selector: 'iq-datendownload-button',
  templateUrl: './datendownload-button.component.html',
  styleUrls: ['./datendownload-button.component.scss']
})
export class DatendownloadButtonComponent implements OnInit {
  /**
   * The current section
   */
  @Input()
    bereich: string;

  /**
   * Specifies the file type to be downloaded
   */
  @Input()
    filetype: string;

  /**
   * Indicates whether a download is available for the current data
   */
  @Input()
    downloadAvailable: boolean;

  /**
   * Indicates whether a warning regarding the download should be displayed.
   */
  @Input()
    exportWarning: boolean;

  /**
   * The selected registration numbers
   */
  @Input()
    registrierungsnummern: string[];

  /**
   * The translation key for the text of the button
   */
  @Input()
    translationKey: string;

  /**
   * Indicates whether polling is active
   */
  @Input()
    polling: boolean;

  /**
   *  The table options of a table
   */
  @Input()
    tableOptions: TableOptions;

  /**
   * The sort configuration of a table
   */
  @Input()
    sort: SortConfig;

  /**
   * The current period set in the filters
   */
  @Input()
    zeitraum: Zeitraum;

  /**
   * Tooltip active
   */
  @Input()
    tooltipEnabled: boolean;

  /**
   * Tooltip text
   */
  @Input()
    tooltipText: string;

  /**
   * Additional query params
   */
  @Input()
    queryParams: Object;

  /**
   * If registration numbers should be append to request body.
   * Otherwise only filters will be provided.
   */
  @Input()
    ignoreRegistrationNumbers = false;

  /**
   * Extra filter terms that should be included to download request body.
   *
   * @type {ListFilterQuery[]}
   * @memberof DatendownloadButtonComponent
   */
  @Input() extraFilterTerms: ListFilterQuery[];

  /**
   * Boolean which indicates whether the data generation was started.
   */
  @Output()
    generierungGestartetEvent: EventEmitter<boolean>;

  /**
   * Boolean which indicates whether the data generation was canceled.
   */
  @Output()
    downloadCanceledEvent: EventEmitter<void>;

  /**
   * Indicates whether the data download is active
   */
  generierungGestartet: boolean;

  /**
   * Promise for server polling to check the progress of file generation.
   */
  pollingObservable: Observable<string> = null;

  /**
   * The route when the component is initialized.
   */
  initRoute: string;

  /**
   * The current route.
   */
  currentRoute: string;

  /**
   * Constructor
   * @param datendownloadService {@link DatendownloadService}
   * @param translate {@link TranslateService}
   * @param toastr {@link ToastrService}
   * @param renderer {@link Renderer2}
   * @param router {@link Router}
   */
  constructor(
    private datendownloadService: DatendownloadService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private renderer: Renderer2,
    private router: Router
  ) {
    this.generierungGestartetEvent = new EventEmitter<boolean>();
    this.downloadCanceledEvent = new EventEmitter<void>();
    this.router.events
      .pipe(
        filter((event) => {
          if (event instanceof NavigationEnd) {
            return true;
          }
          return false;
        })
      )
      .subscribe((event: NavigationEnd) => {
        this.currentRoute = event.url;
      });
  }

  ngOnInit() {
    if (this.datendownloadService.getPollingObservable()) {
      this.pollingObservable = this.datendownloadService.getPollingObservable();
      if (this.polling) {
        this.generierungGestartet = true;
        this.generierungGestartetEvent.emit(this.generierungGestartet);
        this.datendownloadService.checkIfFileIsReady(this.bereich, this.filetype).pipe(
          finalize(() => {
            this.generierungGestartet = false;
            this.generierungGestartetEvent.emit(this.generierungGestartet);
            this.pollingObservable = null;
          })
        );
      } else {
        this.generierungGestartet = false;
        this.generierungGestartetEvent.emit(this.generierungGestartet);
      }
    }
    this.initRoute = this.router.url;
    this.currentRoute = this.router.url;
  }

  /**
   * Gets the parameters needed for the download.
   */
  getDownloadParameter(tableOptions: TableOptions) {
    if (tableOptions) {
      return this.datendownloadService.getDownloadBodyDataTable(tableOptions, this.zeitraum, this.sort, this.ignoreRegistrationNumbers);
    } else {
      return this.datendownloadService.getDownloadBodyData();
    }
  }

  /**
   * Starts the download process, depending on the area. The slaughter data download is
   * handled by server polling, all others so far directly.
   */
  downloadFile() {
    this.getDownloadParameter(this.tableOptions).subscribe({
      next: bodyData => {
        if (this.polling) {
          if (!this.pollingObservable) {
            this.startDownload(bodyData);
          }
        } else {
          this.startDownload(bodyData);
        }
      },
      error: () => {
        this.generierungGestartet = false;
        this.generierungGestartetEvent.emit(this.generierungGestartet);
        const message = this.translate.instant('FEHLER.FEHLER_DOWNLOAD');
        this.toastr.error(message);
      }
    });
  }

  /**
   * Triggers the actual download by filling the data export link and clicking.
   */
  triggerDownload(downloadLink) {
    if (downloadLink !== undefined) {
      if (downloadLink.split('token=')[1] === '') {
        this.generierungGestartet = false;
        this.generierungGestartetEvent.emit(this.generierungGestartet);
        this.downloadCanceledEvent.emit();
        this.pollingObservable = null;
        const message = this.translate.instant('FEHLER.FEHLER_DOWNLOAD');
        this.toastr.error(message);
        return;
      }
      const mimeType = this.getMimeType();
      const downloadButton: HTMLLinkElement = this.renderer.createElement('a');
      this.renderer.setAttribute(downloadButton, 'href', downloadLink);
      this.renderer.setAttribute(downloadButton, 'type', mimeType);
      this.renderer.setAttribute(downloadButton, 'download', null);
      this.renderer.appendChild(document.body, downloadButton);
      downloadButton.click();
      this.generierungGestartet = false;
      this.generierungGestartetEvent.emit(this.generierungGestartet);
      if (!this.currentRoute.includes(this.initRoute)) {
        const message = this.translate.instant('DOWNLOAD.DOWNLOAD_MESSAGE');
        this.toastr.info(message);
      }
      this.renderer.removeChild(document.body, downloadButton);
    }
    this.pollingObservable = null;
  }

  /**
   * Determines the corresponding MIME type depending on the selected file type.
   */
  private getMimeType(): string {
    switch (this.filetype) {
    case FILETYPES.CSV:
      return 'text/csv';

    case FILETYPES.EXCEL:
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    default:
      return 'text/plain';
    }
  }

  private startDownload(bodyData: DatendownloadBodyData) {
    this.generierungGestartet = true;
    this.generierungGestartetEvent.emit(this.generierungGestartet);
    this.datendownloadService
      .download(this.bereich, this.filetype, bodyData, this.polling, this.queryParams)
      .pipe(take(1))
      .subscribe({
        next: data => {
          this.triggerDownload(data);
        },
        error: () => {
          const message = this.translate.instant('FEHLER.FEHLER_DOWNLOAD');
          this.toastr.error(message);
          this.generierungGestartet = false;
          this.generierungGestartetEvent.emit(this.generierungGestartet);
          this.downloadCanceledEvent.emit();
          this.pollingObservable = null;
        }
      });
  }
}
