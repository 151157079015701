import { Injectable } from '@angular/core';
import { EtzSegment, Segment, Segmente, SoEtmSegment } from '@share/filter/segment.interfaces';
import {
  Einsenderzeichen,
  FilterListTypes,
  FilterListTypesItem,
  Geschlechter,
  Schlachtbetriebe,
  Schlachttag,
} from '@share/filter/filter.interfaces';
import { cloneDeep, filter, forEach, isArray } from 'lodash-es';
import { FilterBereiche } from '@share/filter/filter-bereiche';
import { UebergeordneteFilterService } from '@share/filter/uebergeordnete-filter.service';
import {
  DatumInfoQuery,
  FilterQuery,
  ListFilterQueries,
  ListFilterQuery,
  MinMaxQuery,
  SegmentsQuery,
  TermProperties,
  TermProperty,
} from '@share/filter/segment-query.interfaces';
import { DatumInfo, LetzteXTage, MinMax, Zeitraum } from '@share/filter/uebergeordnete-filter.interfaces';
import { LetzteXTageMode } from '@share/filter/datum-filter.enums';
import * as dayjs from 'dayjs';
import { toISODateString } from '@share/helper-functions';
import { SegmentEvent } from './segment/segment.component';

/**
 * Interface, which contains the public functions of (SegmentQueryServices){@link SegmentQueryService}
 * describes.
 */
export interface ISegmentQueryService {
  getQueryForSegments(segmente: Segmente | Segment, filterBereich: FilterBereiche): SegmentsQuery;
  getQueryForSegment(segment: Segment, filterBereich: FilterBereiche): FilterQuery | null;
  getZeitraumForSegmente(segmente: Segmente, filterBereich: FilterBereiche): Zeitraum;
  getGeschlechterForSegmente(segmente: Segmente, filterBereich: FilterBereiche): Geschlechter;
  getSchlachtbetriebeForSegmente(segmente: SoEtmSegment[], filterBereich: FilterBereiche): Schlachtbetriebe;
}

/**
 * The service creates the queries for the segments and parent filters.
 */
@Injectable({
  providedIn: 'root',
})
export class SegmentQueryService implements ISegmentQueryService {
  /**
   * Template for an empty segment.
   */
  private emptySegment: SoEtmSegment;

  /**
   * Template for an empty ETZ segment.
   */
  private emptyEtzSegment: EtzSegment;

  /**
   * Constructor.
   */
  constructor(private uebergeordneteFilterService: UebergeordneteFilterService) {
    this.initEmptySegments();
  }

  /**
   * Generates a query with passed segments that can be passed to the backend
   * @param segmente Array with segments to be included in the generated query
   * @param filterBereich The respective area (SO, ETM, ...) in which the segment is used.
   */
  getQueryForSegments(segmente: Segmente | Segment, filterBereich: FilterBereiche): SegmentsQuery {
    const query: SegmentsQuery = [];
    if (!segmente) {
      const selectedFilters = this.uebergeordneteFilterService.getSelectedFilters(filterBereich);
      if (selectedFilters.length > 0) {
        switch (filterBereich) {
        case FilterBereiche.ETZ:
          query.push({
            Id: null,
            Filters: this.createFilterQuery(cloneDeep(this.emptyEtzSegment), filterBereich),
          });
          break;

        case FilterBereiche.SO:
        case FilterBereiche.ETM:
          query.push({
            Id: null,
            Filters: this.createFilterQuery(cloneDeep(this.emptySegment), filterBereich),
          });
          break;

        default:
        }
      }
      return query;
    }
    if (this.isSegmentArray(segmente)) {
      (segmente as any[]).forEach((segment: Segment) => {
        query.push({ Id: segment.id, Filters: this.createFilterQuery(segment, filterBereich) });
      });
    } else if (this.isSingleSegment(segmente)) {
      query.push({ Id: (segmente as Segment).id, Filters: this.createFilterQuery(segmente as Segment, filterBereich) });
    }
    return query;
  }

  /**
   * Creates a filter query for the passed segment.
   * @param segment The segment to be queried
   * @param filterBereich The respective area (SO, ETM, ...) in which the segment is used.
   */
  getQueryForSegment(segment: Segment, filterBereich: FilterBereiche): FilterQuery | null {
    if (segment) {
      return this.createFilterQuery(segment, filterBereich);
    } else {
      const selectedFilters = this.uebergeordneteFilterService.getSelectedFilters(filterBereich);
      if (selectedFilters.length > 0) {
        switch (filterBereich) {
        case FilterBereiche.ETZ:
          return this.createFilterQuery(cloneDeep(this.emptyEtzSegment), filterBereich);

        case FilterBereiche.SO:
        case FilterBereiche.ETM:
          return this.createFilterQuery(cloneDeep(this.emptySegment), filterBereich);

        default:
        }
      }
    }
    return null;
  }

  /**
   * Determines the period for the transferred segments.
   * @param segmente The segments
   * @param filterBereich The respective area (SO, ETM, ...) in which the segments are used
   */
  getZeitraumForSegmente(segmente: Segmente, filterBereich: FilterBereiche): Zeitraum {
    let zeitraum: Zeitraum = { from: null, to: null };
    const uebergeordneteFilterDatumInfo = <DatumInfo>(
      this.uebergeordneteFilterService.getSelectedItemsOfFilterWithSegmentProperty('datumInfo', filterBereich)
    );
    if (uebergeordneteFilterDatumInfo) {
      zeitraum = this.getZeitraumOfDatumInfo(uebergeordneteFilterDatumInfo);
    } else if (segmente) {
      if (segmente.length > 0) {
        zeitraum = this.getZeitraumOfDatumInfo(segmente[0].datumInfo);
        for (let i = 1; i < segmente.length; i++) {
          const segmentZeitraum = this.getZeitraumOfDatumInfo(segmente[i].datumInfo);

          if (segmentZeitraum.from !== null && zeitraum.from !== null) {
            zeitraum.from = dayjs(segmentZeitraum.from).isBefore(zeitraum.from) ? segmentZeitraum.from : zeitraum.from;
          }

          if (segmentZeitraum.to !== null && zeitraum.to !== null) {
            zeitraum.to = dayjs(segmentZeitraum.to).isAfter(zeitraum.to) ? segmentZeitraum.to : zeitraum.to;
          }
        }
      }
    }
    return zeitraum;
  }

  /**
   * Determines the genders for the transferred segments.
   * @param segmente The segmente
   * @param filterBereich The respective area (SO, ETM, ...) in which the segments are used
   */
  getGeschlechterForSegmente(segmente: Segmente, filterBereich: FilterBereiche): Geschlechter {
    let geschlechter: Geschlechter = [];
    const uebergeordneteFilterGeschlechter = <Geschlechter>(
      this.uebergeordneteFilterService.getSelectedItemsOfFilterWithSegmentProperty('geschlechter', filterBereich)
    );
    if (uebergeordneteFilterGeschlechter && uebergeordneteFilterGeschlechter.length > 0) {
      geschlechter = uebergeordneteFilterGeschlechter;
    } else if (segmente) {
      if (segmente.length > 0) {
        for (let i = 0; i < segmente.length; i++) {
          const segmentGeschlechter = segmente[i].geschlechter;
          for (let j = 0; j < segmentGeschlechter.length; j++) {
            const foundGeschlechter = filter(geschlechter, { id: segmentGeschlechter[j].id });
            if (foundGeschlechter.length === 0) {
              geschlechter.push(segmentGeschlechter[j]);
            }
          }
        }
      }
    }
    return geschlechter;
  }

  /**
   * Determines the slaughterhouses for the transferred segments
   * @param segmente The egmente
   * @param filterBereich The respective area (SO, ETM, ...) in which the segment is used.
   */
  getSchlachtbetriebeForSegmente(segmente: Segmente, filterBereich: FilterBereiche): Schlachtbetriebe {
    let schlachtbetriebe = [];
    if (filterBereich === FilterBereiche.SO || filterBereich === FilterBereiche.ETM) {
      const uebergeordneteFilterSchlachtbetriebe = <Schlachtbetriebe>(
        this.uebergeordneteFilterService.getSelectedItemsOfFilterWithSegmentProperty('schlachtbetriebe', filterBereich)
      );
      if (uebergeordneteFilterSchlachtbetriebe && uebergeordneteFilterSchlachtbetriebe.length > 0) {
        schlachtbetriebe = uebergeordneteFilterSchlachtbetriebe;
      } else if (segmente && segmente.length > 0) {
        for (let i = 0; i < segmente.length; i++) {
          const segmentSchlachtbetriebe = (segmente[i] as SoEtmSegment).schlachtbetriebe;
          for (let j = 0; j < segmentSchlachtbetriebe.length; j++) {
            const foundSchlachtbetriebe = filter<Schlachtbetriebe>(schlachtbetriebe, {
              id: segmentSchlachtbetriebe[j].id,
            });
            if (foundSchlachtbetriebe.length === 0) {
              schlachtbetriebe.push(segmentSchlachtbetriebe[j]);
            }
          }
        }
      }
    }
    return schlachtbetriebe;
  }

  /**
   * Initializes the empty segments for the filter areas (SO, ETM, ...)
   */
  private initEmptySegments(): void {
    this.emptySegment = {
      id: null,
      name: '',
      color: '',
      schlachtbetriebe: [],
      geschlechter: [],
      einsenderzeichen: [],
      betriebsstaetten: [],
      schlachtgewichte: {
        min: null,
        max: null,
      },
      tzKalk: {
        min: null,
        max: null,
      },
      datumInfo: {
        mode: null,
        schlachttage: null,
        zeitraum: null,
        letzteXTage: null,
      },
    };

    this.emptyEtzSegment = {
      id: null,
      name: '',
      color: '',
      geschlechter: [],
      sauRassen: [],
      sauNummern: [],
      wurfNummern: [],
      eberRassen: [],
      eberNummern: [],
      betriebsstaetten: [],
      schlachtgewichte: {
        min: null,
        max: null,
      },
      tierAlter: {
        min: null,
        max: null,
      },
      tzKalk: {
        min: null,
        max: null,
      },
      datumInfo: {
        mode: null,
        schlachttage: null,
        zeitraum: null,
        letzteXTage: null,
      },
    };
  }

  /**
   * Extracts the IDs from the passed array
   * @param arr The array from which the IDs are to be extracted.
   */
  private extractIds(arr: FilterListTypes): string[] {
    if (Array.isArray(arr)) {
      return (arr as any[]).map<string>((value: FilterListTypesItem) => value.id);
    }
  }

  /**
   * Creates a query object for the passed segment property.
   * Uses either the data of the parent filter or that of the segment.
   * @param segment the segment
   * @param segmentProperty the segment property
   * @param queryName the name of the query
   * @param filterBereich The respective area (SO, ETM, ...) in which the segment is used.
   */
  private createListFilterQueryItem(
    segment: Segment,
    segmentProperty: string,
    queryName: string,
    filterBereich: FilterBereiche
  ): ListFilterQuery | null {
    const uebergeordneteFilterItems = <FilterListTypes>(
      this.uebergeordneteFilterService.getSelectedItemsOfFilterWithSegmentProperty(segmentProperty, filterBereich)
    );
    const items = uebergeordneteFilterItems ? uebergeordneteFilterItems : segment[segmentProperty];

    if (items) {
      return { Name: queryName, Werte: this.extractIds(items) };
    } else {
      return null;
    }
  }

  /**
   * Forms the query objects for the segment properties, which are formed from simple arrays.
   * @param segment the segment
   * @param filterBereich The respective area (SO, ETM, ...) in which the segment is used.
   */
  private createListFilterQuery(segment: Segment, filterBereich: FilterBereiche): ListFilterQueries {
    const terms: ListFilterQueries = [];
    const termProperties: TermProperties = [
      { name: 'Geschlecht', property: 'geschlechter' },
      { name: 'Registrierungsnummer', property: 'betriebsstaetten' },
    ];

    switch (filterBereich) {
    case FilterBereiche.ETZ:
      termProperties.push({ name: 'SauRasse', property: 'sauRassen' });
      termProperties.push({ name: 'SauNr', property: 'sauNummern' });
      termProperties.push({ name: 'WurfNr', property: 'wurfNummern' });
      termProperties.push({ name: 'EberRasse', property: 'eberRassen' });
      termProperties.push({ name: 'EberNr', property: 'eberNummern' });
      break;

    case FilterBereiche.SO:
    case FilterBereiche.ETM:
      termProperties.push({ name: 'SchlachtbetriebId', property: 'schlachtbetriebe' });
      termProperties.push({ name: 'Einsenderzeichen', property: 'einsenderzeichen' });
      break;

    default:
    }

    forEach(termProperties, (termProperty: TermProperty) => {
      const termQuery = this.createListFilterQueryItem(
        segment,
        termProperty.property,
        termProperty.name,
        filterBereich
      );
      if (termQuery) {
        terms.push(termQuery);
      }
    });
    return terms;
  }

  /**
   * Forms the query object for the passed property.
   * @param segment the segment
   * @param segmentProperty the segment property
   * @param backendProperty the backend property
   * @param filterBereich The respective area (SO, ETM, ...) in which the segment is used.
   */
  private createMinMaxQuery(
    segment: Segment,
    segmentProperty: string,
    backendProperty: string,
    filterBereich: FilterBereiche
  ): MinMaxQuery | null {
    const uebergeordneteFilterItems = <MinMax>(
      this.uebergeordneteFilterService.getSelectedItemsOfFilterWithSegmentProperty(segmentProperty, filterBereich)
    );
    const minMaxObject = uebergeordneteFilterItems ? uebergeordneteFilterItems : segment[segmentProperty];
    if (minMaxObject) {
      return { Name: backendProperty, Min: minMaxObject.min, Max: minMaxObject.max };
    } else {
      return null;
    }
  }

  /**
   * Returns the start date for the 'Last X days' mode.
   * @param letzteXTage The object that contains the date information.
   */
  private getFromDateForLetzteXTage(letzteXTage: LetzteXTage): Date | null {
    let fromDate: Date | null = null;
    switch (letzteXTage.mode) {
    case LetzteXTageMode.LETZTE_30_TAGE:
      fromDate = dayjs().subtract(30, 'days').toDate();
      break;

    case LetzteXTageMode.LETZTE_90_TAGE:
      fromDate = dayjs().subtract(90, 'days').toDate();
      break;

    case LetzteXTageMode.LETZTE_180_TAGE:
      fromDate = dayjs().subtract(180, 'days').toDate();
      break;

    case LetzteXTageMode.LETZTE_365_TAGE:
      fromDate = dayjs().subtract(365, 'days').toDate();
      break;

    case LetzteXTageMode.FREIE_EINGABE:
      fromDate = dayjs().subtract(letzteXTage.anzahlTage, 'days').toDate();
      break;

    default:
    }
    return fromDate;
  }

  /**
   * Creates the query object for the property 'datumInfo'.
   * @param segment the segment
   * @param filterBereich The respective area (SO, ETM, ...) in which the segment is used.
   */
  private createDatumInfoQuery(segment: Segment, filterBereich: FilterBereiche): DatumInfoQuery {
    const result: DatumInfoQuery = { Terms: null, Dates: null };
    const uebergeordneteFilterDatumInfo = <DatumInfo>(
      this.uebergeordneteFilterService.getSelectedItemsOfFilterWithSegmentProperty('datumInfo', filterBereich)
    );
    const datumInfo = <DatumInfo>(uebergeordneteFilterDatumInfo ? uebergeordneteFilterDatumInfo : segment.datumInfo);
    if (datumInfo) {
      if (datumInfo.zeitraum) {
        result.Dates = {};
        result.Dates.Von = toISODateString(datumInfo.zeitraum.from);
        result.Dates.Bis = toISODateString(datumInfo.zeitraum.to);
      } else if (datumInfo.schlachttage) {
        result.Terms = {
          Name: 'Schlachtdatum',
          Werte: datumInfo.schlachttage.map((schlachttag: Schlachttag) => schlachttag.date),
        };
      } else if (datumInfo.letzteXTage) {
        const fromDate = this.getFromDateForLetzteXTage(datumInfo.letzteXTage);
        result.Dates = {};
        result.Dates.Von = toISODateString(fromDate);
        result.Dates.Bis = toISODateString(new Date());
      }
    }
    return result;
  }

  /**
   * Adds the filter query for animal age for the filter area ETZ.
   * @param queryFilter current filter query
   * @param segment the segment
   * @param filterBereich The respective area (SO, ETM, ...) in which the segment is used.
   */
  private addAdditionalQueryForFilterBereich(
    queryFilter: FilterQuery,
    segment: Segment,
    filterBereich: FilterBereiche
  ): void {
    if (filterBereich === FilterBereiche.ETZ) {
      const tierAlterQuery = this.createMinMaxQuery(segment, 'tierAlter', 'AlterTage', filterBereich);
      if (tierAlterQuery) {
        queryFilter.Ranges.push(tierAlterQuery);
      }
    }
  }

  /**
   * Creates a filter query from a segment.
   * @param segment Segment from which the filter for the query should be created.
   * @param filterBereich The respective area (SO, ETM, ...) for which the segment is used.
   */
  private createFilterQuery(segment: Segment, filterBereich: FilterBereiche): FilterQuery {
    const queryFilter: FilterQuery = { Ranges: [], Terms: [], Dates: {} };

    queryFilter.Terms = this.createListFilterQuery(segment, filterBereich);

    const schlachtgewichtQuery = this.createMinMaxQuery(segment, 'schlachtgewichte', 'Schlachtgewicht', filterBereich);
    if (schlachtgewichtQuery) {
      queryFilter.Ranges.push(schlachtgewichtQuery);
    }

    const datumInfoQuery = this.createDatumInfoQuery(segment, filterBereich);
    if (datumInfoQuery) {
      if (datumInfoQuery.Terms) {
        queryFilter.Terms.push(datumInfoQuery.Terms);
      }
      if (datumInfoQuery.Dates) {
        queryFilter.Dates.Von = datumInfoQuery.Dates.Von;
        queryFilter.Dates.Bis = datumInfoQuery.Dates.Bis;
      }
    }

    const tzKalkQuery = this.createMinMaxQuery(segment, 'tzKalk', 'TZKalk', filterBereich);
    if (tzKalkQuery) {
      queryFilter.Ranges.push(tzKalkQuery);
    }

    this.addAdditionalQueryForFilterBereich(queryFilter, segment, filterBereich);

    return queryFilter;
  }

  /**
   * Determines the period for the transferred date information.
   * @param datumInfo the date informations
   */
  private getZeitraumOfDatumInfo(datumInfo): Zeitraum {
    const zeitraum = { from: null, to: null };

    if (datumInfo) {
      if (datumInfo.zeitraum) {
        zeitraum.from = datumInfo.zeitraum.from ? new Date(datumInfo.zeitraum.from) : null;
        zeitraum.to = datumInfo.zeitraum.to ? new Date(datumInfo.zeitraum.to) : null;
      } else if (datumInfo.schlachttage) {
        if (datumInfo.schlachttage.length > 0) {
          zeitraum.from = dayjs(datumInfo.schlachttage[0].date).toDate();
          zeitraum.to = dayjs(datumInfo.schlachttage[0].date).toDate();
          for (let i = 1; i < datumInfo.schlachttage.length; i++) {
            const segmentSchlachttag = dayjs(datumInfo.schlachttage[i].date).toDate();
            zeitraum.from = segmentSchlachttag.getTime() > zeitraum.from.getTime() ? zeitraum.from : segmentSchlachttag;
            zeitraum.to = segmentSchlachttag.getTime() < zeitraum.to.getTime() ? zeitraum.to : segmentSchlachttag;
          }
        }
      } else if (datumInfo.letzteXTage) {
        const fromDate = this.getFromDateForLetzteXTage(datumInfo.letzteXTage);
        zeitraum.to = new Date();
        zeitraum.from = fromDate;
      }
    }
    return zeitraum;
  }

  private isSegmentArray(segmente: Segmente | Segment): segmente is Segmente {
    return isArray(segmente);
  }

  private isSingleSegment(segment: Segmente | Segment): segment is Segment {
    return !Array.isArray(segment) && (segment as Segment).id !== undefined;
  }
}
