import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts a arabic number to a roman number
 */
@Pipe({
  name: 'roemischeZahl'
})
export class RoemischeZahlPipe implements PipeTransform {
  transform(number: number, args?: any): any {
    switch (number) {
    case 1:
      return 'I';
    case 2:
      return 'II';
    case 3:
      return 'III';
    case 4:
      return 'IV';
    default:
      return number;
    }
  }
}
