/**
 * Enum representing the colors in the widget sent along by the BE.
 */
export enum Ampel {
  Grau = 0,
  Gruen = 10,
  Gelb = 20,
  Rot = 30
}

/**
 * Describes the tabs in the "Conspicuous farms" widget
 */
export enum AuffaelligeBetriebeTabs {
  TageSeitLetzterPartie = 0,
  OhneSchlachtdaten = 1
}

/**
 * Describes the selectable buttons in the information widget
 */
export enum InfoWidgetButtons {
  Kurier = 0,
  Dateneinsicht = 1,
  Support = 2
}
