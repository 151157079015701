import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { catchError, map, take, tap } from 'rxjs/operators';

export interface ILicencesService {
  getLicencesText(): Observable<string>;
}

@Injectable()
export class LicencesService implements ILicencesService {
  /**
   * Stores the license text
   */
  private licenceText = '';

  constructor(private http: HttpClient) {}

  /**
   * Requests and return the license text. Caches the text after the first succesful request.
   */
  getLicencesText(): Observable<string> {
    if (this.licenceText !== '') {
      return of(this.licenceText);
    }

    const headers = {
      'Content-Type': 'text/plain; charset=utf-8',
      'Cache-Control': 'no-cache, no-store',
      Expires: '-1',
      Pragma: 'no-cache'
    };

    return this.http
      .get('3rdpartylicenses.txt', { headers: headers, observe: 'response', responseType: 'text' })
      .pipe(
        take(1),
        map((response: HttpResponse<string>) => response.body),
        tap((licenceText: string) => {
          this.licenceText = licenceText;
        }),
        catchError((err: HttpErrorResponse) => {
          this.licenceText = '';
          return throwError(() => err);
        })
      );
  }
}
