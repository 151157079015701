import { Injectable } from '@angular/core';
import { COMMON } from '@core/constants';
import { DataService } from '@core/data/data.service';
import { isNil } from 'lodash-es';
import { Observable } from 'rxjs';
import { MaskeninformationRequestParams } from './maskeninformationen.interfaces';

@Injectable()
export class MaskeninformationenService {
  private serviceBase = COMMON.API_ROOT + '/schlachtdaten/maskeninformation';

  constructor(private dataService: DataService) {}

  /**
   * Gets the mask information for the mask view
   * @param maskenId the mask id
   * @param bereich the current section (ixp/mfa)
   */
  getMaskeninformationMaskenbetrachtung(maskenId: number, bereich: string, maskType: number | null) {
    const parameter = {
      params: {
        maskenId
      } as MaskeninformationRequestParams,
      headers: { Accept: 'application/json' }
    };
    if (!isNil(maskType)) {
      parameter.params.maskType = maskType;
    }
    return this.dataService.getDataWithParameters(`${this.serviceBase}/${bereich}/maskenbetrachtung`, parameter);
  }

  /**
   * Gets the mask information for a delivery lot in the delivery lot
   * details page.
   * @param maskenId the mask id
   * @param bereich the current section (ixp/mfa)
   * @param registrierungsnummer the registrationnumber
   * @param schlachtbetriebId the id of the slaughterhouse
   * @param schlachtdatum the date of the slaughter
   */
  getMaskeninformationLieferpartie(
    maskenId: number,
    bereich: string,
    maskType: number | null,
    registrierungsnummer: string,
    schlachtbetriebId: string,
    schlachtdatum: string
  ) {
    const parameter = {
      params: {
        maskenId: maskenId,
        registrierungsnummer: registrierungsnummer,
        schlachtbetriebId: schlachtbetriebId,
        schlachtdatum: schlachtdatum
      } as MaskeninformationRequestParams,
      headers: { Accept: 'application/json' }
    };

    if (!isNil(maskType)) {
      parameter.params.maskType = maskType;
    }

    return this.dataService.getDataWithParameters(`${this.serviceBase}/${bereich}/lieferpartie`, parameter);
  }

  /**
   * Gets the mask information for the mask view
   * @param maskenId the mask id
   * @param bereich the current section (ixp/mfa)
   */
  getMaskenInformationMaskenvergleich(maskenId: number, bereich: string): Observable<unknown> {
    const parameter = {
      params: {
        maskenId
      } as MaskeninformationRequestParams,
      headers: { Accept: 'application/json' }
    };
    return this.dataService.getDataWithParameters(`${this.serviceBase}/${bereich}/maskenvergleich`, parameter);
  }
}
