<div class="widget-content-container qs-zusammenfassung-widget">
  <div slimScroll [scrollEvents]="slimScrollEvents" class="scroll-space h-100">
    <iq-loading-indicator *ngIf="dataLoading"></iq-loading-indicator>
    <div *ngIf="errMessage && !dataLoading">
      <h4>{{ errMessage | translate }}</h4>
    </div>
    <div *ngIf="!dataLoading">
      <iq-single-select-navigation
        class="mb-1"
        *ngIf="selectData"
        [selectData]="selectData"
        (selectionChanged)="onSelectionChanged($event)"
      >
      </iq-single-select-navigation>
      <accordion *ngIf="selectedBetriebsstaette">
        <accordion-group
          class="mb-1 mt-2"
          [panelClass]="
            getAccordionCircleColor(accordionSections['QsProdukte'].produktKey) === 'rang-ampel-rot' ? 'qs-warning' : ''
          "
          [isOpen]="accordionSections['QsProdukte'].isOpen"
          (isOpenChange)="accordionOpenChange($event, 'QsProdukte')"
          *ngIf="selectedBetriebsstaette[accordionSections['QsProdukte'].produktKey] as qsProdukte"
        >
          <div accordion-heading>
            <i class="iq-icon-font iq-icon-font-qualitaetssicherung"></i>
            <span class="header-label">{{ 'WIDGETS.QS_ZUSAMMENFASSUNG.QS' | translate }}</span>
            <div class="circle-accordion-header float-right">
              <div class="inner {{ getAccordionCircleColor(accordionSections['QsProdukte'].produktKey) }}">
                <i
                  class="toggle-arrow float-right fa"
                  [ngClass]="{
                    'fa-chevron-down toggle-arrow-down': accordionSections['QsProdukte'].isOpen && qsProdukte,
                    'fa-chevron-right toggle-arrow-right': !accordionSections['QsProdukte'].isOpen && qsProdukte
                  }"
                >
                </i>
              </div>
            </div>
          </div>
          <div *ngFor="let produkt of qsProdukte">
            <table class="table">
              <tbody>
                <tr>
                  <td>
                    <div class="text-bold">{{ produkt.ProduktName }}</div>
                    <span class="text-muted">
                      <span>{{ 'WIDGETS.QS_ZUSAMMENFASSUNG.LIEFERBERECHT' | translate }}: </span>
                      <span
                        ><b>{{ produkt.Lieferberechtigung | lieferberechtigung }}</b></span
                      >
                      <span *ngIf="produkt.Lieferberechtigung !== 4 && produkt.ZugelassenBis !== null">
                        / {{ 'WIDGETS.QS_ZUSAMMENFASSUNG.ZULASSUNG' | translate }}:
                        <b>{{ produkt.ZugelassenBis | date }}</b>
                      </span>
                      <span *ngIf="produkt.Lieferberechtigung === 4 && produkt.Sperrgrund !== null">
                        ({{ produkt.Sperrgrund }})
                      </span>
                    </span>
                  </td>
                  <td>
                    <div class="rang-circle float-right">
                      <div class="inner {{ getCircleColor(produkt.Ampel) }}"></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </accordion-group>
        <accordion-group
          class="mb-1"
          [isOpen]="accordionSections['SalmonellenProdukte'].isOpen"
          (isOpenChange)="accordionOpenChange($event, 'SalmonellenProdukte')"
          *ngIf="selectedBetriebsstaette[accordionSections['SalmonellenProdukte'].produktKey] as salmonellenProdukte"
        >
          <div accordion-heading>
            <i class="iq-icon-font iq-icon-font-salmonellenmonitoring"></i>
            <span class="header-label">{{ 'WIDGETS.QS_ZUSAMMENFASSUNG.SALMO' | translate }}</span>
            <div class="circle-accordion-header float-right">
              <div class="inner {{ getAccordionCircleColor('SalmonellenProdukte') }}">
                <i
                  class="toggle-arrow float-right fa"
                  [ngClass]="{
                    'fa-chevron-down toggle-arrow-down':
                      accordionSections['SalmonellenProdukte'].isOpen && salmonellenProdukte,
                    'fa-chevron-right toggle-arrow-right':
                      !accordionSections['SalmonellenProdukte'].isOpen && salmonellenProdukte
                  }"
                >
                </i>
              </div>
            </div>
          </div>
          <div *ngFor="let produkt of salmonellenProdukte">
            <table class="table">
              <tbody>
                <tr>
                  <td>
                    <div class="text-bold">{{ produkt.ProduktName }}</div>
                    <span class="text-muted">
                      <span *ngIf="produkt.Ampel">
                        {{ 'WIDGETS.QS_ZUSAMMENFASSUNG.KAT' | translate }}:
                        <b>{{ produkt.Ampel / 10 | roemischeZahl }}</b> ({{
                          produkt.ProzentPositiveProben | number: '1.2-2'
                        }}
                        {{ 'MASSEINHEITEN.PROZENT' | translate }} {{ 'WIDGETS.QS_ZUSAMMENFASSUNG.POS' | translate }}) -
                        {{ produkt.GueltigVon | date: dateFormat }} - {{ produkt.GueltigBis | date: dateFormat }}
                      </span>
                      <span *ngIf="!produkt.Ampel"
                        >{{ 'WIDGETS.QS_ZUSAMMENFASSUNG.KAT' | translate }}:
                        <b>{{ 'WIDGETS.QS_ZUSAMMENFASSUNG.KEINE' | translate }}</b></span
                      >
                    </span>
                  </td>
                  <td>
                    <div class="rang-circle float-right">
                      <div class="inner {{ getCircleColor(produkt.Ampel) }}"></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </accordion-group>
        <accordion-group
          class="mb-1"
          [isOpen]="accordionSections['TgiProdukte'].isOpen"
          (isOpenChange)="accordionOpenChange($event, 'TgiProdukte')"
          *ngIf="selectedBetriebsstaette[accordionSections['TgiProdukte'].produktKey] as tgiProdukte"
        >
          <div accordion-heading>
            <i class="iq-icon-font iq-icon-font-tgi"></i>
            <span class="header-label tgi">{{ 'WIDGETS.QS_ZUSAMMENFASSUNG.TGI' | translate }}</span>
            <div class="circle-accordion-header float-right">
              <div class="inner {{ getAccordionTgiCircleColor() }}">
                <i
                  class="toggle-arrow float-right fa"
                  [ngClass]="{
                    'fa-chevron-down toggle-arrow-down': accordionSections['TgiProdukte'].isOpen && tgiProdukte,
                    'fa-chevron-right toggle-arrow-right': !accordionSections['TgiProdukte'].isOpen && tgiProdukte
                  }"
                >
                </i>
              </div>
            </div>
          </div>
          <div *ngFor="let produkt of tgiProdukte">
            <div class="row">
              <div class="col-12 pl-2 pr-2">
                <div class="tgi-info pl-2 pr-2">
                  <div>{{ 'WIDGETS.QS_ZUSAMMENFASSUNG.SCHLACHTHOF' | translate }}</div>
                  <div [tooltip]="tgiInfoBefundgruppenText" [container]="'body'">{{ tgiInfoBefundgruppenText }}</div>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <span class="text-bold">{{ produkt.ProduktName }}: </span>
                <span>{{ produkt.ZeitraumVon | date: dateFormat }} - {{ produkt.ZeitraumBis | date: dateFormat }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div *ngFor="let schlachtbetrieb of produkt.Schlachtbetriebe" class="mt-2">
                  <div>{{ schlachtbetrieb.SchlachtbetriebName }}</div>
                  <div>
                    <div
                      *ngIf="schlachtbetrieb.Atemwegsgesundheit; else showEmptyValueContainer"
                      [class]="
                        getTgiBefundgruppenColor(schlachtbetrieb?.Atemwegsgesundheit) +
                        ' d-inline-block tgi-befundgruppe'
                      "
                    >
                      {{ schlachtbetrieb.Atemwegsgesundheit.Wert }}
                    </div>
                    <div class="d-inline-block">&nbsp;/&nbsp;</div>
                    <div
                      *ngIf="schlachtbetrieb.Organgesundheit; else showEmptyValueContainer"
                      [class]="
                        getTgiBefundgruppenColor(schlachtbetrieb?.Organgesundheit) + ' d-inline-block tgi-befundgruppe'
                      "
                    >
                      {{ schlachtbetrieb.Organgesundheit.Wert }}
                    </div>
                    <div class="d-inline-block">&nbsp;/&nbsp;</div>
                    <div
                      *ngIf="schlachtbetrieb.Gliedmassengesundheit; else showEmptyValueContainer"
                      [class]="
                        getTgiBefundgruppenColor(schlachtbetrieb?.Gliedmassengesundheit) +
                        ' d-inline-block tgi-befundgruppe'
                      "
                    >
                      {{ schlachtbetrieb.Gliedmassengesundheit.Wert }}
                    </div>
                    <div class="d-inline-block">&nbsp;/&nbsp;</div>
                    <div
                      *ngIf="schlachtbetrieb.Unversehrtheit; else showEmptyValueContainer"
                      [class]="
                        getTgiBefundgruppenColor(schlachtbetrieb?.Unversehrtheit) + ' d-inline-block tgi-befundgruppe'
                      "
                    >
                      {{ schlachtbetrieb.Unversehrtheit.Wert }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </accordion-group>
        <accordion-group
          class="mb-1"
          [isOpen]="accordionSections['AntibiotikaProdukte'].isOpen"
          (isOpenChange)="accordionOpenChange($event, 'AntibiotikaProdukte')"
          *ngIf="selectedBetriebsstaette[accordionSections['AntibiotikaProdukte'].produktKey] as antibiotikaProdukte"
        >
          <div accordion-heading>
            <i class="iq-icon-font iq-icon-font-antibiotikamonitoring"></i>
            <span class="header-label">{{ 'WIDGETS.QS_ZUSAMMENFASSUNG.ANTI' | translate }}</span>
            <div class="circle-accordion-header float-right">
              <div class="inner {{ getAccordionCircleColor(accordionSections['AntibiotikaProdukte'].produktKey) }}">
                <i
                  class="toggle-arrow float-right fa"
                  [ngClass]="{
                    'fa-chevron-down toggle-arrow-down':
                      accordionSections['AntibiotikaProdukte'].isOpen && antibiotikaProdukte,
                    'fa-chevron-right toggle-arrow-right':
                      !accordionSections['AntibiotikaProdukte'].isOpen && antibiotikaProdukte
                  }"
                >
                </i>
              </div>
            </div>
          </div>
          <div *ngFor="let produkt of antibiotikaProdukte">
            <table class="table">
              <tbody>
                <tr>
                  <td>
                    <div class="text-bold">{{ produkt.ProduktName }}</div>
                    <span *ngIf="produkt.TherapieIndex" class="text-muted">
                      {{ 'WIDGETS.QS_ZUSAMMENFASSUNG.THERAPIEINDEX' | translate }}
                      <b>{{ produkt.TherapieIndex | antibiotikaTherapieindex }}</b> -
                      {{ produkt.GueltigVon | date: dateFormat }} - {{ produkt.GueltigBis | date: dateFormat }}
                    </span>
                  </td>
                  <td>
                    <div class="rang-circle float-right">
                      <div class="inner {{ getCircleColor(produkt.Ampel) }}"></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </accordion-group>
      </accordion>
    </div>
  </div>
</div>

<ng-template #showEmptyValueContainer><div class="d-inline-block tgi-befundgruppe">-</div></ng-template>

<!-- Edit widget -->
<div class="widget-bearbeiten-container disabled"></div>
