import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from '@mattlewis92/angular-2-local-storage';
import { Location } from '@angular/common';

/**
 * Checks if the browser of the user supports the localstorage.
 */
@Injectable()
export class LocalStorageGuard  {
  constructor(private localStorageService: LocalStorageService, private router: Router, public location: Location) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkIfLocalStorageIsSupported();
  }

  checkIfLocalStorageIsSupported(): boolean {
    if (this.localStorageService.isSupported) {
      return true;
    }

    this.router.navigate(['/localStorageNotSupported'], { skipLocationChange: true });
    return false;
  }
}
