<div class="modal-header">
  <div class="float-left">
    <h4 class="modal-title">{{ 'FILTER.SCHLACHTGEWICHT_FILTER.SCHLACHTGEWICHT' | translate }}</h4>
  </div>
  <button type="button" class="close float-right" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body text-center schlachtgewicht-filter-modal">
  <form
    id="schlachtgewichtForm"
    name="schlachtgewichtForm"
    class="form needs-validation"
    novalidate
    [formGroup]="form"
    (keyup.enter)="submit()"
  >
    <div class="form-group from">
      <label for="schlachtgewichtMin" class="text-bold"> {{ 'FILTER.SCHLACHTGEWICHT_FILTER.VON' | translate }} </label>
      <input
        #schlachtgewichtFocusInput
        iqNumberInput
        id="schlachtgewichtMin"
        type="number"
        class="form-control"
        formControlName="Min"
        [class.is-invalid]="submitted && controls['Min'].invalid"
      />
      <span>{{ 'MASSEINHEITEN.KG' | translate }}</span>
    </div>
    <div class="form-group from">
      <label for="schlachtgewichtMax" class="text-bold"> {{ 'FILTER.SCHLACHTGEWICHT_FILTER.BIS' | translate }} </label>
      <input
        iqNumberInput
        id="schlachtgewichtMax"
        type="number"
        class="form-control"
        formControlName="Max"
        [class.is-invalid]="submitted && controls['Max'].invalid"
      />
      <span>{{ 'MASSEINHEITEN.KG' | translate }}</span>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div>
    <button type="button" class="btn iq-reset-filter-button" (click)="resetFilter()">
      {{ 'ALLGEMEIN.FILTER_ZURUECKSETZEN' | translate }}
    </button>
  </div>
  <div class="ml-auto">
    <button type="button" class="btn btn-secondary" (click)="cancel()">{{ 'ALLGEMEIN.ABBRECHEN' | translate }}</button>
    <button type="button" class="btn btn-primary ml-2" (click)="submit()">
      {{ 'ALLGEMEIN.UEBERNEHMEN' | translate }}
    </button>
  </div>
</div>
