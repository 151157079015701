import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { LoginData } from '../share/login-entities';
import { LoginEvent, ShowPasswordForgottenViewEvent } from '../share/login-events';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'iq-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  /**
   * Indicates if the login data is submitting
   */
  @Input() loading: boolean;

  /**
   * Indicates if the login failed
   */
  @Input() loginFailed: boolean;

  /**
   * Indicates the remaining time blocked
   */
  @Input() remainingTimeBlocked: number;

  /**
   * The event to display the "Forgot password" function
   */
  @Output() showPasswordForgotten: EventEmitter<ShowPasswordForgottenViewEvent>;

  /**
   * The event to log in the user
   */
  @Output() login: EventEmitter<LoginEvent>;

  /**
   * The login data of the {@link FormGroup}.
   */
  public loginForm: FormGroup;

  /**
   * Indicates if the login button was clicked
   */
  public submitted: boolean;

  /**
   * Constructor.
   */
  constructor() {
    this.showPasswordForgotten = new EventEmitter<ShowPasswordForgottenViewEvent>();
    this.login = new EventEmitter<LoginEvent>();
  }

  /**
   * Initializes the FormGroup.
   */
  ngOnInit() {
    this.loginForm = new FormGroup({
      userName: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required])
    });
  }

  /**
   * Forwards the login call.
   */
  emitLogin(event: Event): void {
    event.preventDefault();
    this.submitted = true;

    const userName = (document.getElementById('usernameInput') as HTMLInputElement)?.value;
    const password = (document.getElementById('passwordInput') as HTMLInputElement)?.value;

    if (this.loginForm.valid || userName && password) {
      if (userName && password) {
        // https://github.com/angular/components/issues/3414
        // it is not possible to know the autofilled values until the user interacts with the page.
        // There is no way around this, it's a security feature of Chrome.
        // For this reason try to set value from DOM tree.
        this.loginForm.controls.userName.setValue(userName);
        this.loginForm.controls.password.setValue(password);
      }
      const loginData: LoginData = {
        userName: this.loginForm.value.userName || userName,
        password: this.loginForm.value.password || password
      };
      const emitLoginEvent = new LoginEvent(loginData);
      this.login.emit(emitLoginEvent);
    }
  }

  /**
   * Emits the call to the "Forgot password" function.
   * @param event the Click-Event
   */
  showPasswordForgottenView(event: Event): void {
    event.preventDefault();
    const passwordForgottenViewEvent = new ShowPasswordForgottenViewEvent();
    this.showPasswordForgotten.emit(passwordForgottenViewEvent);
  }
}
