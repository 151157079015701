import { Component, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MultiselectChangedEvent, MultiselectSettings } from '@iq-angular-libs/portal';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SelectedDiagrammValue } from '../basisvergleich.interfaces';

@Component({
  selector: 'iq-select-diagramm-values-modal',
  templateUrl: './select-diagramm-values-modal.component.html',
  styleUrls: ['./select-diagramm-values-modal.component.scss']
})
export class SelectDiagrammValuesModalComponent implements OnDestroy {
  /**
   * The available selection options in the Multiselect Component
   */
  @Input()
  diagrammValues: SelectedDiagrammValue[];

  /**
   * The selected elements in the Multiselect component
   */
  @Input()
  selectedDiagrammValues: SelectedDiagrammValue[];

  /**
   * The FormGroup.
   */
  form: FormGroup;

  /**
   * The subject to convey the user interactions at the modal.
   */
  onClose$: Subject<SelectedDiagrammValue[] | null>;

  /**
   * Settings for the Multiselect component.
   */
  filterSettings: MultiselectSettings = {
    id: 'vergleich-modal-diagrammWerte-multiselect',
    idProp: 'field',
    sortProp: 'displayName',
    showCheckAll: false,
    showUncheckAll: false,
    selectionLimit: 3
  };

  constructor(private bsModalRef: BsModalRef) {
    this.onClose$ = new Subject<SelectedDiagrammValue[] | null>();
    this.form = new FormGroup({});
  }

  ngOnDestroy() {
    this.onClose$.complete();
  }

  /**
   * Called as soon as the multiselect is closed.
   * @param event MultiselectClosedEvent
   */
  onMultiselectChanged(event: MultiselectChangedEvent) {
    this.selectedDiagrammValues = event.selectedItems;
  }

  /**
   * Called when the modal is canceled.
   */
  cancel() {
    this.onClose$.next(null);
    this.onClose$.complete();
    this.bsModalRef.hide();
  }

  /**
   * Called as soon as the modal is confirmed.
   */
  ok() {
    this.onClose$.next(this.selectedDiagrammValues);
    this.onClose$.complete();
    this.bsModalRef.hide();
  }
}
