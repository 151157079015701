import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash-es';
import { BelegeHalbjahr } from '../antibiotika/monitoring-overview/share/antibiotika.interfaces';

@Pipe({
  name: 'antibiotikaBelege'
})
export class AntibiotikaBelegePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: BelegeHalbjahr, args?: any): any {
    if (!isNil(value)) {
      if (isNil(value.AnzahlBelege2)) {
        if (value.HatNullmeldung) {
          return this.translate.instant('ANTIBIOTIKA.NULLMELDUNG');
        } else {
          return value.AnzahlBelege1 === 0 && !value.ValideAngabe
            ? this.translate.instant('ANTIBIOTIKA.KEINE_ANGABE')
            : value.AnzahlBelege1;
        }
      } else {
        const belegeSumme = value.AnzahlBelege1 + value.AnzahlBelege2;
        if (value.HatNullmeldung) {
          return this.translate.instant('ANTIBIOTIKA.NULLMELDUNG');
        } else {
          return belegeSumme === 0 && !value.ValideAngabe
            ? this.translate.instant('ANTIBIOTIKA.KEINE_ANGABE')
            : belegeSumme + ' (' + value.AnzahlBelege1 + '/' + value.AnzahlBelege2 + ')';
        }
      }
    } else {
      return this.translate.instant('ANTIBIOTIKA.NICHT_RELEVANT');
    }
  }
}
