import { Widget } from './startseite.interfaces';

/**
 * Called when a widget is hidden.
 */
export class DisableWidgetEvent {
  widget: Widget;

  constructor(widget: Widget) {
    this.widget = widget;
  }
}

/**
 * Called when the position/size of a widget has changed.
 */
export class WidgetPositionChangedEvent {
  widget: Widget;

  constructor(widget: Widget) {
    this.widget = widget;
  }
}

/**
 * Called as soon as a widget is added.
 */
export class WidgetAddedEvent {
  widget: Widget;

  constructor(widget: Widget) {
    this.widget = widget;
  }
}

/**
 * Is called as soon as a widget container is to be changed in its height or width.
 * e.g. increase cols from 1 to 2
 */
export class WidgetContainerToggleSizeEvent {
  widget: Widget;

  heightChanged: boolean;

  widthChanged: boolean;

  constructor(widget: Widget, heightChanged = false, widthChanged = false) {
    this.widget = widget;
    this.heightChanged = heightChanged;
    this.widthChanged = widthChanged;
  }
}

/**
 * Called as soon as a widget has been changed in its height or width.
 */
export class WidgetSizeChangedEvent {
  widget: Widget;

  constructor(widget: Widget) {
    this.widget = widget;
  }
}
