import { Component, Input } from '@angular/core';
import { Grenzen } from '@share/chart-helper/chart.interfaces';
import { isNil } from 'lodash-es';

@Component({
  selector: 'iq-optimalbereich-prozente',
  templateUrl: './optimalbereich-prozente.component.html',
  styleUrls: ['./optimalbereich-prozente.component.scss']
})
export class OptimalbereichProzenteComponent {
  /**
   * The optimal range limits and the interval values
   */
  @Input()
  grenzen: Grenzen;

  /**
   * The isNil function from lodash
   */
  isNil = isNil;

  constructor() {}
}
