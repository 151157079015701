import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash-es';
import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'befund'
})
export class BefundPipe extends DecimalPipe implements PipeTransform {
  constructor(private translate: TranslateService) {
    super(translate.getDefaultLang());
  }

  /**
   * Return the string 'n.a.' when the given finding is `null`. Else
   * the value of the finding is returned with two decimals.
   * @param value the proportion value of a finding
   * @param digitsInfo the decimal format for the decimal pipe
   * @param locale a language locale
   */
  transform(value: number | null, digitsInfo?: string, locale?: string): any {
    if (isNil(value)) {
      return this.translate.instant('ALLGEMEIN.NICHT_AUSWERTBAR');
    }
    return super.transform(value, digitsInfo, locale);
  }
}
