import { PortalThemeColors } from '../theme.interfaces';
import { Colors, darkenColor, lightenColor, Theme, themeDefault } from '@iq-angular-libs/portal';

/**
 * Extends the default theme with relavant colors for the portal.
 */
const themeColors: PortalThemeColors = {
  ...themeDefault.themeColors,
  segmentPieChartBackground: Colors.gray400,
  befundTypColor1: Colors.denim,
  befundTypColor2: Colors.pictonBlue,
  befundTypColor3: Colors.charlotte,
  maskenvergleichGrundmaskeLineColor: Colors.gray550,
  maskenbetrachtungBalkenNeutralColor: Colors.gray550,
  maskenbetrachtungDiagrammDarkGruen: darkenColor(lightenColor(Colors.chateauGreen, 25), 9),
  tageszunahmeTachoGradientStartColor: lightenColor(Colors.chateauGreen, 35),
  tageszunahmeTachoGradientEndColor: Colors.chateauGreen
};

/**
 * The new default theme extended with relevant colors.
 */
export const portalThemeDefault = new Theme<PortalThemeColors>(
  themeDefault.name,
  'THEME.STANDARD',
  themeDefault.class,
  themeColors
);
