import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { WebAccessService } from './web-access.service';
import { Menupunkte } from '@core/constants';
import { switchMap, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { RoutingHelpers } from '@core/routing/routing-helpers';

/**
 * Route-Guard which checks if a user is allowed to enter a certain area based on his web access.
 */
@Injectable()
export class WebAccessGuard  {
  constructor(
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrService,
    private webAccessService: WebAccessService
  ) {}

  /**
   * Checks if the user has web access for the new route.
   * Must be implemented by the {@link CanActivate} interface.
   * @param next {@link ActivatedRouteSnapshot}
   * @param state {@link RouterStateSnapshot}
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.hasWebAccess(next);
  }

  /**
   * Checks whether the user has web access for the child routes of the route.
   * Must be implemented by the  {@link CanActivateChild} interface.
   * @param childRoute {@link ActivatedRouteSnapshot}
   * @param state {@link RouterStateSnapshot}
   */
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    const parentWithMenupunkt = RoutingHelpers.findFirstParentWithData('menupunkt', childRoute);
    if (parentWithMenupunkt) {
      return this.hasWebAccess(parentWithMenupunkt);
    }
    return of(true);
  }

  /**
   * Helper function, which checks if the user has access to the new route.
   * @param next {@link ActivatedRouteSnapshot}
   */
  private hasWebAccess(next: ActivatedRouteSnapshot | null): Observable<boolean> {
    return this.webAccessService.getMenupunkte().pipe(
      switchMap((menupunkte: Menupunkte) => {
        let canActivate = false;
        const nextRouteData: Data = next && next.routeConfig && next.routeConfig.data ? next.routeConfig.data : {};

        if (nextRouteData && !nextRouteData.menupunkt) {
          canActivate = true;
        } else if (nextRouteData.menupunkt && menupunkte.includes(nextRouteData.menupunkt)) {
          canActivate = true;
        } else {
          const title = 'ALLGEMEIN.TOASTS.HINWEIS';
          const message = 'ROUTING.TOASTS.KEINE_RECHTE';
          this.translate
            .get([message, title])
            .pipe(take(1))
            .subscribe(translations => {
              this.toastr.info(translations[message], translations[title]);
            });

          this.router.navigate(['/portal/startseite']);
        }

        return of(canActivate);
      })
    );
  }
}
