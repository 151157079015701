/**
 * The list of supported languages
 */
export const SUPPORTED_LANGS = ['de', 'en'];

/**
 * Default UI language, also used as a fallback language
 */
export const DEFAULT_LANG = 'de';

/**
 * Enumerated list of supported languages for quick access
 */
export enum SUPPORTED_LANGS_ENUM {
  en = 'en',
  de = 'de'
}
