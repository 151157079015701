<div class="modal-header">
  <div class="float-left" *ngIf="linkRequested">
    <h4 class="modal-title">{{ 'EMAIL_VALIDIEREN.EMAIL_ADRESSE_BESTAETIGEN' | translate }}</h4>
  </div>
  <div class="float-left" *ngIf="mustValidateEmail && !linkRequested">
    <h4 class="modal-title">{{ 'EMAIL_VALIDIEREN.EMAIL_HINTERLEGEN' | translate }}</h4>
  </div>
  <div class="float-left" *ngIf="!mustValidateEmail && !linkRequested">
    <h4 class="modal-title">{{ 'EMAIL_VALIDIEREN.EMAIL_AENDERN' | translate }}</h4>
  </div>
  <button
    type="button"
    class="close float-right"
    aria-label="Close"
    *ngIf="!mustValidateEmail && !linkRequested"
    (click)="cancel()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="col-12">
    <div *ngIf="!linkRequested; else linkRequestedTemplate">
      <div class="text-center mb-4">
        <div class="mb-2" *ngIf="mustValidateEmail">
          {{ 'EMAIL_VALIDIEREN.INFO_EMAIL_ADRESSE_ANGEBEN' | translate }}
        </div>
        <div class="mb-2" *ngIf="!mustValidateEmail">
          {{ 'EMAIL_VALIDIEREN.INFO_EMAIL_ADRESSE_AENDERN' | translate }}
        </div>
        <div>{{ 'EMAIL_VALIDIEREN.INFO_AKTIVIERUNGSLINK' | translate }}</div>
      </div>

      <form
        id="emailValidationForm"
        name="emailValidationForm"
        class="form needs-validation"
        novalidate
        (keyup.enter)="submit()"
        [formGroup]="form"
      >
        <div class="form-group">
          <div class="row">
            <label class="col-sm-2 col-12 col-form-label text-right">
              {{ 'EMAIL_VALIDIEREN.EMAIL' | translate }}:
            </label>
            <div class="col-sm-10 col-12">
              <input
                id="email"
                class="form-control"
                type="text"
                formControlName="email"
                autocomplete="new-password"
                [class.is-invalid]="submitted && controls['email'].invalid"
              />
              <div class="invalid-feedback" *ngIf="submitted && controls['email'].errors as errors">
                <span *ngIf="errors.required"> {{ 'ALLGEMEIN.LEERES_FELD' | translate }} </span>
                <span *ngIf="errors.email">
                  {{ 'EMAIL_VALIDIEREN.UNGUELTIGE_EMAIL' | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!mustValidateEmail" class="form-group">
          <div class="row">
            <label class="col-sm-2 col-12 col-form-label text-right">
              {{ 'EMAIL_VALIDIEREN.PASSWORT' | translate }}:
            </label>
            <div class="col-sm-10 col-12">
              <input
                id="password"
                class="form-control"
                type="password"
                formControlName="password"
                autocomplete="new-password"
                [class.is-invalid]="submitted && controls['password'].invalid"
              />
              <div class="invalid-feedback" *ngIf="submitted && controls['password'].errors as errors">
                <span *ngIf="errors.required"> {{ 'ALLGEMEIN.LEERES_FELD' | translate }} </span>
                <span *ngIf="errors.password">
                  {{ 'EMAIL_VALIDIEREN.UNGUELTIGES_PASSWORT' | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <ng-template #linkRequestedTemplate>
      <div class="text-center mb-3">
        <div>{{ 'EMAIL_VALIDIEREN.BESTAETIGUNGSLINK_VERSENDET' | translate }}:</div>
        <div class="text-highlight">{{ controls['email'].value }}</div>
      </div>
      <div class="text-center mb-3">
        <span> {{ 'EMAIL_VALIDIEREN.HABEN_SIE_SICH_VERTIPPT' | translate }} </span>
        <span class="text-highlight text-underline" (click)="resetForm()">
          {{ 'EMAIL_VALIDIEREN.HIER_ADRESSE_KORRIGIEREN' | translate }}
        </span>
      </div>
      <div class="text-center">
        <span> {{ 'EMAIL_VALIDIEREN.INFO_EMAIL_ADRESSE_AKTIVIEREN' | translate }} </span>
      </div>
    </ng-template>
  </div>
</div>

<div class="modal-footer">
  <div class="ml-auto">
    <button type="button" class="btn btn-secondary" *ngIf="!mustValidateEmail && !linkRequested" (click)="cancel()">
      {{ 'ALLGEMEIN.ABBRECHEN' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-primary ml-2"
      data-style="slide-left"
      [disabled]="linkRequested && seconds > 0"
      [ladda]="submitting"
      (click)="submit()"
    >
      <span *ngIf="!linkRequested; else linkRequestedSubmitButtonTemplate">
        {{ 'EMAIL_VALIDIEREN.BESTAETIGUNGSLINK_ANFORDERN' | translate }}
      </span>
      <ng-template #linkRequestedSubmitButtonTemplate>
        <span> {{ 'ALLGEMEIN.WEITER' | translate }} </span>
        <span [hidden]="seconds <= 0"> ({{ seconds }} {{ 'EMAIL_VALIDIEREN.SECONDS' | translate }}) </span>
      </ng-template>
    </button>
  </div>
</div>
