<div class="widget-content-container termine-widget">
  <div slimScroll [scrollEvents]="slimScrollEvents" class="scroll-space h-100">
    <div *ngIf="termineLoading"><iq-loading-indicator></iq-loading-indicator></div>
    <div *ngIf="errMessage && !termineLoading">
      <h4>{{ errMessage | translate }}</h4>
    </div>
    <div *ngIf="!termineLoading">
      <p *ngIf="termine.length === 0" class="text-center">{{ 'KEINE_DATENSAETZE_VORHANDEN' | translate }}</p>
      <div class="table-container table-responsive" *ngIf="termine.length > 0">
        <table class="table table-hover">
          <tbody>
            <ng-container *ngFor="let termin of termine">
              <tr #terminRow class="no-inline-border" [class.abgelaufen]="termin.Abgelaufen">
                <td>
                  <button
                    type="button"
                    class="btn btn-sm m-0 p-0 expand-button"
                    (click)="termin.expanded = !termin.expanded"
                  >
                    <span class="fa" [class.fa-angle-down]="termin.expanded" [class.fa-angle-right]="!termin.expanded">
                    </span>
                  </button>
                </td>
                <td>
                  <div class="text-bold">
                    <span> {{ termin.GueltigVon | date: FORMAT.DATE }} </span>
                    <span *ngIf="termin.GueltigVon !== termin.GueltigBis">
                      - {{ termin.GueltigBis | date: FORMAT.DATE }}
                    </span>
                  </div>
                  <div class="text-muted">{{ termin.Ueberschrift }}</div>
                </td>
                <td></td>
              </tr>
              <tr class="hide-tr-hover" *ngIf="termin.expanded">
                <td colspan="2">{{ termin.Text }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<!-- widget bearbeiten -->
<div class="widget-bearbeiten-container disabled"></div>
