import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FORMAT, VALUES } from '@core/constants';
import { LocalStorageService } from '@mattlewis92/angular-2-local-storage';
import { LOCALSTORAGE } from '@core/local-storage/local-storage.constants';
import { FilterService } from '@share/filter/filter.service';
import { FilterBereiche } from '@share/filter/filter-bereiche';
import { find } from 'lodash-es';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import {
  GesetzteFelder,
  GesperrteFelder,
  VergleichOptionen,
  VersteckteFelder
} from './vergleichswerte-bearbeiten-modal.interfaces';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Schlachtbetrieb, Schlachtbetriebe } from '@share/filter/filter.interfaces';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iq-vergleichswerte-bearbeiten-modal',
  templateUrl: './vergleichswerte-bearbeiten-modal.component.html',
  styleUrls: ['./vergleichswerte-bearbeiten-modal.component.scss']
})
export class VergleichswerteBearbeitenModalComponent implements OnInit, OnDestroy {
  /**
   * The comparison options to edit.
   */
  @Input()
  vergleichsoptionen: VergleichOptionen;

  /**
   * The available slaughterhouses.
   */
  @Input()
  schlachtbetriebe: Schlachtbetriebe;

  /**
   * The locked fields inside the form.
   */
  @Input()
  gesperrteFelder: GesperrteFelder;

  /**
   * The hidden fields inside the form.
   */
  @Input()
  versteckteFelder: VersteckteFelder;

  /**
   * The fields already set in the form with their values
   */
  @Input()
  gesetzteFelder: GesetzteFelder;

  /**
   * The filter sections (SO, ETM, ETZ)
   */
  @Input()
  filterBereich: FilterBereiche;

  /**
   * The form for the comparison values
   */
  vergleichswerteForm: FormGroup;

  /**
   * Specifies if the user tried to submit the form
   */
  submitted = false;

  /**
   * Indicates whether the hint text that the slaughterhouse with the most
   * data sets is automatically selected is displayed or not.
   */
  hideVergleichSchlachtbetriebHinweis: boolean;

  /**
   * The available percentage figures
   */
  prozentzahlen = VALUES.VERGLEICHSGRUPPEN;

  /**
   * Makes the format constanst available inside the template.
   */
  FORMAT = FORMAT;

  /**
   * Stream, which informs the subscriber whether the modal dialog
   * has been closed by confirming or canceling changes.
   */
  onClose$: Subject<VergleichOptionen>;

  /**
   * Name of the selected slaughterhouse
   */
  selectedSchlachtBetriebName = '';

  /**
   * Indicates if the filters are loading.
   */
  filterLoading: boolean;

  /**
   * Constructor
   * @param bsModalRef {@link BsModalRef}
   * @param localStorageService {@link LocalStorageService}
   * @param filterService {@link FilterService}
   */
  constructor(
    private bsModalRef: BsModalRef,
    private localStorageService: LocalStorageService,
    private filterService: FilterService
  ) {
    this.onClose$ = new Subject<VergleichOptionen>();
    this.filterLoading = true;
    this.hideVergleichSchlachtbetriebHinweis = false;
  }

  ngOnInit() {
    const foundSchlachtbetrieb: Schlachtbetrieb = find(this.schlachtbetriebe, (schlachtbetrieb: Schlachtbetrieb) => schlachtbetrieb.id === this.vergleichsoptionen.schlachtbetriebid);
    if (foundSchlachtbetrieb) {
      this.selectedSchlachtBetriebName = foundSchlachtbetrieb.displayName;
    }

    if (this.filterBereich === FilterBereiche.SO) {
      this.hideVergleichSchlachtbetriebHinweis = this.localStorageService.get(
        LOCALSTORAGE.VERGLEICHS_SCHLACHTBETRIEB_ID + '.' + FilterBereiche.SO
      )
        ? true
        : false;
    }

    this.vergleichswerteForm = new FormGroup({
      prozentzahl: new FormControl(this.vergleichsoptionen.prozent, [Validators.required]),
      schlachtbetrieb: new FormControl(
        this.vergleichsoptionen.schlachtbetriebid,
        this.filterBereich === FilterBereiche.SO ? [Validators.required] : undefined
      ),
      vergleichswerteAusblenden: new FormControl(this.vergleichsoptionen.vergleichswerteAusblenden)
    });

    if (this.gesetzteFelder.vergleichswerteAusblenden) {
      this.vergleichswerteForm.get('vergleichswerteAusblenden').setValue(true);
    }

    this.configureForm();
    this.prepareChangeListener();
  }

  /**
   * Completes the onClose$-stream.
   */
  ngOnDestroy() {
    this.onClose$.complete();
  }

  /**
   * Return the FormControl with the given name
   * @param name the name of the FormControl
   */
  getControl(name: string): AbstractControl {
    return this.vergleichswerteForm.get(name);
  }

  /**
   * Passes the processed comparison options to the caller if the
   * form is valid. Then resets the form and closes the modal dialog.
   */
  submit() {
    this.submitted = true;
    if (this.vergleichswerteForm.valid) {
      this.onClose$.next(this.vergleichsoptionen);
      this.onClose$.complete();
      this.bsModalRef.hide();
    }
  }

  /**
   * Cancels the addition of a missing delivery lot.
   */
  cancel() {
    this.onClose$.complete();
    this.bsModalRef.hide();
  }

  /**
   * Sets valueChanges listener on some form elements.
   */
  private prepareChangeListener() {
    this.vergleichswerteForm
      .get('prozentzahl')
      .valueChanges.pipe(takeUntil(this.onClose$))
      .subscribe(prozentWert => {
        this.vergleichsoptionen.prozent = parseInt(prozentWert, 10);
      });
    this.vergleichswerteForm
      .get('schlachtbetrieb')
      .valueChanges.pipe(takeUntil(this.onClose$))
      .subscribe(schlachtbetriebeId => {
        this.vergleichsoptionen.schlachtbetriebid = schlachtbetriebeId;
      });
    this.vergleichswerteForm
      .get('vergleichswerteAusblenden')
      .valueChanges.pipe(takeUntil(this.onClose$))
      .subscribe(ausblenden => {
        this.vergleichsoptionen.vergleichswerteAusblenden = ausblenden;
      });
  }

  /**
   * Initializes the form.
   */
  private configureForm() {
    this.filterService
      .filterReady(this.filterBereich)
      .pipe(takeUntil(this.onClose$))
      .subscribe(() => {
        this.filterLoading = false;
      });
  }
}
