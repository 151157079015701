import { Component, OnInit } from '@angular/core';
import { AntibiotikaUebersichtData } from '../../../antibiotika/monitoring-overview/share/antibiotika.interfaces';
import { isNil } from 'lodash-es';
import { CellComponent } from '@iq-angular-libs/portal';

@Component({
  selector: 'iq-quartal',
  templateUrl: './halbjahr.component.html'
})
export class HalbjahrComponent implements OnInit, CellComponent {
  /**
   * The column data
   */
  data: AntibiotikaUebersichtData;

  /**
   * The cell options
   */
  options?: {
    columnField: string;
  };

  /**
   * Indicates whether the template should be displayed
   */
  showColumn: boolean;

  /**
   * Indicates whether the quarter should be displayed
   */
  showHalbjahr: boolean;

  /**
   * Indicates whether the template should be displayed and if the quarter
   * suffix should be appended.
   */
  ngOnInit() {
    if (this.data && this.options && this.options.columnField) {
      this.showColumn = !isNil(this.data[this.options.columnField]) || this.data.ProduktId.indexOf('3') === 0;
      this.showHalbjahr = this.data.ProduktId.indexOf('3') !== 0;
    }
  }
}
