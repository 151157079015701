<div class="modal-header">
  <h4 class="modal-title">
    <span class="float-left">
      <span>{{ 'WIDGETS.REGNUMMER_FOKUSSIEREN_TITEL' | translate }}</span>
      <span *ngIf="propertiesHint.field === 'RegnummernFokussierenMitRoutingHinweisAnzeigen'">
        {{ 'WIDGETS.REGNUMMER_FOKUSSIEREN_TITEL_PART_2' | translate }}
      </span>
    </span>
  </h4>
  <button type="button" class="close float-right" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div>
    <span>{{ 'WIDGETS.REGNUMMER_FOKUSSIEREN_FRAGE' | translate }}</span>
    <span *ngIf="rubrikTitel && propertiesHint.field === 'RegnummernFokussierenMitRoutingHinweisAnzeigen'">
      {{ 'WIDGETS.REGNUMMER_FOKUSSIEREN_RUBRIK_AUFRUF' | translate: { rubrikTitel: rubrikTitel } }}
    </span>
  </div>
  <div class="text-muted">
    <small> {{ 'WIDGETS.REGNUMMER_FOKUSSIEREN_HINWEIS' | translate }} </small>
  </div>
</div>

<div class="modal-footer">
  <form [formGroup]="form" role="form">
    <div class="form-group">
      <div class="float-left pretty p-svg">
        <input type="checkbox" formControlName="RegnummernFokussierenHinweisAnzeigen" />
        <div class="state p-primary">
          <iq-svg-check></iq-svg-check>
          <label>{{ 'ALLGEMEIN.MELDUNG_NICHT_MEHR_ANZEIGEN' | translate }}</label>
        </div>
      </div>
    </div>
  </form>

  <div class="ml-auto">
    <button type="button" class="btn btn-secondary" (click)="cancel()">{{ 'ALLGEMEIN.ABBRECHEN' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="ok()">{{ 'ALLGEMEIN.UEBERNEHMEN' | translate }}</button>
  </div>
</div>
