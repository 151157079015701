import { Component, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { XAxisTicksComponent, reduceTicks } from '@swimlane/ngx-charts';
import { forEach } from 'lodash-es';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'g[iq-charts-x-axis-ticks]',
  template: `
    <svg:g #ticksel>
      <svg:g *ngFor="let tick of ticks" class="tick" [attr.transform]="tickTransform(tick)">
        <title>{{ tickFormat(tick) }}</title>
        <svg:text
          stroke-width="0.01"
          [attr.text-anchor]="textAnchor"
          [attr.transform]="textTransform"
          [style.font-size]="'12px'"
        >
          {{ tickTrim(tickFormat(tick)) }}
        </svg:text>
      </svg:g>
    </svg:g>
    <svg:g *ngFor="let tick of ticks" [attr.transform]="tickTransform(tick)">
      <svg:g *ngIf="showGridLines" [attr.transform]="gridLineTransform()">
        <svg:line class="gridline-path gridline-path-vertical" [attr.y1]="-gridLineHeight" y2="0" />
      </svg:g>
    </svg:g>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IqXAxisTicksComponent extends XAxisTicksComponent implements OnChanges {
  ngOnChanges(changes: SimpleChanges): void {
    this.update();
  }

  /**
   * Override the getTicks function from the XAxisTicksComponent.
   * This function is called in the update() function.
   */
  getTicks() {
    let ticks;
    const tickCharacterMultiplier = 10;
    const maxTicks = this.getMaxTicks(this.getAverageTicksLength() * tickCharacterMultiplier);
    const maxScaleTicks = this.getMaxTicks(100);

    if (this.tickValues) {
      ticks = this.tickValues;
    } else if (this.scale.ticks) {
      ticks = this.scale.ticks.apply(this.scale, [maxScaleTicks]);
    } else {
      ticks = this.scale.domain();
      ticks = this.reduceTicks(ticks, maxTicks);
    }

    return ticks;
  }

  /**
   * Determines the average number of characters of the ticks
   */
  private getAverageTicksLength(): number {
    const ticks: string[] = this.scale.domain();
    let totalTicksLength = 0;
    forEach(ticks, (tick: string) => {
      totalTicksLength += tick.length;
    });
    return totalTicksLength / ticks.length;
  }

  /**
   * Reduces the ticks if there are too many elements for the diagram width
   * @param ticks The ticks of the diagram
   * @param maxTicks The precalculated maximum number of ticks
   */
  private reduceTicks(ticks, maxTicks) {
    if (ticks.length > maxTicks) {
      const reduced = [];
      const modulus = Math.ceil(ticks.length / maxTicks);
      for (let i = 0; i < ticks.length; i++) {
        if (i % modulus === 0) {
          reduced.push(ticks[i]);
        }
      }
      ticks = reduced;
    }

    return ticks;
  }
}
