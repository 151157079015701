import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { LocalStorageService } from '@mattlewis92/angular-2-local-storage';
import { LOCALSTORAGE } from '../local-storage/local-storage.constants';
import { catchError, tap, filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from '../session/session.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Stores how many toasts are currently active.
   */
  activeToasts = 0;

  constructor(
    private localStorageService: LocalStorageService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private router: Router,
    private sessionService: SessionService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authRequest: HttpRequest<any>;
    const authData: { token: string } = this.localStorageService.get(LOCALSTORAGE.AUTHORIZATION_DATA);
    if (authData && authData.token) {
      authRequest = req.clone({ headers: req.headers.set('AccessToken', authData.token) });
    }
    const nextRequest = authRequest ? authRequest : req;
    return next.handle(nextRequest).pipe(
      filter((event: HttpEvent<any>) => event instanceof HttpResponse),
      tap({
        next: (response: HttpResponse<any>) => {
          if (
            !response.url.includes('/assets/i18n/') &&
            response.headers &&
            ((nextRequest.method === 'POST' && response.headers.get('AccessToken')) || nextRequest.method !== 'POST')
          ) {
            this.localStorageService.set(LOCALSTORAGE.AUTHORIZATION_DATA, {
              token: response.headers.get('AccessToken')
            });
          }
          this.sessionService.resetSessionNext();
        }
      }),
      catchError(err => {
        if (err.status === 401 || err.status === 402 || err.status === 403) {
          if (err.status === 401) {
            this.sessionService.sessionActive.next(false);
            this.showToast('ROUTING.TOASTS.SESSION_EXPIRED', 'ROUTING.TOASTS.SESSION_EXPIRED_TITLE', 'info');
            return of({} as any);
          } else {
            this.router.navigate(['/']);
            this.showToast('ROUTING.TOASTS.BERECHTIGUNGSFEHLER', 'ALLGEMEIN.TOASTS.WARNUNG', 'warning');
          }
        }
        return throwError(() => err);
      })
    );
  }

  private showToast(
    notificationKey: string,
    notificationTitleKey: string,
    notificationType: string
  ): void {
    this.translate.get([notificationKey, notificationTitleKey]).subscribe(translations => {
      switch (notificationType) {
      case 'warning':
        this.toastr.warning(translations[notificationKey], translations[notificationTitleKey]);
        break;
      default:
        this.toastr.info(translations[notificationKey], translations[notificationTitleKey]);
      }
    });
  }
}
