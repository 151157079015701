<div class="widget-content-container auffaellige-betriebe-widget">
  <iq-loading-indicator *ngIf="betriebsstaettenLoading"></iq-loading-indicator>
  <div *ngIf="!betriebsstaettenLoading" class="h-100">
    <div class="tabs-container h-100">
      <ul class="nav nav-tabs">
        <li class="nav-item" [class.active]="selectedTab === AuffaelligeBetriebeTabs.TageSeitLetzterPartie">
          <a
            class="nav-link"
            (click)="selectTab($event, AuffaelligeBetriebeTabs.TageSeitLetzterPartie)"
            title="{{ 'WIDGETS.AUFFAELLIGE_BETRIEBSSTAETTEN.TAGE_SEIT_LETZTER_LIEFERPARTIE' | translate }}"
            >{{ 'WIDGETS.AUFFAELLIGE_BETRIEBSSTAETTEN.TAGE_SEIT_LETZTER_LIEFERPARTIE' | translate }}</a
          >
        </li>
        <li class="nav-item" [class.active]="selectedTab === AuffaelligeBetriebeTabs.OhneSchlachtdaten">
          <a
            class="nav-link"
            (click)="selectTab($event, AuffaelligeBetriebeTabs.OhneSchlachtdaten)"
            title="{{ 'WIDGETS.AUFFAELLIGE_BETRIEBSSTAETTEN.OHNE_SCHLACHTDATEN' | translate }}"
            >{{ 'WIDGETS.AUFFAELLIGE_BETRIEBSSTAETTEN.OHNE_SCHLACHTDATEN' | translate }}</a
          >
        </li>
      </ul>
      <div class="tab-content-height">
        <div slimScroll class="scroll-space h-100" [scrollEvents]="slimScrollEvents">
          <div [ngSwitch]="selectedTab">
            <ng-container
              *ngSwitchCase="AuffaelligeBetriebeTabs.TageSeitLetzterPartie"
              [ngTemplateOutlet]="tageSeitLetzterPartie"
            ></ng-container>
            <ng-container
              *ngSwitchCase="AuffaelligeBetriebeTabs.OhneSchlachtdaten"
              [ngTemplateOutlet]="betriebeOhneSchlachtdaten"
            ></ng-container>
            <ng-container *ngSwitchDefault [ngTemplateOutlet]="tageSeitLetzterPartie"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Describes the table for the farms without slaughter data -->
  <ng-template #betriebeOhneSchlachtdaten>
    <div *ngIf="betriebsstaettenOhneSchlachtdaten.length === 0">
      <p class="text-center">{{ 'KEINE_DATENSAETZE_VORHANDEN' | translate }}</p>
    </div>
    <div *ngIf="betriebsstaettenOhneSchlachtdaten.length > 0" class="table-container table-responsive mb-2">
      <table class="table table-hover">
        <tbody>
          <tr *ngFor="let betriebsstaette of betriebsstaettenOhneSchlachtdaten">
            <td *ngFor="let column of columnsOhneSchlachtdaten" [style.min-width]="column.minWidth">
              <div [class]="column.cellClass(column)">{{ betriebsstaette[column.field] }}</div>
            </td>
            <td class="text-center btn-column">
              <iq-focus-betriebsstaette
                [data]="{ Registrierungsnummer: betriebsstaette['Registrierungsnummer'] }"
                [options]="focusBetriebsstaetteOptions"
              >
              </iq-focus-betriebsstaette>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-template>

  <!-- Describes the table for the "Days since last delivery batch" table -->
  <ng-template #tageSeitLetzterPartie>
    <div *ngIf="tageSeitLetzterLieferpartie.length === 0">
      <p class="text-center">{{ 'KEINE_DATENSAETZE_VORHANDEN' | translate }}</p>
    </div>
    <div *ngIf="tageSeitLetzterLieferpartie.length > 0" class="table-container table-responsive mb-2">
      <table class="table table-hover">
        <tbody>
          <tr *ngFor="let betriebsstaette of tageSeitLetzterLieferpartie">
            <td *ngFor="let column of columns" [style.min-width]="column.minWidth">
              <div [class]="column.cellClass(column)">{{ betriebsstaette[column.field] }}</div>
              <div *ngIf="column.field === 'Registrierungsnummer'" class="text-muted">
                {{ betriebsstaette.BetriebsstaettenName }}
              </div>
            </td>
            <td class="text-center btn-column">
              <iq-focus-betriebsstaette
                [data]="{ Registrierungsnummer: betriebsstaette['Registrierungsnummer'] }"
                [options]="focusBetriebsstaetteOptions"
              >
              </iq-focus-betriebsstaette>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="tageSeitLetzterLieferpartie.length > 0" class="text-right mb-2">
      <iq-focus-betriebsstaetten [data]="focusBetriebsstaettenDataTage" [options]="focusBetriebsstaettenOptions">
      </iq-focus-betriebsstaetten>
    </div>
  </ng-template>

  <!-- edit widget -->
  <div
    class="widget-bearbeiten-container"
    [class.disabled]="selectedTab === AuffaelligeBetriebeTabs.OhneSchlachtdaten"
    [@openCloseOptions]="optionsOpen ? 'optionsOpen' : 'optionsClosed'"
    (@openCloseOptions.done)="onOptionsAnimationDone($event)"
  >
    <div
      class="icon-container"
      [ngClass]="{ active: optionsOpen, disabled: selectedTab === AuffaelligeBetriebeTabs.OhneSchlachtdaten }"
      (click)="toggleOptionsPanel()"
    >
      <i
        class="fa icon"
        [ngClass]="{
          active: optionsOpen,
          'fa-angle-up': !optionsOpen,
          'fa-angle-down': optionsOpen
        }"
        aria-hidden="true"
      ></i>
    </div>
    <div class="options-content text-center" *ngIf="showOptions">
      <div class="options-text">
        {{ 'WIDGETS.AUFFAELLIGE_BETRIEBSSTAETTEN.TAGE_SEIT_LETZTER_LIEFERPARTIE' | translate }}:
      </div>
      <div class="pretty p-default p-round" *ngFor="let tage of anzahlTageEnum">
        <input
          [name]="radioButtonName + '_' + tage"
          type="radio"
          [(ngModel)]="anzahlTage"
          (ngModelChange)="anzahlTageChanged($event)"
          [value]="tage"
        />
        <div class="state p-primary-o">
          <label>{{ tage }}</label>
        </div>
      </div>

    </div>
  </div>
</div>
