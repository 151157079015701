import { FocusBetriebsstaetteData, FocusBetriebsstaetteOptions } from '../table-cell-components.interfaces';
import { Component, Input, OnInit } from '@angular/core';
import { GlobalFilterService } from '@core/global-filter/global-filter.service';
import { CellComponent } from '@iq-angular-libs/portal';

@Component({
  selector: 'iq-focus-betriebsstaette',
  templateUrl: './focus-betriebsstaette.component.html',
  styleUrls: ['./focus-betriebsstaette.component.scss']
})
export class FocusBetriebsstaetteComponent implements OnInit, CellComponent {
  /**
   * The comerical units data of the comerical units to be focused
   */
  @Input()
  data: FocusBetriebsstaetteData;

  /**
   * The options of the component
   */
  @Input()
  options: FocusBetriebsstaetteOptions;

  constructor(private globalFilterService: GlobalFilterService) {}

  /**
   * Sets the initial options if they are not set.
   */
  ngOnInit() {
    if (this.options) {
      if (!this.options.tooltipText) {
        this.options.tooltipText = '';
      }
      if (!this.options.tooltipPlacement) {
        this.options.tooltipPlacement = 'bottom';
      }
    } else {
      this.options = {
        tooltipText: '',
        tooltipPlacement: 'bottom'
      };
    }
  }

  /**
   * Focuses the selected comercial units
   */
  focusBetriebsstaette() {
    if (this.data && this.data.Registrierungsnummer) {
      this.globalFilterService.addBetriebsstaette(this.data.Registrierungsnummer);
    }
  }
}
