<div class="modal-header">
  <h4 class="modal-title">{{ 'WIDGETS.BETRIEBSSTAETTE_HINZUFUEGEN_TITEL' | translate }}</h4>
  <button type="button" class="close float-right" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p
    class="m-0"
    [innerHTML]="'WIDGETS.BETRIEBSSTAETTE_HINZUFUEGEN_HINWEIS' | translate: { rubrikTitel: rubrikTitel }"
  ></p>
  <div class="text-muted">
    <small> {{ 'WIDGETS.REGNUMMER_FOKUSSIEREN_HINWEIS' | translate }} </small>
  </div>
</div>

<div class="modal-footer">
  <form [formGroup]="form" role="form">
    <div class="form-group">
      <div class="float-left pretty p-svg">
        <input type="checkbox" formControlName="DetailNavigationHinweisAnzeigen" />
        <div class="state p-primary">
          <iq-svg-check></iq-svg-check>
          <label>{{ 'ALLGEMEIN.MELDUNG_NICHT_MEHR_ANZEIGEN' | translate }}</label>
        </div>
      </div>
    </div>
  </form>

  <div class="ml-auto">
    <button type="button" class="btn btn-secondary" id="cancel-btn" (click)="cancel()">
      {{ 'ALLGEMEIN.ABBRECHEN' | translate }}
    </button>
    <button type="button" class="btn btn-primary" id="ok-btn" (click)="ok()">
      {{ 'ALLGEMEIN.WEITER' | translate }}
    </button>
  </div>
</div>
