import { Injectable } from '@angular/core';
import { Observable, of, race, Subject } from 'rxjs';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NewBackendModalComponent } from '@core/lockscreen/new-backend-modal/new-backend-modal.component';
import { ChangePasswordModalComponent } from '@core/lockscreen/change-password-modal/change-password-modal.component';
import { filter, switchMap, take } from 'rxjs/operators';
import { DataService } from '@core/data/data.service';
import { ChangeEmailModalComponent } from '@core/lockscreen/change-email-modal/change-email-modal.component';

@Injectable()
export class LockscreenService {
  /**
   * Specifies if the "new backend version" modal is currently open
   */
  private newBackendModalOpened: boolean;

  constructor(private modalService: BsModalService, private dataService: DataService) {
    this.newBackendModalOpened = false;

    this.dataService.isNewBackendVersion().subscribe(() => this.openNewBackendModal());
  }

  /**
   * Opens the lockscreen to change the password
   * @param useAsLockscreen Specifies if the dialog will be shown as lockscreen
   */
  openChangePasswordModal(useAsLockscreen: boolean): Observable<boolean> {
    const timeoutTime = useAsLockscreen ? 1000 : 0;
    const subject = new Subject<boolean>();
    const modalOptions: ModalOptions<ChangePasswordModalComponent> = {
      backdrop: 'static',
      initialState: {
        mustChangePassword: useAsLockscreen
      }
    };

    if (useAsLockscreen) {
      modalOptions.keyboard = false;
    }

    this.closeAllModals();

    setTimeout(() => {
      const modalRef = this.modalService.show(ChangePasswordModalComponent, modalOptions);
      const modalHidden$ = this.modalService.onHidden.pipe(
        filter(reason => reason === 'esc'),
        switchMap(() => of(<boolean>false))
      );

      race<boolean[]>([modalRef.content.onClose$.asObservable(), modalHidden$])
        .pipe(take(1))
        .subscribe({
          next: closed => {
            subject.next(closed);
          },
          error: () => {
            subject.next(false);
            subject.complete();
          },
          complete: () => {
            subject.complete();
          }
        });
    }, timeoutTime);

    return subject.asObservable();
  }

  /**
   * Open the lockscreen to validate the user email
   * @param useAsLockscreen Specifies if the dialog will be shown as lockscreen
   */
  openValidateEmailModal(useAsLockscreen: boolean): Observable<boolean> {
    const timeoutTime = useAsLockscreen ? 1000 : 0;
    const subject = new Subject<boolean>();
    const modalOptions: ModalOptions<ChangeEmailModalComponent> = {
      backdrop: 'static',
      initialState: {
        mustValidateEmail: useAsLockscreen
      }
    };

    if (useAsLockscreen) {
      modalOptions.keyboard = false;
    }

    this.closeAllModals();

    setTimeout(() => {
      const modalRef = this.modalService.show(ChangeEmailModalComponent, modalOptions);
      const modalHidden$ = this.modalService.onHidden.pipe(
        filter(reason => reason === 'esc'),
        switchMap(() => of(<boolean>false))
      );

      race([modalRef.content.onClose$.asObservable(), modalHidden$])
        .pipe(take(1))
        .subscribe({
          next: closed => {
            subject.next(closed);
          },
          error: () => {
            subject.next(false);
            subject.complete();
          },
          complete: () => {
            subject.complete();
          }
        });
    }, timeoutTime);

    return subject.asObservable();
  }

  isNewBackendModalOpen(): boolean {
    return this.newBackendModalOpened;
  }

  closeAllModals(): void {
    const modalCount = this.modalService.getModalsCount();
    for (let i = modalCount; i > 0; i--) {
      this.modalService.hide(i);
    }
  }

  /**
   * Opens the lockscreen which requests a reload of the page if a new backend version is available. It will be
   * opened when the "new backend version" event of the dataservice emits.
   */
  private openNewBackendModal(): void {
    if (!this.newBackendModalOpened) {
      const modalOptions: ModalOptions = {
        backdrop: 'static',
        keyboard: false
      };
      this.newBackendModalOpened = true;
      this.closeAllModals();
      setTimeout(() => {
        this.modalService.show(NewBackendModalComponent, modalOptions);
      }, 1000);
    }
  }
}
