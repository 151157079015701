import { Injectable } from '@angular/core';
import {
  DatumInfo,
  IqFilterData,
  IqFilterItem,
  IqFilterItems,
  MinMax,
  UebergeordneteFilterChangedEvent
} from '@share/filter/uebergeordnete-filter.interfaces';
import { FilterBereiche } from '@share/filter/filter-bereiche';
import { cloneDeep, filter, find, forEach, isArray, isEqual, orderBy } from 'lodash-es';
import { LOCALSTORAGE } from '@core/local-storage/local-storage.constants';
import { Observable, Subject, throwError } from 'rxjs';
import { filter as rxjsFilter } from 'rxjs/operators';
import { next } from '@core/next-observable';
import { LocalStorageService } from '@mattlewis92/angular-2-local-storage';
import { DatumFilterMode, LetzteXTageMode } from '@share/filter/datum-filter.enums';
import { Router } from '@angular/router';
import { FilterListTypes } from '@share/filter/filter.interfaces';
import { GeschlechterFilterComponent } from '@share/filter/filter-components/geschlechter-filter/geschlechter-filter.component';
import { EinsenderzeichenFilterComponent } from './filter-components/einsenderzeichen-filter/einsenderzeichen-filter.component';
import { SchlachtbetriebeFilterComponent } from './filter-components/schlachtbetriebe-filter/schlachtbetriebe-filter.component';
import { BetriebsstaettenFilterComponent } from './filter-components/betriebsstaetten-filter/betriebsstaetten-filter.component';
import { SchlachtgewichtFilterButtonComponent } from '@share/filter/filter-components/schlachtgewicht-filter/schlachtgewicht-filter-button/schlachtgewicht-filter-button.component';
import { TzKalkFilterButtonComponent } from './filter-components/tz-kalk-filter/tz-kalk-filter-button/tz-kalk-filter-button.component';
import { DatumFilterButtonComponent } from '@share/filter/filter-components/datum-filter/datum-filter-button/datum-filter-button.component';
import { TierAlterFilterButtonComponent } from './filter-components/tier-alter-filter/tier-alter-filter-button/tier-alter-filter-button.component';
import { SauRassenFilterComponent } from './filter-components/sau-rassen-filter/sau-rassen-filter.component';
import { SauNummernFilterComponent } from './filter-components/sau-nummern-filter/sau-nummern-filter.component';
import { EberRassenFilterComponent } from './filter-components/eber-rassen-filter/eber-rassen-filter.component';
import { EberNummernFilterComponent } from './filter-components/eber-nummern-filter/eber-nummern-filter.component';
import { WurfNummernFilterComponent } from './filter-components/wurf-nummern-filter/wurf-nummern-filter.component';
import { orderCaseUnsensitiv } from '@iq-angular-libs/portal';

export interface IUebergeordneteFilterService {
  initSelectedFilters(filterBereich: FilterBereiche): void;
  initAfterLogin(filterBereich: FilterBereiche): void;
  getUebergeordneteFilterChangedObservable(): Observable<UebergeordneteFilterChangedEvent>;
  getUebergeordneteFilterChangedByFilterSetChangedObservable(): Observable<UebergeordneteFilterChangedEvent>;
  getFilters(filterBereich): IqFilterItems;
  getSelectedFilters(filterBereich): IqFilterItems;
  setSelectedFilters(
    newSelectedFilters: IqFilterItems,
    filterBereich: FilterBereiche,
    shouldBroadcastChange: boolean
  ): boolean;

  isInUebergeordneteFilter(filterProperty: string, filterBereich: FilterBereiche): boolean;

  getSelectedItemsOfFilterWithSegmentProperty(
    segmentProperty: string,
    filterBereich: FilterBereiche
  ): FilterListTypes | DatumInfo | MinMax | null;
  removeFilters(segmentProperties: string[], filterBereich: FilterBereiche, broadcastEvent: boolean): void;
  resetSelectedFilters(filterBereich: FilterBereiche, broadcastEvent: boolean): void;
  reset(): void;
}

/**
 * The service manages the higher-level filters of the application of the respective filter area.
 */
@Injectable({
  providedIn: 'root'
})
export class UebergeordneteFilterService implements IUebergeordneteFilterService {
  /**
   * The change subject, which fires an event {@link UebergeordneteFilterChangedEvent} when the filter
   * for a specific filter section has changed.
   */
  private uebergeordneteFilterChangedSubject: Subject<UebergeordneteFilterChangedEvent>;

  /**
   * Stores the data for the specific sections
   */
  private currentData: IqFilterData;

  /**
   * Template which represents an empty date object,
   * Will be used from the date filter.
   */
  private emptyDatumInfo: DatumInfo;

  /**
   * Template, which defines the upper and lower limits for some filters.
   * Will for example be used from the slaughter date filter.
   */
  private emptyMinMaxObject: MinMax;

  /**
   * The available SO filters.
   */
  private soFilters: IqFilterItems;

  /**
   * Stores the data for the slaughter date section.
   */
  private soData: IqFilterData;

  /**
   * Stores the data for the ETM (single animal identification - fattening) section
   */
  private etmData: IqFilterData;

  /**
   * The available ETZ (single animal identification - breed) filters
   */
  private etzFilters: IqFilterItems;

  /**
   * Stores the data for the ETZ (single animal identification - breed) section
   */
  private etzData: IqFilterData;

  /**
   * Constructor.
   * @param router {@link Router}
   * @param localStorageService {@link LocalStorageService}
   */
  constructor(private router: Router, private localStorageService: LocalStorageService) {
    this.currentData = null;
    this.uebergeordneteFilterChangedSubject = new Subject<UebergeordneteFilterChangedEvent>();

    this.emptyDatumInfo = {
      mode: null,
      schlachttage: null,
      zeitraum: null,
      letzteXTage: null
    };

    this.emptyMinMaxObject = { min: null, max: null };

    this.createSoData();
    this.createEtmData();
    this.createEtzData();

    this.subscribeOnSoDataForSynchronizing(FilterBereiche.SO);
    this.subscribeOnEtmDataForSynchronizing(FilterBereiche.ETM);

    this.initSelectedFilters(FilterBereiche.SO);
    this.initSelectedFilters(FilterBereiche.ETM);
    this.initSelectedFilters(FilterBereiche.ETZ);
  }

  /**
   * Sets the selected filters of the passed filter section, if an entry is available in
   * the local storage.
   * @param filterBereich the specific filter section (SO, ETM, ...), in which the filter will be inserted.
   */
  initSelectedFilters(filterBereich: FilterBereiche): void {
    this.setVariablesForFilterBereich(filterBereich);

    const storedSelectedFilters = this.localStorageService.get<IqFilterItems>(
      this.currentData.localStorageUebergeordneteFilterKey
    );
    const selectedFilters: IqFilterItems = [];

    if (Array.isArray(storedSelectedFilters)) {
      forEach(storedSelectedFilters, (selectedFilter: IqFilterItem) => {
        const foundFilter = find(this.currentData.filters, (filterItem: IqFilterItem) => filterItem.filterProperty === selectedFilter.filterProperty) as IqFilterItem;

        if (foundFilter) {
          selectedFilter.component = foundFilter.component; // can not save component in LS because it is represented in JS as a function
          selectedFilters.push(selectedFilter); // save filter from local storage to keep selected items
        }
      });
    }

    this.setSelectedFilters(selectedFilters, filterBereich);
  }

  /**
   * Sets the selected filter depending of the given filter section, if
   * an entry exists in the local storage.
   * @param filterBereich the specific filter section (SO, ETM, ...), in which the filter will be inserted.
   */
  initAfterLogin(filterBereich: FilterBereiche): void {
    this.setVariablesForFilterBereich(filterBereich);

    const selectedFiltersFromLocalStorage = this.localStorageService.get<IqFilterItems>(
      this.currentData.localStorageUebergeordneteFilterKey
    );
    const selectedFilters = isArray(selectedFiltersFromLocalStorage) ? selectedFiltersFromLocalStorage : [];

    const searchForGeschlechterFilter = (itemFilter: IqFilterItem): boolean => itemFilter.segmentProperty === 'geschlechter';
    const searchForSchlachtbetriebeFilter = (itemFilter: IqFilterItem): boolean => itemFilter.segmentProperty === 'schlachtbetriebe';
    const searchForDatumFilter = (itemFilter: IqFilterItem): boolean => itemFilter.segmentProperty === 'datumInfo';

    // sets default filter
    const foundGeschlechterFilter = <IqFilterItems>filter(selectedFilters, searchForGeschlechterFilter);
    if (foundGeschlechterFilter.length === 0) {
      const geschlechterFilter = <IqFilterItems>filter(this.currentData.filters, searchForGeschlechterFilter);
      selectedFilters.push(cloneDeep(geschlechterFilter[0]));
    }

    // only for SO & ETM relevant
    if (filterBereich === FilterBereiche.SO || filterBereich === FilterBereiche.ETM) {
      const foundSchlachtbetriebeFilter = <IqFilterItems>filter(selectedFilters, searchForSchlachtbetriebeFilter);
      if (foundSchlachtbetriebeFilter.length === 0) {
        const schlachtbetriebeFilter = <IqFilterItems>filter(this.currentData.filters, searchForSchlachtbetriebeFilter);
        selectedFilters.push(cloneDeep(schlachtbetriebeFilter[0]));
      }
    }

    const foundDatumFilter = <IqFilterItems>filter(selectedFilters, searchForDatumFilter);
    if (foundDatumFilter.length === 0) {
      const datumFilter = <IqFilterItem>cloneDeep(filter(this.currentData.filters, searchForDatumFilter)[0]);

      switch (filterBereich) {
      case FilterBereiche.SO:
      case FilterBereiche.ETM:
        datumFilter.selectedItems = <DatumInfo>{
          mode: DatumFilterMode.LETZTE_X_TAGE,
          letzteXTage: {
            mode: LetzteXTageMode.LETZTE_90_TAGE
          }
        };
        break;

      case FilterBereiche.ETZ:
        datumFilter.selectedItems = <DatumInfo>{
          mode: DatumFilterMode.LETZTE_X_TAGE,
          letzteXTage: {
            mode: LetzteXTageMode.LETZTE_365_TAGE
          }
        };
        break;

      default:
      }

      selectedFilters.push(datumFilter);
    }
    this.setSelectedFilters(selectedFilters, filterBereich);
  }

  /**
   * Returns the (uebergeordneteFilterChangedSubject subject){@link UebergeordneteFilterService#uebergeordneteFilterChangedSubject}
   * as Observable. Internal events and events fired by loading a new filter set, are filtered out.
   */
  getUebergeordneteFilterChangedObservable(): Observable<UebergeordneteFilterChangedEvent> {
    return this.uebergeordneteFilterChangedSubject.asObservable().pipe(
      rxjsFilter((event: UebergeordneteFilterChangedEvent) => event.publicEvent && !event.filtersetsChangedExclusiveEvent)
    );
  }

  /**
   * Returns the (uebergeordneteFilterChangedSubject subject){@link UebergeordneteFilterService#uebergeordneteFilterChangedSubject}
   * as Observable. Internal events and events not fired by loading a new filter set, are filtered out.
   */
  getUebergeordneteFilterChangedByFilterSetChangedObservable(): Observable<UebergeordneteFilterChangedEvent> {
    return this.uebergeordneteFilterChangedSubject.asObservable().pipe(
      rxjsFilter((event: UebergeordneteFilterChangedEvent) => event.publicEvent && event.filtersetsChangedExclusiveEvent)
    );
  }

  /**
   * Returns the available filters of the given section.
   * @param filterBereich the specific section (SO, ETM, ...), the filter should be set for.
   */
  getFilters(filterBereich): IqFilterItems {
    this.setVariablesForFilterBereich(filterBereich);
    return cloneDeep(this.currentData.filters);
  }

  /**
   * Returns the selected filters of the given section.
   * @param filterBereich the specific section (SO, ETM, ...), the filter should be set for.
   */
  getSelectedFilters(filterBereich): IqFilterItems {
    this.setVariablesForFilterBereich(filterBereich);
    return cloneDeep(this.currentData.selectedFilters);
  }

  /**
   * Sets the selected filters for the given section.
   * Sends the FILTER_EVENTS.UEBERGEORDNETE_FILTER_CHANGED event, if wished.
   * @param newSelectedFilters the selected filters of the given section.
   * @param filterBereich the specific section (SO, ETM, ...), the filter should be set for.
   * @param shouldBroadcastChange specifies if the changed should be emittet as event
   */
  setSelectedFilters(
    newSelectedFilters: IqFilterItems,
    filterBereich: FilterBereiche,
    shouldBroadcastChange?: boolean,
    filtersetsChangedExclusiveEvent: boolean = false
  ): boolean {
    this.setVariablesForFilterBereich(filterBereich);

    if (shouldBroadcastChange === undefined) {
      shouldBroadcastChange = true;

      const deletedItems = filter(this.currentData.selectedFilters, (selectedFilter: IqFilterItem) => {
        const foundFilter = <IqFilterItems>filter(newSelectedFilters, (filterItem: IqFilterItem) => filterItem.filterProperty === selectedFilter.filterProperty);
        return foundFilter.length === 0;
      });
      const newItems = filter(newSelectedFilters, (newFilter: IqFilterItem) => {
        const foundFilter = filter(this.currentData.selectedFilters, (itemFilter: IqFilterItem) => itemFilter.filterProperty === newFilter.filterProperty);
        return foundFilter.length === 0;
      });

      if (newItems.length === 0) {
        // if none of the deleted filters has selected items,
        // no event has to be fired
        if (deletedItems.length !== 0) {
          forEach(deletedItems, (deletedItem: IqFilterItem) => {
            shouldBroadcastChange = !this.hasEmptyItems(deletedItem);
          });
        } else {
          shouldBroadcastChange = false;
        }
      }
    }

    const sortedSelectedFilters =
      orderBy<IqFilterItem>(
        newSelectedFilters,
        [orderCaseUnsensitiv('sortWeight'), orderCaseUnsensitiv('segmentProperty')],
        ['asc', 'asc']
      ) || [];
    this.currentData.selectedFilters = cloneDeep(sortedSelectedFilters);
    forEach(this.currentData.selectedFilters, (filterItem: IqFilterItem) => {
      filterItem.activeFilter = !this.hasEmptyItems(filterItem);
    });

    this.localStorageService.set(
      this.currentData.localStorageUebergeordneteFilterKey,
      this.currentData.selectedFilters
    );

    this.uebergeordneteFilterChangedSubject.next({
      publicEvent: shouldBroadcastChange,
      filtersetsChangedExclusiveEvent: filtersetsChangedExclusiveEvent,
      filterBereich: filterBereich,
      selectedFilters: this.currentData.selectedFilters
    });

    return shouldBroadcastChange;
  }

  /**
   * Determines if the passed filter property is contained in the selected filters
   * of the given section.
   * @param filterProperty the filter property
   * @param filterBereich the specific section (SO, ETM, ...), the filter should be set for.
   */
  isInUebergeordneteFilter(filterProperty: string, filterBereich: FilterBereiche): boolean {
    this.setVariablesForFilterBereich(filterBereich);

    const foundFilter = filter(this.currentData.selectedFilters, (filterItem: IqFilterItem) => filterItem.filterProperty === filterProperty);
    if (Array.isArray(foundFilter)) {
      return foundFilter.length > 0;
    }
    return false;
  }

  /**
   * Returns the selected entries of the filter which has the passed segmentProperty.
   * If the filter is not selected, null is returned.
   * @param segmentProperty the segment property
   * @param filterBereich the specific section (SO, ETM, ...), the filter should be set for.
   */
  getSelectedItemsOfFilterWithSegmentProperty(
    segmentProperty: string,
    filterBereich: FilterBereiche
  ): FilterListTypes | DatumInfo | MinMax | null {
    this.setVariablesForFilterBereich(filterBereich);

    const foundFilters: IqFilterItems = filter(this.currentData.selectedFilters, (filterItem: IqFilterItem) => filterItem.segmentProperty === segmentProperty);

    if (Array.isArray(foundFilters)) {
      if (foundFilters.length > 0) {
        return foundFilters[0].selectedItems;
      }
    }
    return null;
  }

  /**
   * Deletes the filters with the passed segment properties of the passed filter section.
   * If one of the filters does not exist, it will be ignored.
   * @param segmentProperties the segment properties
   * @param filterBereich the specific section (SO, ETM, ...), the filter should be set for.
   * @param broadcastEvent specifies if the superior SO filter should be fired
   */
  removeFilters(segmentProperties: string[], filterBereich: FilterBereiche, broadcastEvent: boolean): void {
    this.setVariablesForFilterBereich(filterBereich);

    forEach(segmentProperties, (segmentProperty: string) => {
      this.currentData.selectedFilters = filter(this.currentData.selectedFilters, (filterItem: IqFilterItem) => filterItem.segmentProperty !== segmentProperty);
    });

    this.localStorageService.set(
      this.currentData.localStorageUebergeordneteFilterKey,
      this.currentData.selectedFilters
    );

    this.uebergeordneteFilterChangedSubject.next({
      publicEvent: broadcastEvent,
      filterBereich: filterBereich,
      selectedFilters: this.currentData.selectedFilters
    });
  }

  /**
   * Resets the selected items of the active filters of the passed filter section.
   * @param filterBereich the specific section (SO, ETM, ...), the filter should be set for.
   * @param broadcastEvent specifies if the superior filter should be fired
   */
  resetSelectedFilters(filterBereich: FilterBereiche, broadcastEvent: boolean): void {
    this.setVariablesForFilterBereich(filterBereich);

    forEach(this.currentData.selectedFilters, (filterItem: IqFilterItem) => {
      switch (filterItem.segmentProperty) {
      case 'datumInfo':
        filterItem.selectedItems = cloneDeep(this.emptyDatumInfo);
        break;
      case 'schlachtgewichte':
      case 'tierAlter':
      case 'tzKalk':
        filterItem.selectedItems = cloneDeep(this.emptyMinMaxObject);
        break;
      default:
        filterItem.selectedItems = [];
      }
      filterItem.activeFilter = false;
    });

    this.localStorageService.set(
      this.currentData.localStorageUebergeordneteFilterKey,
      this.currentData.selectedFilters
    );

    this.uebergeordneteFilterChangedSubject.next({
      publicEvent: broadcastEvent,
      filterBereich: filterBereich,
      selectedFilters: this.currentData.selectedFilters
    });
  }

  /**
   * Resets the selected filters of all filter ranges.
   */
  reset(): void {
    // general data
    this.currentData = null;

    // slaughter data
    this.soData.selectedFilters = [];

    // individual animal identification (fattening) data
    this.etmData.selectedFilters = [];

    // individual animal identification (breed) data
    this.etzData.selectedFilters = [];
  }

  /** ************************ private functions **************************/

  /**
   * Initialize the slaughter data
   */
  private createSoData(): void {
    this.soFilters = [
      {
        titleKey: 'FILTER.GESCHLECHT_FILTER.GESCHLECHTER',
        sortWeight: 1,
        component: GeschlechterFilterComponent,
        segmentProperty: 'geschlechter',
        filterProperty: 'selected-geschlechter',
        selectedItems: [],
        activeFilter: false,
        filterBereich: FilterBereiche.SO
      },
      {
        titleKey: 'FILTER.SCHLACHTBETRIEB_FILTER.SCHLACHTBETRIEBE',
        sortWeight: 2,
        component: SchlachtbetriebeFilterComponent,
        segmentProperty: 'schlachtbetriebe',
        filterProperty: 'selected-schlachtbetriebe',
        selectedItems: [],
        activeFilter: false,
        filterBereich: FilterBereiche.SO
      },
      {
        titleKey: 'FILTER.DATUM_FILTER.DATUM',
        sortWeight: 3,
        component: DatumFilterButtonComponent,
        segmentProperty: 'datumInfo',
        filterProperty: 'datum-info',
        selectedItems: cloneDeep(this.emptyDatumInfo),
        activeFilter: false,
        filterBereich: FilterBereiche.SO
      },
      {
        titleKey: 'FILTER.SCHLACHTGEWICHT_FILTER.SCHLACHTGEWICHT',
        sortWeight: 4,
        component: SchlachtgewichtFilterButtonComponent,
        segmentProperty: 'schlachtgewichte',
        filterProperty: 'schlachtgewichte',
        selectedItems: cloneDeep(this.emptyMinMaxObject),
        activeFilter: false,
        filterBereich: FilterBereiche.SO
      },
      {
        titleKey: 'FILTER.VVVO_FILTER.VVVOS',
        sortWeight: 5,
        component: BetriebsstaettenFilterComponent,
        segmentProperty: 'betriebsstaetten',
        filterProperty: 'selected-betriebsstaetten',
        selectedItems: [],
        activeFilter: false,
        filterBereich: FilterBereiche.SO
      },
      {
        titleKey: 'FILTER.EINSENDERZEICHEN_FILTER.EINSENDERZEICHEN',
        sortWeight: 6,
        component: EinsenderzeichenFilterComponent,
        segmentProperty: 'einsenderzeichen',
        filterProperty: 'selected-einsenderzeichen',
        selectedItems: [],
        activeFilter: false,
        filterBereich: FilterBereiche.SO
      },
      {
        titleKey: 'FILTER.TZ_KALK_FILTER.TZ_KALK',
        sortWeight: 7,
        component: TzKalkFilterButtonComponent,
        segmentProperty: 'tzKalk',
        filterProperty: 'tz-kalk',
        selectedItems: cloneDeep(this.emptyMinMaxObject),
        activeFilter: false,
        filterBereich: FilterBereiche.SO,
        filterDisabled: true
      }
    ];

    this.soData = {
      filters: this.soFilters,
      selectedFilters: [],
      localStorageUebergeordneteFilterKey: LOCALSTORAGE.FILTER.UEBERGEORDNET.SO
    };
  }

  /**
   * Initialize the individual animal identification (fattening) data
   * Uses the slaughter data for this. (SO data){@link UebergeordneteFilterService#soData}.
   */
  private createEtmData(): void {
    this.etmData = {
      filters: cloneDeep(this.soFilters),
      selectedFilters: [],
      localStorageUebergeordneteFilterKey: LOCALSTORAGE.FILTER.UEBERGEORDNET.ETM
    };

    forEach(this.etmData.filters, (filterItem: IqFilterItem) => {
      if (filterItem.filterProperty === 'tz-kalk') {
        filterItem.filterDisabled = false;
      }
      filterItem.filterBereich = FilterBereiche.ETM;
    });
  }

  /**
   * Initialize the individual animal identification (breed) data
   */
  private createEtzData(): void {
    this.etzFilters = [
      {
        titleKey: 'FILTER.GESCHLECHT_FILTER.GESCHLECHTER',
        sortWeight: 1,
        component: GeschlechterFilterComponent,
        segmentProperty: 'geschlechter',
        filterProperty: 'selected-geschlechter',
        selectedItems: [],
        activeFilter: false,
        filterBereich: FilterBereiche.ETZ
      },
      {
        titleKey: 'FILTER.DATUM_FILTER.DATUM',
        sortWeight: 2,
        component: DatumFilterButtonComponent,
        segmentProperty: 'datumInfo',
        filterProperty: 'datum-info',
        selectedItems: cloneDeep(this.emptyDatumInfo),
        activeFilter: false,
        filterBereich: FilterBereiche.ETZ
      },
      {
        titleKey: 'FILTER.SCHLACHTGEWICHT_FILTER.SCHLACHTGEWICHT',
        sortWeight: 3,
        component: SchlachtgewichtFilterButtonComponent,
        segmentProperty: 'schlachtgewichte',
        filterProperty: 'schlachtgewichte',
        selectedItems: cloneDeep(this.emptyMinMaxObject),
        activeFilter: false,
        filterBereich: FilterBereiche.ETZ
      },
      {
        titleKey: 'FILTER.VVVO_FILTER.VVVOS',
        sortWeight: 4,
        component: BetriebsstaettenFilterComponent,
        segmentProperty: 'betriebsstaetten',
        filterProperty: 'selected-betriebsstaetten',
        selectedItems: [],
        activeFilter: false,
        filterBereich: FilterBereiche.ETZ
      },
      {
        titleKey: 'FILTER.TZ_KALK_FILTER.TZ_KALK',
        sortWeight: 5,
        component: TzKalkFilterButtonComponent,
        segmentProperty: 'tzKalk',
        filterProperty: 'tz-kalk',
        selectedItems: cloneDeep(this.emptyMinMaxObject),
        activeFilter: false,
        filterBereich: FilterBereiche.ETZ
      },
      {
        titleKey: 'FILTER.TIER_ALTER_FILTER.ALTER',
        sortWeight: 6,
        component: TierAlterFilterButtonComponent,
        segmentProperty: 'tierAlter',
        filterProperty: 'tier-alter',
        selectedItems: cloneDeep(this.emptyMinMaxObject),
        activeFilter: false,
        filterBereich: FilterBereiche.ETZ
      },
      {
        titleKey: 'FILTER.SAU_RASSE_FILTER.SAU_RASSE',
        sortWeight: 7,
        component: SauRassenFilterComponent,
        segmentProperty: 'sauRassen',
        filterProperty: 'selected-sau-rassen',
        selectedItems: [],
        activeFilter: false,
        filterBereich: FilterBereiche.ETZ
      },
      {
        titleKey: 'FILTER.SAU_NR_FILTER.SAU_NR',
        sortWeight: 8,
        component: SauNummernFilterComponent,
        segmentProperty: 'sauNummern',
        filterProperty: 'selected-sau-nummern',
        selectedItems: [],
        activeFilter: false,
        filterBereich: FilterBereiche.ETZ
      },
      {
        titleKey: 'FILTER.EBER_RASSE_FILTER.EBER_RASSE',
        sortWeight: 9,
        component: EberRassenFilterComponent,
        segmentProperty: 'eberRassen',
        filterProperty: 'selected-eber-rassen',
        selectedItems: [],
        activeFilter: false,
        filterBereich: FilterBereiche.ETZ
      },
      {
        titleKey: 'FILTER.EBER_NR_FILTER.EBER_NR',
        sortWeight: 10,
        component: EberNummernFilterComponent,
        segmentProperty: 'eberNummern',
        filterProperty: 'selected-eber-nummern',
        selectedItems: [],
        activeFilter: false,
        filterBereich: FilterBereiche.ETZ
      },
      {
        titleKey: 'FILTER.WURF_NR_FILTER.WURF_NR',
        sortWeight: 11,
        component: WurfNummernFilterComponent,
        segmentProperty: 'wurfNummern',
        filterProperty: 'selected-wurf-nummern',
        selectedItems: [],
        activeFilter: false,
        filterBereich: FilterBereiche.ETZ
      }
    ];

    this.etzData = {
      filters: this.etzFilters,
      selectedFilters: [],
      localStorageUebergeordneteFilterKey: LOCALSTORAGE.FILTER.UEBERGEORDNET.ETZ
    };
  }

  /**
   * Checks whether the passed section is contained in the constant
   * FilterBereiche and assignes the associated data
   * to the general data.
   * @param filterBereich the specific section (SO, ETM, ...), the filter should be set for.
   */
  private setVariablesForFilterBereich(filterBereich: FilterBereiche): Observable<void> {
    switch (filterBereich) {
    case FilterBereiche.SO:
      this.currentData = this.soData;
      break;

    case FilterBereiche.ETM:
      this.currentData = this.etmData;
      break;

    case FilterBereiche.ETZ:
      this.currentData = this.etzData;
      break;

    default:
      this.currentData = null;
      // throw 'Wrong Filter Bereich';
      return throwError('UebergeordneteFilterService: Wrong Filter Bereich');
    }

    return next();
  }

  /**
   * Sets the passed filter for the passed filter section.
   * @param filters the list of the existing filter of a filter section
   * @param filterBereich the specific section (SO, ETM, ...), the filter should be set for.
   */
  private setFilterBereichForFilters(filters: IqFilterItems, filterBereich: FilterBereiche): void {
    if (Array.isArray(filters)) {
      forEach(filters, (selectedFilter: IqFilterItem) => {
        selectedFilter.filterBereich = filterBereich;
      });
    }
  }

  /**
   * Synchronizes the related selected filters for the passed filter range,
   * if there are related filters for it.
   * @param filterBereich the specific section (SO, ETM, ...), the filter should be set for.
   */
  private synchronizeCoherentSelectedFilters(filterBereich: FilterBereiche): void {
    switch (filterBereich) {
    case FilterBereiche.SO:
      this.etmData.selectedFilters = cloneDeep(this.soData.selectedFilters);
      forEach(this.etmData.selectedFilters, (filterItem: IqFilterItem) => {
        if (filterItem.filterProperty === 'tz-kalk') {
          filterItem.filterDisabled = false;
        }
      });
      this.setFilterBereichForFilters(this.etmData.selectedFilters, FilterBereiche.ETM);
      this.localStorageService.set(this.etmData.localStorageUebergeordneteFilterKey, this.etmData.selectedFilters);
      break;

    case FilterBereiche.ETM:
      this.soData.selectedFilters = cloneDeep(this.etmData.selectedFilters);
      forEach(this.soData.selectedFilters, (filterItem: IqFilterItem) => {
        if (filterItem.filterProperty === 'tz-kalk') {
          filterItem.filterDisabled = true;
        }
      });
      this.setFilterBereichForFilters(this.soData.selectedFilters, FilterBereiche.SO);
      this.localStorageService.set(this.soData.localStorageUebergeordneteFilterKey, this.soData.selectedFilters);
      break;

    default:
    }
  }

  /**
   * Indicates whether the selectedItems of the passed filter have not yet been set.
   * @param filterItem the filter
   */
  private hasEmptyItems(filterItem: IqFilterItem): boolean {
    switch (filterItem.segmentProperty) {
    case 'schlachtgewichte':
    case 'tierAlter':
    case 'tzKalk':
      const range = <MinMax>filterItem.selectedItems;
      return range.min == null && range.max == null;

    case 'datumInfo':
      const datumInfo = <DatumInfo>filterItem.selectedItems;
      return (
        datumInfo.mode == null &&
          datumInfo.zeitraum == null &&
          datumInfo.schlachttage == null &&
          datumInfo.letzteXTage == null
      );

    default:
      const selectedItems = <FilterListTypes>filterItem.selectedItems;
      return selectedItems.length === 0;
    }
  }

  /**
   * Subscribes to the (uebergeordneteFilterChanged subject){@link UebergeordneteFilterService#uebergeordneteFilterChangedSubject},
   * in order to be aware of any change in the SO data and to synchronize the ETM data.
   * @param filterBereich the specific section (SO, ETM, ...), the filter should be set for.
   */
  private subscribeOnSoDataForSynchronizing(filterBereich: FilterBereiche): void {
    this.uebergeordneteFilterChangedSubject
      .asObservable()
      .pipe(
        rxjsFilter((event: UebergeordneteFilterChangedEvent) => event.filterBereich === filterBereich)
      )
      .subscribe({
        next: (event: UebergeordneteFilterChangedEvent) => {
          if (this.router.isActive('portal/schlachtdaten', {
            paths: 'subset',
            queryParams: 'subset',
            fragment: 'ignored',
            matrixParams: 'ignored'
          })) {
            if (!isEqual(this.soData.selectedFilters, this.etmData.selectedFilters)) {
              this.synchronizeCoherentSelectedFilters(FilterBereiche.SO);
            }
          }
        }
      });
  }

  /**
   * Subscribes to the (uebergeordneteFilterChanged subject){@link UebergeordneteFilterService#uebergeordneteFilterChangedSubject},
   * in order to be aware of any change in the ETM data and to synchronize the SO data.
   * @param filterBereich the specific section (SO, ETM, ...), the filter should be set for.
   */
  private subscribeOnEtmDataForSynchronizing(filterBereich: FilterBereiche): void {
    this.uebergeordneteFilterChangedSubject
      .asObservable()
      .pipe(
        rxjsFilter((event: UebergeordneteFilterChangedEvent) => event.filterBereich === filterBereich)
      )
      .subscribe({
        next: (event: UebergeordneteFilterChangedEvent) => {
          if (this.router.isActive('portal/etm', {
            paths: 'subset',
            queryParams: 'subset',
            fragment: 'ignored',
            matrixParams: 'ignored'
          })) {
            if (!isEqual(this.soData.selectedFilters, this.etmData.selectedFilters)) {
              this.synchronizeCoherentSelectedFilters(FilterBereiche.ETM);
            }
          }
        }
      });
  }
}
