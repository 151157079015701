/**
 * Defines the possible modes for the IQ Date filter
 */
export enum DatumFilterMode {
  ZEITRAUM = 'Zeitraum',
  SCHLACHTTAGE = 'Schlachttage',
  LETZTE_X_TAGE = 'Letzte_X_Tage'
}

/**
 * Defines the possible modes for the IQ-Date filter in the 'Last X days' section.
 */
export enum LetzteXTageMode {
  LETZTE_30_TAGE = 'Letzte30Tage',
  LETZTE_90_TAGE = 'Letzte90Tage',
  LETZTE_180_TAGE = 'Letzte180Tage',
  LETZTE_365_TAGE = 'Letzte365Tage',
  FREIE_EINGABE = 'FreieEingabe'
}

/**
 * Defines the different time periods for the period filter of the delivery lot overview.
 */
export enum Zeitraeume {
  VORHERHIGER_MONAT = 'VorherigerMonat',
  LETZTES_HALBES_JAHR = 'LetztesHalbesJahr',
  LETZTES_JAHR = 'LetztesJahr',
  LETZTE_3_MONATE = 'Letzte3Monate',
  ALLE = 'Alle'
}
