<iq-multiselect-button
  [items]="sauRassen"
  [selectedItems]="selectedItems"
  [activeItems]="activeSauRassen"
  [settings]="settings"
  [buttonDisabled]="filterDisabled"
  (multiselectClosed)="onMultiselectClosed($event)"
  (multiselectChanged)="onMultiselectChanged($event)"
>
</iq-multiselect-button>
