import { Component, OnDestroy, isDevMode } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { setTheme } from 'ngx-bootstrap/utils';
import { AuthService } from '@core/auth/auth.service';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { LoggingService } from '@core/logging/logging.service';
import { deLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { LicencesService } from '@core/licences/licences.service';
import { Subject } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { CurrentUser } from '@core/user/user.interfaces';
import { isNil } from 'lodash-es';
import { ThemeService } from '@iq-angular-libs/portal';
import { loadUserTheme } from '@core/theme/theme-helpers';
import { PortalThemeColors } from '@core/theme/theme.interfaces';
import { LOCALSTORAGE } from '@core/local-storage/local-storage.constants';
import { LocalStorageService } from '@mattlewis92/angular-2-local-storage';
import { DEFAULT_LANG, SUPPORTED_LANGS, SUPPORTED_LANGS_ENUM } from '@core/translate/translation-const';
@Component({
  selector: 'iq-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  /**
   * Die Farbe der ngx-loading-bar
   */
  ngxLoadingBarColor: string;

  /** Unsubscribe Stream */
  unsubscribe$ = new Subject<void>();

  /**
   * Konstruktor.
   * Setzt deutsch als Default-Sprace.
   * Setzt das Bootstrap 4 Theme.
   * Initialisiert den LogginService und den AuthService.
   * @param translate {@link TranslateService}
   * @param authService {@link AuthService}
   * @param loggingService {@link LoggingService}
   * @param licencesService {@link LicencesService}
   * @param themeService {@link ThemeService}
   */
  constructor(
    private translate: TranslateService,
    private authService: AuthService,
    private loggingService: LoggingService,
    private licencesService: LicencesService,
    private themeService: ThemeService<PortalThemeColors>,
    private localStorageService: LocalStorageService
  ) {
    let lang = this.localStorageService.get<string>(LOCALSTORAGE.LANG) || DEFAULT_LANG;
    if (!SUPPORTED_LANGS.includes(lang)) {
      lang = DEFAULT_LANG;
    }
    translate.addLangs(SUPPORTED_LANGS);
    translate.setDefaultLang(DEFAULT_LANG);
    translate.use(lang);

    this.translate.onLangChange
      .asObservable()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params: LangChangeEvent) => {
        this.localStorageService.set(LOCALSTORAGE.LANG, params.lang);
      });

    registerLocaleData(localeDe); // Angular
    defineLocale(lang, deLocale); // ngx-bootstap

    setTheme('bs4');

    this.ngxLoadingBarColor = this.themeService.getActiveThemeColors().primarySchmuckfarbe;

    this.themeService
      .getThemeChangedObservable()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.ngxLoadingBarColor = this.themeService.getActiveThemeColors().primarySchmuckfarbe;
      });

    this.loggingService.init();

    authService
      .fillAuthData()
      .pipe(
        take(1),
        filter((currentUser: CurrentUser) => !isNil(currentUser)),
        tap((currentUser: CurrentUser) => loadUserTheme(this.themeService, currentUser.userProperties))
      )
      .subscribe();

    // lade beim Laden der App die Lizenztexte, damit diese immer mit ausgeliefert werden.
    if (!isDevMode()) {
      this.licencesService.getLicencesText().pipe(take(1)).subscribe();
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
