import { Pipe, PipeTransform } from '@angular/core';
import { isArray } from 'lodash-es';

/**
 * This pipe creates a comma separated list
 * from string arrays.
 */
@Pipe({
  name: 'commaSeparated'
})
export class CommaSeparatedPipe implements PipeTransform {
  /**
   * Checks if the given values is an array. If true, the content
   * will be join with a comma.
   * Else the given value will be returned.
   * @param value the given array
   */
  transform(value: any[]): any {
    if (!Array.isArray(value)) {
      return value;
    }
    return value.join(', ');
  }
}
