import { Component, Inject } from '@angular/core';
import { WINDOW } from '@core/constants';

@Component({
  selector: 'iq-new-backend-modal',
  templateUrl: './new-backend-modal.component.html'
})
export class NewBackendModalComponent {
  constructor(@Inject(WINDOW) private window: Window) {}

  /**
   * Performs a reload of the single-page-application
   */
  reloadPage(): void {
    this.window.location.reload();
  }
}
