import { SessionService } from './../session/session.service';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@mattlewis92/angular-2-local-storage';
import { LOCALSTORAGE } from '../local-storage/local-storage.constants';
import { ResetService } from '../reset/reset.service';
import { LoginData } from '../../login/share/login-entities';
import { DataService } from '../data/data.service';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { UserService } from '../user/user.service';
import { CurrentUser } from '../user/user.interfaces';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError } from 'rxjs';
import { AuthLocalStorageData } from './auth.entities';
import { isEmpty } from 'lodash-es';
import { ThemeService } from '@iq-angular-libs/portal';
import { Router } from '@angular/router';
import { AuthService as IAuthService, LoginBodyData, LoginResponse } from '@core/auth/auth.interfaces';
import { PortalThemeColors } from '@core/theme/theme.interfaces';

@Injectable()
export class AuthService implements IAuthService {
  isLoggedIn: boolean;

  /**
   * Descibes the path where the user should be redirected to
   */
  redirectTo: string;

  private serviceBase = 'backend/api/authentication/';

  /**
   * Constructor.
   * Sets [isLoggedIn]{@link AuthService#isLoggedIn} to false.
   * Sets [redirectTo]{@link AuthService#redirectTo} the startpage path.
   * Subscribs to the [sessionActive]{@link SessionService#sessionActive} event with.
   * the help of the [onSessionInactive]{@link SessionService#onSessionInactive} function.
   */
  constructor(
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrService,
    private localStorageService: LocalStorageService,
    private resetService: ResetService,
    private dataService: DataService,
    private userService: UserService,
    private sessionService: SessionService,
    private themeService: ThemeService<PortalThemeColors>
  ) {
    this.isLoggedIn = false;
    this.redirectTo = '/portal/startseite';
    this.sessionService.sessionActive
      .asObservable()
      .pipe(filter(isSessionActive => isSessionActive === false))
      .subscribe(() => this.onSessionInactive());
  }

  checkIfAlreadyLoggedIn(): boolean {
    const authData = this.localStorageService.get<AuthLocalStorageData>(LOCALSTORAGE.AUTHORIZATION_DATA);
    return this.isLoggedIn && authData && !isEmpty(authData.token);
  }

  /**
   * Gets the auth token from the localstorage.
   * If it exists `isLoggedIn` from the SessionService will be set to true.
   * The session will be activated.
   */
  fillAuthData(): Observable<CurrentUser> {
    const authData = this.localStorageService.get<AuthLocalStorageData>(LOCALSTORAGE.AUTHORIZATION_DATA);

    if (!authData || isEmpty(authData.token)) {
      return of(undefined);
    }

    this.isLoggedIn = true;
    this.sessionService.sessionActive.next(true);

    // get currentUser when a user is logged in
    return this.userService.getCurrentUser().pipe(
      take(1),
      catchError(() => of(undefined))
    );
  }

  /**
   * Performs the login and return the data from the current user.
   */
  login(loginData: LoginData): Observable<CurrentUser> {
    this.resetService.reset(loginData.userName);
    const data: LoginBodyData = {
      Benutzername: loginData.userName,
      Passwort: loginData.password
    };
    return this.dataService.postDataWithParameters<LoginResponse>(this.serviceBase + 'login', data, {}).pipe(
      switchMap(response => {
        if (response.MustChangePassword) {
          this.localStorageService.set(LOCALSTORAGE.MUST_CHANGE_PASSWORD, response.MustChangePassword);
        }
        if (response.MustValidateEmail) {
          this.localStorageService.set(LOCALSTORAGE.MUST_VALIDATE_EMAIL, response.MustValidateEmail);
        }
        this.isLoggedIn = true;
        return this.userService.getCurrentUser();
      }),
      take(1),
      tap(user => {
        this.localStorageService.set(LOCALSTORAGE.CURRENT_USER_ID, user.Id);
      }),
      catchError((err, status) => {
        if (err && err.headers && err.headers.get('ErrorCode') === '0012') {
          this.toastr.info(
            this.translate.instant('EXCEPTIONS.0012'),
            this.translate.instant('ALLGEMEIN.TOASTS.HINWEIS')
          );
        }
        this.isLoggedIn = false;
        return throwError(err);
      })
    );
  }

  /**
   * Log out the user
   */
  logout(): void {
    const defaultThemeName = this.themeService.getDefaultThemeName();
    this.themeService.setActiveTheme(defaultThemeName);

    this.resetService.reset();
    this.isLoggedIn = false;
    this.router.navigate(['/login']);
  }

  /**
   * Will be called when the `sessionActive` Event emits false. Performs a loggout.
   */
  private onSessionInactive(): void {
    this.logout();
  }
}
