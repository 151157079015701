import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'qsCategory'
})
export class QsCategoryPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  /**
   * Transforms the QS (quality assurance) category
   * @param kategorie the QS (quality assurance) category
   * @param args optional arguments
   */
  transform(kategorie: number, args?: any): number | string {
    switch (kategorie) {
    case -1:
      return this.translate.instant('SALMONELLENDATEN.KATEGORIE.NOCH_KEINE');
    case 0:
      return this.translate.instant('SALMONELLENDATEN.KATEGORIE.KEINE');
    default:
      return kategorie;
    }
  }
}
