<svg:g *ngIf="circle">
  <defs>
    <svg:g ngx-charts-svg-linear-gradient orientation="vertical" [name]="gradientId" [stops]="circle.gradientStops" />
  </defs>
  <svg:rect
    *ngIf="barVisible && type === 'standard'"
    [@animationState]="'active'"
    [attr.x]="circle.cx - circle.radius"
    [attr.y]="circle.cy"
    [attr.width]="circle.radius * 2"
    [attr.height]="circle.height"
    [attr.fill]="gradientFill"
    class="tooltip-bar"
  />
  <svg:g
    ngx-charts-circle
    class="circle"
    [cx]="circle.cx"
    [cy]="circle.cy"
    [r]="circle.radius"
    [fill]="circle.color"
    [class.active]="isActive({ name: circle.seriesName })"
    [pointerEvents]="circle.value === 0 ? 'none' : 'all'"
    [data]="circle.value"
    [classNames]="circle.classNames"
    (select)="onClick($event, circle.label)"
    ngx-tooltip
    [tooltipDisabled]="tooltipDisabled"
    [tooltipPlacement]="'top'"
    [tooltipType]="'tooltip'"
    [tooltipTitle]="tooltipTemplate ? undefined : getTooltipText(circle)"
    [tooltipTemplate]="tooltipTemplate"
    [tooltipContext]="circle.data"
  />
</svg:g>
