import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { AnzahlTage } from '@core/constants';
import { UserPropertiesBereiche } from '@core/user/user.enums';
import { CurrentUser } from '@core/user/user.interfaces';
import { UserService } from '@core/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { ISlimScrollEvent, SlimScrollEvent } from 'ngx-slimscroll';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Widget, WidgetComponent } from '../share/startseite.interfaces';
import { RegistrationNumber } from '../share/widgets.interfaces';
import { WidgetsService } from '../share/widgets.service';

@Component({
  selector: 'iq-registered-unregistered-vvvos-widget',
  templateUrl: './registered-unregistered-vvvos.component.html',
  styleUrls: ['./registered-unregistered-vvvos.component.scss']
})
export class RegisteredUnregisteredVVVOsWidgetComponent implements WidgetComponent, OnInit, OnDestroy {
  /**
   * The structure information of the widget.
   */
  @Input()
  widget: Widget;

  /**
   * Indicates whether the data will be loaded.
   */
  dataLoading = false;

  /**
   * The error message in case of incorrect loading of the data.
   */
  errMessage: string;

  /**
   * Specifies whether the options should be displayed.
   */
  showOptions = false;

  /**
   * Used for the animation of the options area.
   */
  optionsOpen = false;

  /**
   * Indicates which tab is selected.
   */
  selectedTab = 0;

  /**
   * Unsusbscribe-Stream.
   */
  unsubscribe$ = new Subject<void>();

  /**
   * Specifies the number of days in the span of which the QA approvals expire.
   */
  anzahlTage: AnzahlTage = AnzahlTage.Dreissig;

  /**
   * List of translated tab names
   */
  tabs: string[] = [
    this.translate.instant('WIDGETS.REGISTERED_UNREGISTERED_VVVOS.UNREGISTERED_VVVOS_TAB'),
    this.translate.instant('WIDGETS.REGISTERED_UNREGISTERED_VVVOS.REGISTERED_VVVOS_TAB'),
  ];

  /**
   * Registration number list received from the server
   */
  data: RegistrationNumber[] = [];

  /**
   * Event emitter for the SlimScroll component.
   */
  slimScrollEvents = new EventEmitter<ISlimScrollEvent>();

  /**
   * The current user.
   */
  currentUser: CurrentUser;

  constructor(
    private widgetsService: WidgetsService,
    private userService: UserService,
    private translate: TranslateService
  ) {
    userService
      .getCurrentUser()
      .pipe(take(1))
      .subscribe((currentUser: CurrentUser) => {
        if (
          currentUser &&
          currentUser.userProperties.widgets
        ) {
          this.currentUser = currentUser;
          this.anzahlTage =
            currentUser.userProperties.widgets?.AnUndAbgemeldeteBetriebeWidgetAnzahlTage || AnzahlTage.Dreissig;
        }
      });
  }

  /**
   * Triggers obtaining the data.
   */
  ngOnInit() {
    this.getData();

    this.widgetsService
      .getWidgetItemResizedObservable()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(event => event.widget.id === this.widget.id)
      )
      .subscribe({
        next: () => {
          const event = new SlimScrollEvent({
            type: 'recalculate'
          });

          this.slimScrollEvents.emit(event);
        }
      });
  }

  /**
   * Completes the Unsubscribe-Stream
   */
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Sets the selected tab and adjusts the selected ProductProgramList accordingly.
   * @param event Click-Event
   * @param selectedTab The selected tab index (0 or 1)
   */
  selectTab(event: Event, selectedTab: number) {
    event.preventDefault();
    this.selectedTab = selectedTab;
    this.getData();
  }

  /**
   * Called as soon as in the options change the number of days.
   * @param anzahlTage The selected number of days
   */
  anzahlTageChanged(anzahlTage: AnzahlTage) {
    this.anzahlTage = anzahlTage;
    if (this.currentUser) {
      this.currentUser.userProperties.widgets.AnUndAbgemeldeteBetriebeWidgetAnzahlTage = anzahlTage;
      this.userService
        .postUserproperties(UserPropertiesBereiche.WIDGETS, this.currentUser.userProperties)
        .pipe(take(1))
        .subscribe();
    }
    this.getData();
  }

  /**
   * Gets the data for registered/unregistered commercial units
   */
  getData() {
    this.widgetsService.getListOfRegistrationNumbers(
      this.selectedTab === 0, this.anzahlTage
    ).subscribe((data: RegistrationNumber[]) => {
      this.data = data;
    });
  }
}
