import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'yesNo'
})
export class YesNoPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: boolean, args?: any): any {
    if (value === true) {
      return this.translate.instant('ALLGEMEIN.JA');
    }

    if (value === false) {
      return this.translate.instant('ALLGEMEIN.NEIN');
    }

    return value;
  }
}
