import { Injectable } from '@angular/core';
import { cloneDeep, merge } from 'lodash-es';
import { UserService } from '@core/user/user.service';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { FilterService } from '@share/filter/filter.service';
import { FilterBereiche } from '@share/filter/filter-bereiche';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { forkJoin, Observable, Subject, throwError } from 'rxjs';
import { LocalStorageService } from '@mattlewis92/angular-2-local-storage';
import { LOCALSTORAGE } from '@core/local-storage/local-storage.constants';
import {
  GesetzteFelder,
  GesperrteFelder,
  VergleichOptionen,
  VersteckteFelder
} from '@share/vergleichswerte-bearbeiten/vergleichswerte-bearbeiten-modal.interfaces';
import { VergleichswerteBearbeitenModalComponent } from '@share/vergleichswerte-bearbeiten/vergleichswerte-bearbeiten-modal.component';
import { UserPropertiesBereiche } from '@core/user/user.enums';
import { next } from '@core/next-observable';
import { CustomModalService } from '@share/custom-modal.service';

export interface IVergleichswerteService {
  showSoVergleichswerteModal: (
    gesperrteFelder: GesperrteFelder,
    versteckteFelder: VersteckteFelder,
    gesetzteFelder: GesetzteFelder,
    customVergleichsoptionen?: any
  ) => Observable<VergleichOptionen | null>;

  showEtkVergleichswerteModal: (
    gesperrteFelder: GesperrteFelder,
    versteckteFelder: VersteckteFelder,
    gesetzteFelder: GesetzteFelder,
    filterBereich: FilterBereiche.ETM | FilterBereiche.ETZ,
    customVergleichsoptionen?: any
  ) => Observable<VergleichOptionen | null>;
}

@Injectable()
export class VergleichswerteService implements IVergleichswerteService {
  constructor(
    private userService: UserService,
    private filterService: FilterService,
    private modalService: CustomModalService,
    private localStorageService: LocalStorageService
  ) {}

  /**
   * Opens the modal dialog for editing the comparison value options.
   * The changes will be saved in the backend upon confirmation.
   *
   * @params gesperrteFelder - the fields, which should be locked
   * @params versteckteFelder - the field, which should be hidden
   * @params gesetzteFelder - the field, which should be set with specific values. They are then also automatically locked.
   */
  showSoVergleichswerteModal(
    gesperrteFelder: GesperrteFelder,
    versteckteFelder: VersteckteFelder,
    gesetzteFelder: GesetzteFelder,
    customVergleichsoptionen?: VergleichOptionen
  ): Observable<VergleichOptionen | null> {
    const subject = new Subject<VergleichOptionen | null>();

    this.userService
      .getCurrentUser()
      .pipe(take(1))
      .subscribe(currentUser => {
        forkJoin([
          this.filterService.getVergleichsSchlachtbetriebId(FilterBereiche.SO),
          this.filterService.getActiveSchlachtbetriebe(FilterBereiche.SO)
        ]).subscribe(data => {
          const defaultVergleichsoptionen: VergleichOptionen = {
            schlachtbetriebid: data[0],
            prozent: currentUser.userProperties.so.VergleichsoptionenProzent,
            vergleichswerteAusblenden: gesetzteFelder.vergleichswerteAusblenden
          };
          const vergleichsoptionen = merge({}, defaultVergleichsoptionen, customVergleichsoptionen);

          const modalOptions: ModalOptions<VergleichswerteBearbeitenModalComponent> = {
            backdrop: 'static',
            initialState: {
              vergleichsoptionen: cloneDeep(vergleichsoptionen),
              schlachtbetriebe: data[1],
              gesperrteFelder: gesperrteFelder ? gesperrteFelder : {},
              versteckteFelder: versteckteFelder ? versteckteFelder : {},
              gesetzteFelder: gesetzteFelder ? gesetzteFelder : {},
              filterBereich: FilterBereiche.SO
            }
          };

          const modalRef = this.modalService.show(VergleichswerteBearbeitenModalComponent, modalOptions);
          let newSelectedVergleichsoptionen: VergleichOptionen;

          modalRef.content.onClose$
            .asObservable()
            .pipe(
              take(1),
              switchMap((newVergleichsoptionen: VergleichOptionen) => {
                if (!newVergleichsoptionen) {
                  return throwError(new Error('no vergleichsoptionen'));
                }

                newSelectedVergleichsoptionen = newVergleichsoptionen;
                this.localStorageService.set(
                  LOCALSTORAGE.VERGLEICHS_SCHLACHTBETRIEB_ID + '.' + FilterBereiche.SO,
                  (newVergleichsoptionen as VergleichOptionen).schlachtbetriebid
                );

                currentUser.userProperties.so.VergleichsoptionenProzent = (newVergleichsoptionen as VergleichOptionen).prozent;
                return this.userService.postUserproperties(UserPropertiesBereiche.SO, currentUser.userProperties);
              }),
              tap(() => {
                subject.next(newSelectedVergleichsoptionen);
                subject.complete();
              }),
              catchError(err => {
                subject.next(null);
                subject.complete();
                return next();
              })
            )
            .subscribe();
        });
      });

    return subject.asObservable();
  }

  /**
   * Opens the modal dialog for editing the comparison value options for ETK.
   * The changes will be saved in the backend upon confirmation.
   *
   * @params gesperrteFelder - the fields, which should be locked
   * @params versteckteFelder - the field, which should be hidden
   * @params gesetzteFelder - the field, which should be set with specific values. They are then also automatically locked.
   */
  showEtkVergleichswerteModal(
    gesperrteFelder: GesperrteFelder,
    versteckteFelder: VersteckteFelder,
    gesetzteFelder: GesetzteFelder,
    filterBereich: FilterBereiche.ETM | FilterBereiche.ETZ,
    customVergleichsoptionen?: VergleichOptionen
  ): Observable<VergleichOptionen | null> {
    const subject = new Subject<VergleichOptionen | null>();

    this.userService
      .getCurrentUser()
      .pipe(take(1))
      .subscribe(currentUser => {
        const defaultVergleichsoptionen: VergleichOptionen = {
          prozent: 100,
          vergleichswerteAusblenden: gesetzteFelder.vergleichswerteAusblenden
        };

        const vergleichsoptionen = merge({}, defaultVergleichsoptionen, customVergleichsoptionen);

        const modalOptions: ModalOptions<VergleichswerteBearbeitenModalComponent> = {
          backdrop: 'static',
          initialState: {
            vergleichsoptionen: cloneDeep(vergleichsoptionen),
            schlachtbetriebe: [],
            gesperrteFelder: gesperrteFelder ? gesperrteFelder : {},
            versteckteFelder: versteckteFelder ? versteckteFelder : {},
            gesetzteFelder: gesetzteFelder ? gesetzteFelder : {},
            filterBereich: filterBereich
          }
        };

        const modalRef = this.modalService.show(VergleichswerteBearbeitenModalComponent, modalOptions);

        modalRef.content.onClose$
          .asObservable()
          .pipe(
            take(1),
            switchMap((newVergleichsoptionen: VergleichOptionen) => {
              if (!newVergleichsoptionen) {
                return throwError(new Error('no vergleichsoptionen'));
              }

              subject.next(newVergleichsoptionen);
              subject.complete();
              return next();
            }),
            catchError(err => {
              subject.next(null);
              subject.complete();
              return next();
            })
          )
          .subscribe();
      });

    return subject.asObservable();
  }
}
