import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LocalStorageModule } from '@mattlewis92/angular-2-local-storage';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CoreModule } from '@core/core.module';
import { LocalStorageNotSupportedComponent } from './local-storage-not-supported/local-storage-not-supported.component';
import { LoginModule } from './login/login.module';
import { ToastrModule } from 'ngx-toastr';
import { AuthInterceptor } from '@core/auth/auth.interceptor';
import { LOCALSTORAGE } from '@core/local-storage/local-storage.constants';
import { ErrorComponent } from './error/error.component';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule as BsTooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ErrorInterceptor } from '@core/error.interceptor';
import { WINDOW } from '@core/constants';
import { HttpTranslateLoaderFactory } from '@core/http-translate-loader-factory';
import { getActivePortalThemeName, getPortalThemes } from '@core/theme/themes/themes';
import { PortalThemeColors } from '@core/theme/theme.interfaces';
import { TableModule, ThemeModule } from '@iq-angular-libs/portal';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import de from '@angular/common/locales/de';

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent, LocalStorageNotSupportedComponent, ErrorComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LocalStorageModule.forRoot({
      prefix: LOCALSTORAGE.PREFIX,
      storageType: 'localStorage'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpTranslateLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    LoadingBarHttpClientModule,
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    CollapseModule.forRoot(),
    BsTooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    PopoverModule.forRoot(),
    TabsModule.forRoot(),
    ToastrModule.forRoot({
      closeButton: true,
      progressBar: true,
      progressAnimation: 'decreasing',
      preventDuplicates: true,
      maxOpened: 2
    }),
    ThemeModule.forRoot<PortalThemeColors>({ themes: getPortalThemes, active: getActivePortalThemeName }),
    CoreModule.forRoot(),
    TableModule.forRoot(),
    LoginModule,
    AppRoutingModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: WINDOW, useValue: window },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  exports: [TranslateModule]
})
export class AppModule {
  constructor() {
    registerLocaleData(de);
  }
}
