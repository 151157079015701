import { DecimalPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { AnzahlTage } from '@core/constants';
import { UserPropertiesBereiche } from '@core/user/user.enums';
import { CurrentUser } from '@core/user/user.interfaces';
import { UserService } from '@core/user/user.service';
import { extractUniqueRegistrierungsnummern, SortConfig } from '@iq-angular-libs/portal';
import { getTranslationKeyForProduktgruppe } from '@share/helper-functions';
import { ISlimScrollEvent, SlimScrollEvent } from 'ngx-slimscroll';
import { Subject } from 'rxjs';
import { filter, finalize, take, takeUntil } from 'rxjs/operators';
import { Widget, WidgetComponent } from '../share/startseite.interfaces';
import { QsMaengelbeseitgungsWidgetData } from '../share/widgets.interfaces';
import { WidgetsService } from '../share/widgets.service';

@Component({
  selector: 'iq-qs-maengelbeseitigung-widget',
  templateUrl: './qs-maengelbeseitigung-widget.component.html',
  styleUrls: ['./qs-maengelbeseitigung-widget.component.scss']
})
export class QsMaengelbeseitigungWidgetComponent implements OnInit, OnDestroy, WidgetComponent {
  /** The structure information of the widget */
  @Input()
  widget: Widget;

  /** Indicates whether the data is loaded */
  dataLoading: boolean;

  /** The widget data  */
  qsMaengelbeseitigungData: QsMaengelbeseitgungsWidgetData;

  /** The number of days in which deadlines expire */
  anzahlTage: AnzahlTage = AnzahlTage.Neunzig;

  /** The current user */
  currentUser: CurrentUser;

  /** The registration numbers of the comerical units  */
  betriebsstaettenRegistrierungsnummern: string[];

  /** Describes the sorting of the QS overview table */
  sortConfig: SortConfig = {
    sortFields: [
      {
        sortType: 'BesitzerName',
        sortOrder: 'asc'
      }
    ]
  };

  /** Helper function to determine the translation keys for the product group */
  getTranslationKeyForProduktgruppe = getTranslationKeyForProduktgruppe;

  /** The values for the hint text */
  translateValues: { maengelImZeitraum: string; maengelGesamt: string; anzahlTage: string };

  /** The error message in case of error */
  errMessage: string;

  /**
   * Event emitter for the SlimScroll component.
   */
  slimScrollEvents = new EventEmitter<ISlimScrollEvent>();

  /**
   * Unsusbscribe-Stream.
   */
  unsubscribe$ = new Subject<void>();

  /**
   * Constructor
   * @param widgetsService {@link WidgetsService}
   * @param userService {@link UserService}
   */
  constructor(
    private widgetsService: WidgetsService,
    private userService: UserService,
    private decimalPipe: DecimalPipe
  ) {}

  /**
   * Queries the current user and updates the widget data.
   */
  ngOnInit() {
    this.userService
      .getCurrentUser()
      .pipe(
        take(1),
        finalize(() => {
          this.getData();
        })
      )
      .subscribe({
        next: currentUser => {
          if (currentUser && currentUser.userProperties.widgets) {
            this.currentUser = currentUser;
            this.anzahlTage = currentUser.userProperties.widgets.QSMaengelbeseitigungWidgetAnzahlTage;
          }
        },
        error: () => {}
      });

    this.widgetsService
      .getWidgetItemResizedObservable()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(event => event.widget.id === this.widget.id)
      )
      .subscribe({
        next: () => {
          const event = new SlimScrollEvent({
            type: 'recalculate'
          });

          this.slimScrollEvents.emit(event);
        }
      });
  }

  /**
   * Next's and completes the unsubscribe$ stream
   */
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Called as soon as the option component emits a number-day change.
   *
   * @param anzahlTage The new selected number of days
   */
  onAnzahlTageChange(anzahlTage: AnzahlTage) {
    this.anzahlTage = anzahlTage;
    if (this.currentUser) {
      this.currentUser.userProperties.widgets.QSMaengelbeseitigungWidgetAnzahlTage = anzahlTage;
      this.userService
        .postUserproperties(UserPropertiesBereiche.WIDGETS, this.currentUser.userProperties)
        .pipe(take(1))
        .subscribe();
    }
    this.getData();
  }

  /**
   * Fetches the widget data from the BE.
   */
  private getData() {
    this.dataLoading = true;
    this.errMessage = undefined;
    this.qsMaengelbeseitigungData = {
      AnzahlOffeneMaengelGesamt: 0,
      OffeneMaengelImZeitraum: []
    };

    this.widgetsService
      .getQsMaengelbeseitigungWidgetData(this.anzahlTage)
      .pipe(
        take(1),
        finalize(() => {
          this.dataLoading = false;
        })
      )
      .subscribe({
        next: (data: QsMaengelbeseitgungsWidgetData) => {
          if (data) {
            this.qsMaengelbeseitigungData = data;
            this.betriebsstaettenRegistrierungsnummern = extractUniqueRegistrierungsnummern(
              data.OffeneMaengelImZeitraum
            );
            this.translateValues = {
              anzahlTage: this.decimalPipe.transform(this.anzahlTage, '1.0-0'),
              maengelGesamt: this.decimalPipe.transform(data.AnzahlOffeneMaengelGesamt, '1.0-0'),
              maengelImZeitraum: this.decimalPipe.transform(data.OffeneMaengelImZeitraum.length, '1.0-0')
            };
          }
        },
        error: (err: HttpErrorResponse) => {
          this.errMessage = 'FEHLER.DATEN_NICHT_GELADEN';
          console.error('Fehler: QS-Mängelbeseitigung Widget: ' + err.message);
        }
      });
  }
}
